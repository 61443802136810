<div>
    <div class="modal-body">
        <p>{{ modalContent }}</p>

    </div>

    <div class="modal-footer">
        <button mat-button class="btn btn-save" (click)="dialogClose()">
            OK
        </button>
    </div>
</div>