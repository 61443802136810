import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NaturalQueryEngineRoutingModule } from "./natural-query-engine-routing.module";
import { NaturalQueryEnginePageModule } from "./natural-query-engine-page/natural-query-engine-page.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NaturalQueryEngineRoutingModule,
    NaturalQueryEnginePageModule,
  ],
})
export class NaturalQueryEngineModule {}
