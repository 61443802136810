import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AdminSettingsRoutingModule } from "./admin-settings-routing.module";
import { AdminSettingsPageComponent } from "./admin-settings-home-page/admin-settings-page.component";
import { AdminSettingsPageModule } from "./admin-settings-home-page/admin-settings-page.module";

@NgModule({
  declarations: [],
  imports: [CommonModule, AdminSettingsRoutingModule, AdminSettingsPageModule],
  exports: [],
})
export class AdminSettingsModule {}
