<section id="hero">
    <div class="content">
        <div class="chat-window">

            <div style="float: left;" (click)="navigateBack()">
                <img src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" class="dashboard-back-icon">
            </div>
            <h1>Analytics</h1>

            <div class="row" *ngIf="!service.isLoading">
                <div class="col-12">
                    <mat-tab-group [selectedIndex]="selectedTab.value"
                        (selectedIndexChange)="selectedTab.setValue($event)">
                        <mat-tab [label]="'Model Usage Chart'">
                            <div class="col-12">
                                <highcharts-chart style="width: 100%; height: 400px; display: block; margin-top: 15px;"
                                    [Highcharts]="Highcharts" [options]="service.usageChartOptions" class="usage-chart">
                                </highcharts-chart>
                            </div>
                        </mat-tab>

                        <mat-tab [label]="'Cost Consumption Chart'">
                            <div class="col-12">
                                <highcharts-chart style="width: 100%; height: 400px; display: block; margin-top: 15px;"
                                    [Highcharts]="Highcharts" [options]="service.costConsumptionChartOptions"
                                    class="cost-consumption-chart">
                                </highcharts-chart>
                            </div>
                        </mat-tab>

                        <mat-tab [label]="'Number of Logins Chart'">
                            <div class="col-12">
                                <highcharts-chart style="width: 100%; height: 400px; display: block; margin-top: 15px;"
                                    [Highcharts]="Highcharts" [options]="service.loginAttemptChartOptions"
                                    class="user-login-chart">
                                </highcharts-chart>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>

            <div role="status" *ngIf="service.isLoading">
                <app-loader></app-loader>
            </div>

        </div>
    </div>
</section>