import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PdfComparisionRoutingModule } from "./pdf-comparision-routing.module";
import { PdfComparisionPageModule } from "./pdf-comparision-page/pdf-comparision-page.module";
import { PdfComparisionFormDataComponent } from "./pdf-comparision-page/pdf-comparision-assistance-form-data/pdf-comparision-form-data.component";

@NgModule({
  imports: [CommonModule, PdfComparisionRoutingModule, PdfComparisionPageModule],
  exports: [PdfComparisionFormDataComponent],
})
export class PdfComparisionModule {}
