import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConvoBiService } from "src/app/module/service/convo-bi.service";
import { FormServiceService } from "src/app/module/service/form-service.service";

@Component({
  selector: "newchat.dialog",
  templateUrl: "newchat.dialog.html",
  styleUrls: ["newchat.dialog.scss"],
})
export class NewChatDialog {
  selectedWorkspaceName: string = "";
  selectedReportName: string = "";
  ReportList: any = [];
  formSubmitted: boolean = false;
  newChatForm!: FormGroup;
  token: any;
  userEmail: any;

  constructor(
    public service: ConvoBiService,
    public dialogRef: MatDialogRef<NewChatDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.getReportList();
    this.newChatForm = this.formBuilder.group({
      reportName: ["", Validators.required],
    });
  }

  getReportList() {
    let data = {
      workspaceid: this.data.workspace_id,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.getReportList(data).subscribe((res) => {
      this.ReportList = res.most_similar_description_dict;
    });
  }

  /* on click of close button */
  onNoClick(): void {
    this.dialogRef.close();
  }
  /* save all information and transfer those information to home-page */
  save() {
    this.formSubmitted = true;
    if (this.newChatForm.valid) {
      localStorage.setItem("workspace_id", this.data.workspace_id);
      localStorage.setItem(
        "report_id",
        this.newChatForm.controls.reportName.value
      );
      let data = {
        persona_id: this.data.persona_id,
        workspace_id: this.data.workspace_id,
        report_id: this.newChatForm.controls.reportName.value,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      this.service.createInstance(data).subscribe((res) => {
        localStorage.setItem("instance_id", res.instance_id);
        this.service.showVisualType = true;
        this.service.showChatLoader = true;
        this.dialogRef.close("ok");
      });
    }
  }
}
