import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PdfComparisionPageRoutingModule } from "./pdf-comparision-page-routing.module";
import { PdfComparisionPageComponent } from "./pdf-comparision-page.component";
import { PdfComparisionFormDataComponent } from "./pdf-comparision-assistance-form-data/pdf-comparision-form-data.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoaderModule } from "../../loader/loader.module";

@NgModule({
  declarations: [PdfComparisionPageComponent, PdfComparisionFormDataComponent],
  imports: [
    CommonModule,
    PdfComparisionPageRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatButtonModule,
    ClipboardModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    LoaderModule,
  ],
  exports: [PdfComparisionFormDataComponent],
})
export class PdfComparisionPageModule {}
