import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DashboardServiceService } from "src/app/module/service/dashboard-service.service";

export function usecaseValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 20;

    if (value && value.length > maxLength) {
      return { maxLengthExceededUsecase: true };
    }

    if (value && !/^[a-zA-Z0-9_\s\-]+$/.test(value)) {
      return { invalidCharactersUsecase: true };
    }

    return null;
  };
}

export function projectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 20;

    if (value && value.length > maxLength) {
      return { maxLengthExceededProject: true };
    }

    if (value && !/^[a-zA-Z0-9_\s\-]+$/.test(value)) {
      return { invalidCharactersProject: true };
    }

    return null;
  };
}

export function domainValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 20;

    if (value && value.length > maxLength) {
      return { maxLengthExceededDomain: true };
    }

    if (value && !/^[A-Za-z0-9\s]+$/.test(value)) {
      return { invalidCharactersDomain: true };
    }

    return null;
  };
}
@Component({
  selector: "request-new-usecases",
  templateUrl: "request-new-usecases.html",
  styleUrls: ["request-new-usecases.scss"],
})
export class RequestNewUseCase {
  UsecaseForm!: FormGroup;
  token: any;
  userEmail: any;
  formSubmitted: boolean = false;
  isLoading: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RequestNewUseCase>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DashboardServiceService
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.UsecaseForm = this.formBuilder.group({
      usecase: ["", [Validators.required, usecaseValidator()]],
      project: ["", [Validators.required, projectValidator()]],
      domain: ["", [Validators.required, domainValidator()]],
      //   email: [
      //     "",
      //     [
      //       Validators.required,
      //       Validators.pattern(
      //         /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      //       ),
      //     ],
      //   ],
      description: ["", [Validators.required, Validators.maxLength(500)]],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /* to save the persona information in API */
  save() {
    this.formSubmitted = true;
    if (this.UsecaseForm.valid) {
      this.isLoading = true;
      this.formSubmitted = false;

      let data = {
        usecase: this.UsecaseForm.value.usecase,
        project: this.UsecaseForm.value.project,
        domain: this.UsecaseForm.value.domain,
        description: this.UsecaseForm.value.description,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };

      this.service.requestNewUseCase(data).subscribe(
        (res) => {
          if (res.status == "success") {
            this.isLoading = false;
            // resetting the form after success
            this.UsecaseForm.reset();
            this.data = [];
            this.isLoading = false;
            this.dialogRef.close("ok");
          } else {
            this.isLoading = false;
            alert(res.message);
          }
        },
        (error) => {
          this.formSubmitted = false;
          this.isLoading = false;
          alert(error.error.message);
        }
      );
    }
  }
}
