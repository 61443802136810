<div class="selection-info">
    <div class="wrapper">
        <span class="title">Type: </span>
        <span>{{selectType}}</span>
    </div>|
    <div *ngIf="service.taskConvertor != 'text_to_image'" class="wrapper">
        <span class="title">Model: </span>
        <span>{{selectedModal}}</span>
    </div>
    <div *ngIf="service.taskConvertor === 'text_to_image'" class="wrapper">
        <span class="title">Model: </span>
        <span>{{selectedModalImage}}</span>
    </div>|
    <div class="wrapper">
        <span class="title">Context: </span>
        <span>{{selectedContex}}</span>
    </div>
</div>
<div class="content" id="content">

    <div role="status" *ngIf="service.showChatLoader || service.alive">
        <app-loader></app-loader>
    </div>

    <ng-container *ngIf="!service.newChat">
        <div class="chat-window chat-window-a chat-box-overflow" *ngIf="service.taskConvertor != 'text_to_image'"
            #chatWindowRef>
            <div class="conversation" *ngFor="let message of messages; let i=index">
                <div class="message" [ngClass]="{from: message.author == 'bot',to: message.author == 'user'}">

                        <div class="content-dflex" *ngIf="message.author == 'user'">
                            <div  class="avatar">
                                <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                            </div>
                            <p class="message-content-text">
                                {{ message.userResponse }}
                            </p>
                        </div>

                        <section id="hero" *ngIf="message.author == 'bot'">
                            {{ generateColumns( message.content[0] ) }}
                            {{ setCurrentRow( message.content ) }}
                            <div class="content">
                                <div class="chat-window">
                                    <div class="feedback-table-wrapper table-responsive">
                                        <table class="gen-table">
                                            <thead>
                                                <tr>
                                                    <th
                                                        style="text-transform: capitalize;"
                                                        *ngFor="let column of columnNames"
                                                    >
                                                        {{TitleCase(column)}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="success-body" *ngIf="columnNames.length && currentRow.length">
                                                <tr *ngFor="let row of currentRow">
                                                    <td *ngFor="let column of columnNames">
                                                        {{ row[stringToSlug(column)] }}
                                                    </td>
                                                </tr>
                                            <tbody>
                                            <tbody class="error-body" *ngIf="!columnNames.length || !currentRow.length">
                                                <tr>
                                                    <td class="alert alert-danger">
                                                        Nothing matches with you criteria, please refine your request.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </section>
                        
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="service.newChat">
        <!-- hide when message appears -->
        <div class="center-wrapper default-template">
            <img src="../../../../../assets/new-logo/new-logo.png" style="width: 50px;" alt="logo" />
            <div class="landing-info" *ngIf="!service.alive">
                <h3 class="lite">Elevate your content with AI-Driven</h3>
                <h3>Natural Query generation</h3>
                <!-- <h3 *ngIf="service.selectedTask == 'text_to_image'">Image generation</h3> -->
            </div>
            <div class="landing-info" *ngIf="service.alive">
                <h5>System is getting configured.</h5>
                <h5>It will take few minutes... </h5>
                <!-- <br />
              <h5>To avoid delay use Model Portfolio page</h5>
              <h5>to get Model available.</h5> -->
            </div>
        </div>
    </div>


    <!-- Stop generation and regeneration button -->

    <div class="regenerate" style="margin-bottom: 0px; margin-top: 0;">
        <button class="primary-color" class="stop-generate" *ngIf="service.stopGeneratorFlag"
            (click)='stopGeneratorPrompt()'>
            Stop Generating
        </button>

        <button class="primary-color" *ngIf="service.regenerateFlag" (click)='regenerateMessage()'>
            <img width="19.84px" height="18px" src="../../../../../assets/icons/regenerate-icon.svg"
                alt="regenerateIcon " />
            Regenerate
        </button>
    </div>
    <div class="footer-wrapper-container">
        <div class="footer-wrapper" style="padding: 20px;">

            <!-- hide when message appears -->
            <div class="notifications-wrapper" *ngIf="service.newChat && service.newInstance">
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
            </div>
            <div class="status-loader" *ngIf="service.showChat">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>

            <!-- <div class="msg-box"> -->
            <!-- <input type="text" placeholder="Type here..." [(ngModel)]="userMessage" (input)="resizeTextarea($event)"
              (keydown.enter)="!flag && sendMessage()" [disabled]="service.showChat" #myinput> -->
            <div class="msg-box" [ngClass]="{'fixed-message-bar': !service.newChat}">
                <!-- <input type="text" placeholder="Type here..." [(ngModel)]="userMessage" (input)="resizeTextarea($event)"
                  (keydown.enter)="!flag && sendMessage()"
                  [disabled]="service.showChat || service.alive || !service.newInstance" #myinput> -->
                <!-- <input type="text" [placeholder]="placeholder" [(ngModel)]="userMessage"
                    (input)="resizeTextarea($event)" (keydown.enter)="!flag && sendMessage()"
                    [disabled]="service.showChat || service.alive || !service.newInstance"
                    style="background-color: #fff !important;" #myinput> -->
                <textarea
                    style="background-color: #fff !important; margin-left: 15px; height: 24px;"
                    [placeholder]="placeholder"
                    [(ngModel)]="userMessage"
                    (keydown.enter)="!flag && sendMessage()"
                    [disabled]="service.showChat || service.alive || !service.newInstance"
                    (input)="resizeTextarea($event)"
                    #myinput
                ></textarea>
                <img *ngIf='!userMessage' src="../../../../../assets/icons/icon-send-grey.svg" alt="send" />
                <img *ngIf='userMessage' class="addCursor" src="../../../../../assets/icons/icon-send.svg" alt="send"
                    (click)="!flag && sendMessage()" />
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>