import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReqGenerationRoutingModule } from './req-generation-routing.module';
import { ReqGenerationPageComponent } from './req-generation-page/req-generation-page.component';
import { ReqGenerationPageModule } from './req-generation-page/req-generation-page.module';
import { ReqFormDataComponent } from './req-generation-page/req-assistance-form-data/req-form-data.component';
import { ReqQualifierTableComponent } from './req-generation-page/req-qualifier-table/req-qualifier-table.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, ReqGenerationRoutingModule, ReqGenerationPageModule],
  exports: [ReqFormDataComponent, ReqQualifierTableComponent],
})
export class ReqGenerationModule {}
