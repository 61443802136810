<!-- <div class="header-dialog">
    <span class="head-text"><h1 mat-dialog-title class="hd-txt">Upload File</h1>
        <mat-icon class="cancel-btn" (click)="onNoClick('')" title="Close">cancel</mat-icon></span>
</div> -->
<!-- to save the industry information -->
<div mat-dialog-content class="contain"
    style="font: 500 14px/24px 'Poppins-Medium', sans-serif;font-weight: bold; background-color: #fff !important;">

    <div class="close-wrapepr" style="float: right; cursor: pointer;" (click)="onNoClick('close')">
        <mat-icon class="" title="Close" title="Close">cancel</mat-icon>
    </div>
    <div></div>

    <div style="text-align: center;" class="form-label" for="customFile">UPLOAD YOUR PPT HERE<span style="color: red;"
            class="danger">*</span>
    </div>
    <br>
    <div style="text-align: center;">
        <a href="javascript:void(0)" (click)="file.click()">
            <img src="../../../../../../assets/img/file_upload.jpg" alt="file upload" />
        </a>
    </div>
    <input type="file" #file [multiple]="false"
        accept="application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
        (change)="onFileChange($event)" style="display:none" />

    <div *ngIf="uploadFile">
        <div *ngFor="let item of listFiles;let i=index">
            {{item}}
        </div>
    </div>
    <form>
        <div>
            <div class="col-md-6 text-center">

                <div class="ft-drop rd-colr">

                </div>
            </div>
        </div>
    </form>

</div>
<!-- <div mat-dialog-actions class="btn-align">
    <button mat-button class="btn" (click)="onNoClick()">Close</button>
    <button mat-button class="btn" (click)="save()" cdkFocusInitial>Save</button>
</div> -->