<div class="modal-wrapper">

    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title class="hd-txt">Context</h1>
            <mat-icon class="cancel-btn" title="Close" (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <div mat-dialog-content class="contain">
        <form [formGroup]="contextForm">
            <div class="row">
                <div class="col-12 mb-3">
                    <label for="basic-url">
                        Workspace Name
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <mat-form-field>
                            <mat-select id="inp3" formControlName="workSpaceName">
                                <mat-option
                                    *ngFor="let dictItem of workSpaceName | keyvalue"
                                    value="{{dictItem.value}}"
                                >
                                    {{dictItem.key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || contextForm.controls.workSpaceName.dirty) && contextForm.controls.workSpaceName.errors?.required">
                            Work space name is required.
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-3">
                    <label for="basic-url">
                        Document Name
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <input type="text" formControlName="documentName" class="form-control rd-colr"
                            (keyup)="limitxt($event)" id="basic-url" aria-describedby="basic-addon3">
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || contextForm.controls.documentName.dirty) && contextForm.controls.documentName.errors?.required">
                            Document Name is required
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || contextForm.controls.documentName.dirty) && contextForm.controls.documentName.errors?.maxLengthExceeded">
                            Document Name allows up to 24 characters only
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || contextForm.controls.documentName.dirty) && contextForm.controls.documentName.errors?.invalidCharacters">
                            Only alpha-numeric, spaces, hyphen, and underscore are allowed
                        </span>
                        <span class="validation-error" *ngIf="limiterror.isError">
                            {{ limiterror.errorMessage }}
                        </span>
                    </div>
                    <!-- <div class="alert">
                            <span class="validation-error"
                                *ngIf="(formSubmitted || contextForm.controls.documentName.touched) && contextForm.controls.documentName.errors?.required">
                                Document is required.
                            </span>
                            <span
                                *ngIf="(formSubmitted || contextForm.controls.documentName.dirty) && contextForm.controls.documentName.errors?.pattern">
                                Only alpha-numeric, hyphen(-), underscore(_) and spaces are allowed.
                            </span>
                            <span class="validation-error"
                            *ngIf="limiterror.isError">
                                {{ limiterror.errorMessage }}
                            </span>
                        </div> -->
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12">
                    <label for="basic-url">Report Visuals<span class="danger">*</span></label>
                    <div class="ft-drop rd-colr">
                        <a href="javascript:void(0)" (click)="file.click()">
                            Click to open
                        </a>
                        <input type="file" #file multiple="false" formControlName="reportVisuals"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            (change)="onFileRepprt($event)" style="display:none" />
                        <div *ngIf="listReportFiles">
                            {{listReportFiles}} -->
                            <!-- <div *ngFor="let item of listReportFiles;let i=index">
                                    {{item}}
                                </div> -->
                        <!-- </div>
                    </div>
                    <div class="alert">
                        <span class="validation-error"
                            *ngIf="listReportFiles == '' && formSubmitted && !error.isError">
                            Report Visual is required.
                        </span>
                        <span *ngIf="error.isError" class="validation-error">
                            {{ error.errorMessage }}
                        </span>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <div class="col-12 mb-3">
                    <label for="basic-url">Custom Visuals<span class="text-danger">*</span></label>
                    <div class="ft-drop rd-colr">
                        <a href="javascript:void(0)" (click)="file2.click()">
                            Click to open
                        </a>
                        <input type="file" #file2 [multiple]="false" formControlName="customVisual"
                            accept="application/json" (change)="onFileworkSpace($event)" style="display:none" />
                        <div *ngIf="listworkSpaceFiles">
                            {{listworkSpaceFiles}}
                            <!-- <div *ngFor="let item of listworkSpaceFiles;let i=index">
                                    {{item}}
                                </div> -->
                        </div>
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                        *ngIf="listworkSpaceFiles == '' && formSubmitted && !error2.isError">
                            Custom Visual is required.
                        </span>
                        <span *ngIf="error2.isError" class="validation-error">
                            {{ error2.errorMessage }}
                        </span>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12 pd-bt">
                    <label for="basic-url">Column Metadata<span class="danger">*</span></label>
                    <div class="ft-drop rd-colr">
                        <a href="javascript:void(0)" (click)="file3.click()">
                            Click to open
                        </a>
                        <input type="file" #file3 [multiple]="false" formControlName="columnMataData"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            (change)="onFileColumnMeteData($event)" style="display:none" />
                        <div *ngIf="listColumnData">
                            {{listColumnData}}
                            
                        </div>
                    </div>
                    <div class="alert">
                        <span class="validation-error"
                        *ngIf="listColumnData == '' && formSubmitted && !error3.isError">
                            Column Metadata is required.
                        </span>
                        <span *ngIf="error3.isError" class="alert">
                            {{ error3.errorMessage }}
                        </span>
                    </div>
                </div>
            </div> -->
        </form>
    </div>
    <div mat-dialog-actions>
        <button class="btn btn-secondary-outline" (click)="onNoClick()">Close</button>
        <button class="btn btn-primary" (click)="clickOK()">Save</button>
    </div>
    <div id="loading" *ngIf="isLoading">
        <app-chat-loader></app-chat-loader>
    </div>
    <!-- <div *ngIf="showLoader && !showText" class="spin-circle">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="showText">
        <p class="text-per">{{newText}}</p>
        <div mat-dialog-actions class="btn-align">
            <button mat-button class="btn btn-primary" (click)="onNoClick()">Cancel</button>
            <button mat-button class="btn btn-primary" (click)="ok()" cdkFocusInitial>ok</button>
        </div>
    </div> -->
</div>