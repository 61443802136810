import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CodeGenerationComponent } from "./module/code-generation/code-generation.component";
import { AuthorizeGuard } from "./utility/AuthorizeGuard";

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: "home",
    loadChildren: () =>
      import("./module/home/home.module").then((m) => m.HomeModule),
    canActivate: [AuthorizeGuard],
  },

  {
    path: "login",
    loadChildren: () =>
      import("./module/login/login.module").then((m) => m.LoginModule),
  },

  {
    path: "idpresponse",
    loadChildren: () =>
      import("./module/idpresponse/idpresponse.module").then(
        (m) => m.IdpresponseModule
      ),
  },

  {
    path: "",
    loadChildren: () =>
      import("./module/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: "dashboard",
    loadChildren: () =>
      import("./module/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: "sow-generation",
    loadChildren: () =>
      import("./module/sow-generation/sow-generation.module").then(
        (m) => m.SowGenerationModule
      ),
  },

  {
    path: "convo-bi-home",
    loadChildren: () =>
      import("./module/convo-bi-home/convo-bi-home.module").then(
        (m) => m.ConvoBiHomeModule
      ),
  },

  {
    path: "natural-query-engine",
    loadChildren: () =>
      import("./module/natural-query-engine/natural-query-engine.module").then(
        (m) => m.NaturalQueryEngineModule
      ),
  },
  
  {
    path: "code-conversion",
    loadChildren: () =>
      import("./module/code-conversion/code-conversion.module").then(
        (m) => m.CodeConversionModule
      ),
  },

  {
    path: "admin-settings",
    loadChildren: () =>
      import("./module/admin-settings/admin-settings.module").then(
        (m) => m.AdminSettingsModule
      ),
  },

  {
    path: "requirement-generator",
    loadChildren: () =>
      import("./module/req-generation/req-generation.module").then(
        (m) => m.ReqGenerationModule
      ),
  },

  {
    path: "requirement-generator/:generatorId",
    loadChildren: () =>
      import("./module/req-generation/req-generation.module").then(
        (m) => m.ReqGenerationModule
      ),
  },

  {
    path: "api-testing",
    loadChildren: () =>
      import("./module/api-testing/api-testing.module").then(
        (m) => m.ApiTestingModule
      ),
  },

  {
    path: "document-comparison",
    loadChildren: () =>
      import("./module/pdf-comparision/pdf-comparision.module").then(
        (m) => m.PdfComparisionModule
      ),
  },

  {
    path: "code-generation",
    component: CodeGenerationComponent,
  },

  {
    // Redirect to login page for any other invalid route
    path: "**",
    redirectTo: "/login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}