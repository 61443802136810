import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog } from "@angular/material/dialog";
import { AdminSettingsServiceService } from "../../service/admin-settings-service.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { CostService } from "src/app/module/service/cost.service";
import { Router } from "@angular/router";
import { MatDatepicker } from "@angular/material/datepicker";

@Component({
  selector: "app-admin-settings-page",
  templateUrl: "./admin-settings-page.component.html",
  styleUrls: ["./admin-settings-page-chat.component.scss"],
})
export class AdminSettingsPageComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild("sDate") sDate!: MatDatepicker<any>;
  @ViewChild("eDate") eDate!: MatDatepicker<any>;

  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  intervalId: any;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;
  sessionStatus: any;

  startDate: any;
  endDate: any;
  selectedInterval: string = "Daily";
  currentRoute: any;
  costFilterData: any;
  analyticsFilterData: any;
  errorFlag: boolean = false;
  errorMsg: string = "";
  showFilter: boolean = true;
  showInterval: boolean = false;

  minDate = new Date(2024, 0, 1); // January 1, 2024
  maxDate = new Date(); // Today's date

  constructor(
    private observer: BreakpointObserver,
    public dialog: MatDialog,
    public route: Router,
    public service: AdminSettingsServiceService,
    public authService: AuthServiceService,
    private costService: CostService,
    public dashboardService: DashboardServiceService
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");

    // Checks every 5 second for session expiration
    this.intervalId = setInterval(() => {
      if (this.isLocalUser === "true") {
        // For Local User
        this.authService.checkAndRefreshSession();
      } else {
        // For Azure AD user
        this.authService.checkAndRefreshSessionForAWSCognitoAzure();
      }
    }, 5000);

    // Integrating a user pool with an identity pool
    // this.authService.integrateUserPoolWithIdentityPool();

    // Set the max date to today's date
    this.maxDate.setDate(this.maxDate.getDate());

    this.currentRoute = this.route.url;
    const currentDate = new Date();
    if (
      this.currentRoute.includes("/admin-settings/admin-settings-page/cost")
    ) {
      this.showFilter = true;

      this.startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      this.endDate = this.getEndDate(
        new Date(),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      );
    } else if (
      this.currentRoute.includes(
        "/admin-settings/admin-settings-page/analytics"
      )
    ) {
      this.showFilter = true;
      this.showInterval = true;

      // this.startDate = new Date(
      //   currentDate.getFullYear(),
      //   currentDate.getMonth() - 2,
      //   1
      // );
      // this.endDate = new Date(
      //   currentDate.getFullYear(),
      //   currentDate.getMonth() + 1,
      //   0
      // );

      this.startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      this.endDate = this.getEndDate(
        new Date(),
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      );

      this.selectedInterval = "Daily";
    } else {
      this.showFilter = false;
    }
  }

  getEndDate(currentDate: Date, monthEndDate: Date): Date {
    const now = new Date();
    // Check if the month end date is in the future
    if (monthEndDate > now) {
      // If month end date is in the future, return current date
      return currentDate;
    } else {
      // If month end date is in the past or current date, return the month end date
      return monthEndDate;
    }
  }

  async ngOnInit() {
    if (
      this.currentRoute.includes("/admin-settings/admin-settings-page/cost") ||
      this.currentRoute.includes(
        "/admin-settings/admin-settings-page/analytics"
      )
    ) {
      this.filterData();
    }
  }

  ngAfterViewInit() {
    /*  to display responsive sidebar */
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }

  logout() {
    this.service.showChatLoader = true;
    this.authService.logout();
  }

  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  onKeyDown(event: KeyboardEvent): void {
    // console.log(event.key);

    // Allow backspace, Backslash, delete, arrows, and numeric keys
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "/",
    ];
    const allowedNumericKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];

    if (
      !allowedKeys.includes(event.key) &&
      !allowedNumericKeys.includes(event.key) &&
      !(event.ctrlKey && event.key === "a") // Allow select all with Ctrl+a
    ) {
      event.preventDefault();
    }
  }

  openDatePicker(val: any): void {
    if (val === "sDate") {
      this.sDate.open();
    } else if (val === "eDate") {
      this.eDate.open();
    }
  }

  onFocusOut() {
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.errorFlag = true;
      this.errorMsg = "End date must be after start date";
    } else {
      this.errorFlag = false;
    }
  }

  filterData() {
    this.onFocusOut();
    if (
      this.currentRoute.includes("/admin-settings/admin-settings-page/cost")
    ) {
      this.costService.group = "";
      this.costService.user = "";
      if (!this.errorFlag) {
        let data = {
          costStartDate: this.startDate
            ? this.getParsedDate(this.startDate)
            : "",
          costEndDate: this.endDate ? this.getParsedDate(this.endDate) : "",
        };
        this.service.setCostFilterData(data);
        this.service.callCostDataLoader();
        this.getGroups();
      }
      this.route.navigate(["/admin-settings/admin-settings-page/cost"]);
    } else if (
      this.currentRoute.includes(
        "/admin-settings/admin-settings-page/analytics"
      )
    ) {
      if (!this.errorFlag) {
        const sDate = this.startDate ? this.getParsedDate(this.startDate) : "";
        const eDate = this.endDate ? this.getParsedDate(this.endDate) : "";
        let data = {
          analyticsStartDate: sDate,
          analyticsEndDate: eDate,
          interval: this.selectedInterval,
        };
        this.service.setAnalyticsFilterData(data);
        this.service.callAnalyticsDataLoader();
        this.getUpdatedAnalyticsData();
      }
      if (
        !this.currentRoute.includes(
          "/admin-settings/admin-settings-page/analytics"
        )
      ) {
        this.route.navigate(["/admin-settings/admin-settings-page/analytics"]);
      }
    }
  }

  onIntervalChange(event: any) {
    this.selectedInterval = event.value;
    this.filterData();
  }

  getParsedDate(dateObj: any) {
    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    return `${year}-${month}-${date}`;
  }

  getGroups() {
    this.costService.showLoader = true;
    this.costFilterData = this.service.getCostFilterData();
    const data = {
      start_date: this.costFilterData ? this.costFilterData.costStartDate : "",
      end_date: this.costFilterData ? this.costFilterData.costEndDate : "",
      token: localStorage.getItem("token"),
      userEmail: localStorage.getItem("userEmail"),
    };
    this.costService.getGroupCostInfo(data).subscribe(
      (response) => {
        if (response.status === "success") {
          this.costService.rowCount = response.DATA.length;
          if (this.costService.rowCount > this.costService.limit) {
            this.costService.pages = Math.ceil(
              this.costService.rowCount / this.costService.limit
            );
          }

          this.costService.groupSet = response.DATA.sort((a: any, b: any) => {
            return a.group_name.localeCompare(b.group_name);
          });
          this.costService.sortGroupFlag["group"] = "ASC";
        } else {
          console.log("Unable to fetch response");
        }
        this.costService.showLoader = false;
        this.getPageData(this.costService.currentPage);
      },
      (error) => {
        console.log(error);
        this.costService.showLoader = false;
      }
    );
  }

  getPageData(page: any) {
    this.costService.currentPage = page;
    this.costService.groupSetPerPage = [];
    const startIndex = (page - 1) * this.costService.limit;
    const endIndex = startIndex + this.costService.limit - 1;

    // Generate an array of values based on the page number
    this.costService.groupSetPerPage = this.costService.groupSet.slice(
      startIndex,
      endIndex + 1
    );
    this.calculatePaginationRange();
  }

  calculatePaginationRange() {
    const totalPages = this.costService.pages;
    const currentPage = this.costService.currentPage;

    let currentRangeStart = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const currentRangeEnd = Math.min(currentRangeStart + 9, totalPages);

    if (currentPage > currentRangeEnd) {
      currentRangeStart += 10;
    }

    const newEnd = Math.min(currentRangeStart + 9, totalPages);

    this.costService.paginationRange = Array.from(
      { length: newEnd - currentRangeStart + 1 },
      (_, i) => currentRangeStart + i
    );
  }

  getUpdatedAnalyticsData() {
    this.service.isLoading = true;

    let data = {};
    switch (this.service.analyticsFilterData.interval) {
      case "Weekly":
        data = {
          token: localStorage.getItem("token"),
          userEmail: localStorage.getItem("userEmail"),
          weeks: this.service.analyticsDateArr,
        };
        break;
      case "Monthly":
        data = {
          token: localStorage.getItem("token"),
          userEmail: localStorage.getItem("userEmail"),
          months: this.service.analyticsDateArr,
        };
        break;
      default:
        data = {
          token: localStorage.getItem("token"),
          userEmail: localStorage.getItem("userEmail"),
          days: this.service.analyticsDateArr,
        };
        break;
    }

    this.service.getAnalyticsData(data).subscribe(
      (response) => {
        if (response.status === "Success") {
          const analyticsDataUsageTotal: number[] = response.Usage[0].data.map(
            (item: any, i: any) =>
              response.Usage.reduce((acc: any, currentUsage: any) => {
                if (currentUsage.data[i]) {
                  // check if the current data exists
                  return acc + currentUsage.data[i]; // sum the specific index
                }
                return acc; // return accumulated sum otherwise
              }, 0)
          );
          response.Usage.push({ name: "Total", data: analyticsDataUsageTotal });

          this.service.analyticsDataUsageChart = response.Usage.map(
            (item: any) => {
              return {
                name: item.name,
                type: "line",
                data: item.data,
              };
            }
          );
          this.service.analyticsDataCostConsumptionChart = response.Count.map(
            (item: any) => {
              return {
                name: item.name,
                type: "column",
                data: item.data,
              };
            }
          );
          this.service.analyticsDataUserLoginChart = response.Logins;

          setTimeout(() => {
            this.service.usageChartOptions = {
              credits: {
                enabled: false,
              },
              accessibility: {
                enabled: true,
              },
              chart: {
                type: "line",
              },
              title: {
                text: "Model Usage Chart",
                style: {
                  fontSize: "1rem",
                  fontWeight: "Normal",
                },
              },
              xAxis: {
                categories: this.service.analyticsCategoryArr,
              },
              yAxis: {
                title: {
                  text: "Number of Prompts",
                },
              },
              tooltip: {
                // shared: true,
                headerFormat: "<b>{point.x}</b><br/>",
                // pointFormat: "{series.name}: {point.y}",
                pointFormatter: function () {
                  // Calculate the total for the current x value
                  const xIndex = this.index; // Get the index of the current x value

                  let cnt = 0;
                  const total = this.series.chart.series.reduce(
                    (sum, series) => {
                      cnt++;
                      const point = series.data[xIndex];
                      // Check if point is defined before accessing y
                      if (
                        point &&
                        point.y !== undefined &&
                        cnt < this.series.chart.series.length
                      ) {
                        return sum + point.y;
                      }
                      return sum; // Return the current sum if point is undefined
                    },
                    0
                  );

                  return `${this.series.name}: ${this.y} <br/>Total: ${total}`;
                },
              },
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true,
                  },
                  events: {
                    legendItemClick: function () {
                      return false; // Disable-Click on Legend
                    },
                  },
                  enableMouseTracking: true,
                },
              },
              series: response.Usage.map((item: any) => {
                return {
                  name: item.name,
                  type: "line",
                  data: item.data,
                };
              }),
              exporting: {
                enabled: true,
                buttons: {
                  contextButton: {
                    menuItems: this.service.exportMenuItem,
                  },
                },
              },
            };

            this.service.costConsumptionChartOptions = {
              credits: {
                enabled: false,
              },
              accessibility: {
                enabled: true,
              },
              chart: {
                type: "column",
              },
              title: {
                text: "Cost Consumption Chart",
                style: {
                  fontSize: "1rem",
                  fontWeight: "Normal",
                },
              },
              xAxis: {
                categories: this.service.analyticsCategoryArr,
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Total Cost Consumption",
                },
                stackLabels: {
                  enabled: true,
                  style: {
                    fontWeight: "bold",
                    color: "black",
                  },
                  formatter: function () {
                    if (this.total > 0) {
                      return this.total + "$";
                    }
                    return "";
                  },
                },
              },
              tooltip: {
                headerFormat: "<b>{point.x}</b><br/>",
                pointFormat:
                  "{series.name}: {point.y} $ <br/>Total: {point.stackTotal} $",
              },
              plotOptions: {
                column: {
                  stacking: "normal",
                  dataLabels: {
                    enabled: false,
                    format: "{point.y} $",
                  },
                  events: {
                    legendItemClick: function () {
                      return false; // Disable-Click on Legend
                    },
                  },
                },
              },
              series: response.Count.map((item: any) => {
                return {
                  name: item.name,
                  type: "column",
                  data: item.data,
                };
              }),
              exporting: {
                enabled: true,
                buttons: {
                  contextButton: {
                    menuItems: this.service.exportMenuItem,
                  },
                },
              },
            };

            this.service.loginAttemptChartOptions = {
              credits: {
                enabled: false,
              },
              accessibility: {
                enabled: true,
              },
              chart: {
                type: "line",
              },
              title: {
                text: "Number of Logins Chart",
                style: {
                  fontSize: "1rem",
                  fontWeight: "Normal",
                },
              },
              xAxis: {
                categories: this.service.analyticsCategoryArr,
              },
              yAxis: {
                title: {
                  text: "Number of Logins",
                },
              },
              tooltip: {
                headerFormat: "<b>{point.x}</b><br/>",
                pointFormat: "{series.name}: {point.y}",
              },
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true,
                  },
                  events: {
                    legendItemClick: function () {
                      return false; // Disable-Click on Legend
                    },
                  },
                  enableMouseTracking: true,
                },
              },
              series: [
                {
                  name: "Number of Logins",
                  type: "line",
                  data: response.Logins,
                },
              ],
              exporting: {
                enabled: true,
                buttons: {
                  contextButton: {
                    menuItems: this.service.exportMenuItem,
                  },
                },
              },
            };
          }, 100);
        } else {
          console.log("Unable to fetch response");
        }
        this.service.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.service.isLoading = false;
      }
    );
  }
}
