<div class="header-dialog">
    <span class="head-text">
        <h2 mat-dialog-title class="hd-txt">Terms and Agreement</h2>
    </span>
</div>
<div class="contain">
    <p>These Terms and Agreement ("Terms") govern your use of the Genysys Platform ("Tool") provided by Apexon. By accessing or using the Tool, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Tool.</p>

    <ol>
        <li>
            <strong>License to Use</strong>: Access to use the Tool is solely for your personal or internal business purposes, subject to these Terms. Access granted is non-transferable, revocable in case of breach of these Terms.
            <br /><br />
            Except as expressly provided above, nothing contained herein shall be construed as conferring, by implication, estoppel or otherwise, any license or right under any patent, trademark or copyright of the Company. You acknowledge and agree that nothing in these terms of use shall have the effect of transferring the ownership of any copyrights, trademarks, service marks, trade names or other proprietary rights in the Site or Material or any part thereof to you or any third party
        </li>
        <li>
            <strong>Prohibited Activities</strong>: You agree not to use the Tool for any unlawful purpose, to violate any laws or regulations, or to infringe upon the rights of others. Prohibited activities include but are not limited to:
            <ul>
                <li>Use of the Tool in violation of applicable laws and regulations, including but not limited to violation of any person’s privacy rights, export control laws/regulations and without the necessary consents and authorization.</li>
                <li>Engaging in any activity that could disrupt or interfere with the functioning of the Tool.</li>
                <li>Attempting to gain unauthorized access to any accounts, systems, or networks related to the Tool.</li>
                <li>Reverse engineering, decompiling, or otherwise attempting to derive the source code of the Tool.</li>
            </ul>
        </li>
        <li>
            <strong>Content Guidelines</strong>: You are solely responsible for the content you generate or share using the Tool(User Content). You agree not to generate or share any user content that is:
            <ul>
                <li>Illegal, harmful, or in violation of any applicable laws. </li>
                <li>Offensive, abusive, defamatory, or otherwise objectionable.</li>
                <li>Infringing upon the intellectual property rights of others.</li>
                <li>
                    User Content posted by you shall be subject to relevant laws and may be disabled, or and may be subject to investigation under appropriate laws. You agree that the Company may disclose or preserve User Content if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: 
                    <br /><br />
                    <ol type="a">
                        <li>Comply with legal process;</li>
                        <li>Respond to claims that any User Content violates the rights of third-parties; or</li>
                        <li>protect the rights, property, or personal safety of the Company and the public. Furthermore, if you are found to be in non-compliance with the laws and regulations, these Terms of Use, or the Privacy Policy of the Site, we may terminate your account/block your access to the Site and we reserve the right to remove any of User Content that is non-compliant with these Terms of Use.</li>
                   </ol>
                </li>
                <li>You agree that Company has no liability or responsibility for the storage or deletion of any User Content and reserves the right to change its general practices and limits at any time in its sole discretion, with or without notice.</li>
            </ul>
        </li>
        <li>
            <strong>Privacy</strong>: You shall refrain from uploading any documents or data which includes sensitive project information, confidential data, client information, or any content that should be kept secure.
            <br /><br />
            <p>Your use of the Tool is subject to OpenAI's & AWS Bedrock Privacy Policy. By using the Tool, you consent to the collection, use, and disclosure of your information as described in the Privacy Policy.</p>
            <p>
                Closed Source – OpenAI: <a href="https://openai.com/policies/terms-of-use" target="_blank">https://openai.com/policies/terms-of-use</a>
                <br />
                Open Source – AWS Bedrock: <a href="https://docs.aws.amazon.com/bedrock/latest/userguide/data-protection.html" target="_blank">https://docs.aws.amazon.com/bedrock/latest/userguide/data-protection.html</a>
            </p>
            <p>
                We capture the prompts & responses in our Database for tracking user feedback, and refinement purposes. 
            </p>
        </li>
        <li>
            <strong>Intellectual Property</strong>: The Tool, including but not limited to its underlying technology is protected by copyright, trademark, and other intellectual property laws. You may not remove, alter, or obscure any copyright, trademark, or other proprietary notices displayed in or on the Tool.
        </li>
        <li>
            <strong>Disclaimer of Warranties</strong>: The Tool is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. Apexon makes no representations or warranties regarding the accuracy, reliability, or availability of the Tool.
        </li>
        <li>
            <strong>Limitation of Liability</strong>: To the maximum extent permitted by law, Apexon shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Tool, even if Apexon has been advised of the possibility of such damages.
        </li>
        <li>
            <strong>Indemnification</strong>: You agree to indemnify and hold harmless Apexon, its affiliates, officers, directors, employees, agents, and licensors from any claims, damages, liabilities, and expenses (including attorneys' fees) arising out of or in connection with your use of the Tool or your violation of these Terms.
        </li>
        <li>
            <strong>Modification of Terms</strong>: Apexon reserves the right to modify these Terms at any time. Any changes to the Terms will be effective immediately upon posting the revised Terms. By continuing to use the Tool after the posting of such changes, you accept the modified Terms.
        </li>
        <li>
            <strong>Termination</strong>: Apexon may terminate your access to the Tool at any time, with or without cause, and without prior notice or liability.
        </li>
        <li>
            <strong>Governing Law</strong>: These Terms shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflicts of law principles.
        </li>
        <li>
            <strong>Entire Agreement</strong>: These Terms constitute the entire agreement between you and Apexon regarding your use of the Tool and supersede all prior or contemporaneous understandings and agreements, whether oral or written.
        </li>
        <li>
            <strong>OpenAI Usage Limitations</strong>: Apexon currently does not have a premium partner license with OpenAI. Accessing OpenAI’s models have a per month Usage limitation. There could be disruption in the OpenAI model services if our organization tier limits/usage are reached.
        </li>

    </ol>
</div>
<div mat-dialog-actions class="btn-align">
    <button mat-button class="btn btn-secondary-outline" (click)="close(0)">Disagree</button>
    <button mat-button class="btn btn-primary" (click)="close(1)">Agree</button>
</div>
