import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import {
  Observable,
  Subject,
  BehaviorSubject,
  Subscription,
  timer,
  from,
} from "rxjs";
import { map, switchMap, takeWhile, catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import { DatePipe } from "@angular/common";
import * as CryptoJS from "crypto-js";
import { AuthServiceService } from "./auth-service.service";

export class Message {
  constructor(public author: string, public content: string) {
    author = "";
    content = "";
  }
}
@Injectable({
  providedIn: "root",
})
export class FormServiceService {
  token: any;
  userEmail: any;
  headers: any;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;

  accessKeyId: any;
  secretAccessKey: any;
  sessionToken: any;

  sharedData: any;
  modelStatusRes: any;
  isSnackbarOpen: boolean = false;
  textToImageFlag:boolean = false;

  // public baseapiUrl =  "https://sandboxgpt.sbt-infra.net/api/";
  // public baseapiUrl = "http://localhost:8000/api/";

  public taskConvertor: string = "";
  // private chatbotApiUrl = 'https://example.com/chatbot-api';
  public baseapiUrl: string = environment.baseapiurl + "api/";
  private newChatInfo = new Subject<any>();
  public isDelete: boolean = false;
  public showChatLoader: boolean = false;
  public showChat: boolean = false;
  public newChat: boolean = true;
  public newInstance: boolean = false;
  public alive: boolean = false;
  public regenerateFlag: boolean = false;
  public stopGeneratorFlag: boolean = false;
  llama2APIUrl: string = "";
  mosaicmlAPIUrl: string = "";
  textToImageAPIUrl: string = "";
  public selectedTask: string = "text_to_text";
  public selectedModel: string = "llama3";
  public selectedModelImage: string = "openJourney";
  public selectedType: string = "open_source";
  public isPersonaSelected: string = "";
  public instance_id: string = "";
  events: any;
  chatTriggered: EventEmitter<void> = new EventEmitter<void>();

  private methodCaller = new Subject<void>();
  methodCalled$ = this.methodCaller.asObservable();
  callMethod(data?: any) {
    this.methodCaller.next(data);
  }

  private inputdisabler = new Subject<void>();
  inputdisabler$ = this.inputdisabler.asObservable();
  disableInput() {
    this.inputdisabler.next();
  }

  private personaLoader = new Subject<void>();
  personaLoaded$ = this.personaLoader.asObservable();
  callPersonaLoader() {
    this.personaLoader.next();
  }

  sendChatMsg() {
    this.newChatInfo.next();
  }
  getChatMsg(): Observable<any> {
    return this.newChatInfo.asObservable();
  }
  private tokenExpire = new Subject<any>();
  sendtokenMsg() {
    this.tokenExpire.next();
  }
  gettokenMsg(): Observable<any> {
    return this.tokenExpire.asObservable();
  }
  private tabChange = new Subject<any>();
  sendTabChangeMsg() {
    this.tabChange.next();
  }
  getTabChangeMsg(): Observable<any> {
    return this.tabChange.asObservable();
  }

  private typeChange = new Subject<any>();
  sendTypeChange(type: any) {
    this.tabChange.next(type);
  }
  getTypeChange(): Observable<any> {
    return this.tabChange.asObservable();
  }

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private authService: AuthServiceService
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.accessKeyId = localStorage.getItem("accessKeyId");
    this.secretAccessKey = localStorage.getItem("secretAccessKey");
    this.sessionToken = localStorage.getItem("sessionToken");

    this.poolData = {
      UserPoolId: environment.cognitoGuest.userPoolId,
      ClientId: environment.cognitoGuest.userPoolWebClientId,
    };

    this.userPool = new CognitoUserPool(this.poolData);

    // this.headers={
    //   'Authorization': `Bearer ${this.token}`,
    // }
  }

  openAiDisclaimer(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "openai_disclaimer", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /* text-to-text model information API */
  postModelInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "get_models", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* login information API*/
  loginApi(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "api/superuser-login/", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-image model information API */
  postImageModelInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "image/models", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-text industry list information API */
  postIndustryInfo(data: any) {
    this.authService.checkTokenExpiry();
    this.token = localStorage.getItem("token");
    // this.headers={
    //   'Authorization': `Bearer ${this.token}`,
    // }
    return this.http
      .post<any>(this.baseapiUrl + "get_list_industry", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  postFeedbackInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "feedback", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-text save industry information API */
  postIndustryFormDetails(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "industry", data, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-text persona information API */
  postPersonaInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "get_list_persona", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /* text-to-text save persona information API */
  postPersonaFormDetails(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "persona", data, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-text create instance API */
  postNewChatInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "create_instance", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-image create inference API */
  postOpenAiInfo(data: any) {
    this.authService.checkTokenExpiry();
    let apiUrl = "";
    if (this.selectedModel === "Titan Text G1-Lite") {
      apiUrl = this.baseapiUrl + "titan";
    } else if (this.selectedModel === "Jurassic-2 Mid") {
      apiUrl = this.baseapiUrl + "jurassic";
    } else {
      apiUrl = this.baseapiUrl + "create_inference";
    }
    return this.http
      .post<any>(apiUrl, data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-image create new chat window API */
  postSandboxNewChatInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sandbox", data, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  /* text-to-image create new chat window API */
  postOpenapiImage(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "image/output", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  // chatstream for claude
  chatStreamForClaude(data: string) {
    this.authService.checkTokenExpiry();
    this.token = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
    };
    let apiUrl = environment.baseapiurl + "api/claude_2";
    const postData = {
      method: "POST",
      body: data,
      headers: headers,
    };
    return new Observable<string>((observer) => {
      fetch(apiUrl, postData)
        .then((response) => {
          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          if (!response.ok) {
            // handle response error
            observer.error();
          }

          let counter = 0;
          function push() {
            return reader?.read().then(({ done, value }) => {
              if (done && counter >= 1) {
                observer.complete();
                return;
              } else {
                counter++;
              }

              //parse text content from response
              const events = decoder.decode(value).split("\n");
              let chat_id = "";
              let content = "";
              for (let i = 0; i < events.length; i++) {
                const event = events[i];
                if (event && event.slice(0, 8) === "Chat_id:") {
                  let chatIdData = event.split(" ", 2);
                  if (i === 0) {
                    chat_id = chatIdData[1];
                  }
                  let len = event.split(" ", 2).join(" ").length;
                  let data = event.slice(len);
                  let parsedData = JSON.parse(data.trim());
                  // if (!parsedData.stop_reason !== null) {
                  //   content += parsedData.completion;
                  // }
                  if (!parsedData.delta.text !== null) {
                    content += parsedData.delta.text;
                  }
                }
                // if (event && event.slice(0, 5) === "Chat_id:") {
                //   let data = event.slice(5);
                //   let parsedData = JSON.parse(data.trim());
                //   if (i === 0) {
                //     chat_id = parsedData.chat_id;
                //   }
                //   if (!parsedData.token.text.includes("</s>")) {
                //     content += parsedData.token.text;
                //   }
                // }
              }
              observer.next(`${content}chat_id:${chat_id}`);
              push();
            });
          }
          setTimeout(() => {
            push();
          }, 1000);
          // push();
        })
        .catch((err: Error) => {
          // handle fetch error
          observer.error();
        });
    });
  }

  // chatstream for Mistral-7B
  chatStreamForMistral(data: string) {
    this.authService.checkTokenExpiry();
    this.token = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
    };
    let apiUrl = environment.baseapiurl + "api/sagemaker_mistral";
    const postData = {
      method: "POST",
      body: data,
      headers: headers,
    };
    return new Observable<string>((observer) => {
      fetch(apiUrl, postData)
        .then((response) => {
          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          if (!response.ok) {
            // handle response error
            observer.error();
          }

          let counter = 0;
          function push() {
            return reader?.read().then(({ done, value }) => {
              if (done && counter >= 1) {
                observer.complete();
                return;
              } else {
                counter++;
              }

              //parse text content from response
              const events = decoder.decode(value).split("\n");
              let chat_id = "";
              let content = "";

              for (let i = 0; i < events.length; i++) {
                const event = events[i];
                if (event && event.slice(0, 9) === "Chat_id:-") {
                  let chatIdData = event.split(" ", 2);
                  if (i === 0) {
                    chat_id = chatIdData[1];
                  }
                  let len = event.split(" ", 2).join(" ").length;
                  let data = event.slice(len).replace("data:", "");
                  let parsedData = JSON.parse(data.trim());
                  // console.log("data " + i + " = " + data);
                  if (!parsedData.generated_text !== null) {
                    content += parsedData.token.text; //.replace("\n", "\\n");
                  }
                }
              }
              observer.next(`${content}chat_id:${chat_id}`);
              push();
            });
          }
          setTimeout(() => {
            push();
          }, 1000);
          // push();
        })
        .catch((err: Error) => {
          // handle fetch error
          observer.error();
        });
    });
  }

  // stop generation prompt
  stopGenerator(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "interupt", data, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /* text-to-text delete persona API */
  deletePersonaOld(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "delete", data, { headers: this.headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /* streaming response */
  chatStream(data: string) {
    this.authService.checkTokenExpiry();
    this.token = localStorage.getItem("token");
    let headers = {
      "Content-Type": "application/json",
      //'Content-Security-Policy': 'upgrade-insecure-requests',
      // 'Authorization': `Bearer ${this.token}`,
    };
    let apiUrl = "";
    if (this.selectedModel === "llama3") {
      apiUrl =
        environment.baseapiurl + "apis/llama2_streaming/llama2_streaming_new";
    }
    // else if (this.selectedModel === "MosaicML") {
    //   apiUrl =
    //     environment.baseapiurl +
    //     "apis/mosaicml_streaming/mosaicml_streaming_new";
    // }

    return new Observable<string>((observer) => {
      //http://localhost:8000/apis/llama2_streaming/llama2_streaming
      fetch(apiUrl, {
        method: "POST",
        body: data,
        headers: headers,
      })
        .then((response) => {
          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          if (!response.ok) {
            // handle response error
            observer.error();
          }

          let counter = 0;
          function push() {
            return reader?.read().then(({ done, value }) => {
              if (done && counter >= 1) {
                observer.complete();
                return;
              } else {
                counter++;
              }

              //parse text content from response
              const events = decoder.decode(value).split("\n");
              let chat_id = "";
              let content = "";
              for (let i = 0; i < events.length; i++) {
                const event = events[i];
                if (event && event.slice(0, 8) === "Chat_id:") {
                  let chatIdData = event.split(" ", 2);
                  if (i === 0) {
                    chat_id = chatIdData[1];
                  }
                  // console.log(chat_id);
                  let len = event.split(" ", 2).join(" ").length;
                  let data = event.slice(len);
                  let parsedData = JSON.parse(data.trim());
                  // console.log(data);
                  // console.log(parsedData.generation);
                  // console.log(parsedData.stop_reason);
                  if (!parsedData.stop_reason !== null) {
                    content += parsedData.generation.replace("\n", "\\n");
                  }
                }
                // if (event && event.slice(0, 5) === "Chat_id:") {
                //   let data = event.slice(5);
                //   let parsedData = JSON.parse(data.trim());
                //   if (i === 0) {
                //     chat_id = parsedData.chat_id;
                //   }
                //   if (!parsedData.token.text.includes("</s>")) {
                //     content += parsedData.token.text;
                //   }
                // }
              }
              observer.next(`${content}chat_id:${chat_id}`);
              push();
            });
          }
          setTimeout(() => {
            push();
          }, 1000);
          // push();
        })
        .catch((err: Error) => {
          // handle fetch error
          observer.error();
        });
    });
  }

  getAuthorizationHeader(httpMethod: string, path: string, payload: string) {
    const date =
      new Date().toISOString().replace(/[-:]/g, "").split(".")[0] + "Z";
    const dateYMD = this.datePipe.transform(date, "yyyyMMdd");

    const canonicalRequest = this.getCanonicalRequest(
      httpMethod,
      path,
      date,
      payload
    );

    const stringToSign = this.getStringToSign(date, canonicalRequest);

    const signingKey = this.getSigningKey(date);

    const signature = CryptoJS.HmacSHA256(stringToSign, signingKey).toString(
      CryptoJS.enc.Hex
    );

    // console.log("canonicalRequest: " + canonicalRequest);
    // console.log("stringToSign: " + stringToSign);
    // console.log("signingKey: " + signingKey);
    // console.log("signature: " + signature);

    const authHeaderObj = [
      date,
      `${environment.identityPool.authRequestAlgorithm} Credential=${this.accessKeyId}/${dateYMD}/${environment.identityPool.identityPoolRegion}/${environment.identityPool.serviceName}/aws4_request, SignedHeaders=host;x-amz-content-sha256;x-amz-date;x-amz-security-token, Signature=${signature}`,
    ];

    return authHeaderObj;
  }

  private getCanonicalRequest(
    httpMethod: string,
    path: string,
    date: string,
    payload: string
  ): string {
    const host = path.split("https://")[1].split("/endpoints")[0];
    const endpoint = path.split(".com")[1];

    return `${httpMethod}\n${endpoint}\n\nhost:${host}\nx-amz-content-sha256:${CryptoJS.SHA256(
      payload
    ).toString(CryptoJS.enc.Hex)}\nx-amz-date:${date}\nx-amz-security-token:${
      this.sessionToken
    }\n\nhost;x-amz-content-sha256;x-amz-date;x-amz-security-token\n${CryptoJS.SHA256(
      payload
    ).toString(CryptoJS.enc.Hex)}`;
  }

  private getStringToSign(date: string, canonicalRequest: string): string {
    return `${environment.identityPool.authRequestAlgorithm}\n${date}\n${
      date.split("T")[0]
    }/${environment.identityPool.identityPoolRegion}/${
      environment.identityPool.serviceName
    }/aws4_request\n${CryptoJS.SHA256(canonicalRequest).toString(
      CryptoJS.enc.Hex
    )}`;
  }

  private getSigningKey(date: string) {
    const kDate = CryptoJS.HmacSHA256(
      date.split("T")[0],
      "AWS4" + this.secretAccessKey
    );
    const kRegion = CryptoJS.HmacSHA256(
      environment.identityPool.identityPoolRegion,
      kDate
    );
    const kService = CryptoJS.HmacSHA256(
      environment.identityPool.serviceName,
      kRegion
    );
    const kSigning = CryptoJS.HmacSHA256("aws4_request", kService);

    return kSigning;
  }

  getAuthHeaderForAWSSagemaker(
    task: string,
    model: string,
    httpMethod: string,
    apiURL: string,
    payload: string
  ) {
    // Configure the AWS region
    AWS.config.update({
      region: environment.identityPool.identityPoolRegion,
    });

    this.accessKeyId = localStorage.getItem("accessKeyId");
    this.secretAccessKey = localStorage.getItem("secretAccessKey");
    this.sessionToken = localStorage.getItem("sessionToken");

    const authHeaderObj = this.getAuthorizationHeader(
      httpMethod,
      apiURL,
      payload
    );

    let headers = {};
    if (task === "text_to_image") {
      headers = {
        "Content-Type": "application/octet-stream",
        "X-Amz-Date": authHeaderObj[0],
        Authorization: authHeaderObj[1],
        "X-Amz-Security-Token": this.sessionToken,
        "X-Amz-Content-Sha256": CryptoJS.SHA256(payload).toString(
          CryptoJS.enc.Hex
        ),
        "X-Amzn-SageMaker-Target-Model": model,
      };
    } else {
      headers = {
        "Content-Type": "application/json",
        "X-Amz-Date": authHeaderObj[0],
        Authorization: authHeaderObj[1],
        "X-Amz-Security-Token": this.sessionToken,
        "X-Amz-Content-Sha256": CryptoJS.SHA256(payload).toString(
          CryptoJS.enc.Hex
        ),
      };
    }

    return headers;
  }

  getSageMakerOpenSourceResponse(task: string, model: string, payload: any) {
    const httpMethod = "POST";
    // const apiURL = `https://runtime.${environment.identityPool.serviceName}.${environment.identityPool.identityPoolRegion}.amazonaws.com/endpoints/huggingface-pytorch-tgi-inference-2023-12-12-08-17-26-851/invocations`;
    const apiURL =
      model === "Llama2"
        ? this.llama2APIUrl + "-response-stream"
        : this.mosaicmlAPIUrl;

    let headers = this.getAuthHeaderForAWSSagemaker(
      task,
      "",
      httpMethod,
      apiURL,
      payload
    );

    return new Observable<string>((observer) => {
      fetch(apiURL, {
        method: httpMethod,
        // mode: "no-cors", // Set the mode to 'no-cors'
        body: payload,
        headers: headers,
      })
        .then((response) => {
          const reader = response.body?.getReader();
          const decoder = new TextDecoder();
          if (!response.ok) {
            // handle response error
            observer.error();
          }

          function push() {
            return reader?.read().then(({ done, value }) => {
              if (done) {
                observer.complete();
                return;
              }

              //parse text content from response
              const events = decoder.decode(value).split("\n\n");
              console.log(events);
              debugger;
              let content = "";
              for (let i = 0; i < events.length; i++) {
                const event = events[i].trim();
                // console.log(event);
                // console.log(event.slice(5));

                if (event && event.slice(0, 5) === "data:") {
                  const data = JSON.parse(event.slice(5)); //.split("\n").join("\\n")
                  if (data.generated_text !== null) {
                    break;
                  } else {
                    content += data.token.text || "";
                  }
                  // console.log(content);

                  // let text = "";
                  // if (event.includes('"text":')) {
                  //   let textData = event.split('"text":');
                  //   textData = textData[1].split(',"logprob"');
                  //   text = textData[0].slice(1, -1);
                  // }

                  // let generated_text = "";
                  // if (event.includes('"generated_text":')) {
                  //   let generatedTextData = event.split('"generated_text":');
                  //   generatedTextData =
                  //     generatedTextData[1].split(',"details"');
                  //   generated_text = generatedTextData[0];
                  // }

                  // if (generated_text !== "null") {
                  //   break;
                  // } else {
                  //   content += text || "";
                  // }
                }
              }
              observer.next(content);
              push();
            });
          }
          setTimeout(() => {
            push();
          }, 1000);
        })
        .catch((err: Error) => {
          // handle fetch error
          observer.error();
        });
    });
  }

  getSageMakerTextToImageResponse(
    task: string,
    model: string,
    payload: string
  ) {
    const httpMethod = "POST";
    let headers = this.getAuthHeaderForAWSSagemaker(
      task,
      model,
      httpMethod,
      this.textToImageAPIUrl,
      payload
    );

    return this.http
      .post<any>(this.textToImageAPIUrl, payload, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          // console.log("res = " + res);
          return res;
        })
      );
  }

  triggerChat() {
    this.chatTriggered.emit();
  }

  setSharedData(data: any): void {
    this.sharedData = data;
  }

  getSharedData(): any {
    return this.sharedData;
  }

  getSageMakerModelInfo(data: any): Observable<any> {
    let idToken = localStorage.getItem("id_token");
    const authHeaderVal = "Bearer " + idToken;

    let headers = {
      "Content-Type": "application/json",
      Authorization: authHeaderVal,
    };

    let apiUrl = "";
    if (this.isLocalUser === "true") {
      // For Local User
      apiUrl = "https://mrf4qjy2rj.execute-api.us-east-1.amazonaws.com/v1";
    } else {
      // For Azure AD user
      if (environment.cognito.env_name === "dev") {
        apiUrl = "https://78smlpx3uj.execute-api.us-east-1.amazonaws.com/v1/";
      } else if (environment.cognito.env_name === "alpha-prod") {
        apiUrl = "https://td6n3he41e.execute-api.us-east-1.amazonaws.com/v1/";
      }
    }

    return this.http.post<any>(apiUrl, data, { headers: headers }).pipe(
      map((res: any) => {
        return JSON.parse(res.body);
      })
    );
  }

  getSageMakerModelStatus(data: any): void {
    this.alive = true;
    timer(0, 30000) // Initial delay of 0 milliseconds, subsequent calls every 30000 milliseconds
      .pipe(
        takeWhile(() => this.alive),
        switchMap(() => this.getSageMakerModelInfo(data))
      )
      .subscribe(
        (apiResult) => {
          // console.log("API call successful!", apiResult);
          this.modelStatusRes = apiResult;
          this.modelStatusRes = {
            token: localStorage.getItem("token"),
            userEmail: this.userEmail,
            response_code: apiResult.response_code,
            message: apiResult.message,
            model_name: apiResult.model_name,
            ep_name: apiResult.ep_name,
            ep_arn: apiResult.ep_arn,
            ep_url: apiResult.ep_url,
          };
          this.updateModelStatusInDatabase(this.modelStatusRes);
          if (this.modelStatusRes.response_code === 0) {
            this.alive = false; // Stop the continuous calls if status is 0
            this.chatTriggered.emit();

            // console.log("Model started successfully!", this.modelStatusRes);
            if (data.model_name === "llama2") {
              this.llama2APIUrl = this.modelStatusRes.ep_url;
            } else if (data.model_name === "mosaicml") {
              this.mosaicmlAPIUrl = this.modelStatusRes.ep_url;
            } else {
              this.textToImageAPIUrl = this.modelStatusRes.ep_url;
            }
          } else {
            // Check the result status
            console.log("Checking on Model status...");
          }
        },
        (error) => {
          console.error("API call error:", error);
          this.alive = false; // Stop the continuous calls on error
          this.chatTriggered.emit();
        }
      );
  }

  updateModelStatusInDatabase(modelStatus: any) {
    this.authService.checkTokenExpiry();
    this.http
      .post<any>(this.baseapiUrl + "modelstatus", modelStatus, {
        headers: this.headers,
      })
      .subscribe(
        (res) => {
          // console.log(res);
        },
        (error) => {
          alert(error.error.message);
        }
      );
  }

  getTextToImageTitan(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "image_titan", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteIndustry(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "delete_industry", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deletePersona(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "delete_persona", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getIpAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  postTrackingData(data: any) {
    this.authService.checkTokenExpiry();
    this.http
      .post<any>(this.baseapiUrl + "dos_donts", data, {
        headers: this.headers,
      })
      .subscribe(
        (res) => {
          console.log(res);
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
