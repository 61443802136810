import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormServiceService } from "src/app/module/service/form-service.service";

@Component({
  selector: "app-like-deslike-dialog",
  templateUrl: "./like-dislike-dialog.component.html",
  styleUrls: ["./like-dislike-dialog.component.scss"],
})
export class LikeDislikeDialogComponent implements OnInit {
  modalType: string;
  feedbackForm!: FormGroup;
  formSubmitted: boolean = false;
  token: any;
  userEmail: any;

  constructor(
    public formBuilder: FormBuilder,
    public service: FormServiceService,
    public dialogRef: MatDialogRef<LikeDislikeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.modalType = "";
    this.feedbackForm = this.formBuilder.group({
      additional_feedback: [
        "",
        [Validators.required, Validators.maxLength(260)],
      ],
      modal_type: [],
    });
  }

  ngOnInit(): void {
    this.modalType = this.data.type;
    this.feedbackForm.controls.modal_type.setValue(this.modalType);
  }

  /**
   * Dialog close function
   */
  dialogClose(action: string): void {
    this.dialogRef.close(action);
  }

  /**
   * Save feedback modal data and send status to chat page
   */
  save() {
    this.formSubmitted = true;
    if (this.feedbackForm.valid) {
      let user_message = this.feedbackForm.value.additional_feedback;

      user_message = user_message.replaceAll(">", "/>");
      user_message = user_message.replaceAll("#", " ");

      const feedback_data = {
        chat_id: this.data.chat_id,
        feedback: this.feedbackForm.value.modal_type,
        feedback_comment: user_message,
        user_email: localStorage.getItem("userEmail"),
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      this.service.postFeedbackInfo(feedback_data).subscribe(
        (res) => {
          if (res.status == "success") {
            this.dialogClose(res.status);
          } else {
            alert(res.message);
          }
        },
        (error) => {
          alert(error.error.message);
          console.warn("Unable to save your feedback");
          this.dialogClose("error");
        }
      );
    }
  }
}
