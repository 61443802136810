<div class="modal-wrapper">

    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title>{{actionVal}} Group</h1>
            <mat-icon class="cancel-btn" (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <!-- to save the request Group form information -->
    <div mat-dialog-content class="contain">
        <form [formGroup]="GroupForm" class="add-group">

            <div class="row row-container">
                <!-- Group Name -->
                <div class="col-12 mb-3">
                    <label for="group-name">Group Name<span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input [readonly]="isEdit" type="text" class="form-control rd-colr" formControlName="groupName"
                            id="group-name" aria-describedby="basic-addon3" (input)="onGroupNameFocusOut($event)">
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || GroupForm.controls.groupName.touched) && GroupForm.controls.groupName.errors?.required">
                            Group Name is required
                        </span>
                        <span class="validation-error"
                            *ngIf="GroupForm.controls.groupName.dirty && GroupForm.controls.groupName.errors?.maxLengthExceeded">
                            Group Name cannot exceed 20 characters
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || GroupForm.controls.groupName.dirty) && GroupForm.controls.groupName.errors?.invalidCharacters">
                            Only alpha-numeric, spaces, hyphen, and underscore are allowed
                        </span>
                        <span class="validation-error" *ngIf="duplicateGroupError">
                            {{duplicateGroupErrorMsg}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-12 input-wrapper">
                    <label for="member">Member<span class="text-danger">*</span></label>
                    <!-- <div style="width: 100%;"> -->
                        <div class="input-group" style="flex-wrap: nowrap; gap: 10px;">
                            <mat-form-field>
                                <mat-select
                                    class="addCursor select-members"
                                    (blur)="selectClosed($event)"
                                    [formControl]="selectFormControl"
                                    multiple
                                    panelClass="multiSelectUserPanel"
                                >

                                    <mat-optgroup>
                                        <mat-form-field>
                                            <input class="select-search" autocomplete="on" matInput
                                                [formControl]="searchTextboxControl" #search />
                                            <button [disableRipple]="true" *ngIf="search.value" matSuffix
                                                mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
                                                <mat-icon>close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </mat-optgroup>

                                    <mat-optgroup *ngIf="(filteredMembers | async)?.length == 0">
                                        <div style="padding-left: 15px;">No results found!</div>
                                    </mat-optgroup>
                                    
                                    <mat-optgroup>
                                        <div style="margin: 5px 16px;">
                                            <mat-checkbox [(ngModel)]="allMemberSelected"
                                                [ngModelOptions]="{standalone: true}"
                                                (change)="toggleAllSelection()">Select All</mat-checkbox>
                                        </div>
                                    </mat-optgroup>

                                    <mat-option (onSelectionChange)="selectionChange($event)"
                                        *ngFor="let member of filteredMembers | async" [value]="member">
                                        {{ member.employeeName }}
                                    </mat-option>

                                </mat-select>
                            </mat-form-field>
                            <button class="btn btn-primary" (click)="clearSelection()" style="width: 40%;">
                                Unselect All
                            </button>
                        </div>
                    <!-- </div> -->
                    <div class="text-danger">
                        <span class="validation-error" *ngIf="memberErr">
                            {{memberErrMsg}}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- description -->
                <div class="col-12  mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Description<span
                            class="text-danger">*</span></label>
                    <textarea class="form-control rd-colr" formControlName="description"
                        id="exampleFormControlTextarea1" rows="3"></textarea>
                    <!-- <div class="alert">
                        <span class="validation-error" *ngIf="descriptionErr">
                            {{descriptionErrMsg}}
                        </span>
                    </div> -->
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || GroupForm.controls.description.touched) && GroupForm.controls.description.errors?.required">
                            Description is required
                        </span>
                        <span class="validation-error"
                            *ngIf="GroupForm.controls.description.dirty && GroupForm.controls.description.errors?.maxlength">
                            Description cannot exceed 250 characters
                        </span>
                    </div>
                </div>
            </div>

        </form>
    </div>
    <!-- to save and close button -->
    <div mat-dialog-actions>
        <button class="btn btn-secondary-outline" (click)="onNoClick()">Close</button>
        <button class="btn btn-primary" (click)="save()" cdkFocusInitial>Save</button>
    </div>
    <div role="status" *ngIf="isLoading">
        <app-loader></app-loader>
    </div>
</div>