import { Component, OnInit,ViewChild } from '@angular/core';
import { MatSidenav } from "@angular/material/sidenav";
import { SowGenerationServiceService } from "../../service/sow-generation-service.service";
import { FormServiceService } from "../../service/form-service.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-code-generation-page',
  templateUrl: './code-generation-page.component.html',
  styleUrls: ['./code-generation-page.component.scss']
})
export class CodeGenerationPageComponent implements OnInit {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  instance_id: any;

  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  intervalId: any;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;
  sessionStatus: any;
  selectedQualifier: string = "idea";


  constructor(
    public route: Router,
    public sowService: SowGenerationServiceService,
    public service: FormServiceService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService,

  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");

  }

  ngOnInit() {

    this.selectedQualifier = window.location.pathname
      .split("/")
      .filter((ele: any) => ele)[1];
  }

  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  logout() {
    this.authService.logout();
  }

}
