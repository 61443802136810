import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminSettingsComponent } from "./admin-settings.component";

const routes: Routes = [
  {
  path: "",
    component: AdminSettingsComponent,
    children: [
      {
        path: "admin-settings-page",
        loadChildren: () =>
          import("./admin-settings-home-page/admin-settings-page.module").then(
            (m) => m.AdminSettingsPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminSettingsRoutingModule {}
