import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { PersonaDialog } from "./mat-dialog/persona-dialog/persona.dialog";
import { MatDialog } from "@angular/material/dialog";
import { IndustryDialog } from "./mat-dialog/industry-dialog/industry.dialog";
import { FormServiceService } from "../../service/form-service.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "src/app/module/snackbar/snackbar.component";
import { InfoDialogComponent } from "./mat-dialog/info-dialog/info-dialog.component";
import { ConfirmationDialog } from "./mat-dialog/confirmation-dialog/confirmation.dialog";
import { TextToImageDialogComponent } from "./mat-dialog/text-to-image-dialog/text-to-image-dialog.component";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page-chat.component.scss"],
})
export class HomePageComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  selectedType: string = "open_source";
  selectedTask: string = "text_to_text";
  selectedIndustry: string = "None";
  selectedModal: string = "llama3";
  selectedModalImage: string = "";
  selectedPersona: any = "None";
  showImage: boolean = false;
  modelInfo: any = [];
  modelImageInfo: any = [];
  industryInfo: any = [];
  personaInfo: any = [{ industry: "", persona_id: "None", persona: "None" }];
  instance_id: any;
  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  authToken: any;
  intervalId: any;
  modelStatusRes: any;
  deleteFolderFlag: boolean = false;
  deleteDocumentFlag: boolean = false;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;
  sessionStatus: any;
  snackbarFlag: boolean = false;
  clickEventTypeChange!: Subscription;

  token: any;
  userEmail: any;

  private subscription: Subscription;
  private subscription2: Subscription;

  constructor(
    private observer: BreakpointObserver,
    public dialog: MatDialog,
    public route: Router,
    public service: FormServiceService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService,
    private snackBar: MatSnackBar
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");

    this.clickEventTypeChange = this.service
      .getTypeChange()
      .subscribe(async (value) => {
        if (value === "done") {
          this.selectedType = "open_source";
          this.selectedIndustry = "None";
          this.selectedPersona = "None";
          this.getTypeInfo();
        }
      });

    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");

    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    // Checks every 5 second for session expiration
    this.intervalId = setInterval(() => {
      if (this.isLocalUser === "true") {
        // For Local User
        this.authService.checkAndRefreshSession();
      } else {
        // For Azure AD user
        this.authService.checkAndRefreshSessionForAWSCognitoAzure();
      }
    }, 5000);

    // Integrating a user pool with an identity pool
    // this.authService.integrateUserPoolWithIdentityPool();

    // get type value
    const typeVal = this.service.getSharedData();
    this.service.newInstance = false;
    if (
      typeVal === "Text to Image" ||
      localStorage.getItem("prevSelectedTask") === "text_to_image"
    ) {
      this.selectedTask = "text_to_image";
      this.service.selectedTask = "text_to_image";
      localStorage.setItem("prevSelectedTask", "text_to_image");
      this.showImage = true;
    } else {
      this.selectedTask = "text_to_text";
      this.service.selectedTask = "text_to_text";
      localStorage.setItem("prevSelectedTask", "text_to_text");
    }

    this.subscription = this.service.methodCalled$.subscribe((data: any) => {
      this.setIndustrySelected(data);
    });

    this.subscription2 = this.service.personaLoaded$.subscribe(() => {
      this.getPersonaInfo();
    });
  }

  async ngOnInit() {
    this.getModelInfo();
    if (this.selectedTask === "text_to_text") {
      this.getIndustryInfo();
    }
  }

  stopInterval(): void {
    clearInterval(this.intervalId);
  }

  ngOnDestroy(): void {
    this.stopInterval();
  }

  getTaskInfo() {
    if (this.selectedTask === "text_to_image") {
      this.showImage = true;
      this.service.sendTabChangeMsg();
      this.service.selectedTask = "text_to_image";
      this.selectedPersona = "";
      this.selectedIndustry = "";
      this.getModelInfo();
      // this.newChat();
    } else if (this.selectedTask === "text_to_text") {
      this.showImage = false;
      this.service.sendTabChangeMsg();
      this.service.selectedTask = "text_to_text";
      this.selectedPersona = "None";
      this.selectedIndustry = "None";
      this.getModelInfo();
      this.getIndustryInfo();
      // this.newChat();
    }
  }
  /* delete dialog  */
  Delete() {
    this.service.isDelete = true;
    const dialogRef = this.dialog.open(PersonaDialog, {
      width: "30%",
      data: this.selectedPersona,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        let index = this.personaInfo.findIndex(
          (x: any) => x.persona == this.selectedPersona
        );
        if (index > -1) {
          this.personaInfo.splice(index, 1);
        }
      }
    });
  }
  getTypeInfo() {
    this.onModalAndTypeChange();
    if (this.selectedType === "open_ai") {
      this.service.isSnackbarOpen = true;
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        disableClose: true,
        width: "40%",
        data: {
          content:
            "While interacting with OpenAI models, it is critical to NOT upload documents or enter prompts which include confidential data, client information, or any content that should be kept secure. All user prompts and activity is tracked.",
        },
        panelClass: "openAI-dialog-container",
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === "closed") {
          this.selectedType = "open_source";
          this.getTypeInfo();
        }
      });
      // this.showCustomSnackbar("While interacting with OpenAI models, please be cautious and ensure that it is essential to refrain from uploading any documents which includes confidential data, client information, or any content that should be kept secure.");
      // this.customModal();
      // You can now store snackbarRef and use it to dismiss the snackbar when needed
      // For example, if you have a "Done" button, you can call snackbarRef.dismiss() when the button is clicked
    } else {
      this.service.isSnackbarOpen = false;
    }
    this.getModelInfo();
  }

  onModalAndTypeChange() {
    this.service.disableInput();
  }

  showCustomSnackbar(message: string) {
    let snackbarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message },
      duration: 10000, // Set the duration for which the snackbar will be displayed
      verticalPosition: "top", // You can adjust the position of the snackbar
      horizontalPosition: "right",
      panelClass: ["custom-snackbar"], // Add a custom CSS class for styling if needed
    });
    snackbarRef.afterDismissed().subscribe(() => {
      // Set your flag here
      this.service.isSnackbarOpen = false;
    });
  }

  ngAfterViewInit() {
    /*  to display responsive sidebar */
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }
  /*  get model infornmation from API */
  textToImageModalSlugToName(modelSlug: any) {
    let name;
    switch (modelSlug) {
      case "openJourney":
        name = "OpenJourney";
        break;
      case "stableDiffusion-2":
        name = "StableDiffusion-2";
        break;
      case "realisticVision":
        name = "RealisticVision";
        break;
      case "dreamlikePhotoreal":
        name = "DreamlikePhotoreal";
        break;
    }
    return name;
  }
  getModelInfo() {
    if (this.selectedTask === "text_to_image") {
      let data = {
        task: "image",
        username: this.username,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      /* text-to-image model information API */
      this.service.postImageModelInfo(data).subscribe(
        (res) => {
          if (res.status == "success") {
            let arr = [];
            arr.push(res.model_tooltip[0]);
            // this.modelImageInfo = res.model_tooltip;
            this.modelImageInfo = arr;
            this.selectedModalImage = res.model_tooltip[0].title;
            this.service.sendTabChangeMsg();
            // this.newChat();
          } else {
            alert(res.message);
          }
        },
        (error) => {
          alert(error.error.message);
        }
      );
    } else {
      let data = {
        type_model: this.selectedType,
        username: this.username,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      this.service.postModelInfo(data).subscribe(
        (res) => {
          if (res.status == "success") {
            this.modelInfo = res.models_tooltip;
            this.service.selectedModel = this.modelInfo[0].title;
            this.selectedModal = this.modelInfo[0].title;
            this.service.sendTabChangeMsg();
            // this.newChat();
          } else {
            alert(res.message);
          }
        },
        (error) => {
          alert(error.error.message);
        }
      );
    }
  }
  /*  get industry infornmation from API */
  getIndustryInfo() {
    this.industryInfo = [];
    let data = {
      // "username" : "test"
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.postIndustryInfo(data).subscribe(
      (res) => {
        if (res.status == "success") {
          this.industryInfo = res.skills;
          this.personaInfo = [];
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }
  /*  get persona infornmation from API */

  getPersonaInfo() {
    this.selectedPersona = "None";
    this.personaInfo = [];
    let data = {
      industry_id: this.selectedIndustry,
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };

    this.service.postPersonaInfo(data).subscribe((res) => {
      // Assuming res.skills is an array, loop through it
      for (let i = 0; i < res.skills.length; i++) {
        // Check if the persona_id already exists in personaInfo
        const personaIdExists = this.personaInfo.some(
          (item: any) => item.persona_id === res.skills[i].persona_id
        );

        if (!personaIdExists) {
          // If it doesn't exist, add it to personaInfo
          this.personaInfo.push({
            industry: res.skills[i].industry,
            industry_id: res.skills[i].industry_id,
            persona_id: res.skills[i].persona_id,
            persona: res.skills[i].persona,
          });
        }
      }
    });
  }

  /*  persona dialog */
  openPersona() {
    this.service.isDelete = false;
    let info = {
      type: this.selectedType,
      modal: this.selectedModal,
      industry: this.selectedIndustry,
    };
    if (this.selectedIndustry !== "") {
      const dialogRef = this.dialog.open(PersonaDialog, {
        width: "50%",
        data: info,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "ok") {
          this.getPersonaInfo();
        }
      });
    }
  }
  /*  industry dialog */
  openIndustry() {
    let info = {
      type: this.selectedType,
      modal: this.selectedModal,
    };
    const dialogRef = this.dialog.open(IndustryDialog, {
      width: "50%",
      data: info,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.status === "success") {
          this.getIndustryInfo();
          this.selectedIndustry = result.industry_id;
        }
      }
    });
  }
  /* on click of new chat button */
  newChat(value: any) {
    if (value === "text_to_image") {
      const dialogRef = this.dialog.open(TextToImageDialogComponent, {
        disableClose: true,
        width: "40%",
        data: {
          content:
            "This Text to Image model is limited to generating fictional images only. It cannot produce real images, depictions of famous personalities, technical architecture or diagrams.",
        },
        panelClass: "text-to-image-dialog-container",
      });
      dialogRef.afterClosed().subscribe((result) => {
        
        this.service.textToImageFlag = true
        if (result === "closed") {
          this.newChatSessionCreation();         
        }
      });
    }
    else {
      this.service.textToImageFlag = true;
      this.newChatSessionCreation();
    }
  }

  newChatSessionCreation(){
    if ( this.service.textToImageFlag) {
      this.service.newChat = true;
      this.service.showChat = false;
      this.service.regenerateFlag = false;
      this.service.stopGeneratorFlag = false;
      this.service.newInstance = false;
      const data = {
        username: this.username,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };

      this.service.selectedType = this.selectedType;
      this.service.selectedModel = this.selectedModal;

      if (this.selectedPersona === "None") {
        this.service.isPersonaSelected = "None";
      } else {
        this.personaInfo.forEach((value: any) => {
          if (value.persona_id === this.selectedPersona) {
            this.service.isPersonaSelected = value.persona;
          }
        });
      }

      this.service.stopGenerator(data).subscribe((res) => {
        if (res.status === "success") {
        }
      });

      if (this.selectedTask === "text_to_image") {
        /** API to get Model Turn ON on Sagemaker */
        // let model_name = "sd2";
        // let apiData = {
        //   env_name: environment.env_name,
        //   model_name: model_name,
        // };

        // if (this.service.textToImageAPIUrl === "") {
        // this.service.alive = true;
        // this.service.getSageMakerModelStatus(apiData);
        // }

        this.service.selectedModelImage = this.selectedModalImage;
        this.service.selectedType = "-";

        let data = {
          model: this.selectedModalImage,
          username: this.username,
          token: localStorage.getItem("token"),
          userEmail: this.userEmail,
        };
        this.service.selectedModelImage = this.selectedModalImage;
        this.service.showChatLoader = true;
        /* text-to-image sandbox new chat click api */
        this.service.postSandboxNewChatInfo(data).subscribe(
          (res) => {
            if (res.status == "success") {
              this.service.newInstance = true;
              this.instance_id = res.instance_id;
              this.service.taskConvertor = "text_to_image";
              this.service.sendChatMsg();
              this.service.instance_id = this.instance_id;
              this.service.triggerChat();
            } else {
              this.service.newInstance = false;
              alert(res.message);
            }
          },
          (error) => {
            this.service.newInstance = false;
            this.service.showChatLoader = false;
            alert(error.error.message);
          }
        );
      } else {
        /** API to get Model Turn ON on Sagemaker */
        // && this.service.llama2APIUrl === ""
        // && this.service.mosaicmlAPIUrl === ""

        // if (this.selectedModal === "Llama2") {
        //   let apiData = {
        //     env_name: environment.env_name,
        //     model_name: "llama2",
        //   };
        //   this.service.alive = true;
        //   this.service.getSageMakerModelStatus(apiData);
        // } else if (this.selectedModal === "MosaicML") {
        //   let apiData = {
        //     env_name: environment.env_name,
        //     model_name: "mosaicml",
        //   };
        //   this.service.alive = true;
        //   this.service.getSageMakerModelStatus(apiData);
        // }

        let data = {
          type: this.selectedType,
          model: this.selectedModal,
          industry: this.selectedIndustry,
          persona: this.selectedPersona,
          username: this.username,
          token: localStorage.getItem("token"),
          userEmail: this.userEmail,
        };
        this.service.selectedModel = this.selectedModal;
        this.service.showChatLoader = true;
        /* text-to-text sandbox new chat click api */
        this.service.postNewChatInfo(data).subscribe(
          (res) => {
            if (res.status == "success") {
              this.service.newInstance = true;
              this.instance_id = res.instance_id;
              this.service.taskConvertor = "text_to_text";
              this.service.sendChatMsg();
              this.service.instance_id = this.instance_id;
              this.service.triggerChat();
            } else {
              this.service.newInstance = false;
              alert(res.message);
            }
          },
          (error) => {
            this.service.newInstance = false;
            this.service.showChatLoader = false;
            alert(error.error.message);
          }
        );
      }
    }
  }

  logout() {
    this.service.showChatLoader = true;
    this.authService.logout();
  }

  // Redirect to dashboard
  dashboardRedirection() {
    localStorage.setItem("prevSelectedTask", "");
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  setIndustrySelected(industry_id: string) {
    this.selectedIndustry = industry_id;
  }

  onChangeOfSelectFolder() {
    if (!this.deleteFolderFlag) {
      this.getPersonaInfo();
    }
    this.onModalAndTypeChange();
  }

  isFolderSelected(industry_id: string): boolean {
    return this.selectedIndustry === industry_id;
  }

  deleteFolder(industryDetail: any) {
    this.deleteFolderFlag = true;

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: "40%",
      data: {
        content: `This action will delete the <b> ${industryDetail.industry} </b> folder and sub-sequent documents. Are you sure to delete?`,
        action: "Delete",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        let data = {
          token: localStorage.getItem("token"),
          userEmail: this.userEmail,
          username: this.username,
          industry: industryDetail.industry,
          industry_id: industryDetail.industry_id,
        };
        this.service.deleteIndustry(data).subscribe(
          (res) => {
            if (res.status == "success") {
              this.getIndustryInfo();
            } else {
              alert(res.message);
            }
          },
          (error) => {
            alert(error.error.message);
          }
        );
      }
      this.deleteFolderFlag = false;
      this.selectedIndustry = "None";
      this.selectedPersona = "None";
    });
  }

  isDocumentSelected(persona_id: string): boolean {
    return this.selectedPersona === persona_id;
  }

  deleteDocument(personaDetail: any) {
    this.deleteDocumentFlag = true;

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: "40%",
      data: {
        content: `This action will delete the <b> ${personaDetail.persona} </b> document. Are you sure to delete?`,
        action: "Delete",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        let data = {
          token: localStorage.getItem("token"),
          userEmail: this.userEmail,
          username: this.username,
          persona: personaDetail.persona,
          persona_id: personaDetail.persona_id,
          industry_id: personaDetail.industry_id,
        };
        this.service.deletePersona(data).subscribe(
          (res) => {
            if (res.status == "success") {
              this.getPersonaInfo();
            } else {
              alert(res.message);
            }
          },
          (error) => {
            alert(error.error.message);
          }
        );
      }
      this.deleteDocumentFlag = false;
      this.selectedPersona = "None";
    });
  }
}
