<div style=" font-family: 'Poppins';padding: 10px;">
    <div class="overview1">
      
      <mat-accordion >                 
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordian-title">
              Who We Are
            </mat-panel-title>
          </mat-expansion-panel-header>
          <img width="950" src="../../../assets/icons/about-genesys/who-we-are.jpg" />
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordian-title">
              Enabling Generative AI Journeys
            </mat-panel-title>
          </mat-expansion-panel-header>
          <img width="950" src="../../../assets/icons/dashboard/about-ideation.PNG" />
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordian-title">
              What Is Genysys
            </mat-panel-title>
          </mat-expansion-panel-header>
          <img width="950" src="../../../assets/icons/about-genesys/what is genesys.jpg" />
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title class="accordian-title">
              Integrated Models
            </mat-panel-title>
          </mat-expansion-panel-header>
          <img width="950" src="../../../assets/icons/about-genesys/models.jpg" />
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    
  </div>
