import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { FormServiceService } from "../service/form-service.service";
import { AuthServiceService } from "../service/auth-service.service";
import { DashboardServiceService } from "../service/dashboard-service.service";
import { RequestNewUseCase } from "./component/request-new-usecases/request-new-usecases";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  poolData: any;
  userPool: any;
  panelOpenState = false;

  isAdmin: any;
  isCognitoAdminGrp: any;
  isManager: any;
  isLocalUser: any;
  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  userDesignation: any;

  isAnalytics: boolean = false;

  intervalId: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;
  sessionStatus: any;

  allUseCase: boolean = true;
  textGeneration: boolean = false;
  adminSettings: boolean = false;
  analysis: boolean = false;
  imageGeneration: boolean = false;
  softwareDevelopment: boolean = false;
  multiModal: boolean = false;
  interaction: boolean = false;
  faq: boolean = false;
  walkthough: boolean = false;
  loading: boolean = false;
  aboutGenesys: boolean = false;
  hrMenu: boolean = false;

  /**
   * Array of object contains list of documents
   */
  documents = [
    {
      title: "User Guide",
      icon: "./../../../assets/icons/dashboard/document-summarization.svg",
      url: "./../../../assets/documents/GenysysGuide.pdf",
      target: "_blank",
    },
  ];

  items = [
    {
      type: "Overview",
      title: "Genysys Overview",
      icon: "./../../../assets/icons/dashboard/login-logo.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/ER9SL662KGVMpESKsv1QI7sBBY9QZmN3IkGOrmfR6UyTAQ?e=EkAVJ3",
    },
    {
      type: "Text to Text",
      title: "Text to Text",
      icon: "./../../../assets/icons/dashboard/text2text.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/EQBdOrcCrtRJl-_TVCumaqkBsM5mtrfkNAR0Z5YE0-MeDQ?e=tXSdXs",
    },
    {
      type: "Document Upload",
      title: "Document Upload",
      icon: "./../../../assets/icons/dashboard/text2text.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/ER7_D2gYg39JlrEl5W2HRZYB50SKHUZiA_pgZVQTd51nkw?e=CVafcE",
    },
    {
      type: "Text to Image",
      title: "Text to Image",
      icon: "./../../../assets/icons/dashboard/text2image.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/EaSzSYpM4JhHiBxZETeZg5IBVSsOZERYazTSpeYfw3diTw?e=ySWSSa",
    },
    {
      type: "Sow Generation",
      title: "SOW Generation",
      icon: "./../../../assets/icons/dashboard/sow-overview.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/Ecr16DrynvhLndTlLW3htkQByuR0irbunoZSa1fBs9OTOw?e=0Vtlhm",
    },
    {
      type: "ConvoBI",
      title: "Conversational BI",
      icon: "./../../../assets/icons/dashboard/convobi.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/EUiNmxnYoMZLsr9yZY1X0OQBiuJFl3kv5Kcp1WikQchdbw?e=wUqWKT",
    },
    {
      type: "Code Conversion",
      title: "Code Conversion",
      icon: "./../../../assets/icons/dashboard/codeconversion.PNG",
      url: "https://isplahd.sharepoint.com/:v:/s/Apexon-Genesys/EYeHIanfri5Fu0vUhOr9RDsB03lAlXXO8mbwu91H7xPm5Q?e=cdWdK8",
    },
    // Add more items as needed
  ];

  /**
   * Array of object contains left side FAQ points
   */
  faqLeft = [
    {
      title: "What is the purpose of Genysys?",
      content:
        "It is Generative AI accelerator tool, developed by Apexon as an internal solution to showcase and leverage our generative AI capabilities. It's aimed at providing better demos for clients, operational efficiency for internal teams, and enhancing services offered to clients.",
      counter: 1,
    },
    {
      title: "How does this tool interact with models?",
      content:
        "You can connect the tool to both open and closed source models. This flexibility allows for a wider range of AI interactions and capabilities.",
      counter: 3,
    },
    {
      title: "Which LLM models are available in Genysys?",
      content:
        "• Open Source - llama3, Titan Text G1-Lite, Claude-3.5, Jurassic-2 Mid\n" +
        "• Open AI - gpt-4o-mini, gpt-4-turbo\n" +
        "• Text to Image – Titan Image Generator G1",
      counter: 5,
    },
    {
      title: "What are the current features - Use Cases?",
      content:
        "1. Text to Text Generation\n2. SOW Generation\n3. Text to Image\n4. Document Querying\n5. Conversational BI\n6. Code & DB Query Conversion\n7. Requirement Generator\n8. Natural Query Engine",
      counter: 7,
    },
    {
      title: "How can I give Feedback?",
      content:
        "When you interact by giving prompts & receive responses – Copy, Like, Dislike buttons will be visible. Clicking on Like or Dislike you will receive a pop up, that will let you give open ended feedback on the responses.",
      counter: 9,
    },
    {
      title: "What is the Use Case - SOW Creation?",
      content:
        "Generate a SOW draft for the project by providing required information on the projects.",
      counter: 11,
    },
    {
      title: "What is the Use Case – Conversational BI?",
      content:
        "Data-driven insights, understanding data through Graphs, Charts.",
      counter: 13,
    },
    {
      title: "What is the Use Case – Code Conversion?",
      content:
        "It can convert code syntax between different programming languages and also has the capability to convert database query syntax.",
      counter: 15,
    },
    {
      title: "What is the Use Case - Natural Query Engine?",
      content:
        "Converts natural language queries into database queries, making it intuitive and understandable for users. Models interpret human language and retrieve relevant data from databases. ",
      counter: 17,
    },
  ];

  /**
   * Array of object contains right side FAQ points
   */
  faqRight = [
    {
      title: "How do I Login?",
      content:
        "Click on the button ‘Login with Apexon Account’ on the login page. It is SSO integrated with the Apexon domain credentials which lets you in automatically once you are signed in with your Apexon domain credentials.",
      counter: 2,
    },
    {
      title: "Can I upload and customize models with this tool?",
      content:
        "No, the tool will not permit user to upload any models. The user needs to select the list of foundation models available from the list and can also assign a persona to the models, ensuring the AI's output meets your specific needs. Your feedback is also welcome and can be used to refine the model's performance.",
      counter: 4,
    },
    {
      title:
        "Any disclaimers to be aware of while using the Closed Source Models / OpenAI?",
      content:
        "While interacting with OpenAI models, please be cautious and ensure that it is essential to refrain from uploading any documents which includes confidential data, client information, or any content that should be kept secure.",
      counter: 6,
    },
    {
      title: "What new features can we expect in the future?",
      content:
        "We are working on integrating more use cases that will be announced as we prioritize & progress with development.\n" +
        "\n1. Advanced Conversational BI - enhancements to Conversation BI" +
        "\n2. SLM Custom Training, Fine-Tuning" +
        "\n3. Generate Development Code from Requirements or user stories" +
        "\n4. Support Ticket Analysis" +
        "\n5. Resume Ranking, Interview Q&A generation, Custom Training Paths" +
        "\n6. New Prospect Insights and Pitch Material Generation" +
        "\n7. Document Comparison Enhancements" +
        "\n8. Engineering Excellence",
      counter: 8,
    },
    {
      title: "What is the Use Case - Text to Text?",
      content:
        "Use the models to generate contextually relevant text in response to various open-ended prompts for summarization, translation, question-answering, creative writing.",
      counter: 10,
    },
    {
      title: "What is the Use Case - Document Summarization?",
      content:
        "Unravel lengthy documents, extracting key insights, crucial details, and facilitating quick understanding thorough targeted prompts relevant to the document. Create your folder, place document inside it, and Click on New Chat to initiate. When you ask specific relevant prompts from the uploaded document, the model will give response to it.",
      counter: 12,
    },
    {
      title: "What is the Use Case- Text To Image?",
      content:
        "Explore Text to Image model which transform text into captivating images through generative AI technology and provide option to Download the images.\n" +
        "This text-to-image model is limited to generating fictional images only. It cannot produce real images, depictions of famous personalities, technical architecture or diagrams.",
      counter: 14,
    },
    {
      title: "What is the Use Case- Requirement Generator?",
      content:
        "Generate requirements for any application ideas. It can be broken down to features, stories, acceptance criteria, test cases. also, it can produce Requirement Granularity Index.",
      counter: 16,
    },
  ];

  constructor(
    private route: Router,
    public service: FormServiceService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService,
    public dialog: MatDialog
  ) {
    if (this.dashboardService.activeMenu !== "") {
      this.activateMenu(this.dashboardService.activeMenu);
    }

    this.isAdmin = localStorage.getItem("isAdmin") === "true" ? true : false;
    this.isCognitoAdminGrp =
      localStorage.getItem("isCognitoAdminGrp") === "true" ? true : false;
    this.isManager =
      localStorage.getItem("isManager") === "true" ? true : false;
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
    this.userDesignation = localStorage.getItem("userDesignation");

    if (this.isAdmin) {
      if (localStorage.getItem("admin_groups")?.includes("Super User")) {
        this.isAnalytics = true;
      }
    }

    // Checks every 5 second for session expiration
    this.intervalId = setInterval(() => {
      if (this.isLocalUser === "true") {
        // For Local User
        this.authService.checkAndRefreshSession();
      } else {
        // For Azure AD user
        this.authService.checkAndRefreshSessionForAWSCognitoAzure();
      }
    }, 5000);

    // Integrating a user pool with an identity pool
    // this.authService.integrateUserPoolWithIdentityPool();

    localStorage.removeItem("prevSelectedTask");
  }

  ngOnInit() {}

  activateMenu(menuName: string) {
    switch (menuName) {
      case "Text Generation": {
        this.allUseCase = false;
        this.textGeneration = true;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "Analysis": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = true;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "Image Generation": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = true;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "Software Development": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = true;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "HR": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = true;
        break;
      }
      case "Multi-Modal": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = true;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "Interaction": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = true;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "FAQ": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = true;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "WALKTHROUGH": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = true;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "Model Portfolio": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "Admin Settings": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = true;
        this.aboutGenesys = false;
        this.hrMenu = false;
        break;
      }
      case "About Genesys": {
        this.allUseCase = false;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = true;
        this.hrMenu = false;
        break;
      }
      default: {
        this.allUseCase = true;
        this.textGeneration = false;
        this.analysis = false;
        this.imageGeneration = false;
        this.softwareDevelopment = false;
        this.multiModal = false;
        this.interaction = false;
        this.faq = false;
        this.walkthough = false;
        this.adminSettings = false;
        this.aboutGenesys = false;
        this.hrMenu = false;
      }
    }
  }

  requestNewUseCase() {
    const dialogRef = this.dialog.open(RequestNewUseCase, {
      width: "50%",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openChatPage(task: any) {
    this.service.setSharedData(task);
    this.route.navigate(["/home"]);
  }

  openRequirementQualification() {
    this.route.navigate(["/requirement-generator/idea"]);
  }

  openAdminFeedback() {
    this.route.navigate(["/admin-settings/admin-settings-page/feedback"]);
  }

  openAdminCosting() {
    this.route.navigate(["/admin-settings/admin-settings-page/cost"]);
  }

  openAdminAccessList() {
    this.route.navigate(["/admin-settings/admin-settings-page/access-list"]);
  }

  openAdminAnalytics() {
    this.route.navigate(["/admin-settings/admin-settings-page/analytics"]);
  }

  openAdminPortfolio() {
    this.route.navigate([
      "/admin-settings/admin-settings-page/model-portfolio",
    ]);
  }

  openSowGeneration() {
    this.route.navigate(["/sow-generation"]);
  }

  openConvoBI() {
    this.route.navigate(["/convo-bi-home"]);
  }

  openNaturalQueryEngine() {
    this.route.navigate(["/natural-query-engine"]);
  }

  openApiTesting() {
    this.route.navigate(["/api-testing"]);
  }

  openCodeConversion() {
    this.route.navigate(["/code-conversion"]);
  }

  openPdfComparison() {
    this.route.navigate(["/document-comparison"]);
  }

  navigateToCodeGeneration() {
    this.route.navigate(["/code-generation"]);
  }

  openPolicyChatBot() {
    window.open("https://policybot.apexon-apexonhub.com/idpresponse", "_blank");
  }

  logout() {
    this.loading = true;
    this.authService.logout();
  }

  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }
}
