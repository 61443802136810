import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../loader/loader.module';
import { CodeGenerationComponent } from './code-generation.component';
import { CodeGenerationPageComponent } from './code-generation-page/code-generation-page.component';
import { CodeComposerFormComponent } from './code-generation-page/code-composer-form/code-composer-form.component';
import { PrismHighlightDirective } from './prism-highlight.directive';
import { HighlightService } from './highlight.service';
@NgModule({
  declarations: [
    CodeGenerationPageComponent,
    CodeGenerationComponent,
    CodeComposerFormComponent,
    PrismHighlightDirective
  ],
  imports: [CommonModule, ReactiveFormsModule, LoaderModule],
  providers: [
    HighlightService
  ]
})
export class CodeGenerationModule { }