import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomePageComponent } from './home-page/home-page.component';
import { HeaderComponent } from './home-page/component/header/header.component';
import { HomePageModule } from './home-page/home-page.module';

@NgModule({
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    HomePageModule,
  ],
  exports: [
    HeaderComponent
  ],
})
export class HomeModule { }
