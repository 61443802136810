<section id="hero">
  <div class="content">

    <div *ngIf="service.isLoading">
      <app-loader></app-loader>
    </div>

    <h1>Pdf Comparison</h1>
    <div class="chat-window">

      <div class="message info">
        {{ service.pdfComparisionAssiatnceHeaderText}}
      </div>

      <div class="message info">
        {{ service.pdfComparisionMasterFileAssiatnceText}}
      </div>

      <div *ngIf="service.isMasterUploaded" class="message info">
        {{ service.pdfComparisionFileToCompareAssiatnceText }}
      </div>

      <div *ngIf="service.isCompareReady" class="message info">
        {{ service.pdfComparisionNewSessionAssiatnceText }}
      </div>
    </div>

    <div *ngIf="service.showTextDiff" class="message info">

      <div [innerHTML]="service.formattedHtml"></div>

    </div>

  </div>
</section>