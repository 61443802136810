import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReqGenerationPageRoutingModule } from './req-generation-page-routing.module';
import { ReqGenerationPageComponent } from './req-generation-page.component';
import { ReqFormDataComponent } from './req-assistance-form-data/req-form-data.component';
import { ReqQualifierTableComponent } from './req-qualifier-table/req-qualifier-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoaderModule } from '../../loader/loader.module';
@NgModule({
  declarations: [
    ReqGenerationPageComponent,
    ReqFormDataComponent,
    ReqQualifierTableComponent,
  ],
  imports: [
    CommonModule,
    ReqGenerationPageRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatStepperModule,
    MatCheckboxModule,
    LoaderModule
  ],
  exports: [ReqFormDataComponent, ReqQualifierTableComponent],
})
export class ReqGenerationPageModule {}
