<div class="qualifier-title">
	<img src="../../../../assets/img/symbol.svg" alt="logo">
	<h3>Generated App {{reqQualifierService.selectedQualifier.charAt(0).toUpperCase()+reqQualifierService.selectedQualifier.slice(1).replaceAll('_', ' ')}}</h3>
</div>
<div class="input-wrapper">
	<div class="custom-select-container custom-select-container3">
		<select
			id="inp3"
			class="form-select"
			aria-label="Default select example"
			(change)='onLimitChange($event)'
		>
			<option 
				*ngFor="let item of showResultOPtions; let i = index"
				[value]="item.key"
				title="{{item.text}}"
			>
				{{item.text}}
			</option>
		</select>
	</div>
</div>


<div class="content-wrapper">
	<ol>
		<li *ngFor="let item of tableData; index as i">
			<div class="content-item">
				<div class="addCursor idea" #contentToCopy>
					{{ item }}
				</div>
				<div class="action">
					<span>
						<img
							src="../../../../../assets/icons/copy_to_icon.svg"
							class="addCursor"
							alt='copy'
							(click)='copyToClipboard(contentToCopy, true)'
							[title]='"Copy to "+getTitle(reqQualifierService.selectedQualifier)'
						/>
					</span>
					<span>
						<img
							src="../../../../../assets/icons/copy-icon.svg"
							class="addCursor"
							(click)='copyToClipboard(contentToCopy, false)'
							alt='copy'
							title="Copy"
						/>
					</span>
				</div>
			</div>
		</li>
	</ol>
</div>
