import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { FormServiceService } from "src/app/module/service/form-service.service";

export function personaNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 24;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[A-Za-z0-9]+$/.test(value)) {
      return { invalidCharacters: true };
    } else if (value && !/^[A-Za-z]*[A-Za-z][A-Za-z0-9]*$/.test(value)) {
      return { invalidName: true };
    }

    return null;
  };
}

@Component({
  selector: "persona.dialog",
  templateUrl: "persona.dialog.html",
  styleUrls: ["persona.dialog.scss"],
})
export class PersonaDialog {
  showPath: boolean = false;
  showUpload: boolean = false;
  PersonaForm!: FormGroup;
  uploadFile: File[] = [];
  listFiles: any = [];
  file_type: string = "";
  formSubmitted: boolean = false;
  showLoader: boolean = false;
  showText: boolean = false;
  newText: string = "";
  username: any;
  isLoading: boolean = false;
  fileRequiredMsg: boolean = false;
  selectedIndustry: string = "None";
  industryInfo: any = [];
  personaInfo: any = [{ industry: "", persona_id: "None", persona: "None" }];
  fileTypeMsg: boolean = false;
  selectedPersona: string = "";
  documentNameExist: boolean = false;
  token: any;
  userEmail: any;

  constructor(
    public formBuilder: FormBuilder,
    public service: FormServiceService,
    public dialogRef: MatDialogRef<PersonaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.PersonaForm = this.formBuilder.group({
      persona_name: ["", [Validators.required, personaNameValidator()]],
      file_ty: [""],
      file_path: [""],
      user_name: [""],
      password: [""],
      description: [""],
      file_document: [""],
      industry_id: ["", Validators.required],
    });
    this.username = localStorage.getItem("username");
    if (this.data.type == "open_source") {
      this.selectedPersona = "Open Source";
    } else if (this.data.type == "open_ai") {
      this.selectedPersona = "Open AI";
    }
  }

  ngOnInit() {
    this.getIndustryInfo();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  delete() {
    this.dialogRef.close("ok");
  }
  /* to save the persona information in API */
  save() {
    this.formSubmitted = true;
    // if (this.uploadFile.length === 0) {
    if (this.listFiles.length === 0) {
      this.fileRequiredMsg = true;
      return;
    }
    if (this.fileTypeMsg === true) {
      return;
    }
    // if (this.PersonaForm.valid && this.uploadFile != undefined) {
    if (this.PersonaForm.valid && this.listFiles.length >= 1) {
      this.isLoading = true;
      this.formSubmitted = false;
      this.showLoader = true;
      const formData = new FormData();
      // for (var i = 0; i < this.uploadFile.length; i++) {
      //   formData.append(
      //     "filepath",
      //     this.uploadFile[i],
      //     this.uploadFile[i].name
      //   );
      // }
      for (var i = 0; i < this.listFiles.length; i++) {
        formData.append("filepath", this.listFiles[i], this.listFiles[i].name);
      }
      formData.append("type", this.data.type);
      formData.append("model", this.data.modal);
      formData.append("industry", "");
      formData.append("industry_id", this.PersonaForm.value.industry_id);
      formData.append("persona_name", this.PersonaForm.value.persona_name);
      formData.append("file_type", this.file_type);
      formData.append("description", this.PersonaForm.value.description);
      formData.append("username", this.username);
      formData.append("token", this.token);
      formData.append("userEmail", this.userEmail);

      this.service.postPersonaFormDetails(formData).subscribe(
        (res) => {
          if (res.status == "success") {
            this.isLoading = false;
            this.showText = true;
            this.newText = res.message;
            // resetting the form after success
            this.PersonaForm.reset();
            this.file_type = "";
            this.uploadFile = [];
            this.listFiles = [];
            this.data = [];
            this.showLoader = false;
            // here we need to reload the persona list on the main page sidebar
            this.service.callPersonaLoader();
            this.dialogRef.close("ok");
          } else {
            this.showText = true;
            this.showLoader = false;
            this.newText = res.message;
          }
        },
        (error) => {
          this.formSubmitted = false;
          if (error.error.status === "failed") {
            this.documentNameExist = true;
          }
          this.isLoading = false;
          this.showText = true;
          this.newText = error.error.message;
          this.showLoader = false;
        }
      );
    }
  }

  ok() {
    this.showText = false;
    this.dialogRef.close("ok");
  }
  // onFileChange(event: any) {
  //   this.uploadFile = event.target.files;
  //   for (var i = 0; i < this.uploadFile.length; i++) {
  //     this.listFiles.push(this.uploadFile[i].name);
  //   }
  //   if (this.uploadFile.length !== 0) {
  //     this.fileRequiredMsg = false;
  //   }
  //   this.checkFileValidOrNot();
  // }
  onFileChange(event: any) {
    this.uploadFile = event.target.files;
    for (var i = 0; i < this.uploadFile.length; i++) {
      this.listFiles.push(this.uploadFile[i]);
      this.checkFileValidOrNot();
    }
    if (this.uploadFile.length !== 0) {
      this.fileRequiredMsg = false;
    }
  }
  removeFileAttachment(index: any) {
    this.listFiles.splice(index, 1);
    this.checkFileValidOrNot();
    // console.log(this.listFiles);
  }
  getFileUpload() {
    this.showPath = false;
    this.showUpload = true;
    this.file_type = "local";
  }
  getFilePath() {
    this.showPath = true;
    this.showUpload = false;
    this.file_type = "cloud";
  }
  /*  get industry infornmation from API */
  getIndustryInfo() {
    this.industryInfo = [];
    let data = {
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.postIndustryInfo(data).subscribe(
      (res) => {
        if (res.status == "success") {
          this.industryInfo = res.skills;
          if (this.data.industry !== "None") {
            this.PersonaForm.controls["industry_id"].setValue(
              this.data.industry
            );
          } else {
            this.selectedIndustry = "";
          }
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  /** load persona on industry change */
  onIndustryChange(event: any) {
    this.service.callMethod(event.target.value);
  }

  checkFileValidOrNot() {
    const allowedExtensions = [".doc", ".docx", ".pdf"];

    if (this.listFiles.length >= 1) {
      for (let i = 0; i < this.listFiles.length; i++) {
        const fileName = this.listFiles[i].name;
        const fileExtension = fileName
          .substring(fileName.lastIndexOf("."))
          .toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          this.fileTypeMsg = true;
          return; // Exit the function as soon as an invalid file is found
        }
        this.fileTypeMsg = false; // All files are valid
      }
    } else {
      this.fileTypeMsg = false;
    }
  }

  // checkFileValidOrNot(fileName: any) {
  //   let isValid = true;
  //   if (!fileName.includes(".pdf")) {
  //     this.fileTypeMsg = true;
  //     isValid = false;
  //   }
  //   return isValid;
  // }

  removeExistingMessage() {
    this.documentNameExist = false;
  }
}
