import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HomePageRoutingModule } from "./home-page-routing.module";
import { HeaderComponent } from "./component/header/header.component";
import { HomePageComponent } from "./home-page.component";
import { FormDataComponent } from "./form-data/form-data.component";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { PersonaDialog } from "./mat-dialog/persona-dialog/persona.dialog";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { IndustryDialog } from "./mat-dialog/industry-dialog/industry.dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import {MatCheckboxModule} from '@angular/material/checkbox';
import { NewPasswordDialog } from "./mat-dialog/new-password-dialog/new-password.dialog";
import { LikeDislikeDialogComponent } from "./mat-dialog/like-dislike-dialog/like-dislike-dialog.component";
import { LoaderModule } from "../../loader/loader.module";
import { InfoDialogComponent } from "./mat-dialog/info-dialog/info-dialog.component";
import { ConfirmationDialog } from "./mat-dialog/confirmation-dialog/confirmation.dialog";
import { OpenAiDialogComponent } from './mat-dialog/open-ai-dialog/open-ai-dialog.component';
import { TextToImageDialogComponent } from './mat-dialog/text-to-image-dialog/text-to-image-dialog.component';

@NgModule({
  declarations: [
    HomePageComponent,
    FormDataComponent,
    PersonaDialog,
    IndustryDialog,
    NewPasswordDialog,
    LikeDislikeDialogComponent,
    InfoDialogComponent,
    ConfirmationDialog,
    OpenAiDialogComponent,
    TextToImageDialogComponent,
  ],
  imports: [
    CommonModule,
    HomePageRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    LoaderModule,
    MatCheckboxModule
  ],
  exports: [FormDataComponent],
})
export class HomePageModule {}