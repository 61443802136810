import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdminSettingsServiceService } from "src/app/module/service/admin-settings-service.service";
import { read, utils, writeFile } from "xlsx";
import { UpdateGroup } from "./../component/update-group/update-group";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "./../../../../../environments/environment";
import { ConfirmationDialog } from "./../../../home/home-page/mat-dialog/confirmation-dialog/confirmation.dialog";
import { AuthServiceService } from "./../../../service/auth-service.service";
import { DashboardServiceService } from "./../../../service/dashboard-service.service";

@Component({
  selector: "app-access-list",
  templateUrl: "./access-list.component.html",
  styleUrls: ["./access-list.component.scss"],
})
export class AccessListComponent implements OnInit {
  token: any;
  userEmail: any;
  username: any;
  groupList: any;
  groupListPerPage: any;
  groupCount: any;
  pages: any;
  limit: any;
  Arr = Array;
  showLoader: boolean;
  currentPage: number = 1;
  paginationRange: number[] = [];
  nestUserListRes: any;
  userList: any;

  constructor(
    public route: Router,
    public service: AdminSettingsServiceService,
    public dialog: MatDialog,
    private authService: AuthServiceService,
    public dashboardService: DashboardServiceService
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");
    this.username = localStorage.getItem("username");

    this.groupCount = 0;
    this.groupList = [];
    this.groupListPerPage = [];
    this.pages = 0;
    this.limit = environment.paginationLimit;
    this.showLoader = true;
  }

  ngOnInit(): void {
    this.getGroupListForRoleAccess();
    this.calculatePaginationRange();
    this.getActiveUserListFromNest();
  }

  selectDownloadType(event: any) {
    /** common logic to create data file */
    const headings = [["Group", "Member", "Description"]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.groupList, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");

    /** here we can define that how to export it based on condition */
    if (event.target.value === "csv") {
      writeFile(wb, "Access List Reports.csv");
    } else if (event.target.value === "excel") {
      writeFile(wb, "Access List Reports.xlsx");
    }
  }

  getGroupListForRoleAccess() {
    const data = {
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.getGroupListForRoleAccess(data).subscribe(
      (res) => {
        // console.log("res", res);
        this.groupCount = res.Data.length;
        if (this.groupCount > this.limit) {
          this.pages = Math.ceil(this.groupCount / this.limit);
        }
        if (res.status === "success") {
          this.groupList = res.Data;
        }
        this.showLoader = false;
        this.getPageData(this.currentPage);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getPageData(page: any) {
    this.currentPage = page;
    this.groupListPerPage = [];
    const startIndex = (page - 1) * this.limit;
    const endIndex = startIndex + this.limit - 1;

    // Generate an array of values based on the page number
    this.groupListPerPage = this.groupList.slice(startIndex, endIndex + 1);
    this.calculatePaginationRange();
  }

  calculatePaginationRange() {
    const totalPages = this.pages;
    const currentPage = this.currentPage;

    let currentRangeStart = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const currentRangeEnd = Math.min(currentRangeStart + 9, totalPages);

    if (currentPage > currentRangeEnd) {
      currentRangeStart += 10;
    }

    const newEnd = Math.min(currentRangeStart + 9, totalPages);

    this.paginationRange = Array.from(
      { length: newEnd - currentRangeStart + 1 },
      (_, i) => currentRangeStart + i
    );
  }

  getActiveUserListFromNest() {
    let dataObj = {
      token: localStorage.getItem("nestToken"),
    };
    this.authService.getActiveUserListFromNest(dataObj).subscribe(
      (nestUserListRes) => {
        this.nestUserListRes = nestUserListRes.response;
        // console.log(
        //   "Nest nestUserListRes res: " +
        //     JSON.stringify(this.nestUserListRes)
        // );

        if (!this.nestUserListRes.errors) {
          this.userList = this.nestUserListRes.results;
        } else {
          alert(this.nestUserListRes.errors[0].message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  addNewGroupForRoleAccess(action: string, group_detail: any = []) {
    const dialogRef = this.dialog.open(UpdateGroup, {
      width: "40%",
      data: {
        action: action,
        group_detail: group_detail,
        userList: this.userList,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        this.showLoader = true;
        this.groupCount = 0;
        this.groupList = [];
        this.groupListPerPage = [];
        this.pages = 0;
        this.getGroupListForRoleAccess();
      }
    });
  }

  deleteGroupForRoleAccess(group_detail: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: "40%",
      data: {
        content: `This action will delete the <b> ${group_detail.Group_Name} </b> group and Members associated with it. Are you sure to delete?`,
        action: "Delete",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        this.showLoader = true;
        let data = {
          token: localStorage.getItem("token"),
          userEmail: this.userEmail,
          group_name: group_detail.Group_Name,
          member: group_detail.Members,
        };
        this.service.deleteGroupForRoleAccess(data).subscribe(
          (res) => {
            if (res.status == "success") {
              this.groupCount = 0;
              this.groupList = [];
              this.groupListPerPage = [];
              this.pages = 0;
              this.getGroupListForRoleAccess();
              this.showLoader = false;
            } else {
              alert(res.message);
              this.showLoader = false;
            }
          },
          (error) => {
            alert(error.error.message);
            this.showLoader = false;
          }
        );
      }
    });
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }
}
