<div class="modal-wrapper">
    
    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title class="hd-txt">New&nbsp;chat</h1>
            <mat-icon class="cancel-btn" title="Close" (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <!-- to save the industry information -->
    <div mat-dialog-content class="contain">
        <form [formGroup]="newChatForm">
            <div class="row">
                <div class="col-12">
                    <!-- industry description -->
                    <div class="report-container">
                        <label
                            for="exampleFormControlTextarea1"
                            class="form-label"
                        >
                            Report name
                            <span class="text-danger">*</span>
                        </label>
                        <mat-form-field>
                            <mat-select id="inp3" formControlName="reportName">
                                <mat-option
                                    *ngFor="let dictItem of ReportList | keyvalue"
                                    value="{{dictItem.value}}"
                                >
                                    {{dictItem.key}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || newChatForm.controls.reportName.dirty) && newChatForm.controls.reportName.errors?.required">
                            Report name is required.
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <button class="btn btn-secondary-outline" (click)="onNoClick()">Close</button>
        <button class="btn btn-primary" (click)="save()">Save</button>
    </div>
</div>