<div>
    <div class="modal-body">
        <h4><strong style="font-size: 18px; margin-bottom: 5px;"> **WARNING**</strong></h4>
        <h6>DO NOT USE WITH CLIENT OR CONFIDENTIAL DATA.</h6>
        <p>{{ modalContent }}</p>
    </div>

    <div class="modal-footer">
        <!-- <span>Agree</span> -->
        <mat-checkbox
            color="#EB5832"
            style="margin-top: 12px; margin-right: 45%;"
            [(ngModel)]="isChecked"
        >I
            Agree
        </mat-checkbox>
        <div>
            <button
                class="btn btn-primary"
                [disabled]="!isChecked"
                [ngClass]="{'disabled': !isChecked}"
                (click)="dialogClose()"
            >
                OK
            </button>
            <button
                class="btn btn-secondary-outline"
                (click)="CancelClick()"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
