import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReqGenerationComponent } from './req-generation.component';

const routes: Routes = [
  {
    path: '',
    component: ReqGenerationComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./req-generation-page/req-generation-page.module').then(
            (m) => m.ReqGenerationPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReqGenerationRoutingModule {}
