import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AdminSettingsServiceService } from "src/app/module/service/admin-settings-service.service";
import { CostService } from "src/app/module/service/cost.service";
import { AdminSettingsPageComponent } from "./../admin-settings-page.component";
import { DashboardServiceService } from "./../../../service/dashboard-service.service";
import { read, utils, writeFile } from "xlsx";
import { environment } from "./../../../../../environments/environment";

@Component({
  selector: "app-cost-table",
  templateUrl: "./cost-table.component.html",
  styleUrls: ["./cost-table.component.scss"],
})
export class CostTableComponent implements OnInit {
  showLoader: boolean = false;
  costFilterData: any;

  constructor(
    private route: Router,
    public service: CostService,
    private adminSetting: AdminSettingsServiceService,
    public adminSettingsCom: AdminSettingsPageComponent,
    public dashboardService: DashboardServiceService
  ) {
    this.service.groupSet = [];
    this.service.rowCount = 0;
    this.service.pages = 0;
    this.service.limit = environment.paginationLimit;
    this.showLoader = true;
  }

  ngOnInit() {
    this.costFilterData = this.adminSetting.getCostFilterData();
    this.adminSettingsCom.getGroups();
    this.adminSettingsCom.calculatePaginationRange();
  }

  sortGroupData(sortOn: any) {
    let sortBy = this.service.sortGroupFlag[sortOn] === "ASC" ? "DESC" : "ASC";
    this.service.sortGroupFlag[sortOn] = sortBy;
    if (sortOn === "cost") {
      if (sortBy === "DESC") {
        this.service.groupSet = this.service.groupSet.sort((a: any, b: any) => {
          return b.total_count - a.total_count;
        });
      } else {
        this.service.groupSet = this.service.groupSet.sort((a: any, b: any) => {
          return a.total_count - b.total_count;
        });
      }
      this.service.sortGroupFlag["group"] = "";
    } else {
      if (sortBy === "DESC") {
        this.service.groupSet = this.service.groupSet.sort((a: any, b: any) => {
          return b.group_name.localeCompare(a.group_name);
        });
      } else {
        this.service.groupSet = this.service.groupSet.sort((a: any, b: any) => {
          return a.group_name.localeCompare(b.group_name);
        });
      }
      this.service.sortGroupFlag["cost"] = "";
    }
    this.adminSettingsCom.getPageData(this.service.currentPage);
  }

  openGroup(groupName: string, total_count: any) {
    this.service.group = groupName;
    this.service.groupTotalCost = total_count;
    this.route.navigate([
      "/admin-settings/admin-settings-page/cost/cost-details/",
    ]);
  }

  selectDownloadTypeForGroupWiseReport(event: any) {
    /** common logic to create data file */
    const headings = [["Group", "Cost ($)"]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.service.groupSet, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");

    /** here we can define that how to export it based on condition */
    if (event.target.value === "csv") {
      writeFile(wb, "Group-wise Cost Report.csv");
    } else if (event.target.value === "excel") {
      writeFile(wb, "Group-wise Cost Report.xlsx");
    }
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }
}
