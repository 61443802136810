import { Component, ElementRef, ViewChild, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidatorFn,
  FormControl,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { AdminSettingsServiceService } from "./../../../../service/admin-settings-service.service";
import AWS from "aws-sdk";
import { environment } from "./../../../../../../environments/environment";

interface User {
  employeeName: string;
  EmailId: string;
}

export function groupNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 20;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[a-zA-Z0-9_\s\-]+$/.test(value)) {
      return { invalidCharacters: true };
    }

    return null;
  };
}

@Component({
  selector: "update-group",
  templateUrl: "update-group.html",
  styleUrls: ["update-group.scss"],
})
export class UpdateGroup {
  GroupForm!: FormGroup;
  token: any;
  userEmail: any;
  accessKeyId: any;
  secretAccessKey: any;
  sessionToken: any;
  formSubmitted: boolean = false;
  isLoading: boolean = false;
  isEdit: boolean = false;
  actionVal: string = "Add";
  groupDetail: any;
  description: string = "";
  userList: User[] = [];
  groupList: any = [];
  searchText: string = "";
  memberErr: boolean = false;
  memberErrMsg: string = "";
  duplicateGroupError: boolean = false;
  duplicateGroupErrorMsg: string = "";

  @ViewChild("search") searchTextBox!: ElementRef;

  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedMembers: string[] = [];
  allMemberSelected: boolean = false;

  filteredMembers!: Observable<User[]> | null;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<UpdateGroup>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AdminSettingsServiceService
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    // this.accessKeyId = localStorage.getItem("accessKeyId");
    // this.secretAccessKey = localStorage.getItem("secretAccessKey");
    // this.sessionToken = localStorage.getItem("sessionToken");

    this.filteredMembers = new Observable<User[]>();

    this.userList = this.data.userList;

    this.groupDetail = this.data.group_detail;
    if (this.data.action === "edit") {
      this.isEdit = true;
      this.actionVal = "Update";

      this.GroupForm = this.formBuilder.group({
        groupName: [
          this.groupDetail.Group_Name,
          [Validators.required, groupNameValidator()],
        ],
        description: [
          this.groupDetail.description,
          [Validators.required, Validators.maxLength(250)],
        ],
      });

      let members = this.groupDetail.Members.split(", ");
      members.forEach((e: any) => {
        const index = this.userList.findIndex((user) =>
          user.EmailId.includes(e)
        );
        // If the index is found, set the value of the form control
        if (index !== -1) {
          this.selectFormControl.setValue([this.userList[index]]);
          this.setSelectedMembers();
        }
      });
    } else {
      this.isEdit = false;
      this.actionVal = "Add";

      this.GroupForm = this.formBuilder.group({
        groupName: ["", [Validators.required, groupNameValidator()]],
        description: ["", [Validators.required, Validators.maxLength(250)]],
      });
    }
  }

  ngOnInit() {
    const data = {
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.getGroupListForRoleAccess(data).subscribe((res) => {
      if (res.status === "success") {
        this.groupList = res.Data;
      }
    });

    /**
     * Set filter event based on value changes
     */
    this.filteredMembers = this.searchTextboxControl.valueChanges.pipe(
      startWith<any>(""),
      map((name) => this._filter(name))
    );
  }

  /**
   * Used to filter data based on search input
   */
  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedMembers();
    this.selectFormControl.patchValue(this.selectedMembers);
    let filteredList = this.userList.filter(
      (option: any) =>
        option.employeeName.toLowerCase().indexOf(filterValue) === 0
    );
    return filteredList;
  }

  selectMemberValidation(): void {
    if (this.selectFormControl.value.length === 0) {
      this.memberErr = true;
      this.memberErrMsg = "At least one member is required";
    } else {
      this.memberErr = false;
      this.memberErrMsg = "";
    }
  }

  /**
   * Remove from selected values based on uncheck
   */
  selectionChange(event: any) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedMembers.indexOf(event.source.value);
      this.selectedMembers.splice(index, 1);
    }
    this.selectMemberValidation();
  }

  selectClosed(e: any) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue("");
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
    this.selectMemberValidation();
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event: any) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue("");
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedMembers() {
    if (
      this.selectFormControl.value &&
      this.selectFormControl.value.length > 0
    ) {
      this.selectFormControl.value.forEach((e: any) => {
        if (this.selectedMembers.indexOf(e) == -1) {
          this.selectedMembers.push(e);
        }
      });
    }
  }

  toggleAllSelection() {
    if (this.allMemberSelected) {
      this.userList.forEach((e: any) => {
        this.selectFormControl.setValue([e]);
        this.setSelectedMembers();
      });
    } else {
      this.selectFormControl.setValue([]);
      this.selectedMembers = [];
    }
  }

  onGroupNameFocusOut(event: any) {
    if (
      this.groupList.find(
        (group: any) => group.Group_Name === event.target.value
      )
    ) {
      this.duplicateGroupError = true;
      this.duplicateGroupErrorMsg = "Group name already exists";
    } else {
      this.duplicateGroupError = false;
      this.duplicateGroupErrorMsg = "";
    }
  }

  clearSelection() {
    this.selectFormControl.setValue([]);
    this.selectedMembers = [];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  /* to save the persona information in API */
  save() {
    this.formSubmitted = true;

    if (
      this.GroupForm.valid &&
      this.selectFormControl.value.length > 0 &&
      !this.duplicateGroupError
    ) {
      this.isLoading = true;
      this.formSubmitted = false;

      let memberStr = "";
      this.selectedMembers.forEach((value: any) => {
        memberStr =
          memberStr === "" ? value.EmailId : memberStr + ", " + value.EmailId;
      });

      let data = {
        group_name: this.GroupForm.value.groupName,
        member: memberStr,
        description: this.GroupForm.value.description.trim(),
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      // console.log(data);

      this.service.updateGroupForRoleAccess(data).subscribe(
        (res) => {
          if (res.status == "success") {
            this.isLoading = false;
            // resetting the form after success
            this.GroupForm.reset();
            this.data = [];
            this.dialogRef.close("ok");
          } else {
            this.isLoading = false;
            alert(res.message);
          }
        },
        (error) => {
          this.formSubmitted = false;
          this.isLoading = false;
          alert(error.error.message);
        }
      );
    } else {
      this.selectMemberValidation();
    }
  }
}
