import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FormServiceService } from "../service/form-service.service";
import { AuthServiceService } from "../service/auth-service.service";

import jwt_decode from "jwt-decode";

@Component({
  selector: "app-idpresponse",
  templateUrl: "./idpresponse.component.html",
  styleUrls: ["./idpresponse.component.scss"],
})
export class IdpresponseComponent {
  loading: boolean = true;
  isAuthenticated: boolean = false;
  errorMessage: string = "";
  accessToken: any | null;
  nestLoginRes: any;
  nestUserInfoRes: any;

  isAdmin: string = "false";
  isCognitoAdminGrp: string = "false";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthServiceService,
    public service: FormServiceService
  ) {}

  ngOnInit() {
    // For Azure AD user
    this.handleAzureADResponse();
  }

  // For Azure AD user
  handleAzureADResponse() {
    const url = window.location.href;
    if (url.includes("code")) {
      const code = url.split("code=")[1].split("&")[0];
      // Use the obtained 'code' to get Azure AD tokens
      this.exchangeCodeForTokens(code);
    }
  }

  // For Azure AD user
  exchangeCodeForTokens(code: string) {
    this.authService.getTokenFromAWSCognitoAzure(code).subscribe(
      (res) => {
        // console.log("Azure getTokenFromAWSCognitoAzure res: " + JSON.stringify(res));
        this.isAuthenticated = true;

        //Setup isLocalUser flag to false for Azure-AD users
        localStorage.setItem("isLocalUser", "false");

        localStorage.setItem("token", res.access_token);
        localStorage.setItem("id_token", res.id_token);
        localStorage.setItem("refresh_token", res.refresh_token);

        this.authService
          .getUserInfoFromAWSCognitoAzure(res.access_token)
          .subscribe(
            (userInfoRes) => {
              // console.log(
              //   "Azure getUserInfoApi res: " + JSON.stringify(userInfoRes)
              // );

              userInfoRes.email = userInfoRes.email.toLowerCase();
              const username = userInfoRes.email.split("@")[0];

              // const usernameArr = username.split(".");

              // const usernameDisplay =
              //   usernameArr[0].charAt(0).toUpperCase() +
              //   usernameArr[0].slice(1) +
              //   " " +
              //   usernameArr[1].charAt(0).toUpperCase() +
              //   usernameArr[1].slice(1);

              // const usernameInitials =
              //   usernameArr[0].charAt(0).toUpperCase() +
              //   usernameArr[usernameArr.length - 1].charAt(0).toUpperCase();

              const usernameDisplay =
                userInfoRes.given_name + " " + userInfoRes.family_name;

              const usernameInitials =
                userInfoRes.given_name.charAt(0).toUpperCase() +
                userInfoRes.family_name.charAt(0).toUpperCase();

              localStorage.setItem("username", username);
              localStorage.setItem("usernameDisplay", usernameDisplay);
              localStorage.setItem("usernameInitials", usernameInitials);
              localStorage.setItem("userEmail", userInfoRes.email);

              const groupPayload = {
                token: res.access_token,
                userEmail: userInfoRes.email,
              };

              this.authService
                .getGroupListForUser(groupPayload)
                .subscribe((res) => {
                  if (res.status === "Success") {
                    if (res.groups.length > 0) {
                      localStorage.setItem(
                        "admin_groups",
                        res.groups.toString()
                      );
                      if (res.groups.toString()?.includes("Super User")) {
                        this.isAdmin = "true";
                        localStorage.setItem("isAdmin", this.isAdmin);
                      }
                    }
                  }
                });

              const trackingData = {
                token: res.access_token,
                userEmail: userInfoRes.email,
                data: {
                  ip: localStorage.getItem("user_ip"),
                  location: localStorage.getItem("user_coords"),
                },
              };
              this.service.postTrackingData(trackingData);
            },
            (err) => {}
          );

        this.accessToken = jwt_decode(res.access_token);
        localStorage.setItem("token_expiration", this.accessToken.exp);

        if (this.accessToken["cognito:groups"]) {
          if (this.accessToken["cognito:groups"].includes("Admin")) {
            this.isCognitoAdminGrp = "true";
          }
        }
        localStorage.setItem("isCognitoAdminGrp", this.isCognitoAdminGrp);

        /** NEST API call start */
        this.authService.loginIntoNEST().subscribe(
          (nestLoginRes) => {
            this.nestLoginRes = nestLoginRes.response.results;
            // console.log("Nest nestLoginRes res: " + JSON.stringify(this.nestLoginRes));

            if (!this.nestLoginRes.error) {
              localStorage.setItem("nestToken", this.nestLoginRes.token);
            } else {
              alert(this.nestLoginRes.action_message);
            }
          },
          (error) => {
            alert(error.error.message);
          }
        );

        setTimeout(() => {
          let dataObj = {
            token: localStorage.getItem("nestToken"),
            emp_work_email: localStorage.getItem("userEmail"),
          };
          this.authService.getUserInfoFromNEST(dataObj).subscribe(
            (nestUserInfoRes) => {
              this.nestUserInfoRes = nestUserInfoRes.response.results;
              // console.log(
              //   "Nest nestUserInfoRes res: " +
              //     JSON.stringify(this.nestUserInfoRes)
              // );

              if (!this.nestUserInfoRes.error) {
                localStorage.setItem(
                  "userDesignation",
                  this.nestUserInfoRes.Designation
                );

                if (this.nestUserInfoRes.Role === "Manager") {
                  localStorage.setItem("isManager", "true");
                } else {
                  localStorage.setItem("isManager", "false");
                }
              } else {
                alert(this.nestUserInfoRes.action_message);
              }
            },
            (error) => {
              alert(error.error.message);
            }
          );
        }, 2000);
        /** NEST API call end */

        setTimeout(() => {
          this.router.navigate(["/dashboard"]);
        }, 3000);
      },
      (err) => {
        this.isAuthenticated = false;
        this.loading = false;

        this.errorMessage = err.message;
      }
    );
  }
}
