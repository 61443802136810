import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { IdpresponseRoutingModule } from "./idpresponse-routing.module";
import { IdpresponseComponent } from "./idpresponse.component";
import { LoaderModule } from "../loader/loader.module";

@NgModule({
  declarations: [IdpresponseComponent],
  imports: [
    CommonModule,
    IdpresponseRoutingModule,
    ReactiveFormsModule,
    LoaderModule,
  ],
})
export class IdpresponseModule {}
