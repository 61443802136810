import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

export class Message {
  constructor(public author: string, public content: string) {
    author = "";
    content = "";
  }
}
@Injectable({
  providedIn: "root",
})
export class DashboardServiceService {
  isLoading: boolean = false;
  headers: any;
  public activeMenu = "";

  // public baseapiUrl = "http://3.131.8.158:8000/";
  public baseapiUrl: string = environment.baseapiurl + "api/";

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  requestNewUseCase(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "send_mail", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
