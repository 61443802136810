import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NaturalQueryEngineComponent } from "./natural-query-engine.component";

const routes: Routes = [
  {
    path: "",
    component: NaturalQueryEngineComponent,
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home",
        loadChildren: () =>
          import(
            "./natural-query-engine-page/natural-query-engine-page.module"
          ).then((m) => m.NaturalQueryEnginePageModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NaturalQueryEngineRoutingModule {}
