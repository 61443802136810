import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
// import { FormServiceService } from "../../../service/form-service.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { DomSanitizer } from "@angular/platform-browser";

import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { NaturalQueryEngineService } from "../../service/natural-query-engine.service";
// import { LikeDislikeDialogComponent } from "../mat-dialog/like-dislike-dialog/like-dislike-dialog.component";
// import { OpenAiDialogComponent } from "../mat-dialog/open-ai-dialog/open-ai-dialog.component";

export class Message {
  constructor(
    public author: string,
    public content: string,
    public msgImg: string,
    public showCheck: boolean,
    public base64: string,
    public userResponse: string,
    public chatId: string
  ) {
    author = "";
    userResponse = "";
    content = "";
    msgImg = "";
    base64 = "";
    showCheck = false;
    chatId = "";
  }
}
@Component({
  selector: "app-form-data",
  templateUrl: "./form-data.component.html",
  styleUrls: ["./form-data.component.scss"],
})
export class FormDataComponent implements OnInit {
  message: string = ""; // Make sure this is defined correctly

  resizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto";
    if( this.userMessage.includes('\n') ) {
      textarea.style.height = textarea.scrollHeight + "px";
    } else {
      textarea.style.height = "24px";
    }
  }

  @ViewChild("myIframe") myIframe!: ElementRef;
  // @ViewChild("scrollMe") private myScrollContainer: any;
  @ViewChild("myinput") myInputField!: ElementRef;
  // @ViewChild("content") private contentContainer!: ElementRef;
  @ViewChild("chatWindowRef") chatWidowRef!: ElementRef;

  myText: string = "Hello World!";
  showDasboard: boolean = false;
  llmFormData!: FormGroup;
  submittedthree: boolean = false;
  showModel: boolean = false;
  loanStatus: string = "";
  loanRule: string = "";
  value: string = "";
  formDetails: any = [];
  formDetailData: any;
  application_id: string = "";
  BusinessLegalName: string = "";
  BusinessLoanPurpose: string = "";
  BusinessSize: string = "";
  CustomerAge: string = "";
  CustomerName: string = "";
  capital_required: string = "";
  collateral_available: string = "";
  credit_score: string = "";
  existing_loan_amount: string = "";
  growth_rate: string = "";
  loan_to_investment_ratio: string = "";
  previous_business_experience: string = "";
  loanExpain: any;
  AverageActivity: string = "";
  College: string = "";
  HighestEducationQualification: string = "";
  IpAvailable: string = "";
  showCheck: boolean = false;
  IpCount: string = "";
  IpExpiry: string = "";
  NumberOfLinkedinfollowers: string = "";
  YoutubeSubscribers: string = "";
  socialQuotient: string = "";
  questions: any = [""];
  selectedQuestion: string = "";
  messages: Message[] = [];
  messages1: any;
  messageImg: any;
  messagebot: string = "user";
  userMessage: any;
  previousMessage: any;
  content: string = "";
  postedContent: any = [];
  contentRef!: ElementRef;
  contentHeight: any;
  palyMusic: boolean = false;
  clickEventSub!: Subscription;
  clickEventTabChangeSub!: Subscription;
  textArea: string = "";
  imagePath: any;
  username: any;
  usernameDisplay: any;
  flag: boolean = false;
  // stopGeneratorFlag: boolean = false;
  // regenerateFlag: boolean = false;
  botMessage: any = [];
  usernameInitials: any;
  likeBtnClicked: string = "";
  unlikeBtnClicked: string = "";
  token: any;
  userEmail: any;
  selectedModal: any = "-";
  selectedModalImage: any = "-";
  selectType: any = "-";
  selectedContex: any = "-";
  placeholder: any = 'Click on "+ New Chat" to initiate...';
  columnNames: any = [];
  currentRow: any;

  feedbacks: any;
  feedbackCount: any;
  pages: any;
  limit: any;
  Arr = Array;
  showLoader: boolean = false;
  currentPage: number = 1;
  paginationRange: number[] = [];
  isDataReceivd: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private renderer: Renderer2,
    public service: NaturalQueryEngineService,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.clickEventSub = this.service.getChatMsg().subscribe((async) => {
      this.service.showChatLoader = false;
      this.service.stopGeneratorFlag = false;
      this.service.regenerateFlag = false;
      this.textArea = "New Chat Session Started";
      this.messages = [];
    });
    this.clickEventTabChangeSub = this.service
      .getTabChangeMsg()
      .subscribe((async) => {
        this.service.stopGeneratorFlag = false;
        this.service.regenerateFlag = false;
        this.textArea = "";
        this.messages = [];
      });

    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");
    this.service.chatTriggered.subscribe(() => {
      this.placeholder = "Type here...";
      // console.log("this.service.selectedModel",this.service.selectedModel)
      this.selectedModal = this.service.selectedModel;
      this.selectedModalImage = this.service.selectedModelImage;
      this.selectType = this.service.selectedType;
      this.selectedContex = this.service.isPersonaSelected;
      this.setAutofocusOnChatBox();
      this.myInputField.nativeElement.disabled = false;
      this.messages = [];
      this.service.newChat = true
    });

    this.service.methodCalled$.subscribe(() => {
      this.myInputField.nativeElement.disabled = true;
    });

    /** Calls when modals change from sidebar */
    this.service.inputdisabler$.subscribe(() => {
      this.placeholder = 'Click on "+ New Chat" to initiate...';
      this.myInputField.nativeElement.disabled = true;
      this.myInputField.nativeElement.value = '';
    })
  }

  ngAfterViewInit() {
    this.setAutofocusOnChatBox();
    this.myInputField.nativeElement.disabled = true;
  }

  selectQuestion(question: string) {
    this.selectedQuestion = question;
  }

  regenerateMessage() {
    this.service.regenerateFlag = false;
    this.userMessage = localStorage.getItem("userMessageNql");
    this.sendMessage();
  }

  manualRegenerate() {
    this.messages.pop();
    console.log("Manual regenaration start");
    this.regenerateMessage();
  }

  /* on click of enter or send button */
  sendMessage() {
    if (this.userMessage != "") {
      this.userMessage = this.userMessage.replace(/\\/g, "");
      this.service.newChat = false;
      this.service.showChat = true;
      let instance_id = this.service.instance_id;
      localStorage.setItem("userMessageNql", this.userMessage);

      if (this.userMessage.trim() === "") {
        return;
      }

      this.messageImg = "../../../../../assets/img/profile-pic.png";
      let msg = {
        userResponse: this.userMessage,
        content: "",
        author: "user",
        base64: "",
        msgImg: this.messageImg,
        showCheck: false,
        chatId: "",
      };
      this.messages.push(msg);
      this.messagebot = "user";
      this.showDasboard = true;
      let rule = {
        instance_id: instance_id,
        prompt: this.userMessage,
        username: this.username,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };

      this.scrollToBottom();
      if (this.service.taskConvertor !== "text_to_image") {
        if( this.service.selectedModel === "llama3" ) {
          // let botMessage1: string = "";
          // let currentIndex = this.messages.length;
          // let counter = 0;
          // this.service.chatStream(JSON.stringify(rule)).subscribe({
          //   next: (text) => {
          //     if (text) {
          //       counter++;
          //     }
          //     if (text == "chat_id:" && counter === 1) {
          //       console.log(
          //         `No API response counter:${counter}, call sendMessage method again`
          //       );
          //       this.manualRegenerate();
          //     }
          //     /** Here we get data as well as chat ID, further we are splitting the both */
          //     const data = text.split("chat_id:");
          //     text = data[0];
          //     let chat_id = data[1];

          //     /** storing the chat id in array uniquely */
          //     // if( chat_id && !this.chatIdArray.includes( chat_id ) ) {
          //     //   this.chatIdArray.push( chat_id );
          //     // }

          //     //text = text.replace(/ /g,'\n')
          //     this.flag = true;
          //     this.service.stopGeneratorFlag = true;
          //     this.service.regenerateFlag = false;
          //     botMessage1 += text;
          //     //let botMessage1='welcome\n\n to apexon'
          //     this.botMessage = botMessage1.split("\\n");
          //     this.messageImg =
          //       "../../../../../assets/icons/chat-window-icon.svg";
          //     let msg = {
          //       userResponse: "",
          //       content: this.botMessage,
          //       author: "bot",
          //       base64: "",
          //       msgImg: this.messageImg,
          //       showCheck: false,
          //       chatId: chat_id,
          //     };
          //     this.service.showChat = false;
          //     if (currentIndex >= 1) {
          //       this.messages[currentIndex] = msg;
          //     }
          //     this.scrollToBottom();
          //     // this.chatWidowRef.nativeElement.scrollTo(
          //     //   0,
          //     //   this.chatWidowRef.nativeElement.scrollHeight
          //     // );
          //   },
          //   complete: () => {
          //     this.flag = false;
          //     this.service.stopGeneratorFlag = false;
          //     this.service.regenerateFlag = true;
          //     this.previousMessage = this.userMessage;
          //   },
          //   error: () => {
          //     this.service.stopGeneratorFlag = false;
          //   },
          // });
        }
        else {
          this.flag = true;
          this.service.stopGeneratorFlag = false;
          this.service.regenerateFlag = false;

          // let payload = {

          //   chat_id: "0b2229a1-d94a-4f1e-b1ed-6df6381fb46b",
          //   question: this.userMessage,
          //   token: localStorage.getItem("token"),
          //   userEmail: this.userEmail,
          //   model: this.service.selectedModel,
          //   model_type: this.service.selectedType,
          // };
          let payload = {
            chat_id: localStorage.getItem('selectedDb'),
            question: this.userMessage,
            model_type: this.service.selectedType.replace('_', ''),
          }

          this.scrollToBottom();

          this.service.postOpenAiInfo(payload).subscribe(
            (res) => {
              if ( res.status == 'Success' && Array.isArray(res.message[0]) && res.message[0].length > 0 ) {
                this.isDataReceivd = true;
                this.columnNames = Object.keys(res.message[0][0])
                this.flag = false;
                this.service.regenerateFlag = true;
                this.service.showChat = false;
                this.loanExpain = res.message[0];
                this.messages1 = res.message[0];
                this.questions.splice(0);
                this.messageImg = "../../../../../assets/icons/chat-window-icon.svg";
                let msg = {
                  userResponse: "",
                  content: this.loanExpain,
                  author: "bot",
                  base64: "",
                  msgImg: this.messageImg,
                  showCheck: false,
                  chatId: res.chat_id,
                };

                if (this.messages.length >= 1) {
                  this.messages.push(msg);
                }
                // const keyMap = res.message;
                this.scrollToBottom();
              } else {
                // console.log('error: in else');
                let msg = {
                  userResponse: "",
                  content: "",
                  author: "bot",
                  base64: "",
                  msgImg: this.messageImg,
                  showCheck: false,
                  chatId: res.chat_id,
                };

                if (this.messages.length >= 1) {
                  this.messages.push(msg);
                }
                
                this.flag = false;
                this.service.showChat = false;
                this.messages1 = res.message[0];
                this.scrollToBottom();
              }
              // console.log( this.messages );
            },
            (error) => {
              this.flag = false;
              this.service.showChat = false;
              this.scrollToBottom();
            }
          );
        }
        this.userMessage = "";
      }
    }
  }

  TitleCase(obj:string){
    let name = obj;
   let titleCase = name.replace('_', ' ').split(' ').filter( (value) => {
      return value.charAt(0).toUpperCase();
    })
    return titleCase.join(' ');
    // console.log("titleCase",titleCase.join(' '))
    
  }

  download(image: any) {
    const downloadLink = document.createElement("a");
    const fileName = "sample.jpg";

    downloadLink.href = image;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  /*  to scroll the chat below */
  scrollToBottom() {
    try {
      setTimeout(() => {
        this.chatWidowRef.nativeElement.scrollTo(
          0,
          this.chatWidowRef.nativeElement.scrollHeight
        );
      }, 2000);
    } catch (err) { }
  }

  /* to copy the text */
  copyText(event: any, i: number) {
    if (this.service.regenerateFlag) {
      this.clipboard.copy(event.content);
      this.messages[i].showCheck = true;
      // console.log( this.messages[i] );
      // let msg = {
      //   userResponse: event.userResponse,
      //   content: event.content,
      //   author: event.author,
      //   base64: event.base64,
      //   msgImg: event.msgImg,
      //   showCheck: false,
      //   chatId: "",
      // };
      // this.messages[i] = msg;
      setTimeout(() => {
        this.messages[i].showCheck = false;
      }, 2500);
    }
  }

  changeDateFormat(dateString: string) {
    return new Date(dateString).toLocaleDateString();
  }

  save() {
    let instance_id = this.service.instance_id;
    this.showDasboard = true;
    let rule = {
      instance_id: instance_id,
      prompt: this.loanRule,
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.postOpenapiImage(rule).subscribe(
      (res) => {
        if (res.status == "success") {
          this.loanExpain = JSON.stringify(res.txt);
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  reset() {
    window.location.reload();
  }

  stopGeneratorPrompt() {
    let data = {
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.stopGenerator(data).subscribe(
      (res) => { },
      (error) => {
        console.log(error);
      }
    );
    // Call service one more time to make it work
    setTimeout(() => {
      this.service.stopGenerator(data).subscribe(
        (res) => {},
        (error) => {
          console.log(error);
        }
      );
    }, 100);
  }

  like_response(event: any, cid: string) {
    if (this.service.regenerateFlag) {
      event.target.classList.add("likeBtnClicked");
      event.target.nextElementSibling.style.pointerEvents = "none";
      // const dialogRef = this.dialog.open(LikeDislikeDialogComponent, {
      //   width: "50%",
      //   data: { type: "Like", chat_id: cid },
      // });
      // dialogRef.afterClosed().subscribe((result) => {
      //   switch (result) {
      //     case "success":
      //       event.target.style.pointerEvents = "none";
      //       break;
      //     case "error":
      //       console.log("unable to submit feedback");
      //       break;
      //     default:
      //       break;
      //   }
      // });
    }
  }

  unlike_response(event: any, cid: string) {
    if (this.service.regenerateFlag) {
      event.target.classList.add("likeBtnClicked");
      event.target.previousElementSibling.style.pointerEvents = "none";
      // const dialogRef = this.dialog.open(LikeDislikeDialogComponent, {
      //   width: "50%",
      //   data: { type: "Dislike", chat_id: cid },
      // });
      // dialogRef.afterClosed().subscribe((result) => {
      //   switch (result) {
      //     case "success":
      //       event.target.style.pointerEvents = "none";
      //       break;
      //     case "error":
      //       console.log("unable to submit feedback");
      //       break;
      //     default:
      //       break;
      //   }
      // });
    }
  }

  setAutofocusOnChatBox() {
    // console.log(this.service.alive);
    this.myInputField.nativeElement.value = "";
    setTimeout(() => {
      this.myInputField.nativeElement.focus();
    }, 1000);
    this.service.regenerateFlag = false;
    this.service.stopGeneratorFlag = false;
  }

  showFeedback(page?: any) {
    const data = {
      filters: {
        model_type: "",
        model_name: "",
        feedback: "",
        start_date: "",
        end_date: "",
      },
      page: page,
      token: localStorage.getItem("token"),
      userEmail: localStorage.getItem("userEmail"),
      username: localStorage.getItem("username"),
    };
    // this.service.getFeedbackData(data).subscribe(
    //   (res) => {
    //     // console.log("res", res);
    //     this.feedbackCount = res["Total Count of Data"];
    //     if (this.feedbackCount > this.limit) {
    //       this.pages = Math.ceil(this.feedbackCount / this.limit);
    //     }
    //     if (res.status === "success") {
    //       this.feedbacks = res.DATA;
    //     }
    //     this.showLoader = false;
    //     this.calculatePaginationRange();
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }

  getPageData(page: any) {
    this.currentPage = page;
    this.feedbacks = [];
    if (page == 1) {
      this.showFeedback();
    } else {
      this.showFeedback(page);
    }
  }

  calculatePaginationRange() {
    const totalPages = this.pages;
    const currentPage = this.currentPage;

    let currentRangeStart = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const currentRangeEnd = Math.min(currentRangeStart + 9, totalPages);

    if (currentPage > currentRangeEnd) {
      currentRangeStart += 10;
    }

    const newEnd = Math.min(currentRangeStart + 9, totalPages);

    this.paginationRange = Array.from(
      { length: newEnd - currentRangeStart + 1 },
      (_, i) => currentRangeStart + i
    );
  }

  showData( arr: any, key: string ) {
    key = key.replace(' ', '_');
    return arr[key];
  }

  generateColumns( arr: any ) {
    if( arr && !arr.hasOwnProperty('?column?') ) {
      // let columnArr = Object.keys( arr );
      // let newArr: any = [];
      // columnArr.forEach( ( value ) => {
      //   newArr.push( value.replace('_', ' ') );
      // } );
      this.columnNames = Object.keys( arr );
    } else {
      this.columnNames = [];
    }
  }

  setCurrentRow( data: any ) {
    this.currentRow = data;
  }

  stringToSlug( string: string ) {
    return string.replace(' ', '_');
  }

}
