import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FormServiceService } from "../service/form-service.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NewPasswordDialog } from "./../home/home-page/mat-dialog/new-password-dialog/new-password.dialog";
import { AuthServiceService } from "../service/auth-service.service";
import jwt_decode from "jwt-decode";
import { environment } from "../../../environments/environment";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  isShown: boolean = false; // hidden by default loading icon in submit button

  loginForm!: FormGroup;
  loading: boolean = false;
  azureLoading: boolean = false;
  submitted: boolean = false;
  loginStatus: number = 0;
  message: string = "";
  nestLoginRes: any;
  nestUserInfoRes: any;

  poolData: any;
  userPool: any;

  isAuthenticated: boolean = false;
  errorMessage: string = "";
  accessToken: any | null;
  disabledLoginBtn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public service: FormServiceService,
    private dialog: MatDialog,
    private authService: AuthServiceService
  ) {
    this.poolData = {
      UserPoolId: environment.cognitoGuest.userPoolId,
      ClientId: environment.cognitoGuest.userPoolWebClientId,
    };

    this.userPool = new CognitoUserPool(this.poolData);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  /* on click of login button */
  onSubmit() {
    // localStorage.setItem('token','asdfui3456789')
    // this.router.navigate(["/home"])

    // const base64EncodedPassword = btoa(this.loginForm.value.password);
    // let data ={
    //   username: this.loginForm.value.username,
    //   password:base64EncodedPassword
    // }
    // /* login API is calling */
    // this.service.loginApi(data).subscribe(res=>{
    //   if(res.status=="success"){
    //     /* on success display */
    //     localStorage.setItem('token',res.authToken)
    //     localStorage.setItem("username", this.loginForm.value.username)
    //     this.router.navigate(["/home"])
    //   } else {
    //     alert(res.message)
    //   }
    // }, error=>{
    //   /* error display */
    //   alert(error.error.message)
    // });

    // For Local user
    this.errorMessage = "";
    if (this.loginForm.value.username && this.loginForm.value.password) {
      this.loading = true;

      const authenticationData = {
        Username: this.loginForm.value.username,
        Password: this.loginForm.value.password,
        AuthParameters: {
          AuthFlow: "USER_SRP_AUTH",
          USERNAME: this.loginForm.value.username,
          PASSWORD: this.loginForm.value.password,
          SECRET_HASH: environment.cognitoGuest.clientSecret,
        },
      };
      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      const userData = {
        Username: this.loginForm.value.username,
        Pool: this.userPool,
      };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (res: any) => {
          this.successfulLogin(res);
        },
        onFailure: (err: any) => {
          this.isAuthenticated = false;
          this.loading = false;

          if (err.code === "NotAuthorizedException") {
            this.errorMessage = "Incorrect username or password.";
          } else {
            this.errorMessage = err.message;
          }

          // console.log("loginApi err: " + err.message);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // This callback is triggered when a new password is required
          // console.log("New password required");

          try {
            const dialogRef = this.dialog.open(NewPasswordDialog, {
              width: "500px",
            });

            dialogRef.afterClosed().subscribe((result) => {
              // console.log("New password entered:", result.newPassword);
              // console.log("Confirm password entered:", result.confirmPassword);

              cognitoUser.completeNewPasswordChallenge(
                result.newPassword,
                requiredAttributes,
                {
                  onSuccess: (newPasswordSession) => {
                    // console.log("New password challenge completed successfully");
                    this.successfulLogin(newPasswordSession);
                  },
                  onFailure: (newPasswordErr) => {
                    console.error(
                      "Error completing new password challenge:",
                      newPasswordErr
                    );
                  },
                }
              );
            });
          } catch (error) {
            console.error("Dialog closed with an error:", error);
          }
        },
      });
    } else {
      this.errorMessage = "Username and Password cannot be empty";
      // console.log("loginApi err: " + this.errorMessage);
    }
  }

  // For Local User
  successfulLogin(res: any) {
    // console.log("loginApi res: " + JSON.stringify(res));

    this.isAuthenticated = true;

    //Setup isLocalUser flag to true for Local user pool users
    localStorage.setItem("isLocalUser", "true");

    localStorage.setItem("token", res.accessToken.jwtToken);
    localStorage.setItem("id_token", res.idToken.jwtToken);
    localStorage.setItem("refresh_token", res.refreshToken.token);

    this.accessToken = jwt_decode(res.accessToken.jwtToken);
    localStorage.setItem("token_expiration", this.accessToken.exp);
    // console.log("token_expiration Initial:" + this.accessToken.exp);

    let isAdmin = "false";
    if (this.accessToken["cognito:groups"]) {
      if (this.accessToken["cognito:groups"].includes("Admin")) {
        isAdmin = "true";
      }
    }
    localStorage.setItem("isAdmin", isAdmin);
    // console.log("isAdmin: " + localStorage.getItem("isAdmin"));

    localStorage.setItem(
      "userEmail",
      this.accessToken.username + "@apexon.com"
    );

    const usernameArr = this.accessToken.username.split(".");

    const usernameDisplay =
      usernameArr[0].charAt(0).toUpperCase() +
      usernameArr[0].slice(1) +
      " " +
      usernameArr[1].charAt(0).toUpperCase() +
      usernameArr[1].slice(1);

    const usernameInitials =
      usernameArr[0].charAt(0).toUpperCase() +
      usernameArr[usernameArr.length - 1].charAt(0).toUpperCase();

    localStorage.setItem("username", this.accessToken.username);
    localStorage.setItem("usernameDisplay", usernameDisplay);
    localStorage.setItem("usernameInitials", usernameInitials);

    /** NEST API call start */
    this.authService.loginIntoNEST().subscribe(
      (res) => {
        this.nestLoginRes = res.response.results;
        // console.log(this.nestLoginRes);

        if (!this.nestLoginRes.error) {
          localStorage.setItem("nestToken", this.nestLoginRes.token);
        } else {
          alert(this.nestLoginRes.action_message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );

    setTimeout(() => {
      let dataObj = {
        token: localStorage.getItem("nestToken"),
        emp_work_email: localStorage.getItem("userEmail"),
      };
      this.authService.getUserInfoFromNEST(dataObj).subscribe(
        (res) => {
          this.nestUserInfoRes = res.response.results;
          // console.log(
          //   "Nest nestUserInfoRes res: " + JSON.stringify(this.nestUserInfoRes)
          // );

          if (!this.nestUserInfoRes.error) {
            localStorage.setItem(
              "userDesignation",
              this.nestUserInfoRes.Designation
            );

            if (this.nestUserInfoRes.Role === "Manager") {
              localStorage.setItem("isManager", "true");
            } else {
              localStorage.setItem("isManager", "false");
            }
          } else {
            alert(this.nestUserInfoRes.action_message);
          }
        },
        (error) => {
          alert(error.error.message);
        }
      );
    }, 2000);
    /** NEST API call end */

    setTimeout(() => {
      this.router.navigate(["/dashboard"]);
    }, 3000);
  }

  // For Azure AD user
  initiateAzureADLogin() {
    const dialogRef = this.dialog.open(DisclaimerDialog, {
      disableClose: true,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.status) {
        this.disabledLoginBtn = true;
        this.azureLoading = true;

        /** set user coords to be blank */
        localStorage.setItem("user_coords", "");

        /** capture IP Address */
        this.service.getIpAddress().subscribe((res: any) => {
          localStorage.setItem("user_ip", res.ip);
          const loginURL = `${environment.cognito.userPoolDomain}/oauth2/authorize?client_id=${environment.cognito.userPoolWebClientId}&response_type=code&scope=${environment.cognito.scope}&redirect_uri=${environment.cognito.redirectUri}`;
          window.location.href = loginURL;
        });
      }
    });
  }

  // For Custom Credential User
  getClientCredentialsToken() {
    this.authService
      .getClientCredTokenFromAWSCognitoAzure()
      .subscribe((res) => {
        console.log("res = " + JSON.stringify(res));
      });
  }
}

@Component({
  selector: "app-login",
  templateUrl: "login-disclaimer.component.html",
  styleUrls: ["./login.component.scss"],
})
export class DisclaimerDialog {
  constructor(public dialogRef: MatDialogRef<DisclaimerDialog>) {}

  close(status: number) {
    this.dialogRef.close({ status: status });
  }
}
