<mat-sidenav-container class="side-nav-main-container">
    <mat-sidenav #sidenav="matSidenav" class="right-wrapper" disableClose>
        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <!-- Logo wrapper with logo and text -->
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <!-- Menu Icon -->
                    <!-- <div class="custom-menu-icon addCursor" (click)="dashboardRedirection()" title="Home">
                        <div class="menu menu-close">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div> -->
                    <!-- Home Icon -->
                    <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home"
                        (click)="dashboardRedirection()" title="Home" />
                </div>
                <div class="form-wrapper t2t">
                    <!-- <div class="input-wrapper mb-3">
                        <label for="inp1">Task</label>
                        <mat-form-field>
                        </mat-form-field>
                    </div> -->
                    <input type="hidden" *ngIf="selectedTask === 'text_to_text'" value="Text to Text" readonly>
                    <input type="hidden" *ngIf="selectedTask === 'text_to_image'" value="Text to Image" readonly>


                    <!-- sidebar foe text-to-text -->
                    <div *ngIf="!showImage">
                        <div class="input-wrapper mb-3">
                            <label for="inp2">Type</label>
                            <mat-form-field>
                                <mat-select id="inp2" (selectionChange)="getTypeInfo()" [(ngModel)]="selectedType"
                                    [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag">
                                    <mat-option value="open_source">Open Source
                                    </mat-option>
                                    <mat-option value="open_ai">Open AI
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        <div class="input-wrapper mb-3">
                            <label for="inp3">Model Name</label>
                            <mat-form-field><!--(selectionChange)="newChat()"-->
                                <mat-select id="inp3" [(ngModel)]="selectedModal"
                                    [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag" (selectionChange)="onModalAndTypeChange()">
                                    <mat-option *ngFor="let item of modelInfo; let i = index" [value]="item.title"
                                        title="{{item.description}}">
                                        {{item.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <div class="custom-select-container custom-select-container3"> -->
                            <!-- <select id="inp3" class="form-select" aria-label="Default select example"
                                    [(ngModel)]="selectedModal" (selectionChange)="newChat()">
                                    <option *ngFor="let item of modelInfo; let i = index" [value]="item.title"
                                        title="{{item.description}}">
                                        {{item.title}}
                                    </option>
                                </select> -->
                            <!-- </div> -->
                        </div>
                        <div class="input-wrapper mb-3">
                            <label for="inp4" class="label-group">
                                <span>Folder</span>
                                <img src="../../../../assets/icons/add-icon.svg" class="addCursor"
                                    (click)="openIndustry()" title="Add Folder" />
                            </label>
                            <mat-form-field>
                                <mat-select id="inp4" class="addCursor" [(ngModel)]="selectedIndustry"
                                    (selectionChange)='onChangeOfSelectFolder()'
                                    [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag">
                                    <mat-option value="None">None</mat-option>
                                    <mat-option *ngFor="let item of industryInfo" value="{{item.industry_id}}">
                                        <!-- {{item.industry}} -->
                                        <ng-container
                                            *ngIf="!isFolderSelected(item.industry_id); else selectedFolderTemplate">
                                            {{item.industry}}
                                            <button (click)="deleteFolder(item)"
                                                style="float: right; background: none;">
                                                <img class="cross-icon" src="../../../assets/icons/cross-icon.svg"
                                                    alt="Delete" title="Delete" />
                                            </button>
                                        </ng-container>
                                        <ng-template #selectedFolderTemplate>
                                            {{item.industry}}
                                        </ng-template>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <div class="custom-select-container custom-select-container4"> -->
                            <!-- <select id="inp4" class="addCursor" [(ngModel)]="selectedIndustry"
                                (selectionChange)='getPersonaInfo()'>
                                <option value="None">None</option>
                                <option *ngFor="let item of industryInfo" value="{{item.industry_id}}">
                                    {{item.industry}}</option>
                            </select> -->
                            <!-- </div> -->
                        </div>
                        <div class="input-wrapper mb-3">
                            <label for="inp5" class="label-group">
                                <span>Document</span>
                                <img src="../../../../assets/icons/add-icon.svg" class="addCursor"
                                    (click)="openPersona()" title="Add Document" />
                            </label>
                            <mat-form-field>
                                <mat-select id="inp5" [(ngModel)]="selectedPersona" style="cursor: pointer;"
                                    [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag" (selectionChange)="onModalAndTypeChange()">
                                    <mat-option value="None" selected>None</mat-option>
                                    <mat-option *ngFor="let item of personaInfo" [value]="item.persona_id">
                                        <!-- {{item.persona}} -->
                                        <ng-container
                                            *ngIf="!isDocumentSelected(item.persona_id); else selectedDocumentTemplate">
                                            {{item.persona}}
                                            <button (click)="deleteDocument(item)"
                                                style="float: right; background: none;">
                                                <img class="cross-icon" src="../../../assets/icons/cross-icon.svg"
                                                    alt="Delete" title="Delete" />
                                            </button>
                                        </ng-container>
                                        <ng-template #selectedDocumentTemplate>
                                            {{item.persona}}
                                        </ng-template>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <div class="custom-select-container custom-select-container5"> -->
                            <!-- <select id="inp5" [(ngModel)]="selectedPersona" style="cursor: pointer;">
                                <option value="None" selected>None</option>
                                <option *ngFor="let item of personaInfo" [value]="item.persona_id">
                                    {{item.persona}}
                                </option>
                            </select> -->
                            <!-- </div> -->
                        </div>
                        <div class="input-wrapper">
                            <button [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag"
                                (click)="newChat(this.selectedTask)"
                                [ngClass]="{'disabled': service.showChatLoader || service.alive || service.stopGeneratorFlag}">
                                + New Chat
                            </button>
                        </div>
                    </div>

                    <!-- sidebar foe text-to-image -->
                    <div *ngIf="showImage">
                        <div class="input-wrapper mb-3">
                            <label for="inp3">Model Name</label>
                            <mat-form-field><!--(selectionChange)="newChat()"-->
                                <mat-select class="addCursor" [(ngModel)]="selectedModalImage"
                                    [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag">
                                    <mat-option *ngFor="let item of modelImageInfo; let i = index" [value]="item.title"
                                        title="{{item.description}}">
                                        <!-- {{ textToImageModalSlugToName(item.title) }} -->
                                        {{ item.title }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <div class="custom-select-container custom-select-container3"> -->
                            <!-- <select id="inp3" class="addCursor" [(ngModel)]="selectedModalImage" (selectionChange)="newChat()">
                                <option *ngFor="let item of modelImageInfo; let i = index" [value]="item.title"
                                    title="{{item.description}}">
                                    {{item.title}}
                                </option>
                            </select> -->
                            <!-- </div> -->
                        </div>
                        <div class="input-wrapper mb-3">
                            <button [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag"
                                (click)="newChat(this.selectedTask)"
                                [ngClass]="{'disabled': service.showChatLoader || service.alive || service.stopGeneratorFlag}">
                                + New Chat
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p>{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content mat-elevation-z8">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>