import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdminSettingsServiceService } from "../../../service/admin-settings-service.service";
import { FormServiceService } from "../../../service/form-service.service";
import { DashboardServiceService } from "./../../../service/dashboard-service.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../../../environments/environment";
import { Subscription, timer } from "rxjs";
import { switchMap, takeWhile } from "rxjs/operators";
@Component({
  selector: "app-model-portfolio-form-data",
  templateUrl: "./model-portfolio-form-data.component.html",
  styleUrls: ["./model-portfolio-form-data.component.scss"],
})
export class ModelPortfolioFormDataComponent implements OnInit {
  modelForm!: FormGroup;
  isAdmin: any;
  username: any;
  modelList: any = [];
  durationStateArr: any = [];
  isValidForm: boolean = true;
  errorMessage: string = "";
  numberRegex = /^\d+$/;
  modelStatusRes: any;
  machine_status: any;
  response_code: any;
  token: any;
  userEmail: any;

  constructor(
    public route: Router,
    private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public service: AdminSettingsServiceService,
    public formService: FormServiceService,
    public dashboardService: DashboardServiceService
  ) {
    this.isAdmin = localStorage.getItem("isAdmin") === "true" ? true : false;
    this.username = localStorage.getItem("username");
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");
  }

  ngOnInit() {
    this.formService.alive = false;
    this.getTextToImageModelStatus();
  }

  getTextToImageModelStatus() {
    this.service.showChatLoader = true;

    /** Text to Image model status */
    let apiData = {
      env_name: environment.env_name,
      model_name: "sd2",
    };
    this.formService.getSageMakerModelInfo(apiData).subscribe(
      (apiResult) => {
        this.machine_status = apiResult.message;
        this.response_code = apiResult.response_code;

        // Text to Text call
        this.getTextToTextModels();
      },
      (error) => {
        console.error("API call error:", error);
        this.formService.alive = false; // Stop the continuous calls on error
      }
    );
  }

  getTextToTextModels() {
    this.modelList = [];
    this.service.showChatLoader = true;

    let data = {
      type_model: "open_source",
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.formService.postModelInfo(data).subscribe(
      (res) => {
        if (res.status == "success") {
          res.models_tooltip.forEach((rowData: any) => {
            this.durationStateArr.push("");

            let model_name =
              rowData.title === "MosaicML" ? "mosaicml" : "llama2";
            let apiData = {
              env_name: environment.env_name,
              model_name: model_name,
            };
            this.formService.getSageMakerModelInfo(apiData).subscribe(
              (apiResult) => {
                rowData["machine_status"] = apiResult.message;
                rowData["response_code"] = apiResult.response_code;
              },
              (error) => {
                console.error("API call error:", error);
                this.formService.alive = false; // Stop the continuous calls on error
              }
            );

            // rowData["duration"] = "";
            // rowData["machine_status"] = rowData["machine_status"] === true ? "ON" : "OFF";
            // if (rowData["machine_status"] === "ON") {
            //   rowData["validDuration"] = true;
            // } else {
            //   rowData["selectedModel"] = false;
            //   rowData["validDuration"] = false;
            // }
            this.modelList.push(rowData);
          });
        } else {
          this.service.showChatLoader = false;
          alert(res.message);
        }
        this.service.showChatLoader = false;

        // Text to Image call
        this.getTextToImageModel();
      },
      (error) => {
        this.service.showChatLoader = false;
        alert(error.error.message);
      }
    );
  }

  getTextToImageModel() {
    this.service.showChatLoader = true;
    let data2 = {
      task: "image",
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.formService.postImageModelInfo(data2).subscribe(
      (res) => {
        if (res.status == "success") {
          res.model_tooltip.forEach((rowData: any) => {
            this.durationStateArr.push("");

            rowData["machine_status"] = this.machine_status;
            rowData["response_code"] = this.response_code;

            // rowData["duration"] = "";
            // rowData["machine_status"] = rowData["machine_status"] === true ? "ON" : "OFF";
            // if (rowData["machine_status"] === "ON") {
            //   rowData["validDuration"] = true;
            // } else {
            //   rowData["selectedModel"] = false;
            //   rowData["validDuration"] = false;
            // }
            this.modelList.push(rowData);
          });
        } else {
          this.service.showChatLoader = false;
          alert(res.message);
        }
        this.service.showChatLoader = false;
      },
      (error) => {
        this.service.showChatLoader = false;
        alert(error.error.message);
      }
    );
  }

  onDurationChange(rowData: any, index: any) {
    if (this.numberRegex.test(rowData.duration)) {
      this.modelList[index].validDuration = true;
      this.isValidForm = true;
      this.errorMessage = "";
    } else {
      this.modelList[index].validDuration = false;
      this.isValidForm = false;
      this.errorMessage =
        "Duration should not be empty & accepts numeric values only";
    }
  }

  onSubmit(rowData: any, index: any): void {
    // this.service.showChatLoader = true;

    // if (rowData.selectedModel === true) {
    //   if (this.numberRegex.test(rowData.duration)) {
    //     this.isValidForm = true;
    //   } else {
    //     this.isValidForm = false;
    //     this.service.showChatLoader = false;
    //     this.errorMessage =
    //       "Duration should not be empty & accepts numeric values only";

    //     this.modelList[index].duration = "";
    //     this.modelList[index].selectedModel = false;
    //   }
    // } else {
    //   this.isValidForm = true;
    // }

    let model_name = "";
    if (rowData.title === "Llama2") {
      model_name = "llama2";
    } else if (rowData.title === "MosaicML") {
      model_name = "mosaicml";
    } else {
      model_name = "sd2";
    }
    let apiData = {
      env_name: environment.env_name,
      model_name: model_name,
    };
    this.turnOnSageMakerModel(apiData);
  }

  turnOnSageMakerModel(apiData: any) {
    this.formService.alive = true;
    timer(0, 30000) // Initial delay of 0 milliseconds, subsequent calls every 30000 milliseconds
      .pipe(
        takeWhile(() => this.formService.alive),
        switchMap(() => this.formService.getSageMakerModelInfo(apiData))
      )
      .subscribe(
        (apiResult) => {
          // console.log("API call successful!", apiResult);
          this.modelStatusRes = apiResult;
          if (this.modelStatusRes.response_code === 0) {
            this.formService.alive = false; // Stop the continuous calls if status is 0

            // console.log("Model started successfully!", this.modelStatusRes);
            if (apiData.model_name === "llama2") {
              this.formService.llama2APIUrl = this.modelStatusRes.ep_url;
            } else if (apiData.model_name === "mosaicml") {
              this.formService.mosaicmlAPIUrl = this.modelStatusRes.ep_url;
            } else {
              this.formService.textToImageAPIUrl = this.modelStatusRes.ep_url;
            }

            // Reload APIs
            this.getTextToImageModelStatus();
          } else {
            // Check the result status
            console.log("Checking on Model status...");
          }
        },
        (error) => {
          console.error("API call error:", error);
          this.formService.alive = false; // Stop the continuous calls on error
        }
      );
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }
}
