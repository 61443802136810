<section id="hero">

  <div id="loading" style="display: none;">
    <app-loader></app-loader>
  </div>
  <div role="status" *ngIf="service.loading">
    <app-loader></app-loader>
  </div>

  <!-- to display the text and icon in top -->
  <div class="container">

    <div class="row">
      <div class="col-md-12">


        <!-- chat window request and response -->
        <div id="Smallchat">
          
          <!-- style="color: rgb(255, 255, 255);opacity: 5;border-radius: 10px;" -->
          <div class="Layout-right">
            <div class="Messenger_messenger">
              
              <div class="Messenger_content">
                <div class="Messages" id="reportContainer">
                  <div class="Messages_list">
                    <div class="chat-window" *ngIf="service.taskConvertor === 'conversation_bi' && service.showChatLoader">
                      <div class="stat-container">
                        <div class="stats-wrapper">
                          <div class="stat-card">
  
                            <div class="stat-header">
                              <div class="tab-switcher">
                                <div class="chart-button1 tab tab0" title="Pie chart" [ngClass]="{'active': step === 1}"
                                  (click)="pieChart()">
                                  <p> Pie&nbsp;Chart</p>
                                </div>
                                <div class="chart-button2 tab tab1" title="Area chart" [ngClass]="{'active': step === 2}"
                                  (click)="areaChart()">
                                  <p> Area&nbsp;Chart</p>
                                </div>
                                <div class="chart-button3 tab tab2" title="Donut chart" [ngClass]="{'active': step === 3}"
                                  (click)="donutChart()">
                                  <p> Donut&nbsp;Chart</p>
                                </div>
                                <div class="chart-button4 tab tab3" title="Bar chart" [ngClass]="{'active': step === 4}"
                                  (click)="barChart()">
                                  <p> Bar&nbsp;Chart</p>
                                </div>
                              </div>
                              <!-- <div class="like-actions">
                                <div class="action like">
                                  <img title="Like" #like src="../../../../assets/img/like.svg" class="addCursor" [ngClass]="likeBtnClicked"
                                  (click)='like_response($event)' alt="like">
                                  <img title="Dislike" #unlike src="../../../../assets/img/unlike.svg" class="addCursor" [ngClass]="unlikeBtnClicked"
                                  (click)='unlike_response($event)' alt="like">
                                </div>
                              </div> -->
                            </div>
                            <div class="col-lg-12" style="padding: 0;">
                              <section id="report-container" #reportContainer class="report-section"
                                 embed-container
                                col-lg-offset-4 col-lg-7 col-md-offset-5 col-md-7 col-sm-offset-5 col-sm-7 mt-5>
                              </section>
                            </div>
                          </div>
                          <div *ngIf="messages.author == 'user'" class="avatar">
                            <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                            <p class="profile-text">{{ messages.messages }}</p>
                          </div>
                        </div>
                        <div class="regenerate" *ngIf="regenerateFlag">
                          <button (click)='regenerateMessage()'>
                            <img class="regenerate-img" src="../../../../assets/icons/regenerate-icon.svg" /> Regenerate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- loader for loading chat -->
              
              <div class="status-loader" #statusloader id="statusloader" style="display: none;">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
              </div>

              <div class="container stop-generator " *ngIf="stopGeneratorFlag">
                <button (click)='stopGeneratorPrompt()' class="stop-genrator-btn">Stop generating</button>
              </div>

            </div>
          </div>

          <div class="Input Input-blank">
            <div class="msg-box">
              <!-- <input
                type="text"
                id="myinput"
                [placeholder]='placeholder'
                style="background-color: #fff !important;"
                [(ngModel)]="userMessage"
                (input)="resizeTextarea($event)"
                (keydown.enter)="!flag && sendMessage()"
                [disabled]="service.showChat"
                #myinput
              > -->
              <textarea
                id="myinput"
                style="background-color: #fff !important;margin-left: 15px; padding: 0; height: 24px;"
                (input)="resizeTextarea($event)"
                [placeholder]='placeholder'
                [(ngModel)]="userMessage"
                (keydown.enter)="!flag && sendMessage()"
                [disabled]="service.showChat"
                #myinput
              ></textarea>
              <img *ngIf='!userMessage' src="../../../../../assets/icons/icon-send-grey.svg" alt="send" />
              <img *ngIf='userMessage' class="addCursor" src="../../../../../assets/icons/icon-send.svg" alt="send"
                (click)="!flag && sendMessage()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal" #modal tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Error</h5>
        <mat-icon class="cancel-btn" (click)="close()" title="Close" title="Close">cancel</mat-icon>
      </div>
      <div class="modal-body">
        <p class="pmpt">Invalid prompt please enter correct prompts to regenerate chart</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="close()" title="Close" class="btn btn-close">Close</button>
        <button type="button" (click)="close()" title="OK" class="btn btn-save">OK</button>
      </div>
    </div>
  </div>
</div>