import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormServiceService } from 'src/app/module/service/form-service.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  modalContent: string;
  isChecked: boolean = false;
  isLoadingFlag = false;
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    public service: FormServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data.content;
  }


  ngOnInit(): void {
  }

  CancelClick() {
    this.dialogRef.close("closed")
  }

  dialogClose() {
    this.isLoadingFlag = true
    let token = localStorage.getItem("token")
    let userEmail = localStorage.getItem("userEmail")
    let payload = {
      type:"OpenAI",
      isagreed: this.isChecked,
      userEmail,
      token
    }

    this.service.openAiDisclaimer(payload).subscribe(
      (res) => {
        if (res.status == "Success") {
          this.dialogRef.close()
        } else {
          alert(res.message);
        }
        this.isLoadingFlag = false
      },
      (error) => {
        alert(error.error.message);
      }
    );

  }


}