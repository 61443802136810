import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormDataComponent } from './form-data/form-data.component';
import { HomePageComponent } from './home-page.component';

const routes: Routes = [
  {
    path:'',
    component:HomePageComponent,
    children: [
      { path: '', redirectTo: 'form-data', pathMatch: 'full' },
      { 
        path: 'form-data',
        component:FormDataComponent,
      },
    ]
  },
  // {
  //   path:'form-data',
  //   component:FormDataComponent
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomePageRoutingModule { }
