import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ModelPortfolioFormDataComponent } from "./model-portfolio-form-data/model-portfolio-form-data.component";
import { AdminSettingsPageComponent } from "./admin-settings-page.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { CostTableComponent } from "./cost-table/cost-table.component";
import { CostDetailsComponent } from "./cost-table/cost-details/cost-details.component";
import { CostDetailsUserComponent } from "./cost-table/cost-details-user/cost-details-user.component";
import { AccessListComponent } from "./access-list/access-list.component";
import { AnalyticsComponent } from "./analytics/analytics.component";

const routes: Routes = [
  {
    path: "",
    component: AdminSettingsPageComponent,
    children: [
      {
        path: "model-portfolio",
        component: ModelPortfolioFormDataComponent,
      },
      {
        path: "feedback",
        component: FeedbackComponent,
      },
      {
        path: "cost",
        component: CostTableComponent,
        children: [],
      },
      {
        path: "cost/cost-details",
        component: CostDetailsComponent,
      },
      {
        path: "cost/cost-details/cost-details-user",
        component: CostDetailsUserComponent,
      },
      {
        path: "access-list",
        component: AccessListComponent,
      },
      {
        path: "analytics",
        component: AnalyticsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminSettingsPageRoutingModule {}
