import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['snackbar.component.scss'],
})
export class CustomSnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
  private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>
  ) {}
  closeSnackbar() {
    this.snackBarRef.dismiss();
  }
}
