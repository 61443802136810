import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import jwt_decode from "jwt-decode";
import AWS from "aws-sdk";
import { DatePipe } from "@angular/common";
import * as CryptoJS from "crypto-js";

export class Message {
  constructor(public author: string, public content: string) {
    author = "";
    content = "";
  }
}
@Injectable({
  providedIn: "root",
})
export class AuthServiceService {
  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;

  accessKeyId: any;
  secretAccessKey: any;
  sessionToken: any;

  public baseapiUrl: string = environment.baseapiurl + "api/";

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private route: Router
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");

    this.accessKeyId = localStorage.getItem("accessKeyId");
    this.secretAccessKey = localStorage.getItem("secretAccessKey");
    this.sessionToken = localStorage.getItem("sessionToken");

    this.poolData = {
      UserPoolId: environment.cognitoGuest.userPoolId,
      ClientId: environment.cognitoGuest.userPoolWebClientId,
    };

    this.userPool = new CognitoUserPool(this.poolData);
  }

  getExpiryTime() {
    let accessTokenExp = localStorage.getItem("token_expiration");
    return accessTokenExp ? accessTokenExp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: any = this.getExpiryTime();
    if (expiryTime) {
      var d = new Date(expiryTime * 1000); // The 0 there is the key, which sets the date to the epoch
      // Returns true if token expire time is less than 5 second.
      return d.getTime() - new Date().getTime() < 5000;
    } else {
      return true;
    }
  }

  checkTokenExpiry() {
    if (this.isTokenExpired()) {
      console.log("Token Expired");
      this.logout();
    } else {
      console.log("Token Not Expired");
    }
  }

  // For Azure AD User
  getTokenFromAWSCognitoAzure(code: string) {
    const apiURL = `${environment.cognito.userPoolDomain}/oauth2/token`;

    const body = new URLSearchParams();
    body.set("grant_type", "authorization_code");
    body.set("client_id", environment.cognito.userPoolWebClientId);
    body.set("code", code);
    body.set("redirect_uri", environment.cognito.redirectUri);

    const authHeaderVal =
      "Basic " +
      btoa(
        `${environment.cognito.userPoolWebClientId}:${environment.cognito.clientSecret}`
      );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authHeaderVal,
      }),
    };

    return this.http.post(apiURL, body.toString(), httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // For Azure AD User
  getRefreshTokenFromAWSCognitoAzure(refreshToken: string) {
    const apiURL = `${environment.cognito.userPoolDomain}/oauth2/token`;

    const body = new URLSearchParams();
    body.set("grant_type", "refresh_token");
    body.set("client_id", environment.cognito.userPoolWebClientId);
    body.set("refresh_token", refreshToken);

    const authHeaderVal =
      "Basic " +
      btoa(
        `${environment.cognito.userPoolWebClientId}:${environment.cognito.clientSecret}`
      );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authHeaderVal,
      }),
    };

    return this.http.post(apiURL, body.toString(), httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // For Custom Credential User
  getClientCredTokenFromAWSCognitoAzure() {
    const apiURL = `${environment.cognitoClientCred.userPoolDomain}/oauth2/token`;

    const body = new URLSearchParams();
    body.set("grant_type", "client_credentials");
    body.set("client_id", environment.cognitoClientCred.userPoolWebClientId);
    body.set("client_secret", environment.cognitoClientCred.clientSecret);

    const authHeaderVal =
      "Basic " +
      btoa(
        `${environment.cognito.userPoolWebClientId}:${environment.cognito.clientSecret}`
      );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        // Authorization: authHeaderVal,
      }),
    };

    return this.http.post(apiURL, body.toString(), httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // For Azure AD User
  revokeTokenFromAWSCognitoAzure(refreshToken: string) {
    const apiURL = `${environment.cognito.userPoolDomain}/oauth2/revoke`;

    const body = new URLSearchParams();
    body.set("client_id", environment.cognito.userPoolWebClientId);
    body.set("token", refreshToken);

    const authHeaderVal =
      "Basic " +
      btoa(
        `${environment.cognito.userPoolWebClientId}:${environment.cognito.clientSecret}`
      );
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authHeaderVal,
      }),
    };

    return this.http.post(apiURL, body.toString(), httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // For Azure AD User
  getUserInfoFromAWSCognitoAzure(accessToken: string) {
    const apiURL = `${environment.cognito.userPoolDomain}/oauth2/userInfo`;

    const authHeaderVal = "Bearer " + accessToken;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: authHeaderVal,
      }),
    };

    return this.http.get(apiURL, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  logout() {
    // console.log("isLocalUser: " + this.isLocalUser);
    if (this.isLocalUser === "true") {
      // For Local user
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.signOut();
        localStorage.clear();
        this.route.navigate(["/login"]);
      }
    } else {
      // For Azure AD user
      this.refreshToken = localStorage.getItem("refresh_token");
      if (this.refreshToken !== null || this.refreshToken !== undefined) {
        this.revokeTokenFromAWSCognitoAzure(this.refreshToken).subscribe(
          (res) => {
            // console.log("Azure RevokeTokenApi res: " + res);
            localStorage.clear();
            this.route.navigate(["/login"]);
          },
          (err) => {
            // console.log("Azure RevokeTokenApi err: " + err.message);
          }
        );
      } else {
        // console.log("Unable to get Refresh Token");
      }
    }
  }

  // For Local User
  checkAndRefreshSession() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          // console.log("Error getting session: ", err.message);
          localStorage.clear();
          this.route.navigate(["/login"]);
        } else {
          // Check if the session is valid, if not, use the refresh token
          if (session.isValid()) {
            // console.log("Session is valid");

            localStorage.setItem("token", session.accessToken.jwtToken);
            localStorage.setItem("id_token", session.idToken.jwtToken);
            this.accessToken = jwt_decode(session.accessToken.jwtToken);
            localStorage.setItem("token_expiration", this.accessToken.exp);

            // console.log("token_expiration: " + this.accessToken.exp);

            // Update AWS Credentials
            // this.integrateUserPoolWithIdentityPool();
          } else {
            // Use the refresh token to get a new session
            cognitoUser.refreshSession(
              session.getRefreshToken(),
              (err: any, refreshedSession: any) => {
                if (err) {
                  console.log("Error refreshing session: ", err.message);
                  localStorage.clear();
                  this.route.navigate(["/login"]);
                } else {
                  console.log(
                    "Session refreshed:",
                    JSON.stringify(refreshedSession)
                  );
                }
              }
            );
          }
        }
      });
    }
  }

  // For Azure AD user
  checkAndRefreshSessionForAWSCognitoAzure() {
    if (localStorage.getItem("refresh_token")) {
      // Check if the access token is expired
      this.tokenExpired = this.isTokenExpired();
      // console.log("tokenExpired: " + this.tokenExpired);

      // If the access token is expired, call the refresh token API
      if (this.tokenExpired) {
        this.refreshToken = localStorage.getItem("refresh_token");

        this.getRefreshTokenFromAWSCognitoAzure(this.refreshToken).subscribe(
          (session) => {
            // console.log("Session is valid");

            localStorage.setItem("token", session.access_token);
            localStorage.setItem("id_token", session.id_token);
            this.accessToken = jwt_decode(session.access_token);
            localStorage.setItem("token_expiration", this.accessToken.exp);

            // console.log("token_expiration: " + this.accessToken.exp);

            // Update AWS Credentials
            // this.integrateUserPoolWithIdentityPool();
          },
          (err) => {
            console.log("Error refreshing session: ", err.message);
            localStorage.clear();
            this.route.navigate(["/login"]);
          }
        );
      }
    } else {
      localStorage.clear();
      this.route.navigate(["/login"]);
    }
  }

  integrateUserPoolWithIdentityPool() {
    if (this.isLocalUser === "true") {
      // For Local User
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession((err: any, result: any) => {
          if (result) {
            // console.log("Session is valid");

            this.integrationParams = {
              IdentityPoolId: environment.identityPool.identityPoolId,
              Logins: {
                "cognito-idp.us-east-1.amazonaws.com/us-east-1_dL6KzOKjE":
                  result.idToken.jwtToken,
                // "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_PfjeQw1wA": result.idToken.jwtToken,
              },
            };

            // Configure the credentials provider to use your identity pool
            this.getAWSCredentials(this.integrationParams);
          } else {
            console.log("Error getting session: ", err.message);
          }
        });
      }
    } else {
      // For Azure AD User
      this.tokenExpired = this.isTokenExpired();
      if (!this.tokenExpired) {
        this.idToken = localStorage.getItem("id_token");

        if (this.idToken !== null || this.idToken !== undefined) {
          // console.log("Session is valid");

          if (environment.cognito.env_name === "dev") {
            this.integrationParams = {
              IdentityPoolId: environment.identityPool.identityPoolId,
              Logins: {
                "cognito-idp.us-east-1.amazonaws.com/us-east-1_yX6Vlc7KK":
                  this.idToken,
              },
            };
          } else if (environment.cognito.env_name === "alpha-prod") {
            this.integrationParams = {
              IdentityPoolId: environment.identityPool.identityPoolId,
              Logins: {
                "cognito-idp.us-east-1.amazonaws.com/us-east-1_tKNirFdeq":
                  this.idToken,
              },
            };
          }

          // Configure the credentials provider to use your identity pool
          this.getAWSCredentials(this.integrationParams);
        } else {
          console.log("Unable to get Id Token");
        }
      }
    }
  }

  getAWSCredentials(integrationParams: any) {
    // Configure the AWS region
    AWS.config.update({
      region: environment.identityPool.identityPoolRegion,
    });

    const credentials = new AWS.CognitoIdentityCredentials(integrationParams);

    // Credentials will be available when this function is called.
    credentials.get((err: any) => {
      if (err) {
        console.error("Error retrieving AWS credentials: ", err.message);
      } else {
        // Use the credentials for AWS service operations
        // console.log('AWS credentials retrieved successfully: ', JSON.stringify(credentials));

        // console.log("accessKeyId: ", credentials.accessKeyId);
        // console.log("secretAccessKey: ", credentials.secretAccessKey);
        // console.log("sessionToken: ", credentials.sessionToken);

        localStorage.setItem("identityId", credentials.identityId);
        localStorage.setItem("accessKeyId", credentials.accessKeyId);
        localStorage.setItem("secretAccessKey", credentials.secretAccessKey);
        localStorage.setItem("sessionToken", credentials.sessionToken);
      }
    });
  }

  loginIntoNEST() {
    let data = {
      user_name: environment.nestUsername,
      password: environment.nestPassword,
    };
    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post<any>(environment.nestUri + "api-login", data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getUserInfoFromNEST(data: any) {
    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post<any>(environment.nestUri + "get-api-employee-detail", data, {
        headers: headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getActiveUserListFromNest(data: any) {
    let headers = {
      "Content-Type": "application/json",
    };

    return this.http
      .post<any>(
        environment.nestUri + "get-employee-basic-details-for-genysys",
        data,
        {
          headers: headers,
        }
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getGroupListForUser(data: any) {
    return this.http
      .post<any>(this.baseapiUrl + "view_access_list_groups", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
