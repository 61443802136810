import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConvoBiHomeComponent } from './convo-bi-home.component';

const routes: Routes = [
  {
    path: "",
    component: ConvoBiHomeComponent,
    children: [
      { path: "", redirectTo: "conversation-bi", pathMatch: "full" },
      {
        path: "conversation-bi",
        loadChildren: () =>
          import("./conversation-bi/conversation-bi.module").then(
            (m) => m.ConversationBiModule
          ),
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConvoBiHomeRoutingModule { }
