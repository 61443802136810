<div class="content">
    <h3>Code Generator</h3>
    <form [formGroup]="codeRequestForm">
        <div class="chat-window">

            <div class="msg-box">
                <div class="input">
                    <p class="req-qualifier-label" style="margin-bottom: 15px;">
                        Enter any thoughts for getting code to create any application
                    </p>
                    <div class="input-row">
                        <div class="error-input-group">
                            <textarea  (input)="adjustTextareaHeight($event)" formControlName="codeReqInput"
                                required placeholder="Enter thoughts to get code" rows="1"></textarea>

                        </div>
                        <div>
                            <span>Please select at least one Backend or Frontend.</span>
                        </div>
                        <div class="input-ddl">

                            <select id="selectedLanguage" formControlName="selectedBackEndLanguage" class="form-control"
                                (click)="onChanges()">
                                <option value=""> Backend </option>
                                <option *ngFor="let language of backEndLanguages" [value]="language">
                                    {{ language }}
                                </option>
                            </select>
                            <select id="selectedLanguage" formControlName="selectedFrontEndLanguage"
                                class="form-control">
                                <option value=""> Forntend </option>
                                <option *ngFor="let language of frontEndFrameworksAndLibraries" [value]="language">
                                    {{ language }}
                                </option>
                            </select>
                            <select id="selectedLanguage" formControlName="orm" class="form-control">
                                <option value=""> ORM </option>
                                <option *ngFor="let orm of orms" [value]="orm">{{ orm }}</option>
                            </select>
                            <select id="selectedLanguage" formControlName="selectedArchitecture" class="form-control">
                                <option value=""> Architechture </option>
                                <option *ngFor="let architecture of architectures" [value]="architecture">
                                    {{ architecture }}
                                </option>
                            </select>

                            <button class="btn btn-primary" (click)='setSearch()'
                                [disabled]='!(codeRequestForm.valid ) || showLoader' title="Generate Code">
                                Generate
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>
    <app-loader *ngIf='showLoader'></app-loader>
    <div class="data-window" *ngIf="generatedCode">
        <div class="qualifier-title">
            <img src="../../../../assets/img/symbol.svg" alt="logo">
            <h4>Generated Code</h4>
            <!-- <button id="GenFilebtn" (click)="createAndDownloadFiles()">Download Project</button> -->
        </div>

        <div class="content-container">
            <ng-container *ngFor="let block of contentBlocks">
                <p *ngIf="block.type === 'text'" class="normal-text">{{ block.content }}</p>
                <div *ngIf="block.type === 'code'" class="code-block">
                    <div class="code-header">
                        <span class="language-label">{{ block.language }}</span>

                        <img title="Copy" src="../../../../../assets/icons/code-converter/Copy-Default.svg" alt="copy"
                            (click)="copyCode(block.content)">
                    </div>

                    <pre
                        appPrismHighlight><code  [ngClass]="'language-' + block.language">{{ block.content }}</code></pre>
                </div>

            </ng-container>
        </div>

    </div>