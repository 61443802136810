<section id="hero">
  <div class="content">
    <div class="chat-window">

      <div style="float: left;" (click)="navigateBack()">
        <img src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" class="back-icon">
      </div>
      <h1>Costing</h1>
      <div class="message info">
        <div style="float: left;" (click)="navigateBackToCost()">
          <img class="back-icon" src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" />
        </div>
        <div class="top-filter">
          <select id="download" (change)="selectDownloadTypeForGroupWiseUserReport($event)">
            <option value="">Download</option>
            <option value="csv">CSV</option>
            <option value="excel">Excel</option>
          </select>
        </div>

        <div class="groupDetail">
          <p><b> Group: </b><span>{{groupName}}</span></p>
          <p><b> Cost: </b><span>{{groupTotalCost}} $</span></p>
        </div>
        <div class="cost-table-wrapper">
          <table class="gen-table">
            <thead>
              <tr>
                <th (click)="sortGroupUser('user')" style="cursor: pointer;">
                  <label style="margin-top: 2.5px;">User</label>
                  <img *ngIf="sortGroupUserFlag['user'] === 'ASC'" class="order-icon"
                    src="../../../../assets/icons/asc-icon.svg" alt="Asc" />
                  <img *ngIf="sortGroupUserFlag['user'] === 'DESC'" class="order-icon"
                    src="../../../../assets/icons/desc-icon.svg" alt="Desc" />
                </th>
                <th (click)="sortGroupUser('cost')" style="cursor: pointer;">
                  <label style="margin-top: 2.5px;">Cost ($)</label>
                  <img *ngIf="sortGroupUserFlag['cost'] === 'ASC'" class="order-icon"
                    src="../../../../assets/icons/asc-icon.svg" alt="Asc" />
                  <img *ngIf="sortGroupUserFlag['cost'] === 'DESC'" class="order-icon"
                    src="../../../../assets/icons/desc-icon.svg" alt="Desc" />
                </th>
              </tr>
            </thead>
            <tbody *ngIf="usersOfGroup && usersOfGroup.length > 0">
              <tr *ngFor="let user of usersOfGroupPerPage">
                <td>
                  <a href="javascript:void(0);" (click)="openUser(user.user_name, user.total_count)">
                    {{ user.user_name }}
                  </a>
                </td>
                <td>{{ user.total_count }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="usersOfGroup && usersOfGroup.length === 0">
              <tr>
                <td colspan="2">
                  <p class="alert alert-danger">No users are available in this group</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div role="status" *ngIf="showLoader">
            <app-loader></app-loader>
          </div>
        </div>

        <div class="pagination" *ngIf="rowCount > limit">
          <a (click)="currentPage !== 1 && getPageData(1)">&laquo; First</a>
          <a (click)="currentPage > 1 && getPageData(currentPage - 1)">&lsaquo; Previous</a>
          <a *ngFor="let page of paginationRange" [class.active]="page === currentPage" (click)="getPageData(page)">
            {{ page }}
          </a>
          <a (click)="currentPage < pages && getPageData(currentPage + 1)">Next &rsaquo;</a>
          <a (click)="currentPage !== pages && getPageData(pages)">Last &raquo;</a>
        </div>

      </div>
    </div>
  </div>
</section>