<div class="modal-wrapper">

    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title>Provide additional feedback</h1>
            <mat-icon class="cancel-btn" (click)="dialogClose('close')" title="Close" title="Close">cancel</mat-icon>
        </span>
    </div>
    <div mat-dialog-content class="contain">
        <form [formGroup]="feedbackForm">
            <div class="row">
                <div class="col-12 mb-3">
                    <div class="input-group">
                        <textarea class="form-control rd-colr" id="user-feedback" aria-describedby="basic-addon3" formControlName="additional_feedback" rows="6"></textarea>
                    </div>
                    <div class="text-danger">
                        <span class="validation-error" *ngIf="formSubmitted && feedbackForm.controls.additional_feedback.errors?.required">
                            Feedback is required
                        </span>
                        <span class="validation-error" *ngIf="(formSubmitted || feedbackForm.controls.additional_feedback.dirty) && feedbackForm.controls.additional_feedback.errors?.pattern">
                            Only alpha-numeric, hyphen(-), underscore(_) and spaces are allowed
                        </span>
                        <span class="validation-error" *ngIf="(formSubmitted || feedbackForm.controls.additional_feedback.dirty) && feedbackForm.controls.additional_feedback.errors?.maxlength">
                            Additional feedback cannot exceeds 260 characters
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions>
        <button class="btn btn-secondary-outline" (click)='dialogClose("close")'>Close</button>
        <button class="btn btn-primary" (click)="save()" cdkFocusInitial>Submit feedback</button>
    </div>

</div>
