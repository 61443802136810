<div>
    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title class="hd-txt">Change Password</h1>
            <mat-icon class="cancel-btn" (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <!-- to save the persona information -->
    <div mat-dialog-content class="contain">
        <form [formGroup]="PasswordForm">
            <div class="row">
                <!-- new password -->
                <div class="col-12">
                    <div class="mb-3" style="text-align: left;">
                        <label for="newPassword" class="form-label">New Password</label>
                        <input type="password" formControlName="newPassword" class="password-form-control"
                            placeholder="New Password">
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- error -->
                <div *ngIf="isSubmitted && newPassword?.hasError('passwordPolicy')" class="error-msg">
                    Password must contain at least 8 characters, including uppercase, lowercase, number, and special
                    character.
                </div>
            </div>
            <div class="row">
                <!-- confirm password -->
                <div class="col-12">
                    <div class="mb-3" style="text-align: left;">
                        <label for="confirmPassword" class="form-label">Confirm Password</label>
                        <input type="password" formControlName="confirmPassword" class="password-form-control"
                            placeholder="Confirm Password">
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- error -->
                <div *ngIf="!isAuthenticated" class="error-msg">{{errorMessage}}
                </div>
            </div>
        </form>
    </div>
    <!-- to save and close button -->
    <div mat-dialog-actions class="btn-align">
        <button mat-button class="btn btn-close" (click)="onNoClick()">Close</button>
        <button mat-button class="btn btn-save" (click)="onSubmitClick()" cdkFocusInitial>Save</button>
    </div>
</div>