import { Component, Inject } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormServiceService } from "src/app/module/service/form-service.service";

export function industryNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 24;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[A-Za-z0-9]+$/.test(value)) {
      return { invalidCharacters: true };
    } else if( value && !/^[A-Za-z]*[A-Za-z][A-Za-z0-9]*$/.test(value) ) {
      return { invalidName: true };
    }

    return null;
  };
}

@Component({
  selector: "industry.dialog",
  templateUrl: "industry.dialog.html",
  styleUrls: ["industry.dialog.scss"],
})
export class IndustryDialog {
  IndustryForm!: FormGroup;
  formSubmitted: boolean = false;
  industryNameExist: boolean = false;
  token: any;
  userEmail: any;

  constructor(
    public formBuilder: FormBuilder,
    public service: FormServiceService,
    public dialogRef: MatDialogRef<IndustryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.IndustryForm = this.formBuilder.group({
      industry_name: ["", [Validators.required, industryNameValidator()]],
      description: [""],
    });
  }

  /* on click of close button */
  onNoClick(): void {
    this.dialogRef.close( {status: 'false'} );
  }
  /* save all information and transfer those information to home-page */
  save() {
    this.formSubmitted = true;
    if (this.IndustryForm.valid) {
      //this.dialogRef.close(this.IndustryForm);
      let data = {
        name: this.IndustryForm.value.industry_name,
        description: this.IndustryForm.value.description,
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      this.service.postIndustryFormDetails(data).subscribe(
        (res) => {
          if (res.status == "success") {
            this.dialogRef.close({
              status: res.status,
              industry_id: res.industry_id,
            });
          } else {
            console.log( res.message );
          }
          this.industryNameExist = false;
        },
        (error) => {
          if (error.error.status === "failed") {
            // alert(error.error.message);
            this.formSubmitted = false;
            this.industryNameExist = true;
          }
        }
      );
    }
  }

  removeExistingMessage() {
    this.industryNameExist = false;
  }
}
