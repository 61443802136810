import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SowGenerationPageRoutingModule } from "./sow-generation-page-routing.module";
import { HeaderComponent } from "./component/header/header.component";
import { SowGenerationPageComponent } from "./sow-generation-page.component";
import { SowFormDataComponent } from "./sow-assistance-form-data/sow-form-data.component";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { ProjectDetailDialog } from "./mat-dialog/project-detail-dialog/project-detail.dialog";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { UploadPptDialog } from "./mat-dialog/upload-ppt-dialog/upload-ppt.dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatStepperModule } from "@angular/material/stepper";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { LoaderModule } from "../../loader/loader.module";
import { InfoDialogComponent } from './mat-dialog/info-dialog/info-dialog.component';

@NgModule({
  declarations: [
    SowGenerationPageComponent,
    SowFormDataComponent,
    ProjectDetailDialog,
    UploadPptDialog,
    InfoDialogComponent,
  ],
  imports: [
    CommonModule,
    SowGenerationPageRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatStepperModule,
    MatCheckboxModule,
    LoaderModule
  ],
  exports: [SowFormDataComponent],
})
export class SowGenerationPageModule {}
