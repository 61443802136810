import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PdfComparisionFormDataComponent } from "./pdf-comparision-assistance-form-data/pdf-comparision-form-data.component";
import { PdfComparisionPageComponent } from "./pdf-comparision-page.component";

const routes: Routes = [
  {
    path: "",
    component: PdfComparisionPageComponent,
    children: [
      { path: "", redirectTo: "assistance", pathMatch: "full" },
      {
        path: "assistance",
        component: PdfComparisionFormDataComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PdfComparisionPageRoutingModule {}
