import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeConversionComponent } from './code-conversion.component';

const routes: Routes = [
  {
    path:'',
    component:CodeConversionComponent,
    children: [
      { path: "", redirectTo: "code-conversion-page", pathMatch: "full" },
      {
        path: "code-conversion-page",
        loadChildren: () =>
          import("./code-conversion-page/code-conversion-page.module").then((m) => m.CodeConversionPageModule),
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CodeConversionRoutingModule { }
