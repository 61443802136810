import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { LoaderModule } from "../loader/loader.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RequestNewUseCase } from "./component/request-new-usecases/request-new-usecases";
import { MatExpansionModule } from '@angular/material/expansion';
import { AboutUsComponent } from './component/about-us/about-us.component';

@NgModule({
  declarations: [DashboardComponent, RequestNewUseCase, AboutUsComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    ReactiveFormsModule,
    CdkAccordionModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    LoaderModule,
    MatSlideToggleModule
  ],
})
export class DashboardModule {}
