import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { AuthServiceService } from "./auth-service.service";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class CodeGenerationServiceService {
  public baseapiUrl: string = environment.baseapiurl;
  private apiUrl = this.baseapiUrl + "api/generate_code";

  constructor(private http: HttpClient, private authService: AuthServiceService) { }

  getResponse(payload: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.apiUrl, payload);
  }
}
