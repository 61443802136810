<section>
  <div class="main-wrapper">
    <!-- <label style="float: right; color: white; text-align: right; padding: 2rem;">IDP Respons Page</label> -->
    <!-- error -->
    <div *ngIf="!isAuthenticated" class="error-msg-login">{{errorMessage}}
    </div>
    <div role="status" *ngIf="loading">
      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</section>