<div class="container">
    <div class="right-wrapper">

        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <!-- Logo wrapper with logo and text -->
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <!-- Home Icon -->
                    <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home"
                        (click)="dashboardRedirection()" title="Home" />
                </div>
                <div class="options-holder">
                    <button [ngClass]="{'disabled': sowService.isSnackbarOpen}" [disabled]="sowService.isSnackbarOpen"
                        type="button" class="btn btn-primary btn-block mb-3" (click)="addProjectDetails()">
                        Project Description
                    </button>
                    <button [disabled]="!sowService.isUploadPPTBtn" (click)="uploadPPT()"
                        [ngClass]="{'disabled': !sowService.isUploadPPTBtn}" type="button" class="btn btn-primary btn-block mb-3">
                        Add Proposal PPT+
                    </button>
                    <button [ngClass]="{'disabled': sowService.isSnackbarOpen}" [disabled]="sowService.isSnackbarOpen"
                        *ngIf="sowService.isProjectDescriptionBtn" type="button" class="btn btn-primary btn-block mb-3" (click)="newSession()">
                        New Session
                    </button>
                    <button type="button" class="btn btn-primary btn-block mb-3" *ngIf="sowService.isDownloadSowBtn"
                        (click)="handleDownloadClick()">
                        Download SOW
                    </button>
                </div>
            </div>
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p>{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
                </div>
            </div>
        </div>
    </div>
</div>