import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-req-qualification-generation',
  templateUrl: './req-generation.component.html',
  styleUrls: ['./req-generation.component.scss'],
})
export class ReqGenerationComponent implements OnInit {
  // selectedQualifier: any;
  constructor() {}

  ngOnInit() {}
  // setSelectedQualifier(text: any) {
  //   this.selectedQualifier = text;
  // }
}
