<mat-sidenav-container class="side-nav-main-container">
    <mat-sidenav #sidenav="matSidenav" class="right-wrapper" disableClose>
        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <!-- Logo wrapper with logo and text -->
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <!-- Menu Icon -->
                    <div class="custom-menu-icon addCursor" (click)="dashboardRedirection()" title="Back">
                        <!-- <div class="menu menu-close">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> -->
                        <img class="home-icon" src="../../../../assets/icons/home-icon.svg" />
                    </div>
                </div>
                <div class="form-wrapper">
                    <div class="input-wrapper">
                        <button>
                            <img src="../../../../assets/icons/Software Development.svg" />
                            Software Development
                        </button>
                    </div>
                    <div class="input-wrapper " style="margin-left: 30px;">
                        <div class="menu-item-container" (click)="activateMenu('Code Conversion')">
                            <div class="menu-container">
                                <div *ngIf="codeService.codeConversionActive">
                                    <div class="menu-item-active">
                                        <img class="menu-icon-active" loading="lazy" width="38px"
                                            src="../../../../assets/icons/code-converter/code-conversion-icon-white.svg" />
                                        <div class="menu-name-active">Code Conversion</div>
                                    </div>
                                    <div class="menu-item-active-bar">
                                        <img src="../../../../assets/icons/menu-selected-icon.svg" />
                                    </div>
                                </div>
                                <div *ngIf="!codeService.codeConversionActive" class="menu-item">
                                    <img class="menu-icon" loading="lazy" width="38px"
                                        src="../../../../assets/icons/code-converter/code-conversion-icon-black.svg" />
                                    <div class="menu-name">Code Conversion</div>
                                </div>
                            </div>
                        </div>
                        <div class="menu-item-container" (click)="activateMenu('DB Code Conversion')">
                            <div class="menu-container">
                                <div *ngIf="codeService.DBCodeConversionActive">
                                    <div class="menu-item-active">
                                        <img class="menu-icon-active" loading="lazy" width="38px"
                                            src="../../../../assets/icons/code-converter/code-conversion-icon-white.svg" />
                                        <div class="menu-name-active">DB Code Conversion</div>
                                    </div>
                                    <div class="menu-item-active-bar">
                                        <img src="../../../../assets/icons/menu-selected-icon.svg" />
                                    </div>
                                </div>
                                <div *ngIf="!codeService.DBCodeConversionActive" class="menu-item">
                                    <img class="menu-icon" loading="lazy" width="38px"
                                        src="../../../../assets/icons/code-converter/code-conversion-icon-black.svg" />
                                    <div class="menu-name">DB Code Conversion</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="input-wrapper pd">
                        <button>
                            <img src="../../../../assets/icons/code-review.svg"/>
                            Code Review
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p class="para-pad">{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content mat-elevation-z8" style="width: 100%;height: 100%; background-color: white; padding: 15px;">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>