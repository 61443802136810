<section id="hero requirement-quilifier">
	<div class="content">

		<h3>{{ slugToText(reqQualifierService.selectedQualifier) }} Generator</h3>

		<form [formGroup]="reQualifierForm">
			<div class="chat-window">

				<!-- UI for Generators -->
				<div class="msg-box {{ getCurrentSlug() }}" *ngIf="getCurrentSlug() !== 'requirement_granularity'">
					<div class="input">
						<p class="req-qualifier-label" style="margin-bottom: 15px;">
							Enter any thoughts to list {{ slugToText(reqQualifierService.selectedQualifier) }} to create
							any application on
						</p>
						<div class="error-input-group" *ngIf="getCurrentSlug() !== 'requirement_granularity'">
							<!-- <input
								type="text"
								class="myInputBox"
								formControlName="reqQualifierSearch"
								required
								#myinput
								placeholder="Enter {{ slugToText(reqQualifierService.selectedQualifier) }}"
								(keyup)="onInputChange($event)"
							/> -->
							<!-- formControlName="reqQualifierSearch"
							required -->
							<textarea style="height: 24px; background-color: #fff !important;" rows="1"
								class="myInputBox"
								placeholder="Enter {{ slugToText(reqQualifierService.selectedQualifier) }}"
								formControlName="reqQualifierSearch" (input)="resizeTextarea($event)"
								(keydown)="preventNewLinewithoutShift($event)" #myinput></textarea>
							<img class="addCursor" src="../../../../../assets/icons/icon-send.svg" alt="send"
								(click)="setSearch()" />
							<!-- <img
								class="addCursor"
								src="../../../../../assets/icons/icon-send-grey.svg"
								alt="send-disable"
								*ngIf="noInput"
							/> -->
							<!-- <button
								class="btn btn-primary"
								(click)='setSearch()'
								[disabled]='noInput || showLoader'
								*ngIf="getCurrentSlug() !== 'requirement_granularity'"
								title="Generate {{ slugToText(reqQualifierService.selectedQualifier) }}"
							>
								Generate
							</button> -->
						</div>
						<div class="text-danger" *ngIf="promptErrMsg">Please enter a prompt</div>
					</div>
				</div>

				<!-- UI for Metrics -->
				<div class="msg-box requirement_granularity" *ngIf="getCurrentSlug() === 'requirement_granularity'">
					<div class="input">
						<div class="upload">
							<div class="ft-drop rd-colr">
								<a href="javascript:void(0)" (click)="file2.click()">
									<img src="../../../../../assets/icons/upload-icon.svg" />
									Click to upload a CSV file
								</a>
								<input type="file" [multiple]="false" (change)="onFileSelect($event)" accept=".csv"
									style="display:none" #file2 />
							</div>
							<div class="size-info">(max file size: up to 10 MB)</div>
							<div class="text-danger">{{ invalidFileError }}</div>
						</div>
						<div class="download">
							<div class="csv-template"><!--- *ngIf="getCurrentSlug() === 'requirement_granularity'" -->
								<a href="../../../../../assets/documents/requirements.csv">
									<img src="../../../../../assets/icons/download-icon.svg" />
									Download CSV Template from here
								</a>
							</div>
						</div>
					</div>
				</div>

			</div>
		</form>

		<div class="data-window" *ngIf='dataSet'>
			<div class="qualifier-title">
				<img src="../../../../assets/img/symbol.svg" alt="logo">
				<h3>Generated {{slugToText( reqQualifierService.selectedQualifier )}}</h3>
			</div>
			<div class="input-wrapper" *ngIf="getCurrentSlug() !== 'requirement_granularity'">
				<div class="custom-select-container custom-select-container3">
					<select id="inp3" class="form-select" aria-label="Default select example"
						(change)='onLimitChange($event)' [(ngModel)]="limit">
						<option *ngFor="let item of showResultOptions; let i = index" [value]="item.key"
							title="{{item.text}}">
							{{item.text}}
						</option>
					</select>
				</div>
			</div>


			<div class="content-wrapper">
				<ol *ngIf="getCurrentSlug() !== 'requirement_granularity'">
					<li *ngFor="let item of dataSet; index as i">
						<div class="content-item">
							<div class="addCursor" #contentToCopy>
								{{ item }}
							</div>
							<div class="action">
								<img src="../../../../../assets/icons/copy_to_icon.svg" class="addCursor" alt='copy'
									(click)='copyToClipboard(contentToCopy, i, true)'
									[title]='"Copy to "+slugToText(getTitle(reqQualifierService.selectedQualifier))'
									*ngIf="getCurrentSlug() !== 'test_scenario'" />
								<img src="../../../../../assets/icons/copy-icon.svg" class="addCursor"
									(click)='copyToClipboard(contentToCopy, i, false)' alt='copy' title="Copy"
									*ngIf="getCurrentSlug() !== 'requirement_granularity'" />
								<img src="../../../../../assets/icons/ic_check_dark.svg" alt='copy-tick'
									class='copy-tick' style="display: none;" />
							</div>
						</div>
					</li>
				</ol>
			</div>
			<div class="table-wrapper" *ngIf="getCurrentSlug() === 'requirement_granularity'">
				<table class="gen-table">
					<thead>
						<tr>
							<th>Requirements</th>
							<th>Requirements Summary</th>
							<th>Score</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let item of dataSet; index as i">
							<td>{{ item.requirement }}</td>
							<td>{{ item.summary }}</td>
							<td>{{ item.score }}</td>
						</tr>
					</tbody>
				</table>
				<span class="info">
					Note: Here, score indicator 1 indicates minimal detail, and 5 indicates a highly detailed
					requirement.
				</span>
			</div>
		</div>
		<app-loader *ngIf='showLoader'></app-loader>
	</div>
</section>