<div *ngIf="!service.isDelete && !showLoader && !showText" class="modal-wrapper">

  <div class="header-dialog">
    <span class="head-text">
      <h1 mat-dialog-title class="hd-txt">Project Description</h1>
      <mat-icon class="cancel-btn" (click)="onNoClick('')" title="Close" title="Close">cancel</mat-icon>
    </span>
  </div>

  <!-- to save the persona information -->
  <div mat-dialog-content class="contain">
    <form [formGroup]="projectDetailForm">
      <div class="header-text text-center">
        <p>SOW Basic Details</p>
      </div>
      <div class="row">

        <!-- Project Name -->
        <div class="col-6 mb-3">
          <label for="project_name">
            Project Name<span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="project_name"
            class="form-control"
            formControlName="projectName"
            [readonly]="service.isProjectDescriptionView"
            placeholder="Project Name"
          >
          <div class="text-danger">
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.projectName.touched) && projectDetailForm.controls.projectName.errors?.required">
              Project Name is required
            </span>
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.projectName.dirty) && projectDetailForm.controls.projectName.errors?.maxLengthExceeded">
              Project Name allows up to 40 characters only
            </span>
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.projectName.dirty) && projectDetailForm.controls.projectName.errors?.invalidCharacters">
              Only alpha-numeric, spaces, hyphen, and underscore are allowed
            </span>
          </div>
        </div>

        <!-- Client Name -->
        <div class="col-6 mb-3">
          <label for="client_name">Client Name<span class="text-danger">*</span></label>
          <input
            type="text"
            id="client_name"
            class="form-control"
            formControlName="clientName"
            [readonly]="service.isProjectDescriptionView"
            placeholder="Client Name"
          >
          <div class="text-danger">
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.clientName.touched) && projectDetailForm.controls.clientName.errors?.required">
              Client Name is required
            </span>
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.clientName.dirty) && projectDetailForm.controls.clientName.errors?.maxLengthExceeded">
              Client Name allows up to 40 characters only
            </span>
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.clientName.dirty) && projectDetailForm.controls.clientName.errors?.invalidCharacters">
              Only alpha-numeric, spaces, hyphen, and underscore are allowed
            </span>
          </div>
        </div>

        <!-- Effective Date -->
        <div class="col-6 mb-3">
          <label for="effective_date">Effective Date<span class="text-danger">*</span></label>
          <input
            type="date"
            id="effective_date"
            class="form-control"
            [readonly]="service.isProjectDescriptionView"
            (change)="effectiveDateValidation()"
            bsDatepicker
            formControlName="effective_date"
            placeholder="Select End date"
          >
          <div class="text-danger">
            <span class="validation-error"
              *ngIf="(formSubmitted || projectDetailForm.controls.effective_date.touched) && projectDetailForm.controls.effective_date.errors?.required">
              Effective Date is required
            </span>
          </div>
        </div>

        <!-- Agreement date -->
        <div class="col-6 mb-3">
          <label for="agreement_date">Agreement Date<span class="text-danger">*</span></label>
          <input
            type="date"
            id="agreement_date"
            class="form-control"
            [readonly]="service.isProjectDescriptionView"
            (change)="aggrementDateValidation()"
            bsDatepicker
            formControlName="agreement_date"
            placeholder="Select End date"
          >
          <div class="text-danger">
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.agreement_date.touched) && projectDetailForm.controls.agreement_date.errors?.required">
              Agreement Date is required
            </span>
            <span class="validation-error" *ngIf="agreementDateError.isError ||  projectDetailForm.controls.agreement_date.touched">
              {{ agreementDateError.errorMessage }}
            </span>
          </div>
        </div>
        
      </div>

      <div class="header-text text-center mt-4">
        <p>Start Date and Project Schedule</p>
      </div>

      <div class="row">

        <!-- Start Date -->
        <div class="col-4 mb-3">
          <label for="start_date">Start Date<span class="text-danger">*</span></label>
          <input
            type="date"
            id="start_date"
            class="form-control custom-date-picker"
            [readonly]="service.isProjectDescriptionView"
            (change)="startDateValidation()"
            bsDatepicker
            formControlName="startDate"
            placeholder="Select a date"
          >
          <div class="text-danger">
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.startDate.touched) && projectDetailForm.controls.startDate.errors?.required">
              Start Date is required
            </span>
          </div>
        </div>

        <!-- End Date -->
        <div class="col-4 mb-3">
          <label for="end_date">End Date<span class="text-danger">*</span></label>
          <input
            type="date"
            id="end_date"
            [readonly]="service.isProjectDescriptionView"
            (change)="endDateValidation()"
            class="form-control"
            bsDatepicker
            formControlName="endDate"
            placeholder="Select End date"
          >
          <div class="text-danger">
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.endDate.touched) && projectDetailForm.controls.endDate.errors?.required">
              End Date is Required
            </span>
            <div *ngIf="error.isError ||  projectDetailForm.controls.endDate.touched">
              {{ error.errorMessage }}
            </div>
          </div>
        </div>

        <!-- Review Date -->
        <div class="col-4 mb-3">
          <label for="review_date">Review Date<span class="text-danger">*</span></label>
          <input
            type="date"
            id="review_date"
            class="form-control"
            [readonly]="service.isProjectDescriptionView"
            (change)="reviewValidation()"
            bsDatepicker
            formControlName="review_date"
            placeholder="Select End date
          ">
          <div class="text-danger">
            <span class="validation-error" *ngIf="(formSubmitted || projectDetailForm.controls.review_date.touched) && projectDetailForm.controls.review_date.errors?.required">
              Review Date is required
            </span>
            <span class="validation-error" *ngIf="reviewError.isError ||  projectDetailForm.controls.review_date.touched">
              {{ reviewError.errorMessage }}
            </span>
          </div>
        </div>

      </div>
    </form>

  </div>
  <!-- to save and close button -->
  <div mat-dialog-actions>
    <button class="btn btn-secondary-outline" (click)="onNoClick('')">Close</button>
    <button class="btn btn-primary" *ngIf="!service.isProjectDescriptionView" (click)="save()" cdkFocusInitial>Save</button>
  </div>

  <!-- <div class="spinner-grow text-dark" role="status" *ngIf="service.isLoading">
    <span class="sr-only">Loading...</span>
  </div> -->
  <div role="status" *ngIf="service.isLoading">
    <app-loader></app-loader>
  </div>
</div>