import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as Highcharts from "highcharts";
import { AuthServiceService } from "./auth-service.service";

export class Message {
  constructor(public author: string, public content: string) {
    author = "";
    content = "";
  }
}
@Injectable({
  providedIn: "root",
})
export class AdminSettingsServiceService {
  isLoading: boolean = false;
  headers: any;
  costFilterData: any;
  analyticsFilterData: any;
  analyticsCategoryArr: any;
  analyticsDateArr: any;
  analyticsDataUsageChart: any;
  analyticsDataCostConsumptionChart: any;
  analyticsDataUserLoginChart: any;
  exportMenuItem: any = [
    "downloadPNG",
    "downloadJPEG",
    "downloadPDF",
    "separator",
    "downloadCSV",
    "downloadXLS",
    // "viewData",
  ];

  Highcharts: typeof Highcharts = Highcharts;

  usageChartOptions!: Highcharts.Options;
  costConsumptionChartOptions!: Highcharts.Options;
  loginAttemptChartOptions!: Highcharts.Options;

  public showChatLoader: boolean = true;

  // public baseapiUrl = "http://3.131.8.158:8000/";
  public baseapiUrl: string = environment.baseapiurl + "api/";

  private costDataLoader = new Subject<void>();
  costDataLoader$ = this.costDataLoader.asObservable();
  callCostDataLoader() {
    this.costDataLoader.next();
  }

  private analyticsDataLoader = new Subject<void>();
  analyticsDataLoader$ = this.analyticsDataLoader.asObservable();
  callAnalyticsDataLoader() {
    this.analyticsDataLoader.next();
  }

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  getListOfModels(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "model/details/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  setCostFilterData(data: any): void {
    this.costFilterData = data;
  }

  getCostFilterData(): any {
    return this.costFilterData;
  }

  getFeedbackData(data: any) {
    this.authService.checkTokenExpiry();
    let slug = "feedback_data";
    if (data.page) {
      slug = `feedback_data/${data.page}`;
    }
    return this.http
      .post<any>(this.baseapiUrl + slug, data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getGroupListForRoleAccess(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "view_group_info", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateGroupForRoleAccess(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "group_member_info", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  deleteGroupForRoleAccess(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "delete_group_member_info", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getCategoryNames(startDate: Date, endDate: Date, interval: string): string[] {
    const result: string[] = [];
    let currentDate = new Date(startDate); // Start from the startDate

    switch (interval) {
      case "Weekly":
        // while (currentDate <= endDate) {
        //   const weekNumber = this.getISOWeek(currentDate);
        //   result.push("week " + weekNumber);
        //   currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
        // }
        while (currentDate <= endDate) {
          let weekStartDate = new Date(currentDate);
          weekStartDate.setDate(
            currentDate.getDate() - currentDate.getDay() + 1
          ); // Start of the current week

          let weekEndDate = new Date(weekStartDate);
          weekEndDate.setDate(weekStartDate.getDate() + 6); // End of the current week

          if (weekStartDate < new Date(startDate)) {
            weekStartDate = new Date(startDate);
          }

          if (weekEndDate > new Date(endDate)) {
            weekEndDate = new Date(endDate);
          }

          result.push(
            this.getParsedDateMDY(weekStartDate) +
              " to " +
              this.getParsedDateMDY(weekEndDate)
          );

          currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
        }
        break;

      case "Monthly":
        while (currentDate <= endDate) {
          const monthName = this.getMonthName(currentDate);
          result.push(monthName);
          currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
        }
        break;

      default:
        while (currentDate <= endDate) {
          result.push(this.getParsedDateMDY(new Date(currentDate)));
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
        break;
    }

    return result;
  }

  getStartAndEndDates(
    startDate: Date,
    endDate: Date,
    interval: string
  ): { start_date: string; end_date: string }[] {
    const result: { start_date: string; end_date: string }[] = [];

    let currentDate = new Date(startDate);
    switch (interval) {
      case "Weekly":
        while (currentDate <= endDate) {
          let weekStartDate = new Date(currentDate);
          weekStartDate.setDate(
            currentDate.getDate() - currentDate.getDay() + 1
          ); // Start of the current week

          let weekEndDate = new Date(weekStartDate);
          weekEndDate.setDate(weekStartDate.getDate() + 6); // End of the current week

          if (weekStartDate < new Date(startDate)) {
            weekStartDate = new Date(startDate);
          }

          if (weekEndDate > new Date(endDate)) {
            weekEndDate = new Date(endDate);
          }

          result.push({
            start_date: this.getParsedDate(weekStartDate),
            end_date: this.getParsedDate(weekEndDate),
          });

          currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
        }
        break;

      case "Monthly":
        while (currentDate <= endDate) {
          let startOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
          );
          let endOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          );

          if (startOfMonth < new Date(startDate)) {
            startOfMonth = new Date(startDate);
          }

          if (endOfMonth > new Date(endDate)) {
            endOfMonth = new Date(endDate);
          }

          result.push({
            start_date: this.getParsedDate(startOfMonth),
            end_date: this.getParsedDate(endOfMonth),
          });

          currentDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            1
          );
        }
        break;

      default:
        while (currentDate <= endDate) {
          result.push({
            start_date: this.getParsedDate(currentDate),
            end_date: this.getParsedDate(currentDate),
          });
          currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }
        break;
    }

    return result;
  }

  getParsedDate(dateObj: any) {
    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    return `${year}-${month}-${date}`;
  }

  getParsedDateMDY(dateObj: any) {
    let year = dateObj.getFullYear();
    let month = dateObj.getMonth() + 1;
    let date = dateObj.getDate();
    return `${month}-${date}-${year}`;
  }

  getISOWeek(date: Date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.floor(
      (date.getTime() - firstDayOfYear.getTime()) / millisecondsPerDay
    );
    const weekNumber = Math.ceil((diffDays + firstDayOfYear.getDay() + 1) / 7);
    return weekNumber;
  }

  getMonthName(date: Date) {
    return date.toLocaleDateString("en-US", { month: "long" });
  }

  setAnalyticsFilterData(data: any): void {
    this.analyticsFilterData = data;
    this.analyticsCategoryArr = this.getCategoryNames(
      new Date(this.analyticsFilterData.analyticsStartDate),
      new Date(this.analyticsFilterData.analyticsEndDate),
      this.analyticsFilterData.interval
    );
    this.analyticsDateArr = this.getStartAndEndDates(
      new Date(this.analyticsFilterData.analyticsStartDate),
      new Date(this.analyticsFilterData.analyticsEndDate),
      this.analyticsFilterData.interval
    );
  }

  getAnalyticsFilterData(): any {
    return this.analyticsFilterData;
  }

  getAnalyticsData(data: any): Observable<any> {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "analytics_userchat", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
