import { BreakpointObserver } from "@angular/cdk/layout";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { AuthServiceService } from "../../service/auth-service.service";
import { CodeConversionService } from "../../service/code-conversion-service.sevrice";
import { DashboardServiceService } from "../../service/dashboard-service.service";

@Component({
  selector: "app-code-conversion-page",
  templateUrl: "./code-conversion-page.component.html",
  styleUrls: ["./code-conversion-page.component.scss"],
})
export class CodeConversionPageComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;

  username: any;
  usernameDisplay: any;
  usernameInitials: any;

  constructor(
    private route: Router,
    private observer: BreakpointObserver,
    public authService: AuthServiceService,
    public codeService: CodeConversionService,
    public dashboardService: DashboardServiceService
  ) {}

  ngOnInit(): void {
    if (this.codeService.codeConversionActive) {
      this.activateMenu("Code Conversion");
    }
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
  }

  ngAfterViewInit() {
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }

  activateMenu(menuName: string) {
    switch (menuName) {
      case "Code Conversion": {
        this.codeService.codeConversionActive = true;
        this.route.navigate(["/code-conversion"]);
        this.codeService.DBCodeConversionActive = false;
        break;
      }
      case "DB Code Conversion": {
        this.codeService.codeConversionActive = false;
        this.codeService.DBCodeConversionActive = true;
        this.route.navigate(["/code-conversion/code-conversion-page/db-code-conversion"]);
        break;
      }

      default: {
        this.codeService.codeConversionActive = true;
        this.codeService.DBCodeConversionActive = false;
      }
    }
  }

  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  logout() {
    //this.service.showChatLoader = true;
    this.authService.logout();
  }
}
