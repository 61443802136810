<div class="code-convertor">

    <!-- Heading with margin botton -->
    <h3>Code Conversion</h3>

    <!-- <form [formGroup]="conversionForm" (ngSubmit)="convertCode()"> -->
    <!-- Button with border radius and will carry the action to convert the code -->
    <div class="button-wrapper">
        <button [disabled]="!codeText.trim() || loading " type="submit" class="btn btn-convert p-small"
            (click)="convertCode()">Convert
            Code</button>
    </div>

    <!-- The wrapper of both editors -->
    <div class="editor-wrapper">
        <div class="editor-container editor">

            <!-- Header for convert select box and file selections -->
            <div class="filter-header">
                <!-- dummy for empty-space -->
                <div class="dummy">
                    <!-- Source From -->
                    <!-- <button>
                        Clear
                    </button> -->
                </div>

                <div class="select-wrapper">
                    <div class="select-wrapper">
                        <select [(ngModel)]="selectedLanguage">
                            <option *ngFor="let language of inputLanguages" [value]="language">{{ language }}</option>
                        </select>
                    </div>
                </div>

                <!-- dummy for empty-space -->
                <div class="dummy"></div>

            </div>
            <!-- Code editor comprises both side numberbar & code playground -->
            <div class="code-editor">
                <!-- div to show line numbers of code -->
                <div
                    class="line-nos"
                    id="lineNumbers"
                    (mouseenter)="updateCurrentElement('lineNumberRef')"
                    (scroll)="scrollSynchronization($event)"
                    #lineNumberRef
                ></div>

                <div class="input-size-limit-banner" *ngIf="showInputSizeLimitBanner">
                    <div class="cursor close-icon" (click)="closeInputSizeLimitBanner()"><img title="Close"
                            src="../../../../../assets/icons/code-converter/cancel.svg" /></div>
                    <div class="banner-header">
                        <img src="../../../../../assets/icons/code-converter/info.svg" />&nbsp;
                        <div class="banner-header-text">Input Size Limit Reached</div>
                    </div>
                    <p class="banner-content">Only the first 4,000 characters will be converted.</p>
                    <!-- <button >Close</button> -->
                </div>
                <!-- div for code writing (textarea field) -->
                <div class="code-wrapper">
                    <textarea
                        name="codeText"
                        id="codeText"
                        class="code"
                        placeholder="Your input code here"
                        style="line-height: 23px;"
                        [(ngModel)]="codeText"
                        (input)="updateLineNumbers(); checkInputSizeLimit()"
                        (mouseenter)="updateCurrentElement('inputCodeRef')"
                        (scroll)="scrollSynchronization($event)"
                        #inputCodeRef
                        wrap="off"
                    ></textarea>
                    <div class="float-msg-wrapper">
                        <div class="float-msg">
                            <p class="p-normal">Use code with caution </p>
                            <div class="copy-wrapper">
                                <img title="Clear" class="p-normal cursor" (click)="clearCode()" width="25px"
                                    src="../../../../../assets/icons/dashboard/reset-form.svg" alt="copy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- edditor 2 -->
        <div class="editor-container editor">
            <!-- Header for convert select box and file selections -->
            <div class="filter-header">
                <!-- dummy for empty-space -->
                <div class="dummy"></div>

                <div class="select-wrapper">
                    <select [(ngModel)]="outputSelectedLanguage">
                        <option *ngFor="let language of outputLanguages" [value]="language">{{ language }}</option>
                    </select>
                </div>

                <!-- dummy for empty-space -->
                <div class="dummy"></div>

            </div>

            <!-- Code editor comprises both side numberbar & code playground -->
            <div class="code-editor">
                <!-- div to show line numbers of code -->
                <div
                    class="line-nos"
                    id="lineNumbers1"
                    (mouseenter)="updateCurrentElement('lineNumberRef1')"
                    (scroll)="scrollSynchronization($event)"
                    #lineNumberRef1
                >
                    <div class="line" *ngFor="let lineNumber of lineNumbers">{{ lineNumber }}</div>
                </div>
                <!-- div for code writing (textarea field) -->
                <div class="code-wrapper">
                    <textarea
                        [placeholder]="placeholderValue"
                        class="code"
                        id="convertedCode"
                        readonly
                        [(ngModel)]="convertedCode"
                        name="convertedCode"
                        (input)="updateLineNumbersForOutput()"
                        (mouseenter)="updateCurrentElement('inputCodeRef1')"
                        (scroll)="scrollSynchronization($event)"
                        #inputCodeRef1
                        wrap="off"
                    ></textarea>
                    <div class="float-msg-wrapper">
                        <div class="float-msg">
                            <p class="p-normal">This code is generated by LLM and needs to be reviewed functionally
                                before
                                using</p>
                            <div class="copy-wrapper" *ngIf="!convertedCodeFlag">

                                <img title="Copy" class="p-normal default-copy"
                                    src="../../../../../assets/icons/code-converter/Copy-Default.svg" alt="copy" />
                            </div>
                            <div class="copy-wrapper" *ngIf="convertedCodeFlag">
                                <img title="Copy" (click)="copyCode()" class="p-normal default-copy cursor"
                                    src="../../../../../assets/icons/code-converter/Copy-Hover.svg" alt="copy" />
                            </div>
                            <div class="copy-wrapper" *ngIf="!convertedCodeFlag">
                                <img title="Download" class="p-normal default-download"
                                    src="../../../../../assets/icons/code-converter/Download-Default.svg" alt="copy" />
                            </div>
                            <div class="copy-wrapper" *ngIf="convertedCodeFlag">
                                <img title="Download" (click)="downloadCode()" class="p-normal default-download cursor"
                                    src="../../../../../assets/icons/code-converter/Download-Hovert.svg" alt="copy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>





        </div>
    </div>
    <div *ngIf="loading">
        <app-loader></app-loader>
    </div>
    <!-- </form> -->
</div>