<div class="modal-wrapper">

    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title>Add&nbsp;Folder</h1>
            <mat-icon class="cancel-btn" (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <!-- to save the industry information -->
    <div mat-dialog-content class="contain">
        <form [formGroup]="IndustryForm">
            <div class="row">
                <!-- to display industry name -->
                <div class="col-12 mb-3">
                    <label for="basic-url">
                        Industry Name
                        <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="industry_name" id="basic-url"
                            aria-describedby="basic-addon3" (keydown)="removeExistingMessage()" />
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || IndustryForm.controls.industry_name.touched) && IndustryForm.controls.industry_name.errors?.required">
                            Industry Name is required
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || IndustryForm.controls.industry_name.dirty) && IndustryForm.controls.industry_name.errors?.maxLengthExceeded">
                            Industry Name allows up to 24 characters only
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || IndustryForm.controls.industry_name.dirty) && IndustryForm.controls.industry_name.errors?.invalidCharacters">
                            Only alpha-numeric characters are allowed.
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || IndustryForm.controls.industry_name.dirty) && IndustryForm.controls.industry_name.errors?.invalidName">
                            Industry name starts with alphabet only
                        </span>
                        <span class="validation-error" *ngIf="industryNameExist">
                            Industry name already exists
                        </span>
                    </div>
                </div>
                <div class="col-12">
                    <!-- industry description -->
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                        <textarea class="form-control" formControlName="description"
                            id="exampleFormControlTextarea1" rows="3"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <button class="btn btn-secondary-outline" (click)="onNoClick()">Close</button>
        <button class="btn btn-primary" (click)="save()" cdkFocusInitial>Save</button>
    </div>
</div>