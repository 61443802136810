import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SowGenerationServiceService } from "src/app/module/service/sow-generation-service.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, fromEvent } from "rxjs";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "src/app/module/snackbar/snackbar.component";

export class Message {
  constructor(
    public author: string,
    public content: string,
    public msgImg: string,
    public showCheck: boolean,
    public base64: string
  ) {
    author = "";
    content = "";
    msgImg = "";
    base64 = "";
    showCheck = false;
  }
}
@Component({
  selector: "app-sow-form-data",
  templateUrl: "./sow-form-data.component.html",
  styleUrls: ["./sow-form-data.component.scss"],
})
export class SowFormDataComponent implements OnInit {
  lines: any;
  resizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  @ViewChild("myIframe") myIframe!: ElementRef;
  @ViewChild("scrollMe") private myScrollContainer: any;

  $: any;

  myText: string = "Hello World!";
  showDasboard: boolean = false;
  llmFormData!: FormGroup;
  submittedthree: boolean = false;
  showModel: boolean = false;
  loanStatus: string = "";
  loanRule: string = "";
  value: string = "";
  formDetails: any = [];
  formDetailData: any;
  application_id: string = "";
  BusinessLegalName: string = "";
  BusinessLoanPurpose: string = "";
  BusinessSize: string = "";
  CustomerAge: string = "";
  CustomerName: string = "";
  capital_required: string = "";
  collateral_available: string = "";
  credit_score: string = "";
  existing_loan_amount: string = "";
  growth_rate: string = "";
  loan_to_investment_ratio: string = "";
  previous_business_experience: string = "";
  loanExpain: string = "";
  AverageActivity: string = "";
  College: string = "";
  HighestEducationQualification: string = "";
  IpAvailable: string = "";
  showCheck: boolean = false;
  IpCount: string = "";
  IpExpiry: string = "";
  NumberOfLinkedinfollowers: string = "";
  YoutubeSubscribers: string = "";
  socialQuotient: string = "";
  questions = [""];
  selectedQuestion: string = "";
  messages: Message[] = [];
  messageImg: any;
  messagebot: string = "user";
  userMessage: any;
  previousMessage: any;
  content = "";
  postedContent: any = [];
  contentRef!: ElementRef;
  contentHeight: any;
  palyMusic: boolean = false;
  clickEventSub!: Subscription;
  clickEventTabChangeSub!: Subscription;
  textArea: string = "";
  showChat: boolean = false;
  imagePath: any;
  sessionId: any;
  flag: boolean = false;
  stopGeneratorFlag: boolean = false;
  regenerateFlag: any = "modify";
  isLinear = false;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  StepperData: any = [];
  index: number = 0;
  backbuttonDisableFlag: boolean = false;
  ContentFlag: boolean = false;
  editFlag: boolean = false;
  isVerifed: boolean = false;
  backFlag: boolean = false;
  editContent: any;
  outputId: any;
  dummyText: any = "";
  resultString: any = "";
  editContentForCancel: any = "";
  editFieldChange: boolean = false;
  token: any;
  userEmail: any;

  constructor(
    private _sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder,
    private clipboard: Clipboard,
    private renderer: Renderer2,
    public service: SowGenerationServiceService,
    private snackBar: MatSnackBar
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.clickEventSub = this.service.getChatMsg().subscribe((async) => {
      this.service.showChatLoader = false;
      this.textArea = "New Chat Session Started";
      this.messages = [];
      this.getStepperApi();
    });
    this.clickEventTabChangeSub = this.service
      .getTabChangeMsg()
      .subscribe((async) => {
        this.textArea = "";
        this.messages = [];
      });
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });
  }
  selectQuestion(question: string) {
    this.selectedQuestion = question;
  }

  // error handling
  showCustomSnackbar(message: string) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message },
      duration: 10000, // Set the duration for which the snackbar will be displayed
      verticalPosition: "top", // You can adjust the position of the snackbar
      panelClass: ["custom-snackbar"], // Add a custom CSS class for styling if needed
    });
  }

  getStepperApi() {
    this.service.sowAssiatnceContentText = ["Loading....."];
    this.service.isLoading = true;
    let data = {
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.getSowList(data).subscribe(
      (res) => {
        if (res.status == "success") {
          this.service.isLoading = false;
          this.index = 0;
          this.StepperData = res.section_list;
          let regFlag = "modify";
          this.sowSectionwiseResponse(res.section_list[0], this.index, regFlag);
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  sowSectionwiseResponse(data: any, indexNumber: any, regFlag: any) {
    if (indexNumber === 0) {
      this.backbuttonDisableFlag = true;
    } else {
      this.backbuttonDisableFlag = false;
    }
    this.isVerifed = false;
    this.service.isLoading = true;
    this.sessionId = localStorage.getItem("sessionId");
    let payload = {
      field_name: data,
      regenerateFlag: regFlag,
      session_id: this.sessionId,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    try {
      this.service.sowSectionWiseResponse(payload).subscribe(
        (res) => {
          if (res.status === "success") {
            this.service.sowAssiatnceHeaderText = this.StepperData[this.index];
            this.dummyText = res.section_wise_content;
            this.editContentForCancel = res.section_wise_content;
            this.service.sowContentButtonFlag = true;
            this.service.isLoading = false;
            this.backFlag = false;
            this.service.sowAssiatnceHeaderText = res.field_name;
            const formattedText = res.section_wise_content;
            if (formattedText !== null && formattedText !== undefined) {
              this.service.sowAssiatnceContentText = formattedText.split("\n");
            } else {
              this.service.sowAssiatnceContentText = [
                `For this ${res.field_name} There is no Content`,
              ];
            }
          }
        },
        (error) => {
          if (this.index === 0) {
            this.backbuttonDisableFlag = true;
          } else {
            this.backbuttonDisableFlag = false;
          }
          this.service.isLoading = false;
          this.index = this.index - 1;

          if (this.index === 0) {
            this.backbuttonDisableFlag = true;
          } else {
            this.backbuttonDisableFlag = false;
          }
          // Handle the error here
          this.showCustomSnackbar(error);
          // alert(error)
        }
      );
    } catch (error: any) {
      this.service.isLoading = false;

      // This block will not catch errors from asynchronous operations like observables
      console.error("Synchronous error:", error);
      this.showCustomSnackbar(error);
      // alert(error)
    }
  }
  verify(event: any) {
    this.isVerifed = event.checked;
  }
  EditClick() {
    if (this.editFlag) {
      this.editFlag = false;
      this.service.sowAssiatnceContentText =
        this.editContentForCancel.split("\n");
    } else {
      this.editFlag = true;
      this.service.sowAssiatnceContentText =
        this.editContentForCancel.split("\n");
      this.dummyText = [this.editContentForCancel];
    }
  }
  firstClick() {
    if (this.editFlag && this.backFlag) {
      let payload = {
        output_id: this.outputId,
        // "prompt_output": this.service.sowAssiatnceContentText,
        prompt_output: this.dummyText,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      this.service.updateSectionWiseResponse(payload).subscribe(
        (res) => {
          if (res.status == "success") {
            this.editFlag = false;
            this.backFlag = false;
            this.isVerifed = false;
            this.service.isLoading = false;
            this.service.sowAssiatnceContentText = this.dummyText.split("\n");
            if (this.StepperData.length !== this.index) {
              this.index++;
              let regFlag = "modify";
              this.sowSectionwiseResponse(
                this.StepperData[this.index],
                this.index,
                regFlag
              );
            } else {
              this.service.sowAssiatnceHeaderText =
                this.StepperData[this.StepperData.length - 1];
              this.service.isDownloadSowBtn = true;
              this.service.sowContentButtonFlag = false;
              this.service.sowAssiatnceHeaderText = `SOW document generated successfully.`;
              this.service.sowAssiatnceContentText = [
                `Please click on "Download" button to download SOW document.`,
              ];
              this.service.isLoading = false;
            }
          } else {
            alert(res.message);
          }
        },
        (error: any) => {
          this.showCustomSnackbar(error);
          // alert(error);
        }
      );
    } else {
      this.index++;
      this.service.isLoading = true;
      if (this.StepperData.length !== this.index - 1) {
        this.swoSectionWiseSaveResponse();
      } else {
        this.service.isDownloadSowBtn = true;
        this.service.sowContentButtonFlag = false;
        this.service.sowAssiatnceHeaderText = `SOW document generated successfully.`;
        this.service.sowAssiatnceContentText = [
          `Please click on "Download" button to download SOW document.`,
        ];
        this.service.isLoading = false;
      }
    }
  }

  swoSectionWiseSaveResponse() {
    this.sessionId = localStorage.getItem("sessionId");
    if (Array.isArray(this.dummyText)) {
      // Join the array elements into a single string
      this.resultString = this.dummyText.join("");
    } else if (typeof this.dummyText === "string") {
      // If it's already a string, use it as is
      this.resultString = this.dummyText;
    } else {
      // Handle other cases or throw an error if needed
      console.error("Unsupported type for this.dummyText");
    }
    let payload = {
      session_id: this.sessionId,
      prompt_key: this.StepperData[this.index - 1],
      prompt_output: this.resultString,
      Verified: this.isVerifed,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.sowSectionWiseSaveResponse(payload).subscribe(
      (res) => {
        if (res.status == "success") {
          this.editFlag = false;
          this.isVerifed = false;
          this.service.isLoading = false;
          if (!this.backFlag) {
            this.service.sowAssiatnceContentText = Array.isArray(this.dummyText)
              ? this.dummyText.join("").split("\n")
              : this.dummyText.split("\n");
          }
          if (this.StepperData.length !== this.index) {
            let regFlag = "modify";
            this.sowSectionwiseResponse(
              this.StepperData[this.index],
              this.index,
              regFlag
            );
          } else {
            this.service.sowAssiatnceHeaderText =
              this.StepperData[this.StepperData.length - 1];
            this.service.isDownloadSowBtn = true;
            this.service.sowContentButtonFlag = false;
            this.service.sowAssiatnceHeaderText = `SOW document generated successfully.`;
            this.service.sowAssiatnceContentText = [
              `Please click on "Download SOW" button to download SOW document.`,
            ];
            this.service.isLoading = false;
          }
        } else {
          alert(res.message);
        }
      },
      (error: any) => {
        this.showCustomSnackbar(error);
        // alert(error);
      }
    );
  }
  regenrateClick() {
    let regFlag = "True";
    this.sowSectionwiseResponse(
      this.StepperData[this.index],
      this.index,
      regFlag
    );
  }
  backClick() {
    this.sessionId = localStorage.getItem("sessionId");
    this.index--;
    if (this.index === 0) {
      this.backbuttonDisableFlag = true;
    } else {
      this.backbuttonDisableFlag = false;
    }
    this.backFlag = true;
    let payload = {
      session_id: this.sessionId,
      prompt_key: this.StepperData[this.index],
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.getParticularSectionResponse(payload).subscribe(
      (res) => {
        this.outputId = res.output_id;
        this.service.sowAssiatnceHeaderText = res.prompt_key;
        this.service.sowAssiatnceContentText = res.prompt_output.split("\n");
        this.dummyText = [res.prompt_output];
        this.editContentForCancel = res.prompt_output;
      },
      (error: any) => {
        this.showCustomSnackbar(error);
        // alert(error);
      }
    );
  }

  reset() {
    window.location.reload();
  }
}
