<div>
    <div class="modal-body">
        <p>{{ modalContent }}</p>

    </div>

    <div class="modal-footer">

        <div>
            <button mat-button class="btn btn-save" (click)="dialogClose()">
                OK
            </button>
        </div>
    </div>

    <div role="status" *ngIf="isLoadingFlag">
        <app-loader></app-loader>
    </div>
</div>