import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SowFormDataComponent } from "./sow-assistance-form-data/sow-form-data.component";
import { SowGenerationPageComponent } from "./sow-generation-page.component";

const routes: Routes = [
  {
    path: "",
    component: SowGenerationPageComponent,
    children: [
      { path: "", redirectTo: "assistance", pathMatch: "full" },
      {
        path: "assistance",
        component: SowFormDataComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SowGenerationPageRoutingModule {}
