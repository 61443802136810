<mat-sidenav-container class="side-nav-main-container">
  <mat-sidenav #sidenav="matSidenav" class="right-wrapper" disableClose>

    <div class="main-wrapper">
      <div class="filter-box">

        <div class="title-bar">
          <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
              <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
              <h3>Genysys</h3>
          </div>
          <img
            class="home-icon"
            src="../../../../assets/icons/home-icon.svg"
            alt="Home"
            (click)="dashboardRedirection()"
            title="Home"
          />
        </div>

        <div class="form-wrapper">
          
          <div class="input-wrapper mb-3 convobi-context">
            <label for="inp1" class="label-group addCursor">
              <span>Context</span>
              <img
                src="../../../../assets/icons/add-icon.svg"
                class="cont-icon"
                title="Add Context"
                alt=""
                (click)="openPersona()"
              >
            </label>

            <mat-form-field>
              <mat-select id="inp5" [(ngModel)]="selectedPersona">
                <mat-option *ngFor="let item of documentList" [value]="item.persona_id">
                  <!-- {{item.persona_name}} -->
                  <ng-container *ngIf="!isContextSelected(item.persona_id); else selectedContextTemplate">
                    {{item.persona_name}}
                    <button (click)="deleteContext(item)" style="float: right; background: none;">
                      <img class="cross-icon" src="../../../assets/icons/cross-icon.svg" alt="Delete" title="Delete" />
                    </button>
                  </ng-container>
                  <ng-template #selectedContextTemplate>
                    {{item.persona_name}}
                  </ng-template>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="text-danger">
              <span class="validation-error" *ngIf="formSelected && selectedPersona==''">
                Context is required
              </span>
            </div>
    
          </div>
          
          <div class="input-wrapper">
            <button
              class="btn btn-primary btn-block"
              (click)="newChat()"
              title="New chat"
            >
              <img class="new_cht_pls" src="../../../../assets/img/Plus.svg" alt="">
              <span class="new_cht_txt">New Chat</span>
            </button>
          </div>

        </div>

        <div class="action-wrapper">
          <div class="profiler">
              <div class="avatar">
                  <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
              </div>
              <p>{{usernameDisplay}}</p>
          </div>
          <div class="logout-cta" (click)="logout()">
              <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
          </div>
      </div>
        
      </div>
    </div>

    <!-- <div class="logo-container">
      <div class="logo">
        <img class="apexon-logo" src="../../../../assets/new-logo/new-logo.png" alt="Logo" />
        <p class="logo-text" (click)="dashboardRedirection()" title="Home">Genysys</p>
      </div>
      <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home" (click)="dashboardRedirection()"
        title="Home" />
    </div> -->

    <!-- sidebar for text-to-image -->
    <!-- <div class="sidenav-conatiner">
      <div class="input-wrap">
        <div class="contxt-div">
          <span style="font-size: 14px; font-weight: 600; color: #000; margin-bottom: 0.25rem">Context</span>
          <img (click)="openPersona()" title="Add Context" class="cont-icon" src="../../../../assets/icons/add-icon.svg"
            alt="">
        </div>
        <mat-form-field>
          <mat-select id="inp5" [(ngModel)]="selectedPersona">
            <mat-option *ngFor="let item of documentList" [value]="item.persona_id">
              <ng-container *ngIf="!isContextSelected(item.persona_id); else selectedContextTemplate">
                {{item.persona_name}}
                <button (click)="deleteContext(item)" style="float: right; background: none;">
                  <img class="cross-icon" src="../../../assets/icons/cross-icon.svg" alt="Delete" title="Delete" />
                </button>
              </ng-container>
              <ng-template #selectedContextTemplate>
                {{item.persona_name}}
              </ng-template>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="text-danger">
          <span class="validation-error" *ngIf="formSelected && selectedPersona==''">
            Context is required.
          </span>
        </div>
      </div>
      <button class="btn btn-primary btn-block" (click)="newChat()" title="New chat">
        <img class="new_cht_pls" src="../../../../assets/img/Plus.svg" alt=""><span class="new_cht_txt">New Chat</span>
      </button>
    </div> -->
    <!-- <div class="logout-container">
      <div class="logout-btn">
        <span class="logout-profile" ng-bind="usernameInitials">{{usernameInitials}}</span>
        <p class="logout-para">{{usernameDisplay}}</p>
      </div>
      <img (click)="logout()" class="logout-icon" title="logout" src="../../../../assets/icons/logout-icon.svg" />
    </div> -->
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <div class="content mat-elevation-z8">
      <!-- route to form data componenet -->
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>