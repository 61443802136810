import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CodeConversionPageComponent } from './code-conversion-page.component';
import { CodeConversionFormDataComponent } from './code-conversion-form-data/code-conversion-form-data.component';
import { DbCodeConversionFormDataComponent } from './db-code-conversion-form-data/db-code-conversion-form-data.component';

const routes: Routes = [
  {
    path:'',
    component:CodeConversionPageComponent,
    children: [
      { path: '', redirectTo: 'code-conversion', pathMatch: 'full' },
      { 
        path: 'code-conversion',
        component:CodeConversionFormDataComponent,
      },
      { 
        path: 'db-code-conversion',
        component: DbCodeConversionFormDataComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CodeConversionPageRoutingModule { }
