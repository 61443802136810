import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatLoaderComponent } from './chat-loader.component';



@NgModule({
  declarations: [ChatLoaderComponent],
  imports: [
    CommonModule
  ],
  exports: [ChatLoaderComponent],
  providers: []
})
export class ChatLoaderModule { }
