import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SowGenerationComponent } from "./sow-generation.component";

const routes: Routes = [
  {
    path: "",
    component: SowGenerationComponent,
    children: [
      { path: "", redirectTo: "sow", pathMatch: "full" },
      {
        path: "sow",
        loadChildren: () =>
          import("./sow-generation-page/sow-generation-page.module").then(
            (m) => m.SowGenerationPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SowGenerationRoutingModule {}
