import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "new-password.dialog",
  templateUrl: "new-password.dialog.html",
  styleUrls: ["new-password.dialog.scss"],
})
export class NewPasswordDialog {
  PasswordForm!: FormGroup;
  errorMessage: string = "";
  isAuthenticated: boolean = false;
  isSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<NewPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.PasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, this.passwordValidator()]],
      confirmPassword: ['', Validators.required],
    });
  }

  get f() {
    return this.PasswordForm.controls;
  }

  get newPassword() {
    return this.PasswordForm.get('newPassword');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmitClick(): void {
    this.isSubmitted = true;
    if (
      !this.PasswordForm.value.newPassword ||
      !this.PasswordForm.value.confirmPassword
    ) {
      this.isAuthenticated = false;
      this.errorMessage = "New Password & Confirm Password cannot be empty";
      console.log("newPassword err: " + this.errorMessage);
    } else if (
      this.PasswordForm.value.newPassword !==
      this.PasswordForm.value.confirmPassword
    ) {
      this.isAuthenticated = false;
      this.errorMessage = "New Password & Confirm Password should be same";
      console.log("newPassword err: " + this.errorMessage);
    } else {
      this.isAuthenticated = true;
      const result = {
        newPassword: this.PasswordForm.value.newPassword,
        confirmPassword: this.PasswordForm.value.confirmPassword,
      };

      this.dialogRef.close(result);
    }
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      // Check if the password meets the required criteria
      // Password minimum length - 8 character(s)
      // Contains at least 1 number, Contains at least 1 special character, Contains at least 1 uppercase letter, Contains at least 1 lowercase letter

      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /\d/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\/-]/.test(
        value
      );
      const isLengthValid = value.length >= 8;

      const isValid =
        hasUpperCase &&
        hasLowerCase &&
        hasNumber &&
        hasSpecialCharacter &&
        isLengthValid;

      return isValid ? null : { passwordPolicy: true };
    };
  }
}
