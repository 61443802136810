import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

export class Message {
  constructor(public author: string, public content: string) {
    author = "";
    content = "";
  }
}
@Injectable({
  providedIn: "root",
})
export class SowGenerationServiceService {
  //SOW
  isLoading: boolean = false;
  sowContentButtonFlag = false;
  projectDescriptionSuccess: boolean = false;
  public showStepper: boolean = false;
  isDownloadSowBtn: boolean = false;
  isProjectDescriptionBtn: boolean = false;
  public isProjectDescriptionView: boolean = false;
  isUploadPPTBtn: boolean = false;
  sowAssiatnceHeaderText: any = "Hi, I am your Personal SOW Assistant.......";
  sowAssiatnceSubHeaderText: any = "";
  sowAssiatnceContentText: any = [
    "Please add a Project Description by clicking the “Project Description” button on the left to start with a New SOW Document.",
  ];
  projectDetails: any;
  isSnackbarOpen: boolean = false;

  // public baseapiUrl = "http://3.131.8.158:8000/";
  public baseapiUrl: string = environment.baseapiurl;

  private newChatInfo = new Subject<any>();
  public isDelete: boolean = false;
  public showChatLoader: boolean = false;

  sendChatMsg() {
    this.newChatInfo.next();
  }
  getChatMsg(): Observable<any> {
    return this.newChatInfo.asObservable();
  }
  private tokenExpire = new Subject<any>();
  // sendtokenMsg() {
  //   this.tokenExpire.next();
  // }
  gettokenMsg(): Observable<any> {
    return this.tokenExpire.asObservable();
  }
  private tabChange = new Subject<any>();

  getTabChangeMsg(): Observable<any> {
    return this.tabChange.asObservable();
  }

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  // SOW API's
  getSessionId(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "user/session/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSowList(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sow/get/section/list/", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  sowSectionWiseResponse(payload: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sow/get/section/wise/response/", payload)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateSectionWiseResponse(payload: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sow/update/model/data", payload)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getParticularSectionResponse(payload: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sow/get/model/data/acc/prompt/key", payload)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public getXML(payload: any): Observable<Blob> {
    this.authService.checkTokenExpiry();
    return this.http.post(this.baseapiUrl + "sow/download/", payload, {
      responseType: "blob",
    });
  }

  downloadSOW(payload: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "sow/download/", payload).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  sowSectionWiseSaveResponse(payload: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sow/save/section/wise/response/", payload)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  postProjectDetails(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "sow/create/metadata/", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  uploadPPT(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "doc/upload/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  setProjectDetails(data: any): void {
    this.projectDetails = data;
  }

  getProjectDetails(): any {
    return this.projectDetails;
  }
}
