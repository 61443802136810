import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PdfComparisionComponent } from "./pdf-comparision.component";

const routes: Routes = [
  {
    path: "",
    component: PdfComparisionComponent,
    children: [
      { path: "", redirectTo: "pdf-comparison-page", pathMatch: "full" },
      {
        path: "pdf-comparison-page",
        loadChildren: () =>
          import("./pdf-comparision-page/pdf-comparision-page.module").then(
            (m) => m.PdfComparisionPageModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PdfComparisionRoutingModule {}
