import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validator,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConvoBiService } from "src/app/module/service/convo-bi.service";
import { AbstractControl, ValidatorFn } from "@angular/forms";

export function documentNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 24;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[A-Za-z0-9_ -]*$/.test(value)) {
      return { invalidCharacters: true };
    }

    return null;
  };
}
// export class FileValidator implements Validator {
//   static required: any;
//   static validate(c: FormControl): {[key: string]: any} {
//      this.required = c.value == null ||c.value.length == 0 ? {required : true} : null;
//      console.log(this.required)
//       return this.required
//   }

//   validate(c: FormControl): {[key: string]: any} | null {
//       return FileValidator.validate(c);
//   }
// }

@Component({
  selector: "persona.dialog",
  templateUrl: "persona.dialog.html",
  styleUrls: ["persona.dialog.scss"],
})
export class PersonaDialog {
  showPath: boolean = false;
  showUpload: boolean = false;
  // PersonaForm!:FormGroup;
  contextForm!: FormGroup;
  uploadFile: File[] = [];
  uploadReportFile: File[] = [];
  uploadworkSpaceFile: File[] = [];
  uploadMetaDataFile: File[] = [];
  listFiles: any = [];
  listReportFiles: string = "";
  listworkSpaceFiles: string = "";
  listColumnData: string = "";
  file_type: string = "";
  formSubmitted: boolean = false;
  showLoader: boolean = false;
  showText: boolean = false;
  newText: string = "";
  selectedworkSpaceName: string = "";
  username: any;
  isLoading: boolean = false;
  workSpaceName: any = [];
  document_name: string = "";
  usernameDisplay: any;
  documentList: any = [];
  limiterror: any = { isError: false, errorMessage: "" };
  token: any;
  userEmail: any;
  constructor(
    public formBuilder: FormBuilder,
    public service: ConvoBiService,
    public dialogRef: MatDialogRef<PersonaDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.contextForm = this.formBuilder.group({
      workSpaceName: ["", Validators.required],
      documentName: [
        "",
        [
          Validators.required,
          Validators.pattern("[A-Za-z0-9_ -]*"),
          documentNameValidator(),
        ],
      ],
      // reportVisuals: [""],
      customVisual: ["", Validators.required],
      // columnMataData: ["", Validators.required],
    });
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.getWorkspaceName();
    this.getlistDocumentName();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  delete() {
    this.dialogRef.close("ok");
  }
  getlistDocumentName() {
    let data = {
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.listdocumentName(data).subscribe((res) => {
      this.documentList = res.skills;
    });
  }
  limitxt(event: any) {
    if (this.contextForm.controls.documentName.value != "") {
      for (let document of this.documentList) {
        if (
          document.persona_name.replaceAll("\\s+", "").toLowerCase() ==
          this.contextForm.controls.documentName.value
            .replaceAll("\\s+", "")
            .toLowerCase()
        ) {
          this.limiterror = {
            isError: true,
            errorMessage: " Document Name should not be duplicate.",
          };
          return;
        }
      }
      this.limiterror = {
        isError: false,
        errorMessage: "",
      };
    } else {
      this.limiterror = {
        isError: false,
        errorMessage: "",
      };
    }
    // if (this.contextForm.controls.documentName.value.length>=40) {
    //   this.limiterror = { isError: true, errorMessage: " Document Name should the limit of 40 characters." };
    // } else {
    //   this.limiterror = { isError: false , errorMessage: ''};
    // }
  }
  getWorkspaceName() {
    let data = {
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.getWorkspaceNme(data).subscribe(
      (res) => {
        if (res.status == "success") {
          // this.workSpaceName = res.most_similar_description_dict;
          this.workSpaceName = res.workspace_info;
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }
  clickOK() {
    // console.log(this.contextForm);
    this.formSubmitted = true;
    if (
      this.contextForm.valid &&
      !this.error.isError &&
      !this.error2.isError &&
      !this.error3.isError &&
      !this.limiterror.isError
    ) {
      this.isLoading = true;
      this.formSubmitted = false;
      localStorage.setItem("workspace_id", this.selectedworkSpaceName);
      const formData = new FormData();
      // for (var i = 0; i < this.uploadReportFile.length; i++) {
      //   formData.append(
      //     "filepath",
      //     this.uploadReportFile[i],
      //     this.uploadReportFile[i].name
      //   );
      // }
      for (var i = 0; i < this.uploadworkSpaceFile.length; i++) {
        formData.append(
          "jsonfile",
          this.uploadworkSpaceFile[i],
          this.uploadworkSpaceFile[i].name
        );
      }
      // for (var i = 0; i < this.uploadMetaDataFile.length; i++) {
      //   formData.append(
      //     "column_mete_data",
      //     this.uploadMetaDataFile[i],
      //     this.uploadMetaDataFile[i].name
      //   );
      // }
      formData.append(
        "persona_name",
        this.contextForm.controls.documentName.value
      );
      formData.append(
        "workspace_id",
        this.contextForm.controls.workSpaceName.value
      );
      formData.append("description", "");
      formData.append("username", this.username);
      formData.append("token", this.token);
      formData.append("userEmail", this.userEmail);
      let document_name = this.contextForm.controls.documentName.value;
      this.contextForm.reset();
      this.listReportFiles = "";
      this.listworkSpaceFiles = "";
      this.listColumnData = "";
      this.service.documentUpload(formData).subscribe(
        (res) => {
          if (res.status == "success") {
            this.ok();
            this.onContextChange(res.persona_id);
            this.isLoading = false;
          } else {
            alert(res.message);
            this.isLoading = false;
          }
        },
        (error) => {
          alert(error.error.message);
          this.isLoading = false;
        }
      );
    } else {
      this.isLoading = false;
    }
  }
  onContextChange(persona_id: number) {
    this.service.callMethod(persona_id);
  }

  ok() {
    this.showText = false;
    this.dialogRef.close("ok");
  }
  onFileChange(event: any) {
    this.uploadFile = event.target.files;
    for (var i = 0; i < this.uploadFile.length; i++) {
      this.listFiles.push(this.uploadFile[i].name);
    }
  }
  error: any = { isError: false, errorMessage: "" };
  onFileRepprt(event: any) {
    if (event.target.files.length > 0) {
      this.uploadReportFile = [];
      this.listReportFiles = "";
      if (
        event.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.error = {
          isError: true,
          errorMessage: "Please add valid CSV /xlsx format only.",
        };
      } else {
        this.error = {
          isError: false,
          errorMessage: "",
        };
        this.uploadReportFile = event.target.files;
        this.listReportFiles = this.uploadReportFile[0].name;
      }
    }
  }
  error2: any = { isError: false, errorMessage: "" };
  onFileworkSpace(event: any) {
    if (event.target.files.length > 0) {
      this.uploadworkSpaceFile = [];
      this.listworkSpaceFiles = "";
      if (event.target.files[0].type != "application/json") {
        this.error2 = {
          isError: true,
          errorMessage: "Please add valid json format only.",
        };
      } else {
        this.error2 = {
          isError: false,
          errorMessage: "",
        };
        this.uploadworkSpaceFile = event.target.files;
        this.listworkSpaceFiles = this.uploadworkSpaceFile[0].name;
      }
    }
    // for(var i=0;i<this.uploadworkSpaceFile.length;i++){
    //   this.listworkSpaceFiles.push(this.uploadworkSpaceFile[i].name)
    // }
  }
  error3: any = { isError: false, errorMessage: "" };
  onFileColumnMeteData(event: any) {
    if (event.target.files.length > 0) {
      this.uploadMetaDataFile = [];
      this.listColumnData = "";
      if (
        event.target.files[0].type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        this.error3 = {
          isError: true,
          errorMessage: "Please add valid CSV /xlsx format only.",
        };
      } else {
        this.error3 = {
          isError: false,
          errorMessage: "",
        };
        this.uploadMetaDataFile = event.target.files;
        this.listColumnData = this.uploadMetaDataFile[0].name;
      }
    }
    // for(var i=0;i<this.uploadMetaDataFile.length;i++){
    //   this.listColumnData.push(this.uploadMetaDataFile[i].name)
    // }
  }
  getFileUpload() {
    this.showPath = false;
    this.showUpload = true;
    this.file_type = "local";
  }
  getFilePath() {
    this.showPath = true;
    this.showUpload = false;
    this.file_type = "cloud";
  }
}
