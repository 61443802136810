<mat-sidenav-container class="side-nav-main-container">
    <!-- mode="side" opened="true" -->
    <mat-sidenav #sidenav="matSidenav" class="right-wrapper" disableClose>
        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home"
                        (click)="dashboardRedirection()" title="Home" />
                </div>

                <div class="form-wrapper costing-filter" *ngIf="showFilter">
                    
                    <div class="input-wrapper mb-3 date-picker-field">
                        <label for="sDate">Start date</label>
                        <mat-form-field>
                            <div  class="d-flex" style="position:relative;bottom:15px;align-items:baseline;">
                                <input
                                    matInput
                                    [min]="minDate"
                                    (keydown)="onKeyDown($event)"
                                    (focusout)="onFocusOut()"
                                    [matDatepicker]="sDate"
                                    [(ngModel)]="startDate"
                                    (click)="openDatePicker('sDate')"
                                />
                                <mat-datepicker-toggle matIconSuffix [for]="sDate"></mat-datepicker-toggle>
                            </div>
                            <mat-datepicker #sDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    <div class="input-wrapper mb-3 date-picker-field">
                        <label for="eDate">End date</label>
                        <mat-form-field>
                            <div  class="d-flex" style="position:relative;bottom:15px;align-items:baseline;">
                                <input
                                    matInput
                                    [max]="maxDate"
                                    (keydown)="onKeyDown($event)"
                                    (focusout)="onFocusOut()"
                                    [matDatepicker]="eDate"
                                    [(ngModel)]="endDate"
                                    (click)="openDatePicker('eDate')"
                                >
                                <mat-datepicker-toggle matIconSuffix [for]="eDate"></mat-datepicker-toggle>
                            </div>
                            <mat-datepicker #eDate></mat-datepicker>
                        </mat-form-field>
                    </div>
                    
                    <div class="input-wrapper mb-3" *ngIf="showInterval">
                        <label for="type">Select Interval</label>
                        <mat-radio-group [(ngModel)]="selectedInterval" (change)="onIntervalChange($event)">
                            <mat-radio-button value="Daily">Daily</mat-radio-button>
                            <mat-radio-button value="Weekly">Weekly</mat-radio-button>
                            <mat-radio-button value="Monthly">Monthly</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="input-wrapper button-block">
                        <button class="btn btn-primary btn-block" (click)="filterData()">
                            Filter
                        </button>
                    </div>
                    
                    <div class="validation-error">
                        <span *ngIf="errorFlag" class="text-danger">{{errorMsg}}</span>
                    </div>

                </div>
            </div>
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p>{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="content mat-elevation-z8">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>