import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthServiceService } from "../module/service/auth-service.service";

@Injectable({ providedIn: "root" })
export class AuthorizeGuard implements CanActivate {
  constructor(
    private AuthServiceService: AuthServiceService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.AuthServiceService.isTokenExpired()) {
      return true;
    } else {
      return this.router.navigate(["/login"]);
    }
  }
}
