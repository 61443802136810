<div class="modal-wrapper">
    <div role="status" style="position: absolute;top: 40%; left: 48%;z-index: 999;" *ngIf="isLoading">
        <app-loader></app-loader>
      </div>
    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title class="hd-txt">Database</h1>
            <mat-icon class="cancel-btn" title="Close" (click)="onNoClick('cancled')">cancel</mat-icon>
        </span>
    </div>
    <div mat-dialog-content class="contain">
        <form [formGroup]="contextForm">
            <fieldset>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="dbName">
                            DB Name <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" formControlName="dbName" class="form-control rd-colr" id="dbName"
                                aria-describedby="basic-addon3">
                        </div>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="contextForm.get('dbName')?.errors?.required && contextForm.get('dbName')?.touched">
                                DB Name is required
                            </span>
                            <!-- Add other validation messages if needed -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="dbHostServerUrl">
                            DB Host <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" formControlName="dbHostServerUrl" class="form-control rd-colr" id="dbHostServerUrl"
                                aria-describedby="basic-addon3">
                        </div>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="contextForm.get('dbHostServerUrl')?.errors?.required && contextForm.get('dbHostServerUrl')?.touched">
                                DB Host is required
                            </span>
                            <!-- Add other validation messages if needed -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="dbPort">
                            DB Port <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" formControlName="dbPort" class="form-control rd-colr" id="dbPort"
                                aria-describedby="basic-addon3">
                        </div>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="contextForm.get('dbPort')?.errors?.required && contextForm.get('dbPort')?.touched">
                                DB Port is required
                            </span>
                            <!-- Add other validation messages if needed -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3 db-type">
                        <label for="dbType">
                            DB Type <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <mat-form-field>
                                <mat-select id="inp3" formControlName="dbType" id="dbType" [(ngModel)]="selectedLanguage" >
                                    <mat-option *ngFor="let language of inputLanguages" [value]="language" >
                                        {{ language }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="contextForm.get('dbType')?.errors?.required && contextForm.get('dbType')?.touched">
                                DB Type is required
                            </span>
                            <!-- Add other validation messages if needed -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="dbUsername">
                            DB Username <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <input type="text" formControlName="dbUsername" class="form-control rd-colr" id="dbUsername"
                                aria-describedby="basic-addon3">
                        </div>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="contextForm.get('dbUsername')?.errors?.required && contextForm.get('dbUsername')?.touched">
                                DB Username is required
                            </span>
                            <!-- Add other validation messages if needed -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                        <label for="dbPassword">
                            DB Password <span class="text-danger">*</span>
                        </label>
                        <div class="input-group">
                            <input type="password" formControlName="dbPassword" class="form-control rd-colr" id="dbPassword"
                                aria-describedby="basic-addon3">
                        </div>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="contextForm.get('dbPassword')?.errors?.required && contextForm.get('dbPassword')?.touched">
                                DB Password is required
                            </span>
                            <!-- Add other validation messages if needed -->
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-3">
                      <label for="connectionName">
                        Connection Name <span class="text-danger">*</span>
                      </label>
                      <div class="input-group">
                        <input type="text" formControlName="connectionName" class="form-control rd-colr" id="connectionName" aria-describedby="basic-addon3" #connectionNameField>
                      </div>
                      <div class="text-danger">
                        <span class="validation-error" *ngIf="contextForm.get('connectionName')?.errors?.required && contextForm.get('connectionName')?.touched">
                          Connection Name is required
                        </span>
                        <!-- Add other validation messages if needed -->
                      </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
    <div mat-dialog-actions>
        <button class="btn btn-primary" (click)="handleConnectClick()" [disabled]="!isFormValid() || isDatabaseConnectionEnabled">Connect</button>
        <div>
            <button class="btn btn-primary" [disabled]="!isDatabaseConnectionEnabled" (click)="handleSaveClick()">Save</button>
        </div>
    </div>
</div>