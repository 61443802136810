import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

@Injectable({
  providedIn: "root",
})
export class ConvoBiService {
  token: any;
  headers: any;
  public baseapiUrl = "http://13.59.118.33:8000/api/";

  // public baseapiUrl =  "https://sandboxgpt.sbt-infra.net/api/";

  public taskConvertor: string = "";
  // private chatbotApiUrl = 'https://example.com/chatbot-api';
  //public baseapiUrl =  "http://192.168.128.64:8000/api/";
  public baseBiApi = environment.baseapiurl + "bi";
  private newChatInfo = new Subject<any>();
  private newBIChatInfo = new Subject<any>();
  public IsConversationBi: boolean = false;
  public showChatLoader: boolean = false;
  public showChat: boolean = false;
  // public selectedModel: string = "Llama2";
  public showVisualType: boolean = false;
  public chatLoad: boolean = false;
  public loading: boolean = false;
  chatTriggered: EventEmitter<void> = new EventEmitter<void>();
  sendChatMsg() {
    this.newChatInfo.next();
  }

  private methodCaller = new Subject<void>();
  methodCalled$ = this.methodCaller.asObservable();
  callMethod(data?: any) {
    this.methodCaller.next(data);
  }
  getChatMsg(): Observable<any> {
    return this.newChatInfo.asObservable();
  }
  sendBIChatMsg(item: any) {
    this.newBIChatInfo.next(item);
  }
  getBIChatMsg(): Observable<any> {
    return this.newBIChatInfo.asObservable();
  }
  private tokenExpire = new Subject<any>();
  sendtokenMsg() {
    this.tokenExpire.next();
  }
  gettokenMsg(): Observable<any> {
    return this.tokenExpire.asObservable();
  }
  private tabChange = new Subject<any>();
  sendTabChangeMsg() {
    this.tabChange.next();
  }
  getTabChangeMsg(): Observable<any> {
    return this.tabChange.asObservable();
  }

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  /* conversation bi services */
  getWorkspaceNme(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseBiApi + "/get/workspaces/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  documentUpload(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseBiApi + "/upload/files/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  listdocumentName(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseBiApi + "/list/documents/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getReportList(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseBiApi + "/get/reports/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  createInstance(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseBiApi + "/create/cbi/instance/", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getEmbeddedData(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseBiApi + "/getembedinfo", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  triggerChat() {
    this.chatTriggered.emit();
  }

  deleteContext(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseBiApi + "/delete_context", data, {
        headers: this.headers,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
