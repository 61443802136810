import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  style: any;

  @Input()
  get size() {
    return this._size;
  }
  set size(value: number) {
    this._size = value;
    this.style = {
      transform: `translate(-50%, -50%) scale(${value})`,
    };
  }
  private _size: number = 1;

  constructor() {}

  ngOnInit() {}
}
