import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatSidenav } from "@angular/material/sidenav";
import { PdfComparisionService } from "../../service/pdf-comparision-service.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "src/app/module/snackbar/snackbar.component";

@Component({
  selector: "app-pdf-comparision-page",
  templateUrl: "./pdf-comparision-page.component.html",
  styleUrls: ["./pdf-comparision-page.component.scss"],
})
export class PdfComparisionPageComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @ViewChild("masterInput") masterInput!: ElementRef<HTMLInputElement>; // Reference to the input element
  @ViewChild("compareInput") compareInput!: ElementRef<HTMLInputElement>; // Reference to the input element
  instance_id: any;

  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  intervalId: any;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  token: any;
  userEmail: any;

  masterCopyError: boolean = false;
  fileToCompareError: boolean = false;
  validFileErrorMsg: string = "Upload a valid .pdf file";

  constructor(
    public route: Router,
    public pdfComparisionService: PdfComparisionService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");

    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    // Checks every 5 second for session expiration
    this.intervalId = setInterval(() => {
      if (this.isLocalUser === "true") {
        // For Local User
        this.authService.checkAndRefreshSession();
      } else {
        // For Azure AD user
        this.authService.checkAndRefreshSessionForAWSCognitoAzure();
      }
    }, 5000);

    // Integrating a user pool with an identity pool
    // this.authService.integrateUserPoolWithIdentityPool();
  }

  async ngOnInit() {
    this.getSessionId();
  }

  ngAfterViewInit() {
    /*  to display responsive sidebar */
    // this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
    //   if (res.matches) {
    //     this.sidenav.mode = "over";
    //     this.sidenav.close();
    //   } else {
    //     this.sidenav.mode = "side";
    //     this.sidenav.open();
    //   }
    // });
  }

  // error handling
  showCustomSnackbar(message: string) {
    let snackbarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message },
      // duration: 10000, // Set the duration for which the snackbar will be displayed
      verticalPosition: "top", // You can adjust the position of the snackbar
      panelClass: ["custom-snackbar"], // Add a custom CSS class for styling if needed
    });
    snackbarRef.afterDismissed().subscribe(() => {
      // Set your flag here
      this.pdfComparisionService.isSnackbarOpen = false;
    });

    return snackbarRef;
  }

  getSessionId() {
    this.pdfComparisionService.isLoading = true;
    let data = {
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
      username: this.username,
    };
    this.pdfComparisionService.getSessionId(data).subscribe(
      (res) => {
        if (res.status == "success") {
          localStorage.setItem("sessionId", res.session_id);
          this.pdfComparisionService.pdfComparisionAssiatnceHeaderText =
            "Hi, I am your Personal Pdf Comparison Assistant.......";
          this.pdfComparisionService.pdfComparisionMasterFileAssiatnceText = [
            `Please upload the master copy, which will be used as the reference file for comparison with other documents by clicking "Master Copy" button on left to start with a New Pdf Comparison Document.`,
          ];
          this.pdfComparisionService.isLoading = false;
        } else {
          console.log(JSON.stringify(res.message));
          this.showCustomSnackbar(JSON.stringify(res.message));
        }
      },
      (error) => {
        console.log(JSON.stringify(error));
        this.showCustomSnackbar(error.message);
      }
    );
  }

  newSession() {
    window.location.reload();
  }

  // Trigger file input for master copy
  uploadMasterCopy() {
    this.masterInput.nativeElement.click(); // Use nativeElement to trigger the input
  }

  // Trigger file input for file to compare
  uploadFileToCompare() {
    this.compareInput.nativeElement.click(); // Use nativeElement to trigger the input
  }

  checkFileValidOrNot(fileName: any) {
    const allowedExtensions = [".pdf"];

    const fileExtension =
      "." + fileName.split(".").slice(-1).join(".").toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      return false;
    }

    return true;
  }

  // When master copy is selected
  onMasterCopySelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      // validate file
      if (!this.checkFileValidOrNot(file.name)) {
        this.masterCopyError = true;
      } else {
        this.masterCopyError = false;
      }

      if (!this.masterCopyError) {
        this.pdfComparisionService.masterFile = file;
        this.pdfComparisionService.isMasterUploaded = true;
      }
    }
  }

  // When file to compare is selected
  onFileToCompareSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      // validate file
      if (!this.checkFileValidOrNot(file.name)) {
        this.fileToCompareError = true;
      } else {
        this.fileToCompareError = false;
      }

      this.pdfComparisionService.compareFile = file;

      if (!this.masterCopyError && !this.fileToCompareError) {
        this.pdfComparisionService.isCompareReady =
          this.pdfComparisionService.isMasterUploaded; // Enable compare if master is uploaded
      }
    }
  }

  // Function to convert string response to HTML with line breaks and lists
  convertToHtml(response: string) {
    // Replace new lines with <br> or wrap lines in <p> tags
    let formatted = response
      .replace(/\n\n/g, "</p><p>") // Convert double newlines to paragraphs
      .replace(/\n/g, "<br/>"); // Convert single newlines to line breaks

    // Sanitize the HTML string to ensure safety
    this.pdfComparisionService.formattedHtml =
      this.sanitizer.bypassSecurityTrustHtml(`<p>${formatted}</p>`);
  }

  // Compare the files by calling an API
  compareFiles() {
    if (!this.masterCopyError && !this.fileToCompareError) {
      if (
        this.pdfComparisionService.masterFile &&
        this.pdfComparisionService.compareFile
      ) {
        this.pdfComparisionService.isLoading = true;

        console.log(
          "Comparing files:",
          this.pdfComparisionService.masterFile.name,
          this.pdfComparisionService.compareFile.name
        );

        const formData = new FormData();
        formData.append("token", this.token);
        formData.append("userEmail", this.userEmail);
        formData.append("username", this.username);
        formData.append("files", this.pdfComparisionService.masterFile);
        formData.append("files", this.pdfComparisionService.compareFile);

        this.pdfComparisionService.compareFiles(formData).subscribe(
          (res) => {
            if (res.text_diff) {
              this.pdfComparisionService.showTextDiff = true;
              this.convertToHtml(res.text_diff);
              this.pdfComparisionService.isLoading = false;
            } else {
              console.log("Unable to find text difference!");
              this.showCustomSnackbar("Unable to find text difference!");
            }
          },
          (error) => {
            this.pdfComparisionService.isLoading = false;
            console.log(JSON.stringify(error));
            this.showCustomSnackbar(error.message);
          }
        );
      }
    }
  }

  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  logout() {
    this.pdfComparisionService.isLoading = true;
    this.authService.logout();
  }
}
