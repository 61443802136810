import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminSettingsServiceService } from "src/app/module/service/admin-settings-service.service";
import { CostService } from "src/app/module/service/cost.service";
import { DashboardServiceService } from "./../../../../service/dashboard-service.service";
import { read, utils, writeFile } from "xlsx";
import { environment } from "./../../../../../../environments/environment";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-cost-details-user",
  templateUrl: "./cost-details-user.component.html",
  styleUrls: [
    "../cost-table.component.scss",
    "./cost-details-user.component.scss",
  ],
})
export class CostDetailsUserComponent implements OnInit {
  groupName: string = "";
  userName: string = "";
  userTotalCost: string = "";
  showLoader: boolean = false;
  userDetails: any;
  userDetailsPerPage: any;
  costFilterData: any;
  sortUserDetailFlag: Record<string, string> = {
    date: "DESC",
    cost: "",
  };
  pages: any;
  limit: any;
  Arr = Array;
  currentPage: number = 1;
  paginationRange: number[] = [];
  rowCount: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminSetting: AdminSettingsServiceService,
    private service: CostService,
    public dashboardService: DashboardServiceService,
    private route: Router,
    public dialog: MatDialog
  ) {
    this.userDetails = [];
    this.userDetailsPerPage = [];
    this.rowCount = 0;
    this.pages = 0;
    this.limit = environment.paginationLimit;
    this.showLoader = true;
  }

  ngOnInit() {
    if (this.service.group === "" || this.service.user === "") {
      this.navigateBackToCost();
    }
    this.costFilterData = this.adminSetting.getCostFilterData();
    this.groupName = this.service.group;
    this.userName = this.service.user;
    this.userTotalCost = this.service.userTotalCost;
    this.getCostDetailsOfUsers();
    this.calculatePaginationRange();
  }

  getCostDetailsOfUsers() {
    this.showLoader = true;
    const data = {
      start_date: this.costFilterData ? this.costFilterData.costStartDate : "",
      end_date: this.costFilterData ? this.costFilterData.costEndDate : "",
      token: localStorage.getItem("token"),
      userEmail: localStorage.getItem("userEmail"),
      client_mail: this.userName,
      group_name: this.groupName,
    };
    this.service.getCostDetailsOfUsers(data).subscribe(
      (response) => {
        if (response.status === "success" && response.data.length) {
          this.rowCount = response.data.length;
          if (this.rowCount > this.limit) {
            this.pages = Math.ceil(this.rowCount / this.limit);
          }
          this.userDetails = response.data.sort((a: any, b: any) => {
            const dateA = new Date(a.date_time);
            const dateB = new Date(b.date_time);
            return dateB.getTime() - dateA.getTime();
          });

          this.sortUserDetailFlag["date"] = "DESC";
        }
        this.showLoader = false;
        this.getPageData(this.currentPage);
      },
      (error) => {
        this.showLoader = false;
      }
    );
  }

  getPageData(page: any) {
    this.currentPage = page;
    this.userDetailsPerPage = [];
    const startIndex = (page - 1) * this.limit;
    const endIndex = startIndex + this.limit - 1;

    // Generate an array of values based on the page number
    this.userDetailsPerPage = this.userDetails.slice(startIndex, endIndex + 1);
    this.calculatePaginationRange();
  }

  calculatePaginationRange() {
    const totalPages = this.pages;
    const currentPage = this.currentPage;

    let currentRangeStart = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const currentRangeEnd = Math.min(currentRangeStart + 9, totalPages);

    if (currentPage > currentRangeEnd) {
      currentRangeStart += 10;
    }

    const newEnd = Math.min(currentRangeStart + 9, totalPages);

    this.paginationRange = Array.from(
      { length: newEnd - currentRangeStart + 1 },
      (_, i) => currentRangeStart + i
    );
  }

  sortUserDetail(sortOn: any) {
    let sortBy = this.sortUserDetailFlag[sortOn] === "ASC" ? "DESC" : "ASC";
    this.sortUserDetailFlag[sortOn] = sortBy;
    if (sortOn === "cost") {
      if (sortBy === "DESC") {
        this.userDetails = this.userDetails.sort((a: any, b: any) => {
          return b.total_count - a.total_count;
        });
      } else {
        this.userDetails = this.userDetails.sort((a: any, b: any) => {
          return a.total_count - b.total_count;
        });
      }
      this.sortUserDetailFlag["date"] = "";
    } else {
      if (sortBy === "DESC") {
        this.userDetails = this.userDetails.sort((a: any, b: any) => {
          const dateA = new Date(a.date_time);
          const dateB = new Date(b.date_time);
          return dateB.getTime() - dateA.getTime();
        });
      } else {
        this.userDetails = this.userDetails.sort((a: any, b: any) => {
          const dateA = new Date(a.date_time);
          const dateB = new Date(b.date_time);
          return dateA.getTime() - dateB.getTime();
        });
        this.sortUserDetailFlag["cost"] = "";
      }
    }
    this.getPageData(this.currentPage);
  }

  navigateBackToCost() {
    this.route.navigate(["/admin-settings/admin-settings-page/cost"]);
  }

  navigateBackToCostDetails() {
    this.route.navigate([
      "/admin-settings/admin-settings-page/cost/cost-details/",
    ]);
  }

  changeDateFormat(dateString: string) {
    return new Date(dateString).toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  selectDownloadTypeForGroupWiseUserDetailReport(event: any) {
    /** common logic to create data file */
    const headings = [
      [
        "User",
        "Date",
        "Cost ($)",
        "Question",
        "Answer",
        "Input Token",
        "Output Token",
      ],
    ];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.userDetails, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");

    /** here we can define that how to export it based on condition */
    if (event.target.value === "csv") {
      writeFile(wb, "Group-wise User Detail Cost Report.csv");
    } else if (event.target.value === "excel") {
      writeFile(wb, "Group-wise User Detail Cost Report.xlsx");
    }
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }

  trimFeedback( data: string ) {
    const limit = 60;
    if( data.length > limit ) {
      return data.substring(0, limit).trim() + ' ...';
    } else {
      return data;
    }
  }
  
  showAnswerInModal( answer: string ) {
    if( answer.length > 60) {
      const dialogRef = this.dialog.open(ResponseModal, {
        width: "100%",
        data: answer,
      });
    }
  }

}

@Component({
  selector: "app-cost-details-user",
  templateUrl: "./cost-details-user-modal.component.html",
  styleUrls: [
    "../cost-table.component.scss",
    "./cost-details-user.component.scss",
  ],
})
export class ResponseModal {

  modalContent: string = '';

  constructor(
    public dialogRef: MatDialogRef<ResponseModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data;
  }

  close() {
    this.dialogRef.close();
  }

}
