<section id="hero">
  <div class="content">
    <div class="chat-window">

      <div style="float: left;" (click)="navigateBack()">
        <img src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" class="dashboard-back-icon">
      </div>
      <h1>Costing</h1>
      <div class="message info">
        <div class="top-filter">
          <select id="download" (change)="selectDownloadTypeForGroupWiseReport($event)">
            <option value="">Download</option>
            <option value="csv">CSV</option>
            <option value="excel">Excel</option>
          </select>
        </div>

        <div class="cost-table-wrapper">
          <table class="gen-table">
            <thead>
              <tr>
                <th (click)="sortGroupData('group')" style="cursor: pointer;">
                  <label style="margin-top: 2.5px;">Group</label>
                  <img *ngIf="service.sortGroupFlag['group'] === 'ASC'" class="order-icon"
                    src="../../../../assets/icons/asc-icon.svg" alt="Asc" />
                  <img *ngIf="service.sortGroupFlag['group'] === 'DESC'" class="order-icon"
                    src="../../../../assets/icons/desc-icon.svg" alt="Desc" />
                </th>
                <th (click)="sortGroupData('cost')" style="cursor: pointer;">
                  <label style="margin-top: 2.5px;">Cost ($)</label>
                  <img *ngIf="service.sortGroupFlag['cost'] === 'ASC'" class="order-icon"
                    src="../../../../assets/icons/asc-icon.svg" alt="Asc" />
                  <img *ngIf="service.sortGroupFlag['cost'] === 'DESC'" class="order-icon"
                    src="../../../../assets/icons/desc-icon.svg" alt="Desc" />
                </th>
              </tr>
            </thead>
            <tbody *ngIf="service.groupSet && service.groupSet.length > 0">
              <tr *ngFor="let group of service.groupSetPerPage">
                <td>
                  <a href="javascript:void(0);" (click)="openGroup(group.group_name, group.total_count)">
                    {{ group.group_name }}
                  </a>
                </td>
                <td>
                  {{ group.total_count }}
                </td>
              </tr>
            </tbody>
            <tbody *ngIf="service.groupSet && service.groupSet.length === 0">
              <tr>
                <td colspan="2">
                  <p class="alert alert-danger">No groups available</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div role="status" *ngIf="service.showLoader">
            <app-loader></app-loader>
          </div>
        </div>

        <div class="pagination" *ngIf="service.rowCount > service.limit">
          <a (click)="service.currentPage !== 1 && adminSettingsCom.getPageData(1)">&laquo; First</a>
          <a (click)="service.currentPage > 1 && adminSettingsCom.getPageData(service.currentPage - 1)">&lsaquo;
            Previous</a>
          <a *ngFor="let page of service.paginationRange" [class.active]="page === service.currentPage"
            (click)="adminSettingsCom.getPageData(page)">
            {{ page }}
          </a>
          <a (click)="service.currentPage < service.pages && adminSettingsCom.getPageData(service.currentPage + 1)">Next
            &rsaquo;</a>
          <a (click)="service.currentPage !== service.pages && adminSettingsCom.getPageData(service.pages)">Last
            &raquo;</a>
        </div>

      </div>
    </div>
  </div>
</section>