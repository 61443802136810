import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog } from "@angular/material/dialog";
import { FormServiceService } from "../../service/form-service.service";
import { Router } from "@angular/router";
import { ConvoBiService } from "../../service/convo-bi.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { PersonaDialog } from "../mat-dialog/persona-dialog/persona.dialog";
import { NewChatDialog } from "../mat-dialog/newChat-dialog/newchat.dialog";
import { Subscription } from "rxjs";
import { ConfirmationDialog } from "../../home/home-page/mat-dialog/confirmation-dialog/confirmation.dialog";

@Component({
  selector: "app-conversation-bi",
  templateUrl: "./conversation-bi.component.html",
  styleUrls: ["./conversation-bi.component.scss"],
})
export class ConversationBiComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  selectedType: string = "open_source";
  selectedTask: string = "conversation_bi";
  selectedIndustry: string = "";
  selectedModal: string = "";
  selectedModalImage: string = "";
  selectedPersona: string = "";
  showImage: boolean = true;
  modelInfo: any = [];
  industryInfo: any = [];
  selectedWorkspaceName: string = "None";
  selectedReportName: string = "None";
  documentList: any = [];
  instance_id: any;
  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  formSelected: boolean = false;
  token: any;
  userEmail: any;
  deleteContextFlag: boolean = false;

  private subscription: Subscription;
  constructor(
    private observer: BreakpointObserver,
    public dialog: MatDialog,
    public route: Router,
    public service: ConvoBiService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.subscription = this.service.methodCalled$.subscribe((data: any) => {
      this.getlistDocumentName();
      this.setContextSelected(data);
    });
  }

  ngOnInit() {
    this.service.taskConvertor = "conversation_bi";
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
    this.getlistDocumentName();
    //this.getPersonaInfo();
    //this.getIndustryInfo();
  }
  Back() {
    this.route.navigateByUrl("/home");
  }
  getlistDocumentName() {
    let data = {
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.listdocumentName(data).subscribe((res) => {
      this.documentList = res.skills;
    });
  }
  getDocument(event: any) {}

  getTaskInfo() {
    if (this.selectedTask == "text_to_image") {
      this.route.navigateByUrl("/text-to-image");
    } else if (this.selectedTask == "text_to_text") {
      this.route.navigateByUrl("/home-page");
    } else if (this.selectedTask == "conversation_bi") {
      this.route.navigateByUrl("/conversation_bi");
    }
  }

  ngAfterViewInit() {
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }

  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }
  openPersona() {
    this.service.IsConversationBi = true;
    let info = {
      workspace_name: this.selectedWorkspaceName,
      report_name: this.selectedReportName,
    };
    // if(this.selectedIndustry!='' && this.selectedModal!=''){
    const dialogRef = this.dialog.open(PersonaDialog, {
      width: "50%",
      data: info,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
      }
    });
  }
  newChat() {
    let selectpersona = "";
    for (let item of this.documentList) {
      if (item.persona_id == this.selectedPersona) {
        selectpersona = item;
      }
    }
    this.formSelected = true;
    if (this.selectedPersona != "") {
      for (let item of this.documentList) {
        if (item.persona_id == this.selectedPersona) {
          selectpersona = item;
        }
      }
      this.formSelected = false;
      const dialogRef = this.dialog.open(NewChatDialog, {
        width: "50%",
        data: selectpersona,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result == "ok") {
          this.service.sendBIChatMsg("BI screen");
        }
      });
    }
    this.service.triggerChat();
  }

  setContextSelected(persona_id: string) {
    // let document_detail = this.documentList.filter(function (obj:any){
    //   return obj.persona_id == persona_id
    // });
    this.selectedPersona = persona_id;
  }

  logout() {
    this.service.loading = true;
    this.authService.logout();
  }

  isContextSelected(persona_id: string): boolean {
    return this.selectedPersona === persona_id;
  }

  deleteContext(contextDetail: any) {
    this.deleteContextFlag = true;

    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: "40%",
      data: {
        content: `This action will delete the <b> ${contextDetail.persona_name} </b> context. Are you sure to delete?`,
        action: "Delete",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        let data = {
          token: localStorage.getItem("token"),
          userEmail: this.userEmail,
          username: this.username,
          persona_id: contextDetail.persona_id,
          persona: contextDetail.persona_name,
        };
        this.service.deleteContext(data).subscribe(
          (res) => {
            if (res.status == "success") {
              this.getlistDocumentName();
            } else {
              alert(res.message);
            }
          },
          (error) => {
            alert(error.error.message);
          }
        );
      }
      this.deleteContextFlag = false;
      this.selectedPersona = "None";
    });
  }
}
