<div class="modal-wrapper">

    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title>Request&nbsp;Use&nbsp;Case</h1>
            <mat-icon class="cancel-btn" (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <!-- to save the request use case form information -->
    <div mat-dialog-content class="contain">
        <form [formGroup]="UsecaseForm">
            <div class="row row-container">
                <!-- Use Case Name -->
                <div class="col-6 mb-3">
                    <label for="basic-url">
                        Use Case Name<span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="usecase" id="basic-url"
                            aria-describedby="basic-addon3">
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.usecase.touched) && UsecaseForm.controls.usecase.errors?.required">
                            Use Case Name is required
                        </span>
                        <span class="validation-error"
                            *ngIf="UsecaseForm.controls.usecase.dirty && UsecaseForm.controls.usecase.errors?.maxLengthExceededUsecase">
                            Use Case Name cannot exceed 20 characters
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.usecase.dirty) && UsecaseForm.controls.usecase.errors?.invalidCharactersUsecase">
                            Only alpha-numeric, spaces, hyphen, and underscore are allowed
                        </span>
                    </div>
                </div>
                <!-- Project -->
                <div class="col-6  mb-3">
                    <label for="basic-url">
                        Project Name<span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="project" id="basic-url"
                            aria-describedby="basic-addon3">
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.project.touched) && UsecaseForm.controls.project.errors?.required">
                            Project Name is required
                        </span>
                        <span class="validation-error"
                            *ngIf="UsecaseForm.controls.project.dirty && UsecaseForm.controls.project.errors?.maxLengthExceededProject">
                            Project Name cannot exceed 20 characters
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.project.dirty) && UsecaseForm.controls.project.errors?.invalidCharactersProject">
                            Only alpha-numeric, spaces, hyphen, and underscore are allowed
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- Domain -->
                <div class="col-12 mb-3">
                    <label for="basic-url">Domain<span class="text-danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="domain" id="basic-url"
                            aria-describedby="basic-addon3">
                    </div>
                    <div class="text-danger">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.domain.touched) && UsecaseForm.controls.domain.errors?.required">
                            Domain is required
                        </span>
                        <span class="validation-error"
                            *ngIf="UsecaseForm.controls.domain.dirty && UsecaseForm.controls.domain.errors?.maxLengthExceededDomain">
                            Domain cannot exceed 20 characters
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.domain.dirty) && UsecaseForm.controls.domain.errors?.invalidCharactersDomain">
                            Only alpha-numeric and spaces are allowed
                        </span>
                    </div>
                </div>
                <!-- Email -->
                <!-- <div class="col-6">
                    <label for="basic-url">Email<span class="danger">*</span></label>
                    <div class="input-group">
                        <input type="text" class="form-control rd-colr" formControlName="email" id="basic-url"
                            aria-describedby="basic-addon3">
                    </div>
                    <div class="alert">
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.email.touched) && UsecaseForm.controls.email.errors?.required">
                            Email is required
                        </span>
                        <span class="validation-error"
                            *ngIf="(formSubmitted || UsecaseForm.controls.email.dirty) && UsecaseForm.controls.email.errors?.pattern">
                            Invalid email format
                        </span>
                    </div>
                </div> -->
            </div>
            <div class="row">
                <!-- description -->
                <div class="col-12">
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Description<span
                                class="text-danger">*</span></label>
                        <textarea class="form-control rd-colr" formControlName="description"
                            id="exampleFormControlTextarea1" rows="3"></textarea>
                        <div class="text-danger">
                            <span class="validation-error"
                                *ngIf="(formSubmitted || UsecaseForm.controls.description.touched) && UsecaseForm.controls.description.errors?.required">
                                Description is required
                            </span>
                            <span class="validation-error"
                                *ngIf="UsecaseForm.controls.description.dirty && UsecaseForm.controls.description.errors?.maxlength">
                                Description cannot exceed 500 characters
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!-- to save and close button -->
    <div mat-dialog-actions>
        <button mat-button class="btn btn-secondary-outline" (click)="onNoClick()">Close</button>
        <button mat-button class="btn btn-primary" (click)="save()">Send</button>
    </div>
    <div role="status" *ngIf="isLoading">
        <app-loader></app-loader>
    </div>
</div>