<div class="container">
    <div class="right-wrapper">

        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <!-- Logo wrapper with logo and text -->
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <!-- Home Icon -->
                    <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home"
                        (click)="dashboardRedirection()" title="Home" />
                </div>
                <div class="options-holder">
                    <button [ngClass]="{'disabled': pdfComparisionService.isCompareReady}"
                        [disabled]="pdfComparisionService.isCompareReady" type="button"
                        class="btn btn-primary btn-block mb-3" (click)="uploadMasterCopy()">
                        Master Copy
                    </button>
                    <!-- File input for master copy, hidden by default -->
                    <input type="file" #masterInput accept=".pdf" (change)="onMasterCopySelected($event)"
                        style="display: none;" />
                    <div class="validation-error">
                        <span *ngIf="masterCopyError" class="text-danger">{{validFileErrorMsg}}</span>
                    </div>

                    <button [disabled]="!pdfComparisionService.isMasterUploaded" (click)="uploadFileToCompare()"
                        [ngClass]="{'disabled': !pdfComparisionService.isMasterUploaded}" type="button"
                        class="btn btn-primary btn-block mb-3">
                        File To Compare
                    </button>
                    <!-- File input for file to compare, hidden by default -->
                    <input type="file" #compareInput accept=".pdf" (change)="onFileToCompareSelected($event)"
                        style="display: none;" />
                    <div class="validation-error">
                        <span *ngIf="fileToCompareError" class="text-danger">{{validFileErrorMsg}}</span>
                    </div>

                    <button type="button" class="btn btn-primary btn-block mb-3"
                        *ngIf="pdfComparisionService.isCompareReady" (click)="compareFiles()">
                        Compare
                    </button>

                    <button *ngIf="pdfComparisionService.isCompareReady" type="button"
                        class="btn btn-primary btn-block mb-3" (click)="newSession()">
                        New Session
                    </button>

                </div>
            </div>
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p>{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
                </div>
            </div>
        </div>
    </div>
</div>