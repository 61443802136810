import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FormServiceService } from "src/app/module/service/form-service.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable, fromEvent } from "rxjs";

import * as $ from "jquery";
import { Subscription } from "rxjs";
import { ConvoBiService } from "../../service/convo-bi.service";
import * as powerbiClient from "powerbi-client";
import * as models from "powerbi-models";
import { MatDialog } from "@angular/material/dialog";
import { LikeDislikeDialogComponent } from "../../home/home-page/mat-dialog/like-dislike-dialog/like-dislike-dialog.component";

declare const customVisual: any;
declare const getCustomVisuals: any;

export class Message {
  constructor(
    public author: string,
    public content: string,
    public msgImg: string,
    public showCheck: boolean,
    public base64: string,
    public userResponse: string
  ) {
    author = "";
    userResponse = "";
    content = "";
    msgImg = "";
    base64 = "";
    showCheck = false;
  }
}
@Component({
  selector: "app-form-data",
  templateUrl: "./form-data.component.html",
  styleUrls: ["./form-data.component.scss"],
})
export class FormDataComponent implements OnInit {
  message: string = ""; // Make sure this is defined correctly

  resizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto";
    if (this.userMessage.includes("\n")) {
      textarea.style.height = textarea.scrollHeight + "px";
    } else {
      textarea.style.height = "24px";
    }
  }

  @ViewChild("myinput") myinput!: ElementRef;
  @ViewChild("reportContainer") reportContainer!: ElementRef;
  @ViewChild("scrollMe") private myScrollContainer: any;
  @ViewChild("modal") modal!: ElementRef;
  @ViewChild("like") like!: ElementRef;
  @ViewChild("unlike") unlike!: ElementRef;
  @ViewChild("statusloader") statusloader!: ElementRef;

  $: any;

  myText: string = "Hello World!";
  showDasboard: boolean = false;
  llmFormData!: FormGroup;
  submittedthree: boolean = false;
  showModel: boolean = false;
  loanStatus: string = "";
  loanRule: string = "";
  value: string = "";
  formDetails: any = [];
  formDetailData: any;
  application_id: string = "";
  BusinessLegalName: string = "";
  BusinessLoanPurpose: string = "";
  BusinessSize: string = "";
  CustomerAge: string = "";
  CustomerName: string = "";
  capital_required: string = "";
  collateral_available: string = "";
  credit_score: string = "";
  existing_loan_amount: string = "";
  growth_rate: string = "";
  loan_to_investment_ratio: string = "";
  previous_business_experience: string = "";
  loanExpain: string = "";
  AverageActivity: string = "";
  College: string = "";
  HighestEducationQualification: string = "";
  IpAvailable: string = "";
  showCheck: boolean = false;
  IpCount: string = "";
  IpExpiry: string = "";
  NumberOfLinkedinfollowers: string = "";
  YoutubeSubscribers: string = "";
  socialQuotient: string = "";
  questions = [""];
  selectedQuestion: string = "";
  messages = { author: "", messages: "" };
  messageImg: any;
  messagebot: string = "user";
  userMessage: any;
  previousMessage: any;
  content = "";
  postedContent: any = [];
  contentRef!: ElementRef;
  contentHeight: any;
  palyMusic: boolean = false;
  clickEventSub!: Subscription;
  clickEventBISub!: Subscription;
  clickEventTabChangeSub!: Subscription;
  textArea: string = "";
  showChat: boolean = false;
  imagePath: any;
  username: any;
  flag: boolean = false;
  stopGeneratorFlag: boolean = false;
  regenerateFlag: boolean = false;
  botMessage: any = [];
  embedData: any;
  loadedResolve: any;
  reportLoaded = new Promise((res) => {
    this.loadedResolve = res;
  });
  renderedResolve: any;
  reportRendered = new Promise((res) => {
    this.renderedResolve = res;
  });
  powerbi: powerbiClient.service.Service = window["powerbi"];
  models: any;
  accesstokenn: any;
  tokenExpiry: any;
  authoringPage: any;
  isCustomVisual: boolean = false;
  userResponse: string = "";
  count: number = 0;
  idCount: any = [];
  chatLoad: any;
  step: number = 0;
  usernameDisplay: any;
  usernameInitials: any;
  likeBtnClicked: string = "";
  unlikeBtnClicked: string = "";
  likeClicked: boolean = false;
  token: any;
  userEmail: any;
  placeholder: string = 'Click on "+ New Chat" to initiate';

  constructor(
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    public formService: FormServiceService,
    private clipboard: Clipboard,
    private renderer: Renderer2,
    public service: ConvoBiService,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.clickEventSub = this.service.getChatMsg().subscribe((async) => {
      this.service.showChatLoader = false;
      this.textArea = "New Chat Session Started";
    });
    this.clickEventBISub = this.service.getBIChatMsg().subscribe((value) => {
      if (value == "BI screen") {
        this.messages.author = "";
        this.isCustomVisual = true;
        customVisual();
        // this.chatLoad = "true";
        // this.service.loading = false;
        // this.regenerateFlag = false;
        // this.reportContainer.nativeElement.style.display = "none";
        // this.statusloader.nativeElement.style.display = "none";
        // this.like.nativeElement.classList.remove("likeBtnClicked");
        // this.unlike.nativeElement.classList.remove("likeBtnClicked");
        // this.like.nativeElement.style.pointerEvents = "none";
        // this.unlike.nativeElement.style.pointerEvents = "none";
        // this.likeClicked = false;

        this.myinput.nativeElement.disabled = false;
        this.placeholder = "Type here...";
        this.myinput.nativeElement.focus();
      }
    });
    this.clickEventTabChangeSub = this.service
      .getTabChangeMsg()
      .subscribe((async) => {
        this.stopGeneratorFlag = false;
        this.regenerateFlag = false;
        this.textArea = "";
      });
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
  }

  ngAfterViewInit() {
    this.myinput.nativeElement.disabled = true;
  }

  window: any;

  like_response(event: any, cid?: string) {
    event.target.classList.add("likeBtnClicked");
    event.target.nextElementSibling.style.pointerEvents = "none";
    const dialogRef = this.dialog.open(LikeDislikeDialogComponent, {
      width: "50%",
      data: { type: "like", chat_id: cid },
    });
    dialogRef.afterClosed().subscribe((result) => {
      switch (result) {
        case "success":
          event.target.style.pointerEvents = "none";
          break;
        case "error":
          console.log("unable to submit feedback");
          break;
        default:
          break;
      }
    });
  }

  unlike_response(event: any, cid?: string) {
    if (this.likeClicked) {
      event.target.classList.add("likeBtnClicked");
      event.target.previousElementSibling.style.pointerEvents = "none";
      const dialogRef = this.dialog.open(LikeDislikeDialogComponent, {
        width: "50%",
        data: { type: "like", chat_id: cid },
      });
      dialogRef.afterClosed().subscribe((result) => {
        switch (result) {
          case "success":
            event.target.style.pointerEvents = "none";
            break;
          case "error":
            console.log("unable to submit feedback");
            break;
          default:
            break;
        }
      });
    }
  }

  close() {
    this.modal.nativeElement.style.display = "none";
  }

  pieChart() {
    this.step = 1;
  }

  areaChart() {
    this.step = 2;
  }

  donutChart() {
    this.step = 3;
  }

  barChart() {
    this.step = 4;
  }

  selectQuestion(question: string) {
    this.selectedQuestion = question;
  }

  regenerateMessage() {
    this.userMessage = localStorage.getItem("userMessage");
    this.sendMessage();
  }

  sendMessage() {
    if (this.userMessage != "") {
      this.service.showChat = true;
      let instance_id = localStorage.getItem("instance_id");
      // let audio = new Audio();
      // audio.src = "../../../../../assets/img/Message - Notification.mp3";
      // audio.load();
      // audio.play();
      localStorage.setItem("userMessage", this.userMessage);
      if (this.userMessage.trim() === "") {
        return;
      }

      this.messageImg = "../../../../../assets/img/profile-pic.png";
      let msg = {
        messages: this.userMessage,
        author: "user",
      };
      this.messages = msg;
      this.messagebot = "user";
      this.showDasboard = true;
      let rule = {
        instance_id: instance_id,
        prompt: this.userMessage,
        username: this.username,
      };

      this.scrollToBottom();
      this.regenerateFlag = true;
      localStorage.setItem("userMessage", this.userMessage);
      this.userResponse = this.userMessage;
      if (this.isCustomVisual) {
        this.reportContainer.nativeElement.style.display = "block";
        this.service.showChat = false;
        this.userMessage = "";
        getCustomVisuals();
        this.likeClicked = true;
        this.like.nativeElement.classList.remove("likeBtnClicked");
        this.unlike.nativeElement.classList.remove("likeBtnClicked");
        this.like.nativeElement.style.pointerEvents = "auto";
        this.unlike.nativeElement.style.pointerEvents = "auto";
        // getCustomVisuals();
      }
    }
  }

  download(image: any) {
    const downloadLink = document.createElement("a");
    const fileName = "sample.jpg";

    downloadLink.href = image;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        this.myScrollContainer.nativeElement.scrollToBottom =
          this.myScrollContainer.nativeElement.scrollHeight + 500;
      } catch (err) {}
    }, 150);
  }

  copyText(event: any, i: number) {
    this.clipboard.copy(event.content);
    let msg = {
      userResponse: event.userResponse,
      content: event.content,
      author: event.author,
      base64: event.base64,
      msgImg: event.msgImg,
      showCheck: true,
    };
    //this.messages[i] = msg;
  }

  save() {
    let instance_id = localStorage.getItem("instance_id");
    this.showDasboard = true;
    let rule = {
      instance_id: instance_id,
      prompt: this.loanRule,
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.formService.postOpenapiImage(rule).subscribe(
      (res) => {
        if (res.status == "success") {
          this.loanExpain = JSON.stringify(res.txt);
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  reset() {
    window.location.reload();
  }

  stopGeneratorPrompt() {
    let data = {
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.formService.stopGenerator(data).subscribe(
      (res) => {
        if (res.status == "success") {
          // this.loanExpain = JSON.stringify(res.txt);
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
    // Call service one more time to make it work
    setTimeout(() => {
      this.formService.stopGenerator(data).subscribe(
        (res) => {
          if (res.status == "success") {
            // this.loanExpain = JSON.stringify(res.txt);
          } else {
            alert(res.message);
          }
        },
        (error) => {
          alert(error.error.message);
        }
      );
    }, 100);
  }
}
