import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-code-generation',
  templateUrl: './code-generation.component.html',
  styleUrls: ['./code-generation.component.scss']
})
export class CodeGenerationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  } 

}
