import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./module/home/home.component";
import { HomeModule } from "./module/home/home.module";
import { LoginModule } from "./module/login/login.module";
import { IdpresponseModule } from "./module/idpresponse/idpresponse.module";
import { DashboardModule } from "./module/dashboard/dashboard.module";
import { SowGenerationComponent } from "./module/sow-generation/sow-generation.component";
import { SowGenerationModule } from "./module/sow-generation/sow-generation.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DatePipe } from "@angular/common";
import { ConvoBiHomeModule } from "./module/convo-bi-home/convo-bi-home.module";
import { ConvoBiHomeComponent } from "./module/convo-bi-home/convo-bi-home.component";
import { LoaderModule } from "./module/loader/loader.module";
import { ChatLoaderModule } from "./module/chat-loader/chat-loader.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "./module/snackbar/snackbar.component";
import { AdminSettingsComponent } from "./module/admin-settings/admin-settings.component";
import { AdminSettingsModule } from "./module/admin-settings/admin-settings.module";
import { ReqGenerationModule } from "./module/req-generation/req-generation.module";
import { ReqGenerationComponent } from "./module/req-generation/req-generation.component";
import { CodeConversionComponent } from "./module/code-conversion/code-conversion.component";
import { CodeConversionModule } from "./module/code-conversion/code-conversion.module";
import { NaturalQueryEngineModule } from "./module/natural-query-engine/natural-query-engine.module";
import { NaturalQueryEngineComponent } from "./module/natural-query-engine/natural-query-engine.component";
import { PdfComparisionComponent } from "./module/pdf-comparision/pdf-comparision.component";
import { PdfComparisionModule } from "./module/pdf-comparision/pdf-comparision.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CodeGenerationModule } from "./module/code-generation/code-generation.module";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SowGenerationComponent,
    ConvoBiHomeComponent,
    CustomSnackbarComponent,
    AdminSettingsComponent,
    ReqGenerationComponent,
    CodeConversionComponent,
    NaturalQueryEngineComponent,
    PdfComparisionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    LoginModule,
    IdpresponseModule,
    ConvoBiHomeModule,
    DashboardModule,
    HomeModule,
    SowGenerationModule,
    ReqGenerationModule,
    BrowserAnimationsModule,
    LoaderModule,
    ChatLoaderModule,
    MatSnackBarModule,
    AdminSettingsModule,
    CodeConversionModule,
    NaturalQueryEngineModule,
    PdfComparisionModule,
    CodeGenerationModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
