import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-text-to-image-dialog',
  templateUrl: './text-to-image-dialog.component.html',
  styleUrls: ['./text-to-image-dialog.component.scss']
})
export class TextToImageDialogComponent implements OnInit {

  modalContent: string;
  constructor(
    public dialogRef: MatDialogRef<TextToImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data.content;
  }


  ngOnInit(): void {
  }

  CancelClick() {
    this.dialogRef.close("closed")
  }
       
}
