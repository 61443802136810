<div>
    <div class="modal-body">
        <!-- <h4><strong style="font-size: 18px; margin-bottom: 5px;"> **WARNING**</strong></h4>
        <h6>DO NOT USE WITH CLIENT OR CONFIDENTIAL DATA.</h6> -->
        <p>{{ modalContent }}</p>
    </div>

    <div class="modal-footer">
        <div>
            <button class="btn btn-primary" (click)="CancelClick()">
                OK
            </button>

        </div>
    </div>
</div>