import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConversationBiComponent } from './conversation-bi.component';
import { FormDataComponent } from '../form-data/form-data.component';

const routes: Routes = [
  {
    path:'',
    component:ConversationBiComponent,
    children: [
      { path: '', redirectTo: 'form-data', pathMatch: 'full' },
      { 
        path: 'form-data',
        component:FormDataComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConversationBiRoutingModule { }
