import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-natural-query-engine",
  templateUrl: "./natural-query-engine.component.html",
  styleUrls: ["./natural-query-engine.component.scss"],
})
export class NaturalQueryEngineComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
