<div class="modal-wrapper">
    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title class="hd-txt">Delete Confirmation</h1>
            <mat-icon class="cancel-btn" (click)="onCancelClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <!-- to save the persona information -->
    <div mat-dialog-content class="contain" [innerHTML]="modalContent">
    </div>
    <!-- to save and close button -->
    <div mat-dialog-actions>
        <button class="btn btn-secondary-outline" (click)="onCancelClick()">Close</button>
        <button class="btn btn-primary" (click)="onSubmitClick()" cdkFocusInitial>{{actionBtnVal}}</button>
    </div>
</div>