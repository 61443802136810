import { Component, Inject, ElementRef, ViewChild  } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NaturalQueryEngineService } from "src/app/module/service/natural-query-engine.service";
import { AbstractControl, ValidatorFn } from "@angular/forms";

export function documentNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 24;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[A-Za-z0-9_ -]*$/.test(value)) {
      return { invalidCharacters: true };
    }

    return null;
  };
}
// export class FileValidator implements Validator {
//   static required: any;
//   static validate(c: FormControl): {[key: string]: any} {
//      this.required = c.value == null ||c.value.length == 0 ? {required : true} : null;
//      console.log(this.required)
//       return this.required
//   }

//   validate(c: FormControl): {[key: string]: any} | null {
//       return FileValidator.validate(c);
//   }
// }

@Component({
  selector: "database.dialog",
  templateUrl: "database.dialog.html",
  styleUrls: ["database.dialog.scss"],
})
export class DatabaseDialog {

  inputLanguages!: string[];
  selectedLanguage!: string;
  isLoading:boolean = false
  isDatabaseConnectionEnabled:boolean = false
  contextForm!: FormGroup;
  formSubmitted: boolean = false;
  username: any;
  usernameDisplay: any;
  documentList: any = [];
  limiterror: any = { isError: false, errorMessage: "" };
  token: any;
  userEmail: any;
  
  constructor(
    public formBuilder: FormBuilder,
    public service: NaturalQueryEngineService,
    public dialogRef: MatDialogRef<DatabaseDialog>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.contextForm = this.formBuilder.group({
      connectionName: ["", Validators.required], // Add form control for Connection Name
      dbName: ["", Validators.required],
      dbHostServerUrl: ["", Validators.required],
      dbPort: ["", Validators.required],
      dbType: ["", Validators.required],
      dbUsername: ["", Validators.required],
      dbPassword: ["", Validators.required],
    });
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");

  }

  ngOnInit(): void {
    this.isLoading = true;
    let token = localStorage.getItem("token");
    let userEmail = localStorage.getItem("userEmail")
    let data = { "column_name": "sql_languages", "token": token, "userEmail": userEmail };
    this.service.getLanguages(data).subscribe(
      (res) => {
        this.isLoading = false;
        this.inputLanguages = res.Data;
        this.selectedLanguage = res.Data[0];
      },
      (err) => {
        this.isLoading = false;
        // this.loading = false;
        console.log(err);
      }
    );
  }

  onNoClick( action: any ): void {
    this.dialogRef.close( action );
  }

  isFormValid(): boolean {
    return this.contextForm.valid;
  }

  handleConnectClick() {
    this.isLoading = true;
    let dbType: string = this.contextForm.value.dbType;
    let data = {
      "db_name": this.contextForm.value.dbName,
      "db_user": this.contextForm.value.dbUsername,
      "db_password": this.contextForm.value.dbPassword,
      "db_host": this.contextForm.value.dbHostServerUrl,
      "db_port": this.contextForm.value.dbPort,
      "db_type": dbType.charAt(0).toUpperCase() + dbType.slice(1).toLowerCase(),
      "connection_name":this.contextForm.value.connectionName,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
      "user_command": "Connect"
    };
    this.service.textToSQLtext(data).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.status == "success") {
          this.isDatabaseConnectionEnabled = true
          this.dialog.open(GeneralDialog, {
            disableClose: true,
            data: res.message
          });
          this.disableAllFields();
        } else {
          this.dialog.open(GeneralDialog, {
            disableClose: true,
            data: res.message
          });
        }
      },
      (error) => {
        this.isLoading = false;
        this.dialog.open(GeneralDialog, {
          disableClose: true,
          data: "Invalid database selected for this connection"
        });
      }
    );

  }

  handleSaveClick() {
    this.enableAllFields();
    this.isLoading = true;
    let data = {
      // "db_name": this.contextForm.value.dbName,
      // "db_user": this.contextForm.value.dbUsername,
      // "db_password": this.contextForm.value.dbPassword,
      // "db_host": "dev3-genesys-postgresql-rds.cowisidbp2lg.us-east-1.rds.amazonaws.com",
      // "db_port": "5432",
      // "db_type": this.selectedLanguage.charAt(0).toUpperCase() + this.selectedLanguage.slice(1).toLowerCase(),
      // "connection_name":this.contextForm.value.connectionName,
      // token: localStorage.getItem("token"),
      // userEmail: this.userEmail,
      // "user_command": "Save",

      "db_name": this.contextForm.value.dbName,
      "db_user": this.contextForm.value.dbUsername,
      "db_password": this.contextForm.value.dbPassword,
      "db_host": this.contextForm.value.dbHostServerUrl,
      "db_port": this.contextForm.value.dbPort,
      "db_type": this.selectedLanguage.charAt(0).toUpperCase() + this.selectedLanguage.slice(1).toLowerCase(),
      "connection_name":this.contextForm.value.connectionName,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
      "user_command": "Save"

    };
    this.service.textToSQLtext(data).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.status == "success") {
          let childRef = this.dialog.open(GeneralDialog, {
            disableClose: true,
            data: res.message
          });
          childRef.afterClosed().subscribe((result) => {
            if( result === 'close_parent' ) {
              this.onNoClick( 'saved' );
            }
          });
        } else {
          console.log('unable to save db');
        }
      },
      (error) => {
        this.isLoading = false;
        this.disableAllFields();
        this.contextForm.controls['connectionName'].enable();
        this.dialog.open(GeneralDialog, {
          disableClose: true,
          data: "Connection name already exists. Please rename it."
        });
      }
    );

  }

  disableAllFields() {
    this.contextForm.controls['connectionName'].disable();
    this.contextForm.controls['dbName'].disable();
    this.contextForm.controls['dbHostServerUrl'].disable();
    this.contextForm.controls['dbPort'].disable();
    this.contextForm.controls['dbType'].disable();
    this.contextForm.controls['dbUsername'].disable();
    this.contextForm.controls['dbPassword'].disable();
  }

  enableAllFields() {
    this.contextForm.controls['connectionName'].enable();
    this.contextForm.controls['dbName'].enable();
    this.contextForm.controls['dbHostServerUrl'].enable();
    this.contextForm.controls['dbPort'].enable();
    this.contextForm.controls['dbType'].enable();
    this.contextForm.controls['dbUsername'].enable();
    this.contextForm.controls['dbPassword'].enable();
  }

}

@Component({
  selector: "database.dialog",
  templateUrl: "database.common-dialog.html",
  styleUrls: ["./database.dialog.scss"],
})
export class GeneralDialog {

  modalContent: string = '';

  constructor(
    public dialogRef: MatDialogRef<GeneralDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data;
  }

  close() {
    this.dialogRef.close('close_parent');
  }
}
