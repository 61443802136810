import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { PdfComparisionService } from "src/app/module/service/pdf-comparision-service.service";

@Component({
  selector: "app-pdf-comparision-form-data",
  templateUrl: "./pdf-comparision-form-data.component.html",
  styleUrls: ["./pdf-comparision-form-data.component.scss"],
})
export class PdfComparisionFormDataComponent implements OnInit {
  token: any;
  userEmail: any;

  constructor(public service: PdfComparisionService) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");
  }

  ngOnInit() {}

  reset() {
    window.location.reload();
  }
}
