import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
// import { FormServiceService } from 'src/app/module/service/form-service.service';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  modalContent: string;
 
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data.content;
  }


  ngOnInit(): void {
  }
 
  dialogClose() {
    this.dialogRef.close()   

  }
}