<div class="container">
    <div class="right-wrapper">

        <div class="main-wrapper">
            <div class="filter-box">
                <div class="title-bar">
                    <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
                        <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
                        <h3>Genysys</h3>
                    </div>
                    <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home"
                        (click)="dashboardRedirection()" title="Home" />
                </div>
                <div class="options-holder">
                    <h5>
                        <img src="../../../../assets/icons/generator.svg" alt="generator logo">
                        Generators
                    </h5>
                    <div class="menu-container">
                        <div class="menu-item-active">
                            <img class="menu-icon-active" loading="lazy"
                                src="./../../../assets/icons/requirement_granularity_active.svg" />
                            <div class="menu-name-active">Code Generator</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="action-wrapper">
                <div class="profiler">
                    <div class="avatar">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>
                    <p>{{usernameDisplay}}</p>
                </div>
                <div class="logout-cta" (click)="logout()">
                    <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="logout" />
                </div>
            </div>
        </div>
    </div>
</div>