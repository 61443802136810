import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReqFormDataComponent } from './req-assistance-form-data/req-form-data.component';
import { ReqGenerationPageComponent } from './req-generation-page.component';

const routes: Routes = [
  {
    path: '',
    component: ReqGenerationPageComponent,
    children: [
      {
        path: 'requirement-generator',
        component: ReqFormDataComponent,
      },
      {
        path: 'requirement-generator/:generatorId',
        component: ReqFormDataComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReqGenerationPageRoutingModule {}
