import { ReqQualifierServiceService } from "./../../service/req-qualifier-service.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { SowGenerationServiceService } from "../../service/sow-generation-service.service";
import { FormServiceService } from "../../service/form-service.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Location } from '@angular/common';
@Component({
  selector: "app-req-qui-gen-page",
  templateUrl: "./req-generation-page.component.html",
  styleUrls: ["./req-generation-page.component.scss"],
})
export class ReqGenerationPageComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  instance_id: any;

  /** nikita start */
  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  intervalId: any;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;
  sessionStatus: any;
  selectedQualifier: string = "idea";

  // private subscription: Subscription;

  constructor(
    public route: Router,
    public sowService: SowGenerationServiceService,
    public service: FormServiceService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService,
    public reqQualifierService: ReqQualifierServiceService,
    private location: Location,
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
    // this.selectedQualifier = this.reqQualifierService.getSelectedQualifier();

    // Checks every 5 second for session expiration
    this.intervalId = setInterval(() => {
      if (this.isLocalUser === "true") {
        // For Local User
        this.authService.checkAndRefreshSession();
      } else {
        // For Azure AD user
        this.authService.checkAndRefreshSessionForAWSCognitoAzure();
      }
    }, 5000);

    // Integrating a user pool with an identity pool
    // this.authService.integrateUserPoolWithIdentityPool();

    // this.subscription = this.reqQualifierService.methodCalled$.subscribe(
    //   (data) => {
    //     this.setQulifierName(data);
    //   }
    // );
  }

  ngOnInit() {
    this.selectedQualifier = this.reqQualifierService.getSelectedQualifier();
    // console.log('this.selectedQualifier = ', this.selectedQualifier);
    this.selectedQualifier = window.location.pathname
      .split("/")
      .filter((ele: any) => ele)[1];

    this.reqQualifierService.sidebarSyncer$.subscribe((nextSlug) => {
      this.selectedQualifier = nextSlug;
    });
  }
  setQulifierName(qualifierName: any) {
    this.selectedQualifier = qualifierName;
    this.reqQualifierService.setSelectedQualifier(qualifierName);
    this.reqQualifierService.callMethod(qualifierName);
    this.location.go(`/requirement-generator/${qualifierName}`);
    // this.route.navigate(["/requirement-generator/" + qName]);
  }
  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  logout() {
    this.authService.logout();
  }

  clearHistory() {
    this.reqQualifierService.clearHistory();
  }

}
