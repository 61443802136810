import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConvoBiHomeRoutingModule } from './convo-bi-home-routing.module';
import { ConversationBiModule } from './conversation-bi/conversation-bi.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConvoBiHomeRoutingModule,
    ConversationBiModule
  ]
})
export class ConvoBiHomeModule { }
