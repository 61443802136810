import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CodeConversionService } from 'src/app/module/service/code-conversion-service.sevrice';

@Component({
  selector: 'app-code-conversion-form-data',
  templateUrl: './code-conversion-form-data.component.html',
  styleUrls: ['./code-conversion-form-data.component.scss']
})
export class CodeConversionFormDataComponent implements OnInit {
  convertedCode!: string;
  convertedCodeFlag: boolean = false
  loading: boolean = false;
  selectedLanguage!: string;
  outputSelectedLanguage!: string;
  codeText: string = '';
  lineNumbers: number[] = [];
  inputLanguages!: string[];
  outputLanguages!: string[];

  @ViewChild('lineNumberRef') lineNumberRef!: ElementRef;
  @ViewChild('inputCodeRef') inputCodeRef!: ElementRef;
  @ViewChild('lineNumberRef1') lineNumberRef1!: ElementRef;
  @ViewChild('inputCodeRef1') inputCodeRef1!: ElementRef;
  placeholderValue: string = `/* Looks like the input you've given is empty \n or doesn't contain any code.*/`;
  showInputSizeLimitBanner: boolean = false;
  maxInputSize: number = 4000;
  currentElement: string = '';

  constructor(private http: HttpClient, private formBuilder: FormBuilder, public service: CodeConversionService) {}

  ngOnInit(): void {
    this.copyCode1();
    this.loading = true;
    let token = localStorage.getItem("token");
    let userEmail = localStorage.getItem("userEmail")
    let data = { "column_name": "programming_languages", "token":token, "userEmail":userEmail };
    this.service.getLanguages(data).subscribe(
      (res) => {
        this.loading = false
        this.inputLanguages = res.Data;
        this.outputLanguages = res.Data;
        this.selectedLanguage = res.Data[0];
        this.outputSelectedLanguage = res.Data[0];
      },
      (err) => {
        this.loading = false
        console.log(err);
      }
    );
  }

  updateLineNumbers() {
    const codeTextArea = document.getElementById('codeText') as HTMLTextAreaElement;
    const lineNumbers = document.getElementById('lineNumbers');

    if (codeTextArea && lineNumbers) {
      const lines = codeTextArea.value.split('\n');
      lineNumbers.innerHTML = '';
      for (let i = 1; i <= lines.length; i++) {
        lineNumbers.innerHTML += i + '<br>';
      }
    }
  }

  updateLineNumbersForOutput() {
    const linesCount = this.convertedCode.split('\n').length;
    this.lineNumbers = Array.from({ length: linesCount }, (_, index) => index + 1);
  }

  copyCode() {
    const codeTextArea = document.getElementById('convertedCode') as HTMLTextAreaElement;
    codeTextArea.select(); // Select the text inside the textarea
    document.execCommand('copy'); // Copy the selected text to the clipboard
  }

  clearCode() {
    this.codeText = "";
    this.convertedCode = "";
    this.convertedCodeFlag = false
    this.placeholderValue = `/* Looks like the input you've given is empty \n or doesn't contain any code.*/`;
    // this.copyCode1();
  }

  copyCode1() {
    const codeTextArea = document.getElementById('codeText') as HTMLTextAreaElement;
    codeTextArea.select(); // Select the text inside the textarea
    document.execCommand('copy'); // Copy the selected text to the clipboard
  }

  downloadCode() {
    const filename = 'converted_code.txt';
    const blob = new Blob([this.convertedCode], { type: 'text/plain' });

    // Create a link element
    const link = document.createElement('a');
    link.download = filename;
    link.href = window.URL.createObjectURL(blob);

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  checkInputSizeLimit() {
    if (this.codeText.length > this.maxInputSize) {
      this.showInputSizeLimitBanner = true;
      // Truncate codeText to maxInputSize characters
      this.codeText = this.codeText.substring(0, this.maxInputSize);
    } else {
      this.showInputSizeLimitBanner = false;
    }
  }

  closeInputSizeLimitBanner() {
    this.showInputSizeLimitBanner = false;
  }

  convertCode() {
    this.convertedCode = "";
    this.convertedCodeFlag = false
    this.placeholderValue = `/* Code is converting into ${this.outputSelectedLanguage} Please wait... */`
    let token = localStorage.getItem("token");
    let userEmail = localStorage.getItem("userEmail")
    this.loading = true;
    const payload = {
      "from_code": this.selectedLanguage,
      "to_code": this.outputSelectedLanguage,
      "code": this.codeText,
      // "instance_id": "0202045235",
      "userEmail": userEmail,
      "token": token
    }
    this.service.codeConvert(payload)
      .subscribe(
        (response) => {
          if (response.status === 'Success') {
            let message = response.message;
            if (/\s/.test(message[0])) {
              // If the first character is whitespace, remove leading and trailing whitespace from the first line
              let lines = message.split('\n');
              lines[0] = lines[0].trim();
              message = lines.join('\n');
          } else {
              // If the first character is not whitespace, do something else
              // For example, remove only trailing whitespace from the first line
              let lines = message.split('\n');
              let firstLine = lines[0].replace(/\s+$/, ''); // Remove trailing whitespace
              lines[0] = firstLine;
              message = lines.join('\n');
          }
            this.convertedCode = message;
            this.updateLineNumbersForOutput()
            this.convertedCodeFlag = true
          } else {
            this.convertedCode = ""
            this.placeholderValue = `/*Something went wrong. Please try again.*/`;
            this.convertedCodeFlag = false
          }
          this.loading = false;
        },
        (error) => {
          this.placeholderValue = `/* ${error.error.detail} */`;
          this.loading = false;
          this.convertedCodeFlag = false
        }
      );
  }

  updateCurrentElement( element: 'inputCodeRef' | 'lineNumberRef' | 'inputCodeRef1' | 'lineNumberRef1' ) {
    this.currentElement = element;
  }

  scrollSynchronization( event: any ) {
    if (this.currentElement === 'inputCodeRef') {
      this.lineNumberRef.nativeElement.scrollTop = event.target.scrollTop;
    } else if (this.currentElement === 'lineNumberRef') {
      this.inputCodeRef.nativeElement.scrollTop = event.target.scrollTop;
    }

    if (this.currentElement === 'inputCodeRef1') {
      this.lineNumberRef1.nativeElement.scrollTop = event.target.scrollTop;
    } else if (this.currentElement === 'lineNumberRef1') {
      this.inputCodeRef1.nativeElement.scrollTop = event.target.scrollTop;
    }
  }

}