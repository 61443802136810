import { Directive, ElementRef, AfterViewInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import * as Prism from 'prismjs';

@Directive({
  selector: '[appPrismHighlight]'
})
export class PrismHighlightDirective implements AfterViewInit, OnChanges {
  @Input('appPrismHighlight') code!: string;
  @Input() language!: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.highlightCode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.code) {
      this.highlightCode();
    }
  }

  private highlightCode() {
    if (this.code) {
      const encodedCode = this.escapeHtml(this.code);
      this.el.nativeElement.innerHTML = `<pre><code class="language-${this.language}">${this.code}</code>${encodedCode}</pre>`;
      Prism.highlightElement(this.el.nativeElement.querySelector('code'));
    }
  }
  private escapeHtml(unsafe: string): string {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
}



