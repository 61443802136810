import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

@Injectable({
  providedIn: "root",
})
export class CostService {
  public baseapiUrl: string = environment.baseapiurl + "api/";

  showLoader: boolean = false;
  sortGroupFlag: Record<string, string> = {
    group: "ASC",
    cost: "",
  };
  groupSet: any = [];
  groupSetPerPage: any;
  group: any = "";
  user: any = "";
  groupTotalCost: any = "";
  userTotalCost: any = "";
  /** For group list pagination */
  pages: any;
  limit: any;
  Arr = Array;
  currentPage: number = 1;
  paginationRange: number[] = [];
  rowCount: any;

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  getGroupCostInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "group_price", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUsersByGroup(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "user_price_sum", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getCostDetailsOfUsers(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "user_price_data", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
