import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReqQualifierServiceService } from 'src/app/module/service/req-qualifier-service.service';
import { Router } from "@angular/router"

export class Message {
  constructor() {}
}
@Component({
  selector: 'app-req-qualifier-table',
  templateUrl: './req-qualifier-table.component.html',
  styleUrls: ['./req-qualifier-table.component.scss'],
})
export class ReqQualifierTableComponent implements OnInit {
  lines: any;
  resizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  }
  @ViewChild('myIframe') myIframe!: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer: any;
  $: any;
  @Input() tableData: any;
  @Input() cbGetData: any;
  @Input() loaderState: any;

  sessionId: any;
  showResultOPtions: any = [
    { key: 10, text: 'Show 10 Results' },
    { key: 20, text: 'Show 20 Results' },
    { key: 30, text: 'Show 30 Results' },
    { key: 50, text: 'Show 50 Results' },
  ];
  titles: any = [
    {
      key: 'idea',
      text: 'Feature',
    },
    {
      key: 'feature',
      text: 'Story',
    },
    {
      key: 'story',
      text: 'Acceptance Criteria',
    },
  ];
  constructor(public reqQualifierService: ReqQualifierServiceService, private router: Router) {}

  ngOnInit() {}

  copyToClipboard(el: HTMLSpanElement, doRedirect = false) {
    if (navigator.clipboard) {

      /** remove bullet points from generated ideas */
      let currentCbText = this.trimResponse( el.innerText );
      navigator.clipboard.writeText( currentCbText ).then(
        () => {
          if (doRedirect) {
            localStorage.setItem('selectedIdea', currentCbText);
            this.reqQualifierService.setSelectedGeneratorData(currentCbText);
            // this.router.navigate([`/requirement-generator/${this.getTitle(this.reqQualifierService.selectedQualifier)}`])
            this.router.navigate([`/requirement-generator/feature`])
            // set current data to null
            this.tableData = null;

            // show loader
            this.loaderState = true;

            this.reqQualifierService.setSelectedQualifier( this.getTitle(this.reqQualifierService.selectedQualifier) );
            // call data generation method
            this.cbGetData()
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log('Browser do not support Clipboard API');
    }
  }

  getTitle(routeName: string) {
    const matchedRoute = this.titles.find(
      (title: any) => title.key === routeName
    );
    return matchedRoute?.text;
  }

  onLimitChange( e: any ) {
    let limit = e.target.value;
    this.cbGetData( limit );
  }

  trimResponse( data: any ) {
    return data.split('. ')[1]
  }
}
