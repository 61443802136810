<section id="hero">
    <div class="content">
        <div class="chat-window">

            <div style="float: left;" (click)="navigateBack()">
                <img src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" class="dashboard-back-icon">
            </div>
            <h1>Feedback</h1>
            <div class="message info">

                <div class="top-filter">
                    <select id="download" (change)="selectDownloadType($event)">
                        <option value="">Download</option>
                        <option value="csv">CSV</option>
                        <option value="excel">Excel</option>
                    </select>
                </div>
                <div class="feedback-table-wrapper">
                    <table class="gen-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Model Type</th>
                                <th>Model Name</th>
                                <th>Feedback</th>
                                <th>Feedback Comment</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="feedbacks !== null">
                            <tr *ngFor="let feedback of feedbacks">
                                <td>{{ changeDateFormat( feedback.date ) }}</td>
                                <td>{{ feedback.user_email }}</td>
                                <td>{{ feedback.question }}</td>
                                <td>
                                    <a
                                        href="javascript:void(0);"
                                        class="expandResponse"
                                        (click)="showAnswerInModal(feedback.answer)"
                                        title="Click to open"
                                        *ngIf="feedback.answer && feedback.answer.length > 60"
                                    >
                                        {{ trimFeedback( feedback.answer ) }}
                                    </a>
                                    <span *ngIf="feedback.answer && feedback.answer.length <= 60">
                                        {{ feedback.answer }}
                                    </span>
                                </td>
                                <!-- <td>{{ feedback.answer }}</td> -->
                                <td style="text-transform: capitalize;">{{ feedback.type_model }}</td>
                                <td style="text-transform: capitalize;">{{ feedback.model_name }}</td>
                                <td style="text-transform: capitalize;">{{ feedback.feedback }}</td>
                                <td>{{ feedback.feedback_comment }}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="feedbacks === null">
                            <tr>
                                <td colspan="8" style="padding-left: 0;">
                                    <div class="alert alert-danger">
                                        No feedback is available for now
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div role="status" *ngIf="showLoader">
                        <app-loader></app-loader>
                    </div>
                </div>

                <!-- <div class="pagination" *ngIf="feedbackCount > 10">
                    <a>&laquo;</a>
                    <a (click)="getPageData(i+1)" *ngFor="let page of Arr(pages).fill(1); let i = index">
                        {{ i+1 }}
                    </a>
                    <a>&raquo;</a>
                </div> -->

                <!-- <div class="pagination" *ngIf="feedbackCount > 10">
                    <a (click)="currentPage !== 1 && getPageData(1)">&laquo;</a>
                    <a *ngFor="let page of paginationRange" [class.active]="page === currentPage"
                        (click)="getPageData(page)">
                        {{ page }}
                    </a>
                    <a (click)="currentPage !== pages && getPageData(pages)">&raquo;</a>
                </div> -->

                <div class="pagination" *ngIf="feedbackCount > limit">
                    <a (click)="currentPage !== 1 && getPageData(1)">&laquo; First</a>
                    <a (click)="currentPage > 1 && getPageData(currentPage - 1)">&lsaquo; Previous</a>
                    <a *ngFor="let page of paginationRange" [class.active]="page === currentPage"
                        (click)="getPageData(page)">
                        {{ page }}
                    </a>
                    <a (click)="currentPage < pages && getPageData(currentPage + 1)">Next &rsaquo;</a>
                    <a (click)="currentPage !== pages && getPageData(pages)">Last &raquo;</a>
                </div>
            </div>

        </div>
    </div>
</section>