import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-admin-settings",
  templateUrl: "./admin-settings.component.html",
  styleUrls: ["./admin-settings.component.scss"],
})
export class AdminSettingsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
