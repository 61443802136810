import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CodeConversionRoutingModule } from './code-conversion-routing.module';
import { CodeConversionPageModule } from './code-conversion-page/code-conversion-page.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CodeConversionRoutingModule,
    CodeConversionPageModule
  ]
})
export class CodeConversionModule { }
