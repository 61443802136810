<section id="hero">
    <div class="content">
        <div class="chat-window">

            <div style="float: left;" (click)="navigateBack()">
                <img src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" class="dashboard-back-icon">
            </div>
            <h1>Access List</h1>
            <div class="message info">

                <div class="top-filter">
                    <select id="download" (change)="selectDownloadType($event)">
                        <option value="">Download</option>
                        <option value="csv">CSV</option>
                        <option value="excel">Excel</option>
                    </select>
                </div>
                <div class="group-table-wrapper">
                    <table class="gen-table">
                        <thead>
                            <tr>
                                <th>Group</th>
                                <th>Member</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="groupList !== null">
                            <tr *ngFor="let group of groupListPerPage">
                                <td>{{ group.Group_Name }}</td>
                                <td>{{ group.Members }}</td>
                                <td>
                                    <img class="icon" (click)="addNewGroupForRoleAccess('edit', group)"
                                        src="../../../../assets/icons/edit-icon.svg" alt="Edit" title="Edit" />
                                    <img class="icon" (click)="deleteGroupForRoleAccess(group)"
                                        src="../../../../assets/icons/cross-icon.svg" alt="Delete" title="Delete" />
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="groupList === null">
                            <tr>
                                <td colspan="3" style="padding-left: 0;">
                                    <div class="alert alert-danger">
                                        No group is available for now
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div role="status" *ngIf="showLoader">
                        <app-loader></app-loader>
                    </div>
                </div>

                <div>
                    <div style="float: left;">
                        <button mat-button class="btn btn-primary" (click)="addNewGroupForRoleAccess('add')">
                            Add New
                        </button>
                    </div>
                    <div class="pagination" *ngIf="groupCount > limit" style="float: right;">
                        <a (click)="currentPage !== 1 && getPageData(1)">&laquo; First</a>
                        <a (click)="currentPage > 1 && getPageData(currentPage - 1)">&lsaquo; Previous</a>
                        <a *ngFor="let page of paginationRange" [class.active]="page === currentPage"
                            (click)="getPageData(page)">
                            {{ page }}
                        </a>
                        <a (click)="currentPage < pages && getPageData(currentPage + 1)">Next &rsaquo;</a>
                        <a (click)="currentPage !== pages && getPageData(pages)">Last &raquo;</a>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>