import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

@Injectable({
  providedIn: "root",
})
export class ReqQualifierServiceService {
  public baseapiUrl: string = environment.baseapiurl + "api/";
  public selectedQualifier: string = "idea";
  public selectedGeneratorData: string = "";
  private newChatInfo = new Subject<any>();
  private methodCaller = new Subject<any>();
  private resetHistory = new Subject<any>();
  private sidebarSyncer = new Subject<any>();

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  methodCalled$ = this.methodCaller.asObservable();
  callMethod(data = "") {
    this.methodCaller.next(data);
  }

  resetHistory$ = this.resetHistory.asObservable();
  clearHistory() {
    this.resetHistory.next();
  }

  sidebarSyncer$ = this.sidebarSyncer.asObservable();
  syncSideBar(slug: string) {
    this.sidebarSyncer.next(slug);
  }

  setSelectedQualifier(qualifierName: string): void {
    this.selectedQualifier = qualifierName;
  }

  setSelectedGeneratorData(qualifierData: string): void {
    this.selectedGeneratorData = qualifierData;
  }

  getSelectedQualifier(): string {
    return this.selectedQualifier;
  }

  getSelectedGeneratorData(): string {
    return this.selectedGeneratorData;
  }

  postRequirementGeneratorInfo(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "generator", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
