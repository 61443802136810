<mat-sidenav-container class="side-nav-main-container">
  <mat-sidenav #sidenav="matSidenav" class="right-wrapper" disableClose>

    <div class="main-wrapper">
      <div class="filter-box">

        <div class="title-bar">
          <div class="logo-wrapper addCursor" (click)="dashboardRedirection()">
            <img src="../../../../assets/new-logo/new-logo.png" alt="logo" />
            <h3>Genysys</h3>
          </div>
          <img class="home-icon" src="../../../../assets/icons/home-icon.svg" alt="Home"
            (click)="dashboardRedirection()" title="Home" />
        </div>

        <div class="form-wrapper nqe">

          <div class="input-wrapper mb-3">
            <label for="inp2">Type</label>
            <mat-form-field>
              <mat-select
                id="inp2"
                [(ngModel)]="selectedType"
                [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag"
                (selectionChange)='onNqlTypeChange($event)'
              >
                <mat-option value="open_ai">Open AI</mat-option>
                <mat-option value="google">Google</mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <div class="input-wrapper mb-3">
            <label>Model Name</label>
            <input
              type="text"
              class="mat-form-field"
              style="height:'50px'; border:'2px solid #e9f0ff'; border-radius:'8px'; padding:'0 8px'; font-size:'14px';"
              readonly
              [(ngModel)]="selectedModel"
            />
          </div>

          <!-- <div class="input-wrapper mb-3">
            <label for="inp3">Model Name</label>
            <mat-form-field>
              <mat-select
                id="inp3"
                [(ngModel)]="selectedModel" 
                [disabled]="service.showChatLoader || service.alive || service.stopGeneratorFlag"
              >
                <mat-option value="gpt-3.5-turbo">gpt-3.5-turbo</mat-option>
                <mat-option value="gemini">gemini</mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->
 
          <div class="input-wrapper mb-3">
            <label for="inp1" class="label-group addCursor">
              <span>Database Connection</span>
              <img src="../../../../assets/icons/add-icon.svg" class="cont-icon" title="Add Database Connection" alt=""
                (click)="openDatabaseConnection()">
            </label>

            <mat-form-field>
              <mat-select
                id="inp5"
                [(ngModel)]="selectedDatabaseConnection"
                name="selectedDatabaseConnection"
                (selectionChange)='disableInputOnChange()'
                required
              >
                <mat-option value="None">None</mat-option>
                <mat-option *ngFor="let item of databaseDataList" value="{{item.id}}">
                  {{ item.connection_name }}
                  <button (click)="deleteContext(item)" style="float: right; background: none;">
                    <img class="cross-icon" src="../../../assets/icons/cross-icon.svg" alt="Delete" title="Delete" />
                  </button>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="text-danger">
              <span class="validation-error" *ngIf="formSelected && selectedDatabaseConnection === 'None'">
                Database Connection is required
              </span>
            </div>


          </div>

          <div class="input-wrapper">
            <button class="btn btn-primary btn-block" (click)="newChat()" title="New chat">
              <img class="new_cht_pls" src="../../../../assets/img/Plus.svg" alt="">
              <span class="new_cht_txt">New Chat</span>
            </button>
          </div>

        </div>

        <div class="action-wrapper">
          <div class="profiler">
            <div class="avatar">
              <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
            </div>
            <p>{{usernameDisplay}}</p>
          </div>
          <div class="logout-cta" (click)="logout()">
            <img src="../../../../assets/icons/logout-icon.svg" alt="logout" title="Logout" />
          </div>
        </div>

      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <div class="content mat-elevation-z8">
      <!-- route to form data componenet -->
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>