<!-- <div *ngIf="!service.isDelete && !showLoader && !showText"> -->
<div class="header-dialog">
    <span class="head-text">
        <h1 mat-dialog-title class="hd-txt">Add&nbsp;Document</h1>
        <mat-icon class="cancel-btn" (click)="onNoClick()" title="Close" title="Close">cancel</mat-icon>
    </span>
</div>
<!-- to save the persona information -->
<div mat-dialog-content class="contain">
    <form [formGroup]="PersonaForm">
        <div class="row">
            <!-- type  -->
            <div class="col-6">
                <label>Type</label>
                <input type="text" *ngIf="selectedPersona!=''" readonly class="form-control" value="{{selectedPersona}}"
                    style="cursor: no-drop;">
            </div>
            <!-- model -->
            <div class="col-6">
                <label>Model</label>
                <input type="text" *ngIf="data.modal!=undefined" readonly class="form-control" value="{{data.modal}}"
                    style="cursor: no-drop;" />
            </div>
        </div>
        <div class="row row-container">
            <!-- industry -->
            <div class="col-6">
                <label>Folder</label>
                <!-- <input
                        type="text"
                        *ngIf="data.industry!=undefined"
                        readonly
                        class="form-control"
                        value="{{data.industry}}"
                    > -->
                <select id="inp4" class="form-control addCursor" style="cursor: pointer;" formControlName="industry_id"
                    (change)='onIndustryChange($event)'>
                    <option value=''>None</option>
                    <option *ngFor="let item of industryInfo" value="{{item.industry_id}}">
                        {{item.industry}}
                    </option>
                </select>
                <div class="text-danger">
                    <span class="validation-error"
                        *ngIf="(formSubmitted || PersonaForm.controls.industry_id.touched) && PersonaForm.controls.industry_id.errors?.required">
                        Please select a folder
                    </span>
                </div>
            </div>
            <!-- persona name -->
            <div class="col-6">
                <label for="basic-url">
                    Document Name
                    <span class="text-danger">*</span>
                </label>
                <div class="input-group">
                    <input type="text" class="form-control rd-colr" formControlName="persona_name" id="basic-url"
                        aria-describedby="basic-addon3" (keydown)='removeExistingMessage()'>
                </div>
                <div class="text-danger">
                    <span class="validation-error"
                        *ngIf="(formSubmitted || PersonaForm.controls.persona_name.touched) && PersonaForm.controls.persona_name.errors?.required">
                        Document name is required
                    </span>
                    <span class="validation-error"
                        *ngIf="PersonaForm.controls.persona_name.dirty && PersonaForm.controls.persona_name.errors?.maxLengthExceeded">
                        Document name cannot exceed 24 characters
                    </span>
                    <span class="validation-error"
                        *ngIf="(formSubmitted || PersonaForm.controls.persona_name.dirty) && PersonaForm.controls.persona_name.errors?.invalidCharacters">
                        Only alpha-numeric characters are allowed.
                    </span>
                    <span class="validation-error"
                        *ngIf="(formSubmitted || PersonaForm.controls.persona_name.dirty) && PersonaForm.controls.persona_name.errors?.invalidName">
                        Industry name starts with alphabet only
                    </span>
                    <span class="validation-error" *ngIf="documentNameExist">
                        Document name already exists
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- select the local or cloud -->
            <!-- <div class="col-6 pd">
                    <div class="form-check form-check-inline">
                        <label class="form-check-label" for="inlineRadio1" style="cursor: pointer;">
                            <input class="form-check-input rd-colr" (click)="getFileUpload()" formControlName="file_ty"
                                type="radio" id="inlineRadio1" value="option1">
                            Local
                        </label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input rd-colr" (click)="getFilePath()" formControlName="file_ty"
                            type="radio" id="inlineRadio2" value="option2">
                        <label class="form-check-label" for="inlineRadio2">Cloud</label>
                    </div>
                </div> -->
            <!-- file upload -->
            <div class="col-md-12">
                <label class="form-label" for="customFile">
                    File Upload
                    <span class="text-danger">*</span>
                </label>
                <div class="ft-drop rd-colr text-center file-uploader">
                    <a href="javascript:void(0)" (click)="file.click()">
                        Click to open
                    </a>
                    <input
                        type="file"
                        #file
                        [multiple]="true"
                        accept=".doc,.docx,.pdf"
                        (change)="onFileChange($event)"
                        style="display:none"
                        formControlName='file_document'
                    />
                    <div *ngIf="uploadFile">
                        <div *ngFor="let item of listFiles;let i=index">
                            {{item.name}}
                            <span>
                                <img
                                    class="remove-attachment pointer"
                                    (click)="removeFileAttachment(i)"
                                    src="../../../assets/icons/cross-icon.svg" alt="Remove"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="text-danger">
                    <span class="validation-error" *ngIf="fileRequiredMsg">
                        File is required
                    </span>
                    <span class="validation-error" *ngIf="fileTypeMsg">
                        File Upload allows DOC, DOCX & PDF files only
                    </span>
                </div>
            </div>
            <!-- file path -->
            <div class="col-6" *ngIf="showPath">
                <label for="basic-url">File Path</label>
                <div class="input-group">
                    <input type="text" class="form-control rd-colr" formControlName="file_path" id="basic-url"
                        aria-describedby="basic-addon3">
                </div>
            </div>
        </div>
        <div class="row">
            <!-- username -->
            <div class="col-6" *ngIf="showPath">
                <label for="basic-url">User Name</label>
                <div class="input-group">
                    <input type="text" class="form-control rd-colr" formControlName="user_name" id="basic-url"
                        aria-describedby="basic-addon3">
                </div>
            </div>
            <!-- password -->
            <div class="col-6" *ngIf="showPath">
                <label for="basic-url">Password</label>
                <div class="input-group">
                    <input type="text" class="form-control rd-colr" formControlName="password" id="basic-url"
                        aria-describedby="basic-addon3">
                </div>
            </div>
        </div>
        <div class="row">
            <!-- description -->
            <div class="col-md-12">
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                    <textarea class="form-control rd-colr" formControlName="description"
                        id="exampleFormControlTextarea1" rows="1"></textarea>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- to save and close button -->
<div mat-dialog-actions>
    <button class="btn btn-secondary-outline" (click)="onNoClick()">Close</button>
    <button class="btn btn-primary" (click)="save()">Save</button>
</div>
<!-- </div> -->
<!-- <div class="spinner-grow text-dark" role="status" *ngIf="isLoading">
    <span class="sr-only">Loading...</span>
  </div> -->
<div role="status" *ngIf="isLoading">
    <app-loader></app-loader>
</div>
<!-- delete information -->
<div *ngIf="service.isDelete && !showLoader && !showText">
    <div class="header-dialog">
        <span class="head-text">
            <h1 mat-dialog-title class="hd-txt">Delete</h1>
            <mat-icon (click)="onNoClick()" title="Close">cancel</mat-icon>
        </span>
    </div>
    <div mat-dialog-content class="contain text-align"><br>
        <p class="mt">you want to delete '{{data}}'?</p>
    </div>
    <div mat-dialog-actions>
        <button mat-button class="btn btn-primary" (click)="onNoClick()">Close</button>
        <button mat-button class="btn btn-primary" (click)="delete()" cdkFocusInitial>ok</button>
    </div>
</div>
<!-- <div *ngIf="showLoader && !showText" class="spin-circle">
    <mat-spinner></mat-spinner>
</div>
<div *ngIf="showText">
    <p class="text-per">{{newText}}</p>
    <div mat-dialog-actions class="btn-align">
        <button mat-button class="btn btn-primary" (click)="onNoClick()">Cancel</button>
        <button mat-button class="btn btn-primary" (click)="ok()" cdkFocusInitial>ok</button>
    </div>
</div> -->