import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators, NgForm } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { SowGenerationServiceService } from "src/app/module/service/sow-generation-service.service";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "src/app/module/snackbar/snackbar.component";

export function projectNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 40;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[A-Za-z0-9_ -]*$/.test(value)) {
      return { invalidCharacters: true };
    }

    return null;
  };
}

export function clientNameValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const maxLength = 40;

    if (value && value.length > maxLength) {
      return { maxLengthExceeded: true };
    }

    if (value && !/^[A-Za-z0-9_ -]*$/.test(value)) {
      return { invalidCharacters: true };
    }

    return null;
  };
}

@Component({
  selector: "project-detail.dialog",
  templateUrl: "project-detail.dialog.html",
  styleUrls: ["project-detail.dialog.scss"],
})
export class ProjectDetailDialog {
  //sow project details
  clientName: string = "";
  projectName: string = "";
  totalSOWAmount: string = "";
  projectManager: string = "";
  contractorRepresentatives: string = "";
  effective_date: Date | null = null;
  name: string = "";
  dates: string = "";
  agreement_date: Date | null = null;
  effective_data: string = "";
  review_date: Date | null = null;
  startDate: Date | null = null;
  formattedStartDate: string = "";
  endDate: Date | null = null;
  formattedEndDate: string = "";
  date: Date | null = null;
  formattedDate: string = "";
  formattedAgreementDate: string = "";
  formattedEffectiveDate: string = "";
  formattedReviewDate: string = "";
  token: any;
  userEmail: any;

  //old code
  showPath: boolean = false;
  showUpload: boolean = false;
  PersonaForm!: FormGroup;
  projectDetailForm!: FormGroup;
  uploadFile: File[] = [];
  listFiles: any = [];
  formSubmitted: boolean = false;
  showLoader: boolean = false;
  showText: boolean = false;
  username: any;
  projectDetails: any;
  isLoading: boolean = false;
  constructor(
    public formBuilder: FormBuilder,
    public service: SowGenerationServiceService,
    public dialogRef: MatDialogRef<ProjectDetailDialog>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.PersonaForm = this.formBuilder.group({
      persona_name: ["", Validators.required],
      file_ty: [""],
      file_path: [""],
      user_name: [""],
      password: [""],
      description: [""],
    });
    this.projectDetailForm = this.formBuilder.group({
      projectName: [
        "",
        [
          Validators.required,
          Validators.pattern("[A-Za-z0-9_ -]*"),
          Validators.maxLength(40),
          projectNameValidator(),
        ],
      ],
      clientName: [
        "",
        [
          Validators.required,
          Validators.pattern("[A-Za-z0-9_ -]*"),
          Validators.maxLength(40),
          clientNameValidator(),
        ],
      ],
      effective_date: ["", Validators.required],
      review_date: ["", Validators.required],
      agreement_date: ["", Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
    });
    if (this.service.isProjectDescriptionView === true) {
      this.projectDetails = this.service.getProjectDetails();
      this.projectDetailForm = this.formBuilder.group({
        projectName: [this.projectDetails.project_name, Validators.required],
        clientName: [this.projectDetails.client_name, Validators.required],
        effective_date: [
          this.projectDetails.effective_date,
          Validators.required,
        ],
        review_date: [this.projectDetails.review_date, Validators.required],
        agreement_date: [
          this.projectDetails.agreement_date,
          Validators.required,
        ],
        startDate: [this.projectDetails.start_date, Validators.required],
        endDate: [this.projectDetails.end_date, Validators.required],
      });
    }
  }

  showCustomSnackbar(message: string) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message },
      duration: 10000, // Set the duration for which the snackbar will be displayed
      verticalPosition: "top", // You can adjust the position of the snackbar
      panelClass: ["custom-snackbar"], // Add a custom CSS class for styling if needed
    });
  }

  error: any = { isError: false, errorMessage: "" };
  reviewError: any = { isError: false, errorMessage: "" };
  agreementDateError: any = { isError: false, errorMessage: "" };

  startDateValidation() {
    this.endDateValidation();
    this.reviewValidation();
  }
  effectiveDateValidation() {
    this.aggrementDateValidation();
  }

  aggrementDateValidation() {
    if (
      new Date(this.projectDetailForm.controls["agreement_date"].value) <
      new Date(this.projectDetailForm.controls["effective_date"].value)
    ) {
      this.agreementDateError = {
        isError: true,
        errorMessage: "Agreement Date cannot be before Effective Date",
      };
    } else {
      this.agreementDateError = { isError: false, errorMessage: "" };
    }
  }

  endDateValidation() {
    this.reviewValidation();
    if (
      new Date(this.projectDetailForm.controls["endDate"].value) <
      new Date(this.projectDetailForm.controls["startDate"].value)
    ) {
      this.error = {
        isError: true,
        errorMessage: "End Date cannot be before Start Date",
      };
    } else {
      this.error = { isError: false, errorMessage: "" };
    }
  }

  reviewValidation() {
    if (
      new Date(this.projectDetailForm.controls["review_date"].value) <
      new Date(this.projectDetailForm.controls["endDate"].value)
    ) {
      this.reviewError = {
        isError: true,
        errorMessage: "Review Date cannot be before Start Date and End Date",
      };
    } else {
      this.reviewError = { isError: false, errorMessage: "" };
    }
  }

  onNoClick(e: string): void {
    this.dialogRef.close(e);
  }
  delete() {
    this.dialogRef.close("ok");
  }
  /* to save the persona information in API */
  save() {
    this.formSubmitted = true;
    this.service.isLoading = true;
    let sessionId = localStorage.getItem("sessionId");
    if (
      this.projectDetailForm.valid &&
      this.agreementDateError.isError === false &&
      this.reviewError.isError === false &&
      this.error.isError === false
    ) {
      this.formSubmitted = false;
      const payload = {
        session_id: sessionId,
        field_name: this.projectDetailForm.value.projectName,
        client_name: this.projectDetailForm.value.clientName,
        project_name: this.projectDetailForm.value.projectName,
        agreement_date: this.projectDetailForm.value.agreement_date,
        effective_date: this.projectDetailForm.value.effective_date,
        start_date: this.projectDetailForm.value.startDate,
        end_date: this.projectDetailForm.value.endDate,
        review_date: this.projectDetailForm.value.review_date,
        date: this.projectDetailForm.value.agreement_date,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };
      this.service.postProjectDetails(payload).subscribe(
        (res) => {
          if (res.status === "success") {
            this.service.isProjectDescriptionBtn = true;
            this.service.isProjectDescriptionView = true;
            this.service.isUploadPPTBtn = true;
            this.service.setProjectDetails(payload);
            this.service.isLoading = false;
            this.onNoClick("ok");
            this.service.sowAssiatnceHeaderText =
              "Thanks for the project details.";
            this.service.sowAssiatnceSubHeaderText =
              "You can see project description by clicking the “Project Description” button on the left.";
            this.service.sowAssiatnceContentText = [
              `If you "Add Proposal PPT+" it would be really helpful!`,
            ];
          }
        },
        (error) => {
          // Handle errors here
          this.service.isLoading = false;
          this.showCustomSnackbar(error.error.message);
          // alert(error.error.message);
        }
      );
    } else {
      this.service.isLoading = false;
    }
  }
}
