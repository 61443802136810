import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-loader',
  templateUrl: './chat-loader.component.html',
  styleUrls: ['./chat-loader.component.scss']
})
export class ChatLoaderComponent implements OnInit {

  style: any;

  @Input()
  get size() {
    return this._size;
  }
  set size(value: number) {
    this._size = value;
    this.style = {
      transform: `translate(-50%, -50%) scale(${value})`,
    };
  }
  private _size: number = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
