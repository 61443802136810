import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

export class Message {
  constructor(public author: string, public content: string) {
    author = "";
    content = "";
  }
}
@Injectable({
  providedIn: "root",
})
export class CodeConversionService {
  codeConversionActive: boolean = true;
  DBCodeConversionActive: boolean = false;
  // public baseapiUrl = "http://3.131.8.158:8000/";
  public baseapiUrl: string = environment.baseapiurl;

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  getLanguages(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "api/display_languages", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  codeConvert(data: any) {
    return this.http
      .post<any>(this.baseapiUrl + "api/claude_code_convert", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
