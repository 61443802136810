import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormServiceService } from 'src/app/module/service/form-service.service';

@Component({
  selector: 'app-open-ai-dialog',
  templateUrl: './open-ai-dialog.component.html',
  styleUrls: ['./open-ai-dialog.component.scss']
})
export class OpenAiDialogComponent implements OnInit {

  modalContent: string;
  isChecked: boolean = false;
  isLoadingFlag = false;
  constructor(
    public dialogRef: MatDialogRef<OpenAiDialogComponent>,
    public service: FormServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data.content;
  }


  ngOnInit(): void {
  }

  CancelClick() {
    this.dialogRef.close("closed")
  }

  dialogClose() {
    this.isLoadingFlag = true
    this.dialogRef.close("closed");
    this.service.callMethod();
  }


}
