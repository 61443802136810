import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminSettingsServiceService } from "src/app/module/service/admin-settings-service.service";
import { CostService } from "src/app/module/service/cost.service";
import { DashboardServiceService } from "./../../../../service/dashboard-service.service";
import { read, utils, writeFile } from "xlsx";
import { environment } from "./../../../../../../environments/environment";

@Component({
  selector: "app-cost-details",
  templateUrl: "./cost-details.component.html",
  styleUrls: ["../cost-table.component.scss", "./cost-details.component.scss"],
})
export class CostDetailsComponent implements OnInit {
  groupName: string = "";
  groupTotalCost: string = "";
  showLoader: boolean = false;
  usersOfGroup: any;
  usersOfGroupPerPage: any;
  costFilterData: any;
  sortGroupUserFlag: Record<string, string> = {
    user: "ASC",
    cost: "",
  };
  pages: any;
  limit: any;
  Arr = Array;
  currentPage: number = 1;
  paginationRange: number[] = [];
  rowCount: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private adminSetting: AdminSettingsServiceService,
    private service: CostService,
    private dashboardService: DashboardServiceService,
    private route: Router
  ) {
    this.usersOfGroup = [];
    this.usersOfGroupPerPage = [];
    this.rowCount = 0;
    this.pages = 0;
    this.limit = environment.paginationLimit;
    this.showLoader = true;
  }

  ngOnInit() {
    if (this.service.group === "") {
      this.navigateBackToCost();
    }
    this.costFilterData = this.adminSetting.getCostFilterData();
    this.groupName = this.service.group;
    this.groupTotalCost = this.service.groupTotalCost;
    this.getCostByUsers();
    this.calculatePaginationRange();
  }

  getCostByUsers() {
    this.showLoader = true;
    const data = {
      start_date: this.costFilterData ? this.costFilterData.costStartDate : "",
      end_date: this.costFilterData ? this.costFilterData.costEndDate : "",
      token: localStorage.getItem("token"),
      userEmail: localStorage.getItem("userEmail"),
      group_name: this.groupName,
    };
    this.service.getUsersByGroup(data).subscribe(
      (response) => {
        if (response.status === "success" && response.DATA.length) {
          this.rowCount = response.DATA.length;
          if (this.rowCount > this.limit) {
            this.pages = Math.ceil(this.rowCount / this.limit);
          }
          this.usersOfGroup = response.DATA.sort((a: any, b: any) => {
            return a.user_name.localeCompare(b.user_name);
          });
          this.sortGroupUserFlag["user"] = "ASC";
        }
        this.showLoader = false;
        this.getPageData(this.currentPage);
      },
      (error) => {
        this.showLoader = false;
      }
    );
  }

  getPageData(page: any) {
    this.currentPage = page;
    this.usersOfGroupPerPage = [];
    const startIndex = (page - 1) * this.limit;
    const endIndex = startIndex + this.limit - 1;

    // Generate an array of values based on the page number
    this.usersOfGroupPerPage = this.usersOfGroup.slice(
      startIndex,
      endIndex + 1
    );
    this.calculatePaginationRange();
  }

  calculatePaginationRange() {
    const totalPages = this.pages;
    const currentPage = this.currentPage;

    let currentRangeStart = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const currentRangeEnd = Math.min(currentRangeStart + 9, totalPages);

    if (currentPage > currentRangeEnd) {
      currentRangeStart += 10;
    }

    const newEnd = Math.min(currentRangeStart + 9, totalPages);

    this.paginationRange = Array.from(
      { length: newEnd - currentRangeStart + 1 },
      (_, i) => currentRangeStart + i
    );
  }

  sortGroupUser(sortOn: any) {
    let sortBy = this.sortGroupUserFlag[sortOn] === "ASC" ? "DESC" : "ASC";
    this.sortGroupUserFlag[sortOn] = sortBy;
    if (sortOn === "cost") {
      if (sortBy === "DESC") {
        this.usersOfGroup = this.usersOfGroup.sort((a: any, b: any) => {
          return b.total_count - a.total_count;
        });
      } else {
        this.usersOfGroup = this.usersOfGroup.sort((a: any, b: any) => {
          return a.total_count - b.total_count;
        });
      }
      this.sortGroupUserFlag["user"] = "";
    } else {
      if (sortBy === "DESC") {
        this.usersOfGroup = this.usersOfGroup.sort((a: any, b: any) => {
          return b.user_name.localeCompare(a.user_name);
        });
      } else {
        this.usersOfGroup = this.usersOfGroup.sort((a: any, b: any) => {
          return a.user_name.localeCompare(b.user_name);
        });
        this.sortGroupUserFlag["cost"] = "";
      }
    }
    this.getPageData(this.currentPage);
  }

  openUser(userName: string, total_count: any) {
    this.service.user = userName;
    this.service.userTotalCost = total_count;
    this.route.navigate([
      "/admin-settings/admin-settings-page/cost/cost-details/cost-details-user",
    ]);
  }

  navigateBackToCost() {
    this.route.navigate(["/admin-settings/admin-settings-page/cost"]);
  }

  selectDownloadTypeForGroupWiseUserReport(event: any) {
    /** common logic to create data file */
    const headings = [["User", "Cost ($)", "Total Token"]];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.usersOfGroup, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");

    /** here we can define that how to export it based on condition */
    if (event.target.value === "csv") {
      writeFile(wb, "Group-wise User Cost Report.csv");
    } else if (event.target.value === "excel") {
      writeFile(wb, "Group-wise User Cost Report.xlsx");
    }
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }
}
