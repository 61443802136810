import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthServiceService } from "./auth-service.service";

@Injectable({
  providedIn: "root",
})
export class PdfComparisionService {
  isLoading: boolean = false;
  pdfComparisionContentButtonFlag = false;
  projectDescriptionSuccess: boolean = false;
  pdfComparisionAssiatnceHeaderText: any =
    "Hi, I am your Personal Pdf Comparison Assistant.......";
  pdfComparisionFileToCompareAssiatnceText: any = `Please upload the file which to compare with master copy by clicking "File To Compare" button on left`;
  pdfComparisionNewSessionAssiatnceText: any = `New PDF Comparison can start by clicking "New Session" button on left or compare documents by clicking "Compare" button on left`;
  pdfComparisionMasterFileAssiatnceText: any = [
    `Please upload the master copy, which will be used as the reference file for comparison with other documents by clicking "Master Copy" button on left to start with a New Pdf Comparison Document.`,
  ];
  showTextDiff: boolean = false;
  formattedHtml: SafeHtml | null = null;
  // projectDetails: any;
  isSnackbarOpen: boolean = false;

  isMasterUploaded: boolean = false; // To track if the master copy is uploaded
  isCompareReady: boolean = false; // To track if both files are uploaded
  masterFile: File | null = null; // Store the master copy
  compareFile: File | null = null; // Store the file to compare

  // public baseapiUrl = "http://3.131.8.158:8000/";
  public baseapiUrl: string = environment.baseapiurl;

  public isDelete: boolean = false;
  public showChatLoader: boolean = false;

  constructor(
    private http: HttpClient,
    private authService: AuthServiceService
  ) {}

  // Pdf Comparison API's
  getSessionId(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "user/session/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  uploadPPT(data: any) {
    this.authService.checkTokenExpiry();
    return this.http.post<any>(this.baseapiUrl + "doc/upload/", data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  compareFiles(data: any) {
    this.authService.checkTokenExpiry();
    return this.http
      .post<any>(this.baseapiUrl + "doc/compare/upload/", data)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
