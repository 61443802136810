import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { AdminSettingsServiceService } from "./../../../service/admin-settings-service.service";
import { AdminSettingsPageComponent } from "./../admin-settings-page.component";
import { DashboardServiceService } from "./../../../service/dashboard-service.service";
import * as Highcharts from "highcharts";
import accessibility from "highcharts/modules/accessibility";
import HC_exporting from "highcharts/modules/exporting";
import HC_exportdata from "highcharts/modules/export-data";
// import theme from "highcharts/themes/brand-light";

accessibility(Highcharts);
HC_exporting(Highcharts);
HC_exportdata(Highcharts);
// theme(Highcharts);

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.scss"],
})
export class AnalyticsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;

  selectedTab = new FormControl(0);

  analyticsFilterData: any;

  constructor(
    public route: Router,
    public service: AdminSettingsServiceService,
    public adminSettingsCom: AdminSettingsPageComponent,
    public dashboardService: DashboardServiceService
  ) {}

  ngOnInit(): void {
    this.service.isLoading = true;
    this.analyticsFilterData = this.service.getAnalyticsFilterData();

    this.adminSettingsCom.getUpdatedAnalyticsData();
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }
}
