<section>
  <div class="main-wrapper">
    <div class="main-container">

      <div role="status" *ngIf="loading">
        <app-loader></app-loader>
      </div>

      <div class="left-panel-wrapper">
        <div class="left-panel-container">
          <div class="menu-wrapper">

            <div class="image-wrapper">
              <div class="image-container" (click)="dashboardRedirection()">
                <img class="aiLogo" loading="lazy" src="./../../../assets/new-logo/new-logo.png" />
                <div class="appName">Genysys</div>
              </div>
            </div>

            <div style="margin-bottom: 10px;margin-top: -20px;" class="menu-container-wrapper">
              <div class="menu-container">

                <div (click)="activateMenu('All Usecases')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="allUseCase">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/grid-icon.svg" />
                        <div class="menu-name-active">All Usecases</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!allUseCase" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/grid-icon-inactive.svg" />
                      <div class="menu-name">All Usecases</div>
                    </div>
                  </div>

                </div>

                <div (click)="activateMenu('Text Generation')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="textGeneration">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/comments_icon.svg" />
                        <div class="menu-name-active">Text Generation</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!textGeneration" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/comments_icon-inactive.svg" />
                      <div class="menu-name">Text Generation</div>
                    </div>
                  </div>
                </div>

                <div (click)="activateMenu('Analysis')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="analysis">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/stats-icon.svg" />
                        <div class="menu-name-active">Analysis</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!analysis" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/stats-icon-inactive.svg" />
                      <div class="menu-name">Analysis</div>
                    </div>
                  </div>
                </div>

                <div (click)="activateMenu('Software Development')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="softwareDevelopment">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/sdlc.png" />
                        <div class="menu-name-active">
                          Software Development
                        </div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!softwareDevelopment" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/sdlc_inactive.png" />
                      <div class="menu-name">Software Development</div>
                    </div>
                  </div>
                </div>

                <div (click)="activateMenu('Image Generation')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="imageGeneration">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/img-icon.svg" />
                        <div class="menu-name-active">Image Generation</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!imageGeneration" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/img-icon-inactive.svg" />
                      <div class="menu-name">Image Generation</div>
                    </div>
                  </div>
                </div>

                <div (click)="activateMenu('HR')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="hrMenu">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/hr-icon.svg" />
                        <div class="menu-name-active">HR</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!hrMenu" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/hr-icon-inactive.svg" />
                      <div class="menu-name">HR</div>
                    </div>
                  </div>
                </div>

                <!-- <div class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="multiModal">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/layer_icon.svg" />
                        <div class="menu-name-active">Multi-Modal</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!multiModal" class="menu-item-disable">
                      <img class="menu-icon-disable" loading="lazy"
                        src="./../../../assets/icons/layer_icon-disable.svg" />
                      <div class="menu-name-disable">Multi-Modal</div>
                    </div>
                  </div>
                </div>

                <div class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="interaction">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy"
                          src="./../../../assets/icons/stats-icon-inactive.svg" />
                        <div class="menu-name-active">Interaction</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!interaction" class="menu-item-disable">
                      <img class="menu-icon-disable" loading="lazy"
                        src="./../../../assets/icons/stats-icon-disable.svg" />
                      <div class="menu-name-disable">Interaction</div>
                    </div>
                  </div>
                </div> -->

                <div (click)="activateMenu('FAQ')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="faq">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/ic_faq.svg" />
                        <div class="menu-name-active">FAQ</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!faq" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/ic_faq-inactive.svg" />
                      <div class="menu-name">FAQ</div>
                    </div>
                  </div>
                </div>

                <div (click)="activateMenu('WALKTHROUGH')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="walkthough">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy"
                          src="./../../../assets/icons/dashboard/walkthrough.png" />
                        <div class="menu-name-active">Walkthrough</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!walkthough" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="../../../assets/icons/dashboard/walkthrough.svg" />
                      <div class="menu-name">Walkthrough</div>
                    </div>
                  </div>
                </div>

                <div *ngIf="isAdmin || isCognitoAdminGrp" (click)="activateMenu('Admin Settings')"
                  class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="adminSettings">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy" src="./../../../assets/icons/layer_icon.svg" />
                        <div class="menu-name-active">Admin</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!adminSettings" class="menu-item">
                      <img class="menu-icon" loading="lazy" src="./../../../assets/icons/layer_icon-inactive.svg" />
                      <div class="menu-name">Admin</div>
                    </div>
                  </div>
                </div>
                <!-- about genesys start -->
                <div (click)="activateMenu('About Genesys')" class="menu-item-container">
                  <div class="menu-container">
                    <div *ngIf="aboutGenesys">
                      <div class="menu-item-active">
                        <img class="menu-icon-active" loading="lazy"
                          src="./../../../assets/icons/dashboard/About-icon-white.svg" />
                        <div class="menu-name-active">About Us</div>
                      </div>
                      <div class="menu-item-active-bar">
                        <img src="./../../../assets/icons/menu-selected-icon.svg" />
                      </div>
                    </div>
                    <div *ngIf="!aboutGenesys" class="menu-item">
                      <img class="menu-icon" loading="lazy"
                        src="./../../../assets/icons/dashboard/About-icon-black.svg" />
                      <div class="menu-name">About Us</div>
                    </div>
                  </div>
                </div>
                <!-- about genesys end -->

              </div>

              <div class="new-case-btn-wrapper">
                <button class="btn btn-primary" (click)="requestNewUseCase()">
                  + Request New Usecase
                </button>
              </div>

            </div>
          </div>

          <div class="blank-left-panel-container"></div>

        </div>
      </div>

      <div class="right-panel-wrapper">
        <div class="right-panel-container">
          <div class="header-wrapper">
            <div class="header-container">
              <div>
                <!-- <div class="searchbar-container">
                  <img width="20px" height="20px" class="searchbar-img" loading="lazy"
                    src="./../../../assets/icons/search-icon.svg" />
                  <input type="text" class="searchbar-content" placeholder="Search usecases..." />
                </div> -->
              </div>
              <div class="profile-container">
                <!-- <img class="profile-pic" loading="lazy" src="./../../../assets/img/img-avatar.jpg" /> -->
                <span class="no-profile-pic" ng-bind="usernameInitials">{{
                  usernameInitials
                  }}</span>
                <div class="profile-info-wrapper">
                  <div class="profile-info-container">
                    <div class="profiler-name">{{ usernameDisplay }}</div>
                  </div>
                  <!-- <div class="profiler-designation">Designation</div> -->
                </div>
                <div class="logout-bar"></div>
                <div (click)="logout()">
                  <img class="logout" loading="lazy" src="./../../../assets/icons/Logout.svg" alt="logout"
                    title="Logout" />
                </div>
              </div>
            </div>
            <div class="blank-content-container"></div>
          </div>

          <div class="content-wrapper">
            <div class="main-section" *ngIf="allUseCase || textGeneration" for="Text Generation">
              <div class="section-title">Text Generation</div>
              <!-- <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div> -->
              <div class="section-wrapper">

                <div class="section-container">
                  <div class="section-content">
                    <div class="section-column" (click)="openChatPage('Text to Text')">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/text-to-text.svg" />
                          </div>
                          <div class="tile-title">Text to text</div>
                        </div>
                        <div class="tile-description">
                          Use the models to generate contextually relevant text
                          in response to various prompts for summarization,
                          translation, question-answering, creative writing.
                        </div>
                      </div>
                    </div>

                    <!-- *ngIf="isManager" -->
                    <div class="section-column" (click)="openSowGeneration()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/sow-generator.svg" />
                          </div>

                          <div class="tile-title">SOW creation</div>
                        </div>
                        <div class="tile-description">
                          From defining deliverables to establishing timelines,
                          navigate the essential elements that make a
                          well-crafted Statement of Work.
                        </div>
                      </div>
                    </div>

                    <div class="section-column" (click)="openChatPage('Document Summarization')">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/document-summarization.svg" />
                          </div>
                          <div class="tile-title">Document Summarization</div>
                        </div>
                        <div class="tile-description">
                          Witness the magic of technology unravelling lengthy
                          documents, extracting key insights, crucial details,
                          and facilitating quick understanding.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <!-- <div class="section-container">
                  <div class="section-content">
                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title-disable">Clinical Trial Summarization</div>
                        </div>
                        <div class="tile-description">
                          Extracting key insights, crucial details, and facilitating quick understanding for clinical
                          trial documents.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="section-container">
                  <div class="section-content">

                    <div class="section-column" (click)="openChatPage('Document Summarization')">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Document Summarization</div>
                        </div>
                        <div class="tile-description">
                          Witness the magic of technology unravelling lengthy documents, extracting key insights,
                          crucial details, and facilitating quick understanding.
                        </div>
                      </div>
                    </div>

                    <div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title-disable">Finance Report Generation</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title-disable">Insurance Underwriting</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div> 
                  </div>
                </div> -->

                <!-- <div class="section-container">
                  <div class="section-content">
                     <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title-disable">Loan Document Processing</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>-->
              </div>
            </div>

            <div class="main-section" *ngIf="allUseCase || analysis" for="Analysis">
              <div class="section-title">Analysis</div>
              <!-- <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div> -->
              <div class="section-wrapper">
                <div class="section-container">
                  <div class="section-content">

                    <div class="section-column" (click)="openConvoBI()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy" src="./../../../assets/icons/dashboard/convo-bi.svg" />
                          </div>
                          <div class="tile-title">Conversational BI</div>
                        </div>
                        <div class="tile-description">Data-driven insights, understanding data through Graphs, Charts.
                        </div>
                      </div>
                    </div>

                    <div class="section-column" (click)="openNaturalQueryEngine()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/code-conversion.svg" />
                          </div>
                          <div class="tile-title">Natural Query Engine</div>
                        </div>
                        <div class="tile-description">Converts natural language queries into database queries, making it
                          intuitive and understandable for users. Models interpret human language and retrieve relevant
                          data from databases.
                        </div>
                      </div>
                    </div>

                    <div class="section-column" (click)="openPdfComparison()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/document-comparison.svg" />
                          </div>
                          <div class="tile-title">Document Comparison</div>
                        </div>
                        <div class="tile-description">
                          Explore our Generative AI-Driven Document Comparison tool.
                          Streamline Document Comparison process by uploading Master Copy and File to compare.
                        </div>
                      </div>
                    </div>

                    <!-- Dummy's -->
                    <!-- <div></div>
                    <div></div> -->

                    <!-- <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Support Ticket Analytics</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div> -->

                    <!-- <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Autonomous Testing</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div> -->

                  </div>

                </div>

                <!-- <div class="section-container">
                  <div class="section-content">
                     <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Fraud & Anomaly Detection</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Software Dev Life Cycle</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div> 

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Equity Research</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>-->
              </div>
            </div>

            <!-- Walkthrough start -->
            <div class="main-section" *ngIf="walkthough" for="User Guide">
              <div class="section-wrapper">
                <div class="section-container">
                  <div class="section-content">

                    <div *ngFor="let item of documents" class="document-section-column">
                      <div class="tile-wrapper">

                        <div class="tile-description">
                          <img [src]="item.icon" alt="Video Thumbnail" width="50px" />
                        </div>

                        <div class="tile-container">
                          <div class="tile-title text-center">{{ item.title }}</div>
                        </div>

                        <div class="btn-group" style="gap: 10px;">
                          <div class="input-wrapper">
                            <a [href]="item.url" target="_blank">View</a>
                          </div>
                          <div class="input-wrapper">
                            <a [href]="item.url" target="_blank" download="GenysysGuide.pdf">Download</a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="main-section walkthrough-section" *ngIf="walkthough" for="Walkthrough">
              <div class="section-title">Walkthrough Videos</div>
              <div class="section-wrapper">
                <div class="section-container">
                  <!-- <div class="section-wrapper"> -->
                  <!-- <div class="section-container"> -->

                  <div class="section-content">
                    <div class="row" style="margin-left: 0; margin-right: 0;">
                      <div *ngFor="let item of items" class="section-column">
                        <div class="tile-wrapper">
                          <div class="tile-container">
                            <div class="tile-title">{{ item.title }}</div>
                          </div>
                          <div style="position: relative;" class="tile-description">
                            <a [href]="item.url" target="_blank">
                              <img [src]="item.icon" alt="Video Thumbnail" width="250px" />
                              <div style="position: absolute; top:50px; left:46%">
                                <img width="35px" src="../../../assets/icons/dashboard/play-icon.svg" />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- </div> -->
                  <!-- </div> -->

                  <!-- </div> -->
                  <!-- </div> -->

                </div>
              </div>

            </div>
            <!-- Walkthrough end  -->

            <div class="main-section" *ngIf="allUseCase || softwareDevelopment" for="Image Generation">
              <div class="section-title">Software Development</div>
              <!-- <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div> -->
              <div class="section-wrapper">
                <div class="section-container">

                  <div class="section-content software-dev">

                    <div class="section-column" (click)="openCodeConversion()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/code-conversion.svg" />
                          </div>
                          <!-- <div class="tile-title"> -->
                          <!-- <img class="coming-soon" loading="lazy"
                              src="./../../../assets/icons/coming-soon-icon.svg" /> -->
                          <div class="tile-title">
                            Code Conversion
                          </div>
                          <!-- </div> -->
                        </div>
                        <div class="tile-description">
                          Convert code syntax from one language to another
                          language.
                        </div>
                      </div>
                    </div>
                    <div class="section-column" (click)="openRequirementQualification()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/requirements-generator.svg" />
                          </div>
                          <div class="tile-title">Requirement Generator</div>
                        </div>
                        <div class="tile-description">
                          Generate requirements, break it down to features, stories, acceptance criteria, test cases.
                          Produce Requirement Granularity Index.
                        </div>
                      </div>
                    </div>
                    <div class="section-column" (click)="openApiTesting()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/requirements-generator.svg" />
                          </div>
                          <div class="tile-title">API Test Script</div>
                        </div>
                        <div class="tile-description">
                          Streamline your API development process by uploading a Swagger file and creates the necessary
                          routes and generates a test payload, enabling you to quickly validate and interact with your
                          endpoints.
                        </div>
                      </div>
                    </div>

                    <div class="section-column" (click)="navigateToCodeGeneration()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/code-generation-from-req.svg" />
                          </div>
                          <div class="tile-title">Code Generation</div>
                        </div>
                        <div class="tile-description">
                          Code Generation from Requirements.
                        </div>
                      </div>
                    </div>

                    <!--<div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Diagram Creation</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Product Design</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">IMA</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>-->
                  </div>
                </div>

                <!--<div class="section-container">
                  <div class="section-content">

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Marketing Samples</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Image Recognition</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column-disable">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image-disable" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title-disable">Data Visulization</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>-->
              </div>
            </div>


            <!-- About Genesys start -->
            <div *ngIf="aboutGenesys">
              <div class="section-title margin-left">About Us</div>
              <app-about-us></app-about-us>
            </div>
            <!-- About Genesys End -->

            <div class="main-section" *ngIf="allUseCase || imageGeneration" for="Image Generation">
              <div class="section-title">Image Generation</div>
              <!-- <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div> -->
              <div class="section-wrapper">
                <div class="section-container">
                  <div class="section-content">
                    <div class="section-column" (click)="openChatPage('Text to Image')">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/text-to-image.svg" />
                          </div>
                          <div class="tile-title">Text to Image</div>
                        </div>
                        <div class="tile-description">
                          Explore Text to Image models which transform text into
                          captivating images through generative AI technology.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="main-section" *ngIf="allUseCase || hrMenu" for="HR">
              <div class="section-title">HR</div>
              <div class="section-wrapper">
                <div class="section-container">
                  <div class="section-content">

                    <!-- <div class="section-column" (click)="openPolicyChatBot()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/Chatbot_icon.svg" />
                          </div>
                          <div class="tile-title">Policy Chat Bot</div>
                        </div>
                        <div class="tile-description">
                          Explore our Generative AI-Driven Chatbot for HR Policies.
                        </div>
                      </div>
                    </div> -->

                    <div class="section-column">
                      <div class="tile-wrapper-withCmngSoon">
                        <div class="tile-container-withCmngSoon">
                          <div style="margin-top: 15px" class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/Chatbot_icon.svg" />
                          </div>
                          <div class="tile-content-withCmngSoon">
                            <img class="coming-soon" loading="lazy"
                              src="./../../../assets/icons/coming-soon-icon.svg" />
                            <div class="tile-title-withCmngSoon">Policy Chat Bot</div>
                          </div>
                        </div>
                        <div class="tile-description">
                          Explore our Generative AI-Driven Chatbot for HR Policies.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!--<div *ngIf="allUseCase || multiModal" class="main-section" for="Multi-Modal">
              <div class="section-title">Multi-Modal</div>
              <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div>
              <div class="section-wrapper">

                <div class="section-container">
                  <div class="section-content">

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Accessibility Support</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Diagnostic Assistance</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Video Content Creation</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="section-container">
                  <div class="section-content">

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Sentiment Analysis</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Multimedia Educational Material</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy" src="./../../../assets/icons/tile-img-icon-blue.svg" />
                          <div class="tile-title">Video Transcription</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>-->

            <!--<div *ngIf="allUseCase || interaction" class="main-section" for="Interaction">
              <div class="section-title">Interaction</div>
              <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div>
              <div class="section-wrapper">

                <div class="section-container">
                  <div class="section-content">

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title">Search Enhancement</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title">Help Desk</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title">Cust Support</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="section-container">
                  <div class="section-content">

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title">Language Translation</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title">CD Rate Valet</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                    <div class="section-column">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <img class="tile-image" loading="lazy"
                            src="./../../../assets/icons/tile-img-icon-purple.svg" />
                          <div class="tile-title">Marketing Surveys</div>
                        </div>
                        <div class="tile-description">
                          Neque porro quisquam est qui dolorem ipsum quia dolor sit
                          amet, consectetur.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>-->

            <!-- Admin Settings right -->
            <div class="main-section" *ngIf="(isAdmin || isCognitoAdminGrp) && adminSettings" for="Admin Settings">
              <div class="section-title">Admin</div>
              <!-- <div class="section-description">
                This feature has been trained on the knowledge base eg (platform
                Name) Ask a question to get started.
              </div> -->
              <div class="section-wrapper">
                <div class="section-container">
                  <div class="section-content">

                    <!-- <div class="section-column" (click)="openAdminPortfolio()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/text-to-text.svg" />
                          </div>
                          <div class="tile-title">Model Portfolio</div>
                        </div>
                        <div class="tile-description">
                          Use the models to get any of Llama2, MosaicML, Stable Diffusion 2 and Open Journey model turn
                          on.
                        </div>
                      </div>
                    </div> -->

                    <div *ngIf="isCognitoAdminGrp" class="section-column" (click)="openAdminFeedback()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/document-summarization.svg" />
                          </div>
                          <div class="tile-title">Feedback</div>
                        </div>
                        <div class="tile-description">
                          Use this to see the feedback submitted by end users for model responses.
                        </div>
                      </div>
                    </div>

                    <div *ngIf="isCognitoAdminGrp" class="section-column" (click)="openAdminCosting()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy" src="./../../../assets/icons/dashboard/dollar.svg" />
                          </div>
                          <div class="tile-title">Costing</div>
                        </div>
                        <div class="tile-description">
                          Use this to see the group wise costing for models and further drill-down per user cost and per
                          token cost.
                        </div>
                      </div>
                    </div>

                    <div *ngIf="isCognitoAdminGrp" class="section-column" (click)="openAdminAccessList()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/document-summarization.svg" />
                          </div>
                          <div class="tile-title">Role Access List</div>
                        </div>
                        <div class="tile-description">
                          Use this to decide Access for end users.
                        </div>
                      </div>
                    </div>

                    <div *ngIf="isCognitoAdminGrp || isAnalytics" class="section-column" (click)="openAdminAnalytics()">
                      <div class="tile-wrapper">
                        <div class="tile-container">
                          <div class="icon-container">
                            <img class="icon-img" loading="lazy"
                              src="./../../../assets/icons/dashboard/Analytics.svg" />
                          </div>
                          <div class="tile-title">Analytics</div>
                        </div>
                        <div class="tile-description">
                          Use this to see the Analytics by end users for model responses.
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="main-section faq-section" *ngIf="faq" for="faq">
              <div class="section-title">FAQ</div>
              <div class="section-wrapper">
                <div class="section-container">
                  <div class="question">
                    <cdk-accordion class="question-accordion">
                      <div class="col1">
                        <!-- q1 -->
                        <cdk-accordion-item #accordionItem1="cdkAccordionItem" class="accordion-item" role="button"
                          tabindex="0" [attr.id]="'accordion-header-1'" [attr.aria-expanded]="accordionItem1.expanded"
                          [attr.aria-controls]="'accordion-body-1'" *ngFor="let tab of faqLeft">
                          <div class="accordion-item-header" (click)="accordionItem1.toggle()">
                            <span>{{ tab.title }}</span>
                            <span class="accordion-item-description">
                              <img
                                src="{{ accordionItem1.expanded ? './../../../assets/icons/minus.svg' : './../../../assets/icons/plus.svg'}}" />
                            </span>
                          </div>
                          <div class="accordion-item-body" role="region"
                            [style.display]="accordionItem1.expanded ? '' : 'none'" [attr.id]="'accordion-body-1'"
                            [attr.aria-labelledby]="'accordion-header-1'" style="white-space: 'pre-line';">
                            {{ tab.content }}
                          </div>
                        </cdk-accordion-item>

                      </div>
                      <div class="col2">
                        <!-- q2 -->
                        <cdk-accordion-item #accordionItem2="cdkAccordionItem" class="accordion-item" role="button"
                          tabindex="0" [attr.id]="'accordion-header-' + 2"
                          [attr.aria-expanded]="accordionItem2.expanded" [attr.aria-controls]="'accordion-body-' + 2"
                          *ngFor="let tab of faqRight">
                          <div class="accordion-item-header" (click)="accordionItem2.toggle()">
                            <span>{{ tab.title }}</span>
                            <span class="accordion-item-description">
                              <img
                                src="{{accordionItem2.expanded ? './../../../assets/icons/minus.svg' : './../../../assets/icons/plus.svg'}}" />
                            </span>
                          </div>
                          <div class="accordion-item-body" role="region"
                            [style.display]="accordionItem2.expanded ? '' : 'none'" [attr.id]="'accordion-body-2'"
                            [attr.aria-labelledby]="'accordion-header-2'" style="white-space: 'pre-line';">
                            {{ tab.content }}
                          </div>
                        </cdk-accordion-item>

                      </div>
                    </cdk-accordion>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</section>