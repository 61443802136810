import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { ProjectDetailDialog } from "./mat-dialog/project-detail-dialog/project-detail.dialog";
import { MatDialog } from "@angular/material/dialog";
import { UploadPptDialog } from "./mat-dialog/upload-ppt-dialog/upload-ppt.dialog";
import { SowGenerationServiceService } from "../../service/sow-generation-service.service";
import { FormServiceService } from "../../service/form-service.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "src/app/module/snackbar/snackbar.component";

@Component({
  selector: "app-sow-generation-page",
  templateUrl: "./sow-generation-page.component.html",
  styleUrls: ["./sow-generation-page.component.scss"],
})
export class SowGenerationPageComponent implements AfterViewInit, OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  instance_id: any;

  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  intervalId: any;

  poolData: any;
  userPool: any;

  isLocalUser: any;
  accessToken: any | null;
  idToken: any | null;
  refreshToken: any | null;
  tokenExpired: any;
  integrationParams: any;
  sessionStatus: any;
  token: any;
  userEmail: any;

  constructor(
    private observer: BreakpointObserver,
    public dialog: MatDialog,
    public route: Router,
    public sowService: SowGenerationServiceService,
    public service: FormServiceService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService,
    private snackBar: MatSnackBar
  ) {
    this.isLocalUser = localStorage.getItem("isLocalUser");
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");

    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    // Checks every 5 second for session expiration
    this.intervalId = setInterval(() => {
      if (this.isLocalUser === "true") {
        // For Local User
        this.authService.checkAndRefreshSession();
      } else {
        // For Azure AD user
        this.authService.checkAndRefreshSessionForAWSCognitoAzure();
      }
    }, 5000);

    // Integrating a user pool with an identity pool
    // this.authService.integrateUserPoolWithIdentityPool();
  }

  async ngOnInit() {
    this.getSessionId();
  }

  ngAfterViewInit() {
    /*  to display responsive sidebar */
    // this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
    //   if (res.matches) {
    //     this.sidenav.mode = "over";
    //     this.sidenav.close();
    //   } else {
    //     this.sidenav.mode = "side";
    //     this.sidenav.open();
    //   }
    // });
  }

  // error handling
  showCustomSnackbar(message: string) {
    let snackbarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message },
      // duration: 10000, // Set the duration for which the snackbar will be displayed
      verticalPosition: "top", // You can adjust the position of the snackbar
      panelClass: ["custom-snackbar"], // Add a custom CSS class for styling if needed
    });
    snackbarRef.afterDismissed().subscribe(() => {
      // Set your flag here
      this.sowService.isSnackbarOpen = false;
    });

    return snackbarRef;
  }

  getSessionId() {
    this.sowService.isLoading = true;
    let data = {
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.sowService.getSessionId(data).subscribe(
      (res) => {
        if (res.status == "success") {
          localStorage.setItem("sessionId", res.session_id);
          this.sowService.sowAssiatnceHeaderText =
            "Hi, I am your Personal SOW Assistant.......";
          this.sowService.sowAssiatnceContentText = [
            "Please add a Project Description by clicking the “Project Description” button on the left to start with a New SOW Document.",
          ];
          this.sowService.isLoading = false;
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  addProjectDetails() {
    this.sowService.isDelete = false;

    const dialogRef = this.dialog.open(ProjectDetailDialog);

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        this.sowService.projectDescriptionSuccess = true;
      }
    });
  }
  /*  industry dialog */
  uploadPPT() {
    const dialogRef = this.dialog.open(UploadPptDialog, {
      width: "45%",
      panelClass: "upload-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.sowService.sowAssiatnceHeaderText = "Thanks for the Project Details";
      this.sowService.sowAssiatnceSubHeaderText =
        "You can see Project Description by clicking the “Project Description” button on the left.";
      this.sowService.sowAssiatnceContentText = [
        "If you can connect the Proposal PPT, it would be really helpful!",
      ];
    });
  }

  newSession() {
    window.location.reload();
  }

  handleDownloadClick() {
    let id = localStorage.getItem("sessionId");
    this.sowService.isLoading = true;

    let payload = {
      // session_id: "b3b9767f-746b-4184-ab05-08d8f581e4ca",
      session_id: id,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.sowService.getXML(payload).subscribe(
      (data) => {
        var element = document.createElement("a");
        var blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        var url = URL.createObjectURL(blob);
        element.href = url;
        element.setAttribute("download", "sow.docx");
        document.body.appendChild(element);
        element.click();
        this.sowService.isLoading = false;
        this.sowService.isSnackbarOpen = false;
      },
      (error: any) => {
        this.sowService.isLoading = false;
        this.sowService.isSnackbarOpen = true;
        this.showCustomSnackbar(error);
        // alert(error);
      }
    );
  }

  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  logout() {
    this.sowService.isLoading = true;
    this.authService.logout();
  }
}
