import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MatSidenav } from "@angular/material/sidenav";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NaturalQueryEngineService } from "../../service/natural-query-engine.service";
import { AuthServiceService } from "../../service/auth-service.service";
import { DashboardServiceService } from "../../service/dashboard-service.service";
import { DatabaseDialog } from "../mat-dialog/database-dialog/database.dialog";
import { NewChatDialog } from "../mat-dialog/newChat-dialog/newchat.dialog";
import { Subscription } from "rxjs";
import { ConfirmationDialog } from "../../home/home-page/mat-dialog/confirmation-dialog/confirmation.dialog";

@Component({
  selector: "app-natural-query-engine-page",
  templateUrl: "./natural-query-engine-page.component.html",
  styleUrls: ["./natural-query-engine-page.component.scss"],
})
export class NaturalQueryEnginePageComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  selectedDatabaseConnection: string = "None";
  selectedWorkspaceName: string = "None";
  selectedReportName: string = "None";
  documentList: any = [];
  databaseDataList: any = [];
  username: any;
  usernameDisplay: any;
  usernameInitials: any;
  formSelected: boolean = false;
  token: any;
  userEmail: any;
  deleteDatabaseConnectionFlag: boolean = false;

  selectedModel: string = "gpt-4o-mini";
  selectedType: string = "open_ai"

  private subscription: Subscription;
  constructor(
    private observer: BreakpointObserver,
    public dialog: MatDialog,
    public route: Router,
    public service: NaturalQueryEngineService,
    public authService: AuthServiceService,
    public dashboardService: DashboardServiceService
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.subscription = this.service.methodCalled$.subscribe((data: any) => {
      this.getDatabaseConnections();
      this.setDatabaseConnectionSelected(data);

    });
    localStorage.setItem( 'selectedDb', '' );
  }

  ngOnInit() {
    this.service.taskConvertor = "conversation_bi";
    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
    this.getDatabaseConnections();
  }

  Back() {
    this.route.navigateByUrl("/home");
  }

  getDatabaseConnections() {
    let data = {
      // username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.listdatabaseName(data).subscribe((res) => {
      if( res && res.status == 'Success' && res.message.length !== 0 ) {
        this.databaseDataList = res.message
        // this.selectedDatabaseConnection = this.databaseDataList[this.databaseDataList.length - 1]?.id;
        this.documentList = res.skills;
      } else {
        this.databaseDataList = res.message
      }
    });
  }

  ngAfterViewInit() {
    this.observer.observe(["(max-width: 800px)"]).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = "over";
        this.sidenav.close();
      } else {
        this.sidenav.mode = "side";
        this.sidenav.open();
      }
    });
  }

  // Redirect to dashboard
  dashboardRedirection() {
    this.route.navigate(["/dashboard"]);
    this.dashboardService.activeMenu = "";
  }

  openDatabaseConnection() {
    // this.service.IsConversationBi = true;
    let info = {
      workspace_name: this.selectedWorkspaceName,
      report_name: this.selectedReportName,
    };
    const dialogRef = this.dialog.open(DatabaseDialog, {
      width: "50%",
      data: info,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "saved") {
        this.getDatabaseConnections()
      }
    });
  }

  newChat() {
    this.service.selectedModel  = this.selectedModel
    this.service.selectedType   = this.selectedType;
    this.formSelected = true;

    if (this.selectedDatabaseConnection !== "None") {

      localStorage.setItem( 'selectedDb', this.selectedDatabaseConnection );
      this.formSelected = false;

      let payload = {
        connection_name: this.selectedDatabaseConnection,
        connection_id: this.selectedDatabaseConnection,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      }

      this.service.createInstanceForNewChat(payload).subscribe(
        (res) => {
          if (res.status == "success") {
            this.service.selectedModel = this.selectedModel
            this.service.selectedType = this.selectedType;
          } else {
            // alert(res.message);
            console.log( res.message );
          }
        },
        (error) => {
          // alert(error.error.message);
          console.log( error.error.message );
        }
      );
      
    }
    if( this.selectedDatabaseConnection !== 'None' ) {
      this.service.triggerChat();
    }
  }

  setDatabaseConnectionSelected(databaseConnection_id: string) {
    this.selectedDatabaseConnection = databaseConnection_id;
  }

  logout() {
    // this.service.loading = true;
    this.authService.logout();
  }

  deleteContext(contextDetail: any) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      width: "40%",
      data: {
        content: `This action will delete the <b> ${contextDetail.connection_name} </b> context. Are you sure to delete?`,
        action: "Delete",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "ok") {
        let data = {
          userEmail: this.userEmail,
          connection_name: contextDetail.connection_name,
          token: localStorage.getItem("token"),
        };
        this.service. deleteDbConnection(data).subscribe(
          ( res ) => {
            if( res.status === 'success' ) {
              this.getDatabaseConnections();
              this.selectedDatabaseConnection = "None";
            } else {
              console.log(res.message);
            }
          },
          ( err ) => {
            console.log( err );
          },
        );
      }
      // this.deleteContextFlag = false;
      // this.selectedPersona = "None";
    });
  }

  onNqlTypeChange( event: any) {
    if( event.value === 'google' ) {
      const dialogRef = this.dialog.open(DisclaimerDialog, {
        width: "50%",
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.selectedType = 'open_ai';
          this.selectedModel = 'gpt-3.5-turbo';
        }
      });
  
    } else {
    }
    // this.disableInputOnChange();
    // switch( event.value ){
    //   case 'open_ai':
    //     this.selectedModel = 'gpt-3.5-turbo';
    //   break;
    //     case 'google':
    //     this.selectedModel = 'Gemini';
    //   break;
    // }
  }

  disableInputOnChange() {
    this.service.disableInput();
  }

}

@Component({
  selector: "app-natural-query-engine-page",
  templateUrl: "./natural-query-engine-info-modal-page.html",
  styleUrls: ["./natural-query-engine-page.component.scss"],
})
export class DisclaimerDialog {
  constructor(public dialogRef: MatDialogRef<DisclaimerDialog>) {}

  close(status: number) {
    this.dialogRef.close(status);
  }
}
