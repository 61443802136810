import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SowGenerationRoutingModule } from "./sow-generation-routing.module";
import { SowGenerationPageComponent } from "./sow-generation-page/sow-generation-page.component";
import { HeaderComponent } from "./sow-generation-page/component/header/header.component";
import { SowGenerationPageModule } from "./sow-generation-page/sow-generation-page.module";
import { SowFormDataComponent } from "./sow-generation-page/sow-assistance-form-data/sow-form-data.component";

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, SowGenerationRoutingModule, SowGenerationPageModule],
  exports: [HeaderComponent, SowFormDataComponent],
})
export class SowGenerationModule {}
