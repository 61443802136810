import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "confirmation.dialog",
  templateUrl: "confirmation.dialog.html",
  styleUrls: ["confirmation.dialog.scss"],
})
export class ConfirmationDialog {
  modalContent: SafeHtml;
  actionBtnVal: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    this.modalContent = this.applyBoldEffect(this.data.content);
    this.actionBtnVal = this.data.action;
  }

  ngOnInit() {}

  applyBoldEffect(message: string): SafeHtml {
    // Replace <b> and </b> with a span with the bold-text class
    const result = message
      .replace(/<b>/g, '<span style="font-weight: bold; color: #EB5832;">')
      .replace(/<\/b>/g, "</span>");

    return this.sanitizer.bypassSecurityTrustHtml(result);
  }

  onCancelClick(): void {
    this.dialogRef.close("cancel");
  }

  onSubmitClick(): void {
    this.dialogRef.close("ok");
  }
}