import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ConversationBiRoutingModule } from "./conversation-bi-routing.module";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormDataComponent } from "../form-data/form-data.component";
import { PersonaDialog } from "../mat-dialog/persona-dialog/persona.dialog";
import { NewChatDialog } from "../mat-dialog/newChat-dialog/newchat.dialog";
import { ConversationBiComponent } from "./conversation-bi.component";
import { LoaderModule } from "../../loader/loader.module";
import { ChatLoaderModule } from "../../chat-loader/chat-loader.module";
@NgModule({
  declarations: [
    ConversationBiComponent,
    FormDataComponent,
    PersonaDialog,
    NewChatDialog,
  ],
  imports: [
    CommonModule,
    ConversationBiRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    LoaderModule,
    ChatLoaderModule
  ],
  exports: [FormDataComponent],
})
export class ConversationBiModule {}
