import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { SowGenerationServiceService } from "src/app/module/service/sow-generation-service.service";

// import { CustomSnackbarComponent } from './path-to-custom-snackbar/custom-snackbar.component';
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomSnackbarComponent } from "src/app/module/snackbar/snackbar.component";
import { InfoDialogComponent } from "../info-dialog/info-dialog.component";

@Component({
  selector: "upload-ppt.dialog",
  templateUrl: "upload-ppt.dialog.html",
  styleUrls: ["upload-ppt.dialog.scss"],
})
export class UploadPptDialog {
  IndustryForm!: FormGroup;
  showPath: boolean = false;
  showUpload: boolean = false;
  PersonaForm!: FormGroup;
  uploadFile: File[] = [];
  listFiles: any = [];
  file_type: string = "";
  formSubmitted: boolean = false;
  showLoader: boolean = false;
  showText: boolean = false;
  newText: string = "";
  sessionId: any;
  username: any;
  isLoading: boolean = false;
  token: any;
  userEmail: any;

  constructor(
    public formBuilder: FormBuilder,
    public service: SowGenerationServiceService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UploadPptDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.sessionId = localStorage.getItem("sessionId");
  }

  showCustomSnackbar(message: string) {
    console.log("error", this.service.isSnackbarOpen);
    let snackbarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message },
      // duration: 10000, // Set the duration for which the snackbar will be displayed
      verticalPosition: "top", // You can adjust the position of the snackbar
      panelClass: ["custom-snackbar"], // Add a custom CSS class for styling if needed
    });
    this.service.isLoading = true;
    snackbarRef.afterDismissed().subscribe(() => {
      this.service.isLoading = false;
      // Set your flag here
      this.service.isSnackbarOpen = false;
    });

    return snackbarRef;
  }

  onNoClick(res: string): void {
    this.dialogRef.close(res);
  }

  // onFileChange(event: any) {
  //   this.service.sowAssiatnceContentText = ["PPT uploading....."];
  //   this.uploadFile = event.target.files;
  //   for (var i = 0; i < this.uploadFile.length; i++) {
  //     this.listFiles.push(this.uploadFile[i].name);
  //   }
  //   this.fileUploadService();
  // }
  onFileChange(event: any) {
    this.service.sowAssiatnceContentText = ["PPT uploading....."];
    this.uploadFile = event.target.files;

    // Check if only one file is selected
    if (this.uploadFile.length === 1) {
      const fileType = this.uploadFile[0].type;

      // Check if the selected file type is a PowerPoint file
      if (
        fileType === "application/vnd.ms-powerpoint" ||
        fileType ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        this.listFiles.push(this.uploadFile[0].name);
        this.service.isSnackbarOpen = true;
        this.fileUploadService();
      } else {
        this.service.isSnackbarOpen = true;
        // Show alert for invalid file type
        // this.customSnackbarService.open('Please select a valid PowerPoint file (.ppt or .pptx).');
        // alert("Please select a valid PowerPoint file (.ppt or .pptx).");
        // Clear the file input
        this.dialog.open(InfoDialogComponent, {
          disableClose: true,
          width: "35%",
          data: {
            content: "Please select a valid PowerPoint file (.ppt or .pptx).",
          },
          panelClass: "Invalid-dialog-container",
        });
        // this.showCustomSnackbar(
        //   "Please select a valid PowerPoint file (.ppt or .pptx)."
        // );
        this.resetFileInput();
      }
    } else {
      this.service.isSnackbarOpen = true;
      // Show alert for selecting multiple files
      this.showCustomSnackbar("Please select only one file.");
      // this.customSnackbarService.open("Please select only one file.");
      // Clear the file input
      this.resetFileInput();
    }
  }

  // Helper method to reset the file input
  resetFileInput() {
    const fileInput = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  }
  getFileUpload() {
    this.showPath = false;
    this.showUpload = true;
    this.file_type = "local";
  }

  fileUploadService() {
    this.service.sowAssiatnceHeaderText = "Uploading PPT....";
    this.service.sowAssiatnceContentText = ["Loading..."];

    const formData = new FormData();
    formData.append("file", this.uploadFile[0]);
    formData.append("session_id", this.sessionId);
    formData.append("token", this.token);
    formData.append("userEmail", this.userEmail);
    this.onNoClick("ok");
    this.service.isLoading = true;
    this.service.uploadPPT(formData).subscribe(
      (res) => {
        if (res.status == "success") {
          this.service.sowAssiatnceHeaderText = "PPT Uploaded Succesfully";
          this.service.sowAssiatnceSubHeaderText = "";
          this.service.sowAssiatnceContentText = ["Analysing....."];
          this.service.isLoading = false;
          this.service.isUploadPPTBtn = false;
          this.service.showStepper = true;
          this.service.sendChatMsg();
          this.service.isSnackbarOpen = false;
        } else {
          this.service.isSnackbarOpen = true;
          this.showCustomSnackbar(res.message);
          // alert(res.message);
        }
      },
      (error) => {
        this.service.isLoading = false;
        this.service.isSnackbarOpen = true;
        this.dialog.open(InfoDialogComponent, {
          disableClose: true,
          width: "35%",
          data: {
            content: "PPT file is corrupted, please upload another PPT",
          },
          panelClass: "ppt-dialog-container",
        });
        // this.showCustomSnackbar(
        //   "PPT File is corrupted, Please upload another PPT"
        // );
      }
    );
  }
}