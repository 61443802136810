<section id="hero">
  <div class="content">
    <!-- <div class="spinner-grow text-dark" role="status" *ngIf="service.showChatLoader">
      <span class="sr-only">Loading...</span>
    </div> -->
    <div role="status" *ngIf="service.showChatLoader || formService.alive">
      <app-loader></app-loader>
    </div>

    <div class="chat-window">

      <div style="float: left;" (click)="navigateBack()">
        <img src="../../../../assets/icons/back-icon.svg" alt="Back" title="Back" class="dashboard-back-icon">
      </div>
      <h1>Model Portfolio</h1>
      <div *ngIf="modelList.length > 0" class="message info">
        <table class="gen-table">
          <thead>
            <tr>
              <th>Model Name</th>
              <th>Status</th>
              <!-- <th>Duration in No of Hours</th> -->
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of modelList; let i = index">
              <td>{{ row.title }}</td>
              <td>{{ row.machine_status }}</td>
              <!-- <td>
                <label *ngIf="row.machine_status === 'OFF'">
                  <input type="textbox" [(ngModel)]="row.duration" class="duration-form-control" placeholder="Duration"
                    name="duration{{i}}" pattern="[0-9]*" (change)="onDurationChange(row, i)">
                </label>
              </td> -->
              <td>
                <!-- [disabled]="!row.validDuration -->
                <div class="input-wrapper">
                  <button (click)="onSubmit(row, i)" [disabled]="row.response_code === 0 || formService.alive"
                    [ngClass]="{'disabled': row.response_code === 0 || formService.alive}"> Turn On </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <!-- error -->
          <div *ngIf="!isValidForm" class="error-msg">{{errorMessage}}
          </div>
        </div>
      </div>

      <div *ngIf="!service.showChatLoader && !formService.alive && modelList.length === 0" class="message info">
        <div class="no-data">
          No Model Found
        </div>
      </div>
    </div>
  </div>
</section>