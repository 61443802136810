<section>
  <div class="main-wrapper">
    <div class="main-container">
      <div class="image-wrapper">
        <div class="image-container">
          <div class="poweredBy-container">
            <div class="poweredBy">powered by</div>
            <img class="apexonLogo" loading="lazy" src="./../../../assets/img/Apexon.svg" />
          </div>
          <div>
            <img width="100px" class="aiCloudConceptWithRobotArm2" loading="lazy"
              src="./../../../assets/img/Image_Login.png" />
          </div>
        </div>
      </div>
      <div class="auth-wrapper">
        <div class="auth-container">
          <img class="aiLogo" loading="lazy" src="./../../../assets/new-logo/new-logo.png" />
          <div class="title-container">
            <span class="welcomeTo">
              Welcome to
            </span>
            <span class="appName">
              Genysys
            </span>
          </div>
          <div class="subTitle">Login into your account</div>

          <!-- <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
            <div class="">
              <input type="text" style="border-color: #9a378e;" formControlName="username" class="username-form-control"
                [ngClass]="{ 'input-error': submitted && f.username.errors }" placeholder="Username" />
            </div>
            <div class="">
              <input type="password" style="border-color: #9a378e;" formControlName="password"
                class="password-form-control" [ngClass]="{ 'input-error': submitted && f.password.errors }"
                placeholder="Password" />
            </div>
            <div *ngIf="!isAuthenticated" class="error-msg-login">{{errorMessage}}
            </div>
            <div class="">
              <div class="">
                <button [disabled]="loading || azureLoading" class="loginBtn-wrapper">
                  <div class="loginBtn-container">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                      *ngIf="loading"></span>
                    Login
                  </div>
                </button>
              </div>
            </div>
          </form>

          <div class="forgotPassword">Forgot Password?</div>

          <div class="orLabel">OR</div> -->

          <button class="btn btn-secondary" (click)="initiateAzureADLogin()" [disabled]="disabledLoginBtn">
            <img *ngIf="!azureLoading" class="windows-logo" loading="lazy"
              src="./../../../assets/img/windows_logo.svg" />
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="azureLoading"
              style="margin-right: 10px; margin-left: 5px;"></span>
            &nbsp;
            Login with Apexon Account
          </button>

          <!-- <button class="btn btn-secondary" style="margin-top: 20px;" (click)="getClientCredentialsToken()">
            Get Client Credentials Token
          </button> -->

        </div>
      </div>
    </div>
  </div>
</section>