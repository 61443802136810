<section id="hero">
  <div class="content">

    <div *ngIf="service.isLoading">
      <app-loader></app-loader>
    </div>

    <h1>SOW Generator</h1>
    <div class="chat-window">

      <div class="message info">
        {{ service.sowAssiatnceHeaderText}}
      </div>

      <div *ngIf="service.sowAssiatnceSubHeaderText !== ''" class="message info">
        {{ service.sowAssiatnceSubHeaderText }}
      </div>

      <div class="message info">

        <div *ngIf="!editFlag"><p *ngFor="let msg of service.sowAssiatnceContentText">{{msg}}</p></div>

        <textarea
          [disabled]="isVerifed"
          class="message-sender"
          *ngIf="editFlag"
          [(ngModel)]="dummyText"
          id="w3review"
          name="w3review"
          rows="10"
          cols="100%"
          style="width: 100%;background: white; border:#d9d9d9; overflow: auto"
        >
          {{service.sowAssiatnceContentText}}
        </textarea>
        
        <div class="sow-assistant-content-text-container" *ngIf="service.sowContentButtonFlag">
          <div class="sow-assistant-content-text-checkbox">
            <mat-checkbox
              [disabled]="service.isLoading || service.isDownloadSowBtn"
              (change)="verify($event)"
              [(ngModel)]="isVerifed"
              value="isVerified"
              color="primary"
            >
              Verified
            </mat-checkbox>
          </div>
          <div class="options">
            <button
              [disabled]="isVerifed || editFlag || service.isLoading || backbuttonDisableFlag || service.isDownloadSowBtn"
              class="mat-btn"
              mat-button
              (click)="backClick()"
            >
              Back
            </button>
            <button
              [disabled]="isVerifed || service.isLoading || service.isDownloadSowBtn"
              class="mat-btn"
              mat-button
              (click)="EditClick()"
            >
              {{editFlag ? "Cancel": "Edit"}}
            </button>
            <button
              class="mat-btn"
              mat-button
              [disabled]="!isVerifed || service.isLoading || service.isDownloadSowBtn"
              (click)="firstClick()"
            >
              {{editFlag && backFlag ? "Update" : "Save"}}
            </button>
          </div>
        </div>
      </div>

      <div class="regenarate-main" *ngIf="service.sowContentButtonFlag">
        <button
          [ngClass]="{'regenerateDisabled': service.isLoading || isVerifed ||  service.isDownloadSowBtn || editFlag }"
          [disabled]="isVerifed || service.isLoading || service.isDownloadSowBtn || editFlag"
          (click)="regenrateClick()"
          class="regenerate-btn"
        >
          <img
            [ngClass]="{'regenerateDisabled': service.isLoading}"
            src="../../../../../assets/img/regenarate-icon.svg"
            alt="skip"
          />
          <span
            style="margin-left: 5px; font-weight: 500;"
            [ngClass]="{'regenerateDisabled': service.isLoading || service.isDownloadSowBtn || isVerifed || editFlag}"
            class="mat-btn"
          >
            Regenerate
          </span>
        </button>
      </div>
    </div>
  </div>
</section>