export const environment = {
  production: false,
  env_name: "alpha",
  baseapiurl: "https://dev2cont.apexon-genesys.com/",
  convoBiIndexFileUrl: "https://dev2cont.apexon-genesys.com/bi",
  paginationLimit: 10,

  /** Nest Config */
  nestUri: "https://nest.apexon.com/nativefw/services/",
  nestUsername: "Genysys",
  nestPassword: "Inform$genpro@2525",

  /** Dev User Pool Guest */
  cognitoGuest: {
    // USER POOL CONFIG
    region: "us-east-1",
    userPoolDomain: "",
    userPoolId: "us-east-1_dL6KzOKjE",
    userPoolWebClientId: "1dchl5d20dr7inf22v07h3str6",
    clientSecret: "",
    identityProvider: "",
    identityProviderType: "",
    scope: "openid email profile",
    redirectUri: "",
  },

  /** Dev-2 User Pool with AzureAD */
  cognito: {
    env_name: "dev",
    // USER POOL CONFIG
    region: "us-east-1",
    userPoolDomain:
      "https://genesys-user-pool.auth.us-east-1.amazoncognito.com",
    userPoolId: "us-east-1_yX6Vlc7KK",
    userPoolWebClientId: "5g5rdsptri79c98pvuopsv5dta",
    clientSecret: "1a7u8tlbqhgo625ihqofm512mfm7vuprjkrkh8oi0q3cpft6h4mn",
    identityProvider: "genesys-azure",
    identityProviderType: "SAML",
    scope: "openid email profile",
    redirectUri: "https://dev2.apexon-genesys.com/idpresponse",
    // redirectUri: "http://localhost:4200/idpresponse",
  },

  /** Dev & Alpha-Production User Pool with Client Credentials */
  cognitoClientCred: {
    // USER POOL CONFIG
    region: "us-east-1",
    userPoolDomain:
      "https://genysys-prod-user-pool-client-cred.auth.us-east-1.amazoncognito.com",
    userPoolId: "us-east-1_l84azUgcY",
    userPoolWebClientId: "c8c6vh1od65rcmciqjdpdk1vr",
    clientSecret: "412if7lcps8morrfii554i569a0l8m5etat1gm54jhvmq3h1lpe",
  },

  /** Dev & Alpha-Production Identity Pool */
  identityPool: {
    // IDENTITY POOL CONFIG
    identityPoolId: "us-east-1:9cec9518-40bb-4791-9fd8-fb3abb778f26",
    identityPoolRegion: "us-east-1",
    serviceName: "sagemaker",
    authRequestAlgorithm: "AWS4-HMAC-SHA256",
  },
};
