import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HighlightService } from '../../highlight.service';
import { CodeGenerationServiceService } from 'src/app/module/service/code-generation-service.service';
import * as JSZip from 'jszip';


interface ContentBlock {
  type: 'text' | 'code' | 'inlineCode';
  content: string;
  language?: string;
}

interface FolderNode {
  name: string;
  type: 'file' | 'folder';
  children?: FolderNode[];
  content?: string;
  level: number;
}
@Component({
  selector: 'app-code-composer-form',
  templateUrl: './code-composer-form.component.html',
  styleUrls: ['./code-composer-form.component.scss']
})
export class CodeComposerFormComponent implements OnInit, AfterViewInit {
  @ViewChild('autoResizeTextarea') autoResizeTextarea!: ElementRef;
  contentBlocks: ContentBlock[] = [];
  noInput: boolean = true;
  codeRequestForm !: FormGroup;
  showLoader: boolean = false;
  formattedOutput: string = '';
  orms = [];
  ormOptions: any = {
    'Java': ['Hibernate', 'iBatis'],
    'Java/Spring boot': ['Spring Data JPA'],
    'C#': ['Entity Framework', 'Dapper', 'Ado.net']
  };
  generatedCode: boolean = false;

  frontEndFrameworksAndLibraries = [
    'React', 'Angular', 'Vue.js', 'Svelte', 'Ember.js', 'Backbone.js', 'jQuery'
  ];
  backEndLanguages = [
    'Java', 'Java/Spring boot', 'Python', 'Ruby', 'PHP', 'JavaScript (Node.js)', 'C#'
  ];
  architectures = [
    '2-Tier Architecture',
    'MVC (Model-View-Controller) Architecture'
  ];
  form!: FormGroup;
  highlighted: boolean = false;
  receivedMessage: string = '';
  description: string = '';
  code: string = '';
  language: string = '';
  text: string = '';
  status: string = '';
  message: string = '';
  output: string = '';

  constructor(public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private highlightService: HighlightService,
    private codeGenerationService:CodeGenerationServiceService
  ) {
    this.form = this.formBuilder.group({
      codeReqInput: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.receivedMessage = params['message'];
    })
    this.codeRequestForm = this.formBuilder.group({
      codeReqInput: [this.receivedMessage, Validators.required],
      selectedBackEndLanguage: [''],
      selectedFrontEndLanguage: [''],
      orm: [''],
      selectedArchitecture: [''],
      selectedUML: ['']
    }, { validators: this.atLeastOneLanguageSelected });

  }
  ngAfterViewInit() {

  }


  atLeastOneLanguageSelected(formGroup: FormGroup) {
    const backendLang = formGroup.get('selectedBackEndLanguage')! ?? "";
    const frontendLang = formGroup.get('selectedFrontEndLanguage')! ?? "";

    if (backendLang.value === '' && frontendLang.value === '') {
      return { atLeastOneLanguageRequired: true };
    }

    return null;
  }


  ngAfterViewChecked() {
    if (this.contentBlocks) {
      this.highlightService.highlightAll();
      this.highlighted = true;
    }
  }
  onChanges() {
    this.codeRequestForm.get('selectedBackEndLanguage')!.valueChanges.subscribe(val => {
      this.updateOrmOptions(val);
    });
  }

  updateOrmOptions(selectedLanguage: string) {
    this.orms = this.ormOptions[selectedLanguage] || [];
    this.codeRequestForm.get('orm')!.setValue('');
    this.codeRequestForm.get('orm')?.patchValue('');
    this.codeRequestForm.get('orm')?.updateValueAndValidity();
  }

  onInputChange(e: any) {
    this.noInput = true;
    if (e.target.value) {
      this.noInput = false;
    }
  }
  setSearch() {
    if (this.codeRequestForm.valid) {
      this.showLoader = true;
      const payload = {
        architecture: this.codeRequestForm.get('selectedArchitecture')!.value ?? "",
        frontend: this.codeRequestForm.get('selectedFrontEndLanguage')!.value ?? "",
        backend: this.codeRequestForm.get('selectedBackEndLanguage')!.value ?? "",
        prompt: this.codeRequestForm.get('codeReqInput')!.value ?? "",
        orm: this.codeRequestForm.get('orm')!.value ?? "",
        username: localStorage.getItem("username"),
        token: localStorage.getItem("token"),

        userEmail: localStorage.getItem("userEmail")
      };
     this.codeGenerationService.getResponse(payload).subscribe(
        (response) => {
          this.showLoader = false;
          if (response.status === "success") {
            this.generatedCode = true;
            this.status = response.status;
            this.message = response.message;
            this.processOutput(response.output);
          } else {
            console.error('Error generating code:', response);
          }
        },
        (error) => {
          this.showLoader = false;
          console.error('HTTP error:', error);
        }
      );
    }
  }


  
  processOutput(output: string): void {
    this.contentBlocks = [];
    const lines = output.split('\n');
    let currentBlock: ContentBlock | null = null;
    let isInCodeBlock = false;
  
    for (const line of lines) {
      if (line.startsWith('```')) {
        if (isInCodeBlock) {
          if (currentBlock) {
            this.contentBlocks.push(currentBlock);
          }
          currentBlock = null;
          isInCodeBlock = false;
        } else {
          if (currentBlock) {
            this.contentBlocks.push(currentBlock);
          }
          console.log("contentBlocks",this.contentBlocks)
          const language = line.slice(3).trim().toLowerCase() || 'plaintext';
          currentBlock = { type: 'code', content: '', language };
          isInCodeBlock = true;
        }
      } else {
        if (isInCodeBlock) {
          if (currentBlock) {
            currentBlock.content += line + '\n';
          }
        } else {
          if (line.trim().match(/^\d+\./)) {
            if (currentBlock) {
              this.contentBlocks.push(currentBlock);
            }
            currentBlock = { type: 'text', content: line + '\n' };
          } else {
            if (currentBlock && currentBlock.type === 'text') {
              currentBlock.content += line + '\n';
            } else {
              if (currentBlock) {
                this.contentBlocks.push(currentBlock);
              }
              currentBlock = { type: 'text', content: line + '\n' };
            }
          }
        }
      }
    }
  
    if (currentBlock) {
      this.contentBlocks.push(currentBlock);
    }
    this.contentBlocks = this.contentBlocks
      .map(block => ({ ...block, content: block.content.trim() }))
      .filter(block => block.content !== '');
  
    const folderStructureBlocks = this.contentBlocks.filter(block =>
      block.type === 'text' && (block.content.includes('Project Structure:') || block.content.includes('Folder structure:'))
    );
  
    folderStructureBlocks.forEach((block) => {
      block.type = 'code';
      block.language = 'plaintext';
      block.content = block.content.replace('Project Structure:', '').trim();
    });
  }
  
  
  

  
 createAndDownloadFiles(): void {
    const folderStructureBlocks = this.contentBlocks.filter(block => block.type === 'code' && block.language === 'plaintext');
    const codeBlocks = this.contentBlocks.filter(block => block.type === 'code' && block.language !== 'plaintext');
    const zip = new JSZip();
    const frontendStructure = this.parseFolderStructure(folderStructureBlocks[0]?.content || '', codeBlocks);
    const backendStructure = this.parseFolderStructure(folderStructureBlocks[1]?.content || '', codeBlocks);
    this.createFilesInZip(frontendStructure, zip, 'frontend', codeBlocks, 'frontend');
    this.createFilesInZip(backendStructure, zip, 'backend', codeBlocks, 'backend');
    
    this.downloadZip(zip);
  }


  
  createFilesInZip(structure: FolderNode[], zip: JSZip, currentPath: string, codeBlocks: ContentBlock[], folderType: 'frontend' | 'backend'): void {
    structure.forEach(node => {
      const nodePath = currentPath ? `${currentPath}/${node.name}` : node.name;
  
      if (node.type === 'folder') {
        zip.folder(nodePath);
        if (node.children && node.children.length > 0) {
          this.createFilesInZip(node.children, zip, nodePath, codeBlocks, folderType);
        }
      } else {
        const fileName = node.name.split('/').pop()!;
        const codeBlock = codeBlocks.find(block =>
          folderType === 'frontend' 
            ? fileName.toLowerCase().endsWith(this.getExtension(block.language!).toLowerCase()) 
            : fileName.toLowerCase().endsWith(this.getExtension(block.language!).toLowerCase())
        );
        if (codeBlock) {
          zip.file(nodePath, codeBlock.content);
        } else {
          zip.file(nodePath, '');
        }
      }
    });
  }
  
  

  getExtension(language: string): string {
    switch (language.toLowerCase()) {
      case 'csharp':
      case 'cs':
        return '.cs';
      case 'json':
        return '.json';
      case 'csproj':
        return '.csproj';
      case 'java':
        return '.java';
      case 'properties':
        return '.properties';
      case 'xml':
        return '.xml';
      case 'jsx':
        return '.jsx';
      case 'typescript':
        return '.ts';
      case 'html':
        return '.html';
      case 'css':
        return '.css';
      case 'python':
        return '.python';
      default:
        return '';
    }
  }

  parseFolderStructure(structure: string, codeBlocks: ContentBlock[]): FolderNode[] {
    const lines = structure.split('\n').filter(line => line.trim() !== '');
    const root: FolderNode[] = [];
    const stack: FolderNode[] = [];

    lines.forEach(line => {
      let level: number;
      let name: string;
      if (line.includes('│') || line.includes('├') || line.includes('└')) {
        level = line.search(/[└├│]/);
        name = line.replace(/[│├─└\s]/g, '').trim();
      } else {
        level = line.search(/\S/);
        name = line.trim().replace(/^-\s*/, '');
      }
      name = name.replace(/[/\\]+$/, '');
      const type = name.includes('.') ? 'file' : 'folder';
      const node: FolderNode = { name, type, level, children: type === 'folder' ? [] : undefined };
      if (type === 'file') {
        const codeBlock = codeBlocks.find(block =>
          name.toLowerCase().endsWith(this.getExtension(block.language!).toLowerCase())
        );
        if (codeBlock) {
          node.content = codeBlock.content;
        }
      }
      while (stack.length > 0 && stack[stack.length - 1].level >= level) {
        stack.pop();
      }

      if (stack.length > 0) {
        stack[stack.length - 1].children!.push(node);
      } else {
        root.push(node);
      }

      if (type === 'folder') {
        stack.push(node);
      }
    });

    return root;
  }



  downloadZip(zip: JSZip): void {
    zip.generateAsync({ type: 'blob' }).then(content => {
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(content);
      a.href = url;
      a.download = 'project.zip';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
  
  copyCode(code: string) {
    const textarea = document.createElement('textarea');
    textarea.value = code;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
  adjustTextareaHeight(event: any): void {
    const textarea: HTMLTextAreaElement = event.target;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

}
