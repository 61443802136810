<div class="selection-info">
    <div class="wrapper">
        <span class="title">Type: </span>
        <span>{{selectType}}</span>
    </div>|
    <div *ngIf="service.taskConvertor != 'text_to_image'" class="wrapper">
        <span class="title">Model: </span>
        <span>{{selectedModal}}</span>
    </div>
    <div *ngIf="service.taskConvertor === 'text_to_image'" class="wrapper">
        <span class="title">Model: </span>
        <span>{{selectedModalImage}}</span>
    </div>|
    <div class="wrapper">
        <span class="title">Context: </span>
        <span>{{selectedContex}}</span>
    </div>
</div>
<div class="content" id="content">

    <div role="status" *ngIf="service.showChatLoader || service.alive">
        <app-loader></app-loader>
    </div>

    <ng-container *ngIf="!service.newChat">
        <div class="chat-window chat-window-a chat-box-overflow" *ngIf="service.taskConvertor != 'text_to_image'" #chatWindowRef>
            <div class="conversation" *ngFor="let message of messages; let i=index">
                <div class="message" [ngClass]="{from: message.author == 'bot',to: message.author == 'user'}">

                    <div class="content-dflex">
                        <img *ngIf="message.author == 'bot'" class="chat-img" src="{{ message.msgImg }}" alt="" />
                        <div *ngIf="message.author == 'user'" class="avatar">
                            <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                        </div>

                        <!-- <h2>{{message.base64}}</h2> -->
                        <p class="message-content-text" *ngIf="message.author == 'user'">
                            {{ message.userResponse }}
                        </p>

                        <!-- style="margin-left:8%; margin-top: -6%;" -->
                        <div class="message-content" *ngIf="service.selectedModel === 'llama3'">
                            <p *ngFor="let msg of message.content">{{ msg }}</p><br />
                            <div *ngIf="message.author == 'bot'" class="icon-btn">
                                <img src='../../../../../assets/icons/ic-copy.svg' class="addCursor" alt='Copy' title="Copy"
                                    (click)="copyText(message,i)" *ngIf="!message.showCheck" />
                                <img src="../../../../../assets/icons/ic_check_dark.svg" alt='Check'
                                    *ngIf="message.showCheck" />
                                <img src='../../../../../assets/icons/ic-like.svg' class="addCursor" alt='Like' title="Like"
                                    [ngClass]="likeBtnClicked" (click)='like_response($event, message.chatId)' />
                                <img src='../../../../../assets/icons/ic-dislike.svg' class="addCursor" alt='Dislike'
                                    title="Dislike" [ngClass]="unlikeBtnClicked"
                                    (click)='unlike_response($event, message.chatId)' />
                                <input type='hidden' [(ngModel)]="message.chatId" />
                            </div>
                        </div>

                        <!-- style="margin-left: 8%; margin-top:-6%;" -->
                        <div class="message-content" *ngIf="service.selectedModel !== 'llama3'">
                            <p style="white-space:pre-wrap">{{ message.content }}</p><br />
                            <div *ngIf="message.author == 'bot'" class="icon-btn">
                                <img src='../../../../../assets/icons/ic-copy.svg' class="addCursor" alt='Copy' title="Copy"
                                    (click)="copyText(message,i)" *ngIf="!message.showCheck" />
                                <img src="../../../../../assets/icons/ic_check_dark.svg" alt='Check'
                                    *ngIf="message.showCheck" />
                                <img src='../../../../../assets/icons/ic-like.svg' class="addCursor" alt='Like' title="Like"
                                    [ngClass]="likeBtnClicked" (click)='like_response($event, message.chatId)' />
                                <img src='../../../../../assets/icons/ic-dislike.svg' class="addCursor" alt='Dislike'
                                    title="Dislike" [ngClass]="unlikeBtnClicked"
                                    (click)='unlike_response($event, message.chatId)' />
                                <input type='hidden' [(ngModel)]="message.chatId" />
                            </div>
                        </div>
                    </div>

                    <!-- <div *ngIf="message.author == 'bot'" class="icon-btn">
                        <img src='../../../../../assets/icons/ic-copy.svg' class="addCursor" alt='Copy' title="Copy"
                            (click)="copyText(message,i)" *ngIf="!message.showCheck" />
                        <img src="../../../../../assets/icons/ic_check_dark.svg" alt='Check'
                            *ngIf="message.showCheck" />
                        <img src='../../../../../assets/icons/ic-like.svg' class="addCursor" alt='Like' title="Like"
                            [ngClass]="likeBtnClicked" (click)='like_response($event, message.chatId)' />
                        <img src='../../../../../assets/icons/ic-dislike.svg' class="addCursor" alt='Dislike'
                            title="Dislike" [ngClass]="unlikeBtnClicked"
                            (click)='unlike_response($event, message.chatId)' />
                        <input type='hidden' [(ngModel)]="message.chatId" />
                    </div> -->
                </div>
            </div>
        </div>

        <div class="chat-window chat-window-b chat-box-overflow" *ngIf="service.taskConvertor == 'text_to_image'" #chatWindowRef>
            <div class="conversation" *ngFor="let message of messages;let i=index;">
                <div class="message" [ngClass]="{from: message.author == 'bot',to: message.author == 'user'}">

                    <div *ngIf="message.author == 'user'">
                        <span class="no-profile-pic" ng-bind="usernameInitials">{{usernameInitials}}</span>
                    </div>

                    <!-- style="width: 100%;height: 60px;" -->
                    <div *ngIf="message.author == 'bot'" style="width: 100%;margin-bottom: 1rem;">
                        <div style="float: left;">
                            <img class="chat-img" src="{{ message.msgImg }}" alt="" />
                        </div>
                        <div class="icon-btn" style="float: right;">
                            <img (click)="download(message.base64)" src='../../../../../assets/icons/ic_download.svg'
                                class="addCursor" alt='Download' title="Download" />
                            <img src='../../../../../assets/icons/ic-like.svg' class="addCursor"
                                [ngClass]="likeBtnClicked" (click)='like_response($event, message.chatId)' alt='Like'
                                title="Like" />
                            <img src='../../../../../assets/icons/ic-dislike.svg' class="addCursor"
                                [ngClass]="unlikeBtnClicked" (click)='unlike_response($event, message.chatId)'
                                alt='Unlike' title="Dislike" />
                        </div>
                    </div>

                    <div>
                        <!-- <h2>{{message.base64}}</h2> -->
                        <p class="message-content-text">{{ message.userResponse }}</p>
                        <img *ngIf="message.base64!=''" class="imag_dis" [src]="message.base64" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <div *ngIf="service.newChat">
        <!-- hide when message appears -->
        <!-- <div class="dummy-wrapper"></div> -->

        <!-- hide when message appears -->
        <div class="center-wrapper default-template">
            <img src="../../../../../assets/new-logo/new-logo.png" style="width: 50px;" alt="logo" />
            <div class="landing-info" *ngIf="!service.alive">
                <h3 class="lite">Elevate your content with AI-Driven</h3>
                <h3 *ngIf="service.selectedTask == 'text_to_text'">Text generation</h3>
                <h3 *ngIf="service.selectedTask == 'text_to_image'">Image generation</h3>
            </div>
            <div class="landing-info" *ngIf="service.alive">
                <h5>System is getting configured.</h5>
                <h5>It will take few minutes... </h5>
                <!-- <br />
                <h5>To avoid delay use Model Portfolio page</h5>
                <h5>to get Model available.</h5> -->
            </div>
        </div>
    </div>


    <!-- Stop generation and regeneration button -->

    <div class="regenerate">
        <button class="primary-color" class="stop-generate" *ngIf="service.stopGeneratorFlag"
            (click)='stopGeneratorPrompt()'>
            Stop Generating
        </button>

        <button class="primary-color" *ngIf="service.regenerateFlag" (click)='regenerateMessage()'>
            <img width="19.84px" height="18px" src="../../../../../assets/icons/regenerate-icon.svg"
                alt="regenerateIcon " />
            Regenerate
        </button>
    </div>
    <div class="footer-wrapper-container">

        <div class="footer-wrapper" style="padding: 0px 20px 20px 20px">

            <!-- hide when message appears -->
            <div class="notifications-wrapper" *ngIf="service.newChat && service.newInstance">
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
                <div class="notification" style="visibility: hidden;">
                    Prompt examples goes here for text generation
                </div>
            </div>
            <div class="status-loader" *ngIf="service.showChat">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>

            <!-- <div class="msg-box"> -->
            <!-- <input type="text" placeholder="Type here..." [(ngModel)]="userMessage" (input)="resizeTextarea($event)"
                (keydown.enter)="!flag && sendMessage()" [disabled]="service.showChat" #myinput> -->
            <div class="msg-box" [ngClass]="{'fixed-message-bar': !service.newChat}">
                <!-- <input type="text" placeholder="Type here..." [(ngModel)]="userMessage" (input)="resizeTextarea($event)"
                    (keydown.enter)="!flag && sendMessage()"
                    [disabled]="service.showChat || service.alive || !service.newInstance" #myinput> -->
                <!-- <input type="text" [placeholder]="placeholder" [(ngModel)]="userMessage" (input)="resizeTextarea($event)"
                    (keydown.enter)="!flag && sendMessage()"
                    [disabled]="service.showChat || service.alive || !service.newInstance"
                    style="background-color: #fff !important;" #myinput> -->
                <textarea
                    style="background-color: #fff !important;margin-left: 15px;"
                    rows="1"
                    [placeholder]="placeholder"
                    [(ngModel)]="userMessage"
                    (input)="resizeTextarea($event)"
                    (keydown.enter)="!flag && sendMessage()"
                    [disabled]="service.showChat || service.alive || !service.newInstance"
                    #myinput
                ></textarea>
                <img *ngIf='!userMessage' src="../../../../../assets/icons/icon-send-grey.svg" alt="send" />
                <img *ngIf='userMessage' class="addCursor" src="../../../../../assets/icons/icon-send.svg" alt="send"
                    (click)="!flag && sendMessage()" />
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>