import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormServiceService } from "../../../service/form-service.service";
import { Clipboard } from "@angular/cdk/clipboard";
import { DomSanitizer } from "@angular/platform-browser";

import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { LikeDislikeDialogComponent } from "../mat-dialog/like-dislike-dialog/like-dislike-dialog.component";
import { OpenAiDialogComponent } from "../mat-dialog/open-ai-dialog/open-ai-dialog.component";

export class Message {
  constructor(
    public author: string,
    public content: string,
    public msgImg: string,
    public showCheck: boolean,
    public base64: string,
    public userResponse: string,
    public chatId: string
  ) {
    author = "";
    userResponse = "";
    content = "";
    msgImg = "";
    base64 = "";
    showCheck = false;
    chatId = "";
  }
}
@Component({
  selector: "app-form-data",
  templateUrl: "./form-data.component.html",
  // styleUrls: ["./form-data.component.scss"],
  styleUrls: ["../home-page-chat.component.scss"],
})
export class FormDataComponent implements OnInit {
  message: string = ""; // Make sure this is defined correctly

  resizeTextarea(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  @ViewChild("myIframe") myIframe!: ElementRef;
  // @ViewChild("scrollMe") private myScrollContainer: any;
  @ViewChild("myinput") myInputField!: ElementRef;
  // @ViewChild("content") private contentContainer!: ElementRef;
  @ViewChild("chatWindowRef") chatWidowRef!: ElementRef;

  myText: string = "Hello World!";
  showDasboard: boolean = false;
  llmFormData!: FormGroup;
  submittedthree: boolean = false;
  showModel: boolean = false;
  loanStatus: string = "";
  loanRule: string = "";
  value: string = "";
  formDetails: any = [];
  formDetailData: any;
  application_id: string = "";
  BusinessLegalName: string = "";
  BusinessLoanPurpose: string = "";
  BusinessSize: string = "";
  CustomerAge: string = "";
  CustomerName: string = "";
  capital_required: string = "";
  collateral_available: string = "";
  credit_score: string = "";
  existing_loan_amount: string = "";
  growth_rate: string = "";
  loan_to_investment_ratio: string = "";
  previous_business_experience: string = "";
  loanExpain: string = "";
  AverageActivity: string = "";
  College: string = "";
  HighestEducationQualification: string = "";
  IpAvailable: string = "";
  showCheck: boolean = false;
  IpCount: string = "";
  IpExpiry: string = "";
  NumberOfLinkedinfollowers: string = "";
  YoutubeSubscribers: string = "";
  socialQuotient: string = "";
  questions: any = [""];
  selectedQuestion: string = "";
  messages: Message[] = [];
  messageImg: any;
  messagebot: string = "user";
  userMessage: any;
  previousMessage: any;
  content: string = "";
  postedContent: any = [];
  contentRef!: ElementRef;
  contentHeight: any;
  palyMusic: boolean = false;
  clickEventSub!: Subscription;
  clickEventTabChangeSub!: Subscription;
  textArea: string = "";
  imagePath: any;
  username: any;
  usernameDisplay: any;
  flag: boolean = false;
  // stopGeneratorFlag: boolean = false;
  // regenerateFlag: boolean = false;
  botMessage: any = [];
  usernameInitials: any;
  likeBtnClicked: string = "";
  unlikeBtnClicked: string = "";
  token: any;
  userEmail: any;
  selectedModal: any = "-";
  selectedModalImage: any = "-";
  selectType: any = "-";
  selectedContex: any = "-";
  placeholder: any = 'Click on "+ New Chat" to initiate...';

  constructor(
    private formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private clipboard: Clipboard,
    private renderer: Renderer2,
    public service: FormServiceService,
    public dialog: MatDialog
  ) {
    this.token = localStorage.getItem("token");
    this.userEmail = localStorage.getItem("userEmail");

    this.clickEventSub = this.service.getChatMsg().subscribe((async) => {
      this.service.showChatLoader = false;
      this.service.stopGeneratorFlag = false;
      this.service.regenerateFlag = false;
      this.textArea = "New Chat Session Started";
      this.messages = [];
    });
    this.clickEventTabChangeSub = this.service
      .getTabChangeMsg()
      .subscribe((async) => {
        this.service.stopGeneratorFlag = false;
        this.service.regenerateFlag = false;
        this.textArea = "";
        this.messages = [];
      });

    this.username = localStorage.getItem("username");
    this.usernameDisplay = localStorage.getItem("usernameDisplay");
    this.usernameInitials = localStorage.getItem("usernameInitials");
  }

  ngOnInit() {
    this.username = localStorage.getItem("username");
    this.service.chatTriggered.subscribe(() => {
      this.placeholder = "Type here...";
      this.selectedModal = this.service.selectedModel;
      this.selectedModalImage = this.service.selectedModelImage;
      this.selectType = this.service.selectedType;
      this.selectedContex = this.service.isPersonaSelected;
      this.setAutofocusOnChatBox();
      this.myInputField.nativeElement.disabled = false;
    });

    this.service.methodCalled$.subscribe(() => {
      this.myInputField.nativeElement.disabled = true;
    });

    /** Calls when modals change from sidebar */
    this.service.inputdisabler$.subscribe(() => {
      this.myInputField.nativeElement.value = "";
      this.myInputField.nativeElement.disabled = true;
      this.placeholder = 'Click on "+ New Chat" to initiate...';
      this.userMessage = "";
    });
  }

  ngAfterViewInit() {
    this.setAutofocusOnChatBox();
    this.myInputField.nativeElement.disabled = true;
  }

  selectQuestion(question: string) {
    this.selectedQuestion = question;
  }

  regenerateMessage() {
    this.service.regenerateFlag = false;
    this.userMessage = localStorage.getItem("userMessage");
    this.sendMessage();
  }

  manualRegenerate() {
    this.messages.pop();
    console.log("Manual regenaration start");
    this.regenerateMessage();
  }

  /* on click of enter or send button */
  sendMessage() {
    if (this.userMessage != "") {
      this.userMessage = this.userMessage.replace(/\\/g, "");
      this.service.newChat = false;
      this.service.showChat = true;
      let instance_id = this.service.instance_id;
      localStorage.setItem("userMessage", this.userMessage);

      if (this.userMessage.trim() === "") {
        return;
      }

      this.messageImg = "../../../../../assets/img/profile-pic.png";
      let msg = {
        userResponse: this.userMessage,
        content: "",
        author: "user",
        base64: "",
        msgImg: this.messageImg,
        showCheck: false,
        chatId: "",
      };
      this.messages.push(msg);
      this.messagebot = "user";
      this.showDasboard = true;
      let rule = {
        instance_id: instance_id,
        prompt: this.userMessage,
        username: this.username,
        token: localStorage.getItem("token"),
        userEmail: this.userEmail,
      };

      this.scrollToBottom();
      if (this.service.taskConvertor != "text_to_image") {
        if (
          this.service.selectedModel === "llama3"
          // || this.service.selectedModel === "MosaicML"
        ) {
          let botMessage1: string = "";
          let currentIndex = this.messages.length;
          let counter = 0;
          this.service.chatStream(JSON.stringify(rule)).subscribe({
            next: (text) => {
              if (text) {
                counter++;
              }
              if (text == "chat_id:" && counter === 1) {
                console.log(
                  `No API response counter:${counter}, call sendMessage method again`
                );
                this.manualRegenerate();
              }
              /** Here we get data as well as chat ID, further we are splitting the both */
              const data = text.split("chat_id:");
              text = data[0];
              let chat_id = data[1];

              /** storing the chat id in array uniquely */
              // if( chat_id && !this.chatIdArray.includes( chat_id ) ) {
              //   this.chatIdArray.push( chat_id );
              // }

              //text = text.replace(/ /g,'\n')
              this.flag = true;
              this.service.stopGeneratorFlag = true;
              this.service.regenerateFlag = false;
              botMessage1 += text;
              //let botMessage1='welcome\n\n to apexon'
              this.botMessage = botMessage1.split("\\n");
              this.messageImg =
                "../../../../../assets/icons/chat-window-icon.svg";
              let msg = {
                userResponse: "",
                content: this.botMessage,
                author: "bot",
                base64: "",
                msgImg: this.messageImg,
                showCheck: false,
                chatId: chat_id,
              };
              this.service.showChat = false;
              if (currentIndex >= 1) {
                this.messages[currentIndex] = msg;
              }
              this.scrollToBottom();
              // this.chatWidowRef.nativeElement.scrollTo(
              //   0,
              //   this.chatWidowRef.nativeElement.scrollHeight
              // );
            },
            complete: () => {
              this.flag = false;
              this.service.stopGeneratorFlag = false;
              this.service.regenerateFlag = true;
              this.previousMessage = this.userMessage;
            },
            error: () => {
              this.service.stopGeneratorFlag = false;
            },
          });
        } else if (
          this.service.selectedModel === "Claude-3.5" ||
          this.service.selectedModel === "Mistral-7B"
        ) {
          let botMessage1: string = "";
          let currentIndex = this.messages.length;
          let counter = 0;
          this.service.chatStreamForClaude(JSON.stringify(rule)).subscribe({
            next: (text) => {
              if (text) {
                counter++;
              }
              if (text == "chat_id:" && counter === 1) {
                console.log(
                  `No API response counter:${counter}, call sendMessage method again`
                );
                this.manualRegenerate();
              }
              /** Here we get data as well as chat ID, further we are splitting the both */
              const data = text.split("chat_id:");
              text = data[0];
              let chat_id = data[1];

              /** storing the chat id in array uniquely */
              // if( chat_id && !this.chatIdArray.includes( chat_id ) ) {
              //   this.chatIdArray.push( chat_id );
              // }

              //text = text.replace(/ /g,'\n')
              this.flag = true;
              this.service.stopGeneratorFlag = true;
              this.service.regenerateFlag = false;
              botMessage1 += text;
              //let botMessage1='welcome\n\n to apexon'
              this.botMessage = botMessage1.split("\\n");
              this.messageImg =
                "../../../../../assets/icons/chat-window-icon.svg";
              let msg = {
                userResponse: "",
                content: this.botMessage,
                author: "bot",
                base64: "",
                msgImg: this.messageImg,
                showCheck: false,
                chatId: chat_id,
              };
              this.service.showChat = false;
              if (currentIndex >= 1) {
                this.messages[currentIndex] = msg;
              }
              this.scrollToBottom();
              // this.chatWidowRef.nativeElement.scrollTo(
              //   0,
              //   this.chatWidowRef.nativeElement.scrollHeight
              // );
            },
            complete: () => {
              this.flag = false;
              this.service.stopGeneratorFlag = false;
              this.service.regenerateFlag = true;
              this.previousMessage = this.userMessage;
            },
            error: () => {
              this.service.stopGeneratorFlag = false;
            },
          });
        } else {
          this.flag = true;
          this.service.stopGeneratorFlag = false;
          this.service.regenerateFlag = false;

          try {
            setTimeout(() => {
              this.chatWidowRef.nativeElement.scrollTo(
                0,
                this.chatWidowRef.nativeElement.scrollHeight
              );
            }, 2000);
          } catch (err) {
            console.log("element not found");
          }

          this.service.postOpenAiInfo(rule).subscribe(
            (res) => {
              if (res.status == "success") {
                this.flag = false;
                this.service.regenerateFlag = true;
                this.service.showChat = false;
                this.loanExpain = res.output;
                this.questions.splice(0);
                this.messageImg =
                  "../../../../../assets/icons/chat-window-icon.svg";
                let msg = {
                  userResponse: "",
                  content: this.loanExpain,
                  author: "bot",
                  base64: "",
                  msgImg: this.messageImg,
                  showCheck: false,
                  chatId: res.chat_id,
                };

                if (this.messages.length >= 1) {
                  this.messages.push(msg);
                }

                try {
                  setTimeout(() => {
                    this.chatWidowRef.nativeElement.scrollTo(
                      0,
                      this.chatWidowRef.nativeElement.scrollHeight
                    );
                  }, 2000);
                } catch (err) {
                  console.log("element not found");
                }
              } else {
                this.flag = false;
                this.service.showChat = false;
                alert(res.message);
              }
            },
            (error) => {
              this.flag = false;
              this.service.showChat = false;
              if (this.service.selectedType === "open_ai") {
                const dialogRef = this.dialog.open(OpenAiDialogComponent, {
                  disableClose: true,
                  width: "40%",
                  data: {
                    content:
                      "Your organization's tier usage limit has been reached for OpenAI consumption. Please use the Open Source models and click on new chat button to initiate a new session.",
                  },
                  panelClass: "openAI-dialog-container",
                });

                dialogRef.afterClosed().subscribe((result) => {
                  if (result === "closed") {
                    this.service.sendTypeChange("done");
                    this.service.selectedType = "open_source";
                  }
                });
              } else {
                alert(error.error.message);
              }
            }
          );
        }
        this.userMessage = "";
      } else {
        // let imagePrompt = this.userMessage;
        this.scrollToBottom();
        this.flag = true;

        // bedrock hosted model call
        rule.prompt = rule.prompt.toLowerCase();
        this.service.getTextToImageTitan(rule).subscribe(
          (res) => {
            // this.flag = true
            this.chatWidowRef.nativeElement.scrollTo(
              0,
              this.chatWidowRef.nativeElement.scrollHeight
            );
            if (res.status == "success") {
              this.flag = false;
              this.service.showChat = false;
              this.service.regenerateFlag = true;
              this.loanExpain = "data:image/png;base64," + res.output;
              this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(
                this.loanExpain
              );
              this.questions.splice(0);
              this.messageImg =
                "../../../../../assets/icons/chat-window-icon.svg";
              let msg = {
                userResponse: "",
                content: "",
                author: "bot",
                base64: this.loanExpain,
                msgImg: this.messageImg,
                showCheck: false,
                chatId: res.Chat_id,
              };

              if (this.messages.length >= 1) {
                this.messages.push(msg);
              }

              try {
                setTimeout(() => {
                  this.chatWidowRef.nativeElement.scrollTo(
                    0,
                    this.chatWidowRef.nativeElement.scrollHeight
                  );
                }, 2000);
              } catch (err) {
                console.log("element not found");
              }
            } else {
              this.flag = false;
              this.service.showChat = false;
              alert(res.message);
            }
          },
          (error) => {
            this.flag = false;
            this.service.showChat = false;
            alert(error.error.message);
          }
        );
        this.userMessage = "";

        // sagemaker hosted model call
        // const condaPayload = {
        //   inputs: [
        //     {
        //       name: "TEXT",
        //       shape: [1],
        //       datatype: "BYTES",
        //       data: [this.userMessage],
        //     },
        //   ],
        // };

        // this.service
        //   .getSageMakerTextToImageResponse(
        //     this.service.selectedTask,
        //     "setup_conda.tar.gz",
        //     JSON.stringify(condaPayload)
        //   )
        //   .subscribe(
        //     (res: any) => {
        //       // console.log(res);

        //       setTimeout(() => {
        //         if (res.outputs) {
        //           const inputs = {
        //             prompt: imagePrompt,
        //             negative_prompt: "blur, signature, low detail, low quality",
        //             gen_args: JSON.stringify({
        //               num_inference_steps: 50,
        //               guidance_scale: 8,
        //             }),
        //           };
        //           const payload = {
        //             inputs: Object.entries(inputs).map(([name, data]) => ({
        //               name,
        //               shape: [1, 1],
        //               datatype: "BYTES",
        //               data: [data],
        //             })),
        //           };

        //           let model = "sd_base.tar.gz";
        //           if (this.service.selectedModelImage === "openJourney") {
        //             model = "oj4.tar.gz";
        //           }

        //           this.service
        //             .getSageMakerTextToImageResponse(
        //               this.service.selectedTask,
        //               model,
        //               JSON.stringify(payload)
        //             )
        //             .subscribe(
        //               (res: any) => {
        //                 // console.log(res);

        //                 if (res.outputs) {
        //                   // this.chatWidowRef.nativeElement.scrollTo(
        //                   //   0,
        //                   //   this.chatWidowRef.nativeElement.scrollHeight
        //                   // );

        //                   this.flag = false;
        //                   this.service.showChat = false;
        //                   this.regenerateFlag = true;
        //                   this.loanExpain =
        //                     "data:image/png;base64," + res.outputs[0].data[0];
        //                   this.imagePath =
        //                     this._sanitizer.bypassSecurityTrustResourceUrl(
        //                       this.loanExpain
        //                     );
        //                   this.questions.splice(0);
        //                   this.messageImg =
        //                     "../../../../../assets/icons/chat-window-icon.svg";
        //                   let msg = {
        //                     userResponse: "",
        //                     content: "",
        //                     author: "bot",
        //                     base64: this.loanExpain,
        //                     msgImg: this.messageImg,
        //                     showCheck: false,
        //                     chatId: "",
        //                   };
        //                   if (this.messages.length >= 1) {
        //                     this.messages.push(msg);
        //                   }

        //                   this.scrollToBottom();
        //                 } else {
        //                   this.flag = false;
        //                   this.service.showChat = false;
        //                   alert(res.OriginalMessage);
        //                 }
        //               },
        //               (error: any) => {
        //                 this.flag = false;
        //                 this.service.showChat = false;
        //                 alert(error.error.message);
        //               }
        //             );
        //         } else {
        //           this.flag = false;
        //           this.service.showChat = false;
        //           alert(res.OriginalMessage);
        //         }
        //       }, 5000);
        //     },
        //     (error: any) => {
        //       this.flag = false;
        //       this.service.showChat = false;
        //       alert(error.error.message);
        //     }
        //   );
        // this.userMessage = "";

        // separate machine hosted models
        // this.service.postOpenapiImage(rule).subscribe(
        //   (res) => {
        //     // this.flag = true
        //     this.chatWidowRef.nativeElement.scrollTo(
        //       0,
        //       this.chatWidowRef.nativeElement.scrollHeight
        //     );
        //     if (res.status == "success") {
        //       this.flag = false;
        //       this.service.showChat = false;
        //       this.regenerateFlag = true;
        //       this.loanExpain = "data:image/png;base64," + res.output;
        //       this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(
        //         this.loanExpain
        //       );
        //       this.questions.splice(0);
        //       this.messageImg =
        //         "../../../../../assets/icons/chat-window-icon.svg";
        //       let msg = {
        //         userResponse: "",
        //         content: "",
        //         author: "bot",
        //         base64: this.loanExpain,
        //         msgImg: this.messageImg,
        //         showCheck: false,
        //         chatId: res.Chat_id,
        //       };

        //       if (this.messages.length >= 1) {
        //         this.messages.push(msg);
        //       }

        //       try {
        //         setTimeout(() => {
        //           this.chatWidowRef.nativeElement.scrollTo(
        //             0,
        //             this.chatWidowRef.nativeElement.scrollHeight
        //           );
        //         }, 2000);
        //       } catch (err) {
        //         console.log("element not found");
        //       }
        //     } else {
        //       this.flag = false;
        //       this.service.showChat = false;
        //       alert(res.message);
        //     }
        //   },
        //   (error) => {
        //     this.flag = false;
        //     this.service.showChat = false;
        //     alert(error.error.message);
        //   }
        // );
        // this.userMessage = "";

        // this.myInputField.nativeElement.scrollIntoView({
        //   behavior: "smooth",
        //   block: "end",
        // });
        // this.scrollToBottom();
        // this.contentContainer.nativeElement.scroll({
        //   top: this.contentContainer.nativeElement.scrollHeight,
        //   left: 0,
        //   behavior: "smooth",
        // });
        // this.service.triggerChat();
        // this.myInputField.nativeElement.focus();
      }
    }
  }

  download(image: any) {
    const downloadLink = document.createElement("a");
    const fileName = "sample.jpg";

    downloadLink.href = image;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  /*  to scroll the chat below */
  scrollToBottom() {
    // setTimeout(() => {
    //   try {
    //     this.myScrollContainer.nativeElement.scrollToBottom =
    //       this.myScrollContainer.nativeElement.scrollHeight + 500;
    //   } catch (err) {}
    // }, 150);
    try {
      setTimeout(() => {
        this.chatWidowRef.nativeElement.scrollTo(
          0,
          this.chatWidowRef.nativeElement.scrollHeight
        );
      }, 2000);
    } catch (err) {}
  }

  /* to copy the text */
  copyText(event: any, i: number) {
    if (this.service.regenerateFlag) {
      this.clipboard.copy(event.content);
      this.messages[i].showCheck = true;
      // console.log( this.messages[i] );
      // let msg = {
      //   userResponse: event.userResponse,
      //   content: event.content,
      //   author: event.author,
      //   base64: event.base64,
      //   msgImg: event.msgImg,
      //   showCheck: false,
      //   chatId: "",
      // };
      // this.messages[i] = msg;
      setTimeout(() => {
        this.messages[i].showCheck = false;
      }, 2500);
    }
  }

  save() {
    let instance_id = this.service.instance_id;
    this.showDasboard = true;
    let rule = {
      instance_id: instance_id,
      prompt: this.loanRule,
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };
    this.service.postOpenapiImage(rule).subscribe(
      (res) => {
        if (res.status == "success") {
          this.loanExpain = JSON.stringify(res.txt);
        } else {
          alert(res.message);
        }
      },
      (error) => {
        alert(error.error.message);
      }
    );
  }

  reset() {
    window.location.reload();
  }

  stopGeneratorPrompt() {
    let data = {
      username: this.username,
      token: localStorage.getItem("token"),
      userEmail: this.userEmail,
    };

    this.service.stopGenerator(data).subscribe(
      (res) => {},
      (error) => {
        console.log(error);
      }
    );
    // Call service one more time to make it work
    setTimeout(() => {
      this.service.stopGenerator(data).subscribe(
        (res) => {},
        (error) => {
          console.log(error);
        }
      );
    }, 100);
  }

  like_response(event: any, cid: string) {
    if (this.service.regenerateFlag) {
      event.target.classList.add("likeBtnClicked");
      event.target.nextElementSibling.style.pointerEvents = "none";
      const dialogRef = this.dialog.open(LikeDislikeDialogComponent, {
        width: "50%",
        data: { type: "Like", chat_id: cid },
      });
      dialogRef.afterClosed().subscribe((result) => {
        switch (result) {
          case "success":
            event.target.style.pointerEvents = "none";
            break;
          case "error":
            console.log("unable to submit feedback");
            break;
          default:
            break;
        }
      });
    }
  }

  unlike_response(event: any, cid: string) {
    if (this.service.regenerateFlag) {
      event.target.classList.add("likeBtnClicked");
      event.target.previousElementSibling.style.pointerEvents = "none";
      const dialogRef = this.dialog.open(LikeDislikeDialogComponent, {
        width: "50%",
        data: { type: "Dislike", chat_id: cid },
      });
      dialogRef.afterClosed().subscribe((result) => {
        switch (result) {
          case "success":
            event.target.style.pointerEvents = "none";
            break;
          case "error":
            console.log("unable to submit feedback");
            break;
          default:
            break;
        }
      });
    }
  }

  setAutofocusOnChatBox() {
    // console.log(this.service.alive);
    this.myInputField.nativeElement.value = "";
    setTimeout(() => {
      this.myInputField.nativeElement.focus();
    }, 1000);
    this.service.regenerateFlag = false;
    this.service.stopGeneratorFlag = false;
  }
}
