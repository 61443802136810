import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AdminSettingsPageRoutingModule } from "./admin-settings-page-routing.module";
import { AdminSettingsPageComponent } from "./admin-settings-page.component";
import { ModelPortfolioFormDataComponent } from "./model-portfolio-form-data/model-portfolio-form-data.component";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from "@angular/material/core";
import { MatRadioModule } from "@angular/material/radio";
import { LoaderModule } from "../../loader/loader.module";
import { FeedbackComponent } from "./feedback/feedback.component";
import { CostTableComponent } from "./cost-table/cost-table.component";
import { CostDetailsComponent } from "./cost-table/cost-details/cost-details.component";
import { CostDetailsUserComponent } from "./cost-table/cost-details-user/cost-details-user.component";
import { AccessListComponent } from "./access-list/access-list.component";
import { AnalyticsComponent } from "./analytics/analytics.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { UpdateGroup } from "./component/update-group/update-group";
import { HighchartsChartModule } from "highcharts-angular";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  declarations: [
    AdminSettingsPageComponent,
    ModelPortfolioFormDataComponent,
    FeedbackComponent,
    CostTableComponent,
    CostDetailsComponent,
    CostDetailsUserComponent,
    AccessListComponent,
    AnalyticsComponent,
    UpdateGroup,
  ],
  imports: [
    CommonModule,
    AdminSettingsPageRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    ClipboardModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    LoaderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HighchartsChartModule,
    MatTabsModule,
    MatCheckboxModule,
  ],
  exports: [ModelPortfolioFormDataComponent],
})
export class AdminSettingsPageModule {}
