import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AdminSettingsServiceService } from "src/app/module/service/admin-settings-service.service";
import { DashboardServiceService } from "./../../../service/dashboard-service.service";
import { utils, writeFile } from "xlsx";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  feedbacks: any;
  feedbackCount: any;
  pages: any;
  limit: any;
  Arr = Array;
  showLoader: boolean;
  currentPage: number = 1;
  paginationRange: number[] = [];

  constructor(
    public route: Router,
    public service: AdminSettingsServiceService,
    public dashboardService: DashboardServiceService,
    public dialog: MatDialog,
  ) {
    this.feedbackCount = 0;
    this.feedbacks = [];
    this.pages = 0;
    this.limit = 10;
    this.showLoader = true;
  }

  ngOnInit(): void {
    this.showFeedback();
    this.calculatePaginationRange();
  }

  selectDownloadType(event: any) {
    /** common logic to create data file */
    const headings = [
      [
        "Chat ID",
        "Question",
        "Answer",
        "Instance ID",
        "Model name",
        "Model type",
        "Feedback",
        "Feedback comment",
        "Date",
        "User",
      ],
    ];
    const wb = utils.book_new();
    const ws: any = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, this.feedbacks, {
      origin: "A2",
      skipHeader: true,
    });
    utils.book_append_sheet(wb, ws, "Report");

    /** here we can define that how to export it based on condition */
    if (event.target.value === "csv") {
      writeFile(wb, "Feedback Reports.csv");
    } else if (event.target.value === "excel") {
      writeFile(wb, "Feedback Reports.xlsx");
    }
  }

  showFeedback(page?: any) {
    const data = {
      filters: {
        model_type: "",
        model_name: "",
        feedback: "",
        start_date: "",
        end_date: "",
      },
      page: page,
      token: localStorage.getItem("token"),
      userEmail: localStorage.getItem("userEmail"),
      username: localStorage.getItem("username"),
    };
    this.service.getFeedbackData(data).subscribe(
      (res) => {
        // console.log("res", res);
        this.feedbackCount = res["Total Count of Data"];
        if (this.feedbackCount > this.limit) {
          this.pages = Math.ceil(this.feedbackCount / this.limit);
        }
        if (res.status === "success") {
          this.feedbacks = res.DATA;
        }
        this.showLoader = false;
        this.calculatePaginationRange();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getPageData(page: any) {
    this.currentPage = page;
    this.feedbacks = [];
    if (page == 1) {
      this.showFeedback();
    } else {
      this.showFeedback(page);
    }
  }

  calculatePaginationRange() {
    const totalPages = this.pages;
    const currentPage = this.currentPage;

    let currentRangeStart = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const currentRangeEnd = Math.min(currentRangeStart + 9, totalPages);

    if (currentPage > currentRangeEnd) {
      currentRangeStart += 10;
    }

    const newEnd = Math.min(currentRangeStart + 9, totalPages);

    this.paginationRange = Array.from(
      { length: newEnd - currentRangeStart + 1 },
      (_, i) => currentRangeStart + i
    );
  }

  changeDateFormat(dateString: string) {
    return new Date(dateString).toLocaleDateString();
  }

  navigateBack() {
    this.dashboardService.activeMenu = "Admin Settings";
    this.route.navigate(["/dashboard"]);
  }

  //data.substring(0, length);
  trimFeedback( data: string ) {
    const limit = 60;
    if( data.length > limit ) {
      return data.substring(0, limit).trim() + ' ...';
    } else {
      return data;
    }
  }

  showAnswerInModal( answer: string ) {
    const dialogRef = this.dialog.open(ResponseModal, {
      width: "100%",
      data: answer,
    });
  }

}

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback-answer.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class ResponseModal {

  modalContent: string = '';

  constructor(
    public dialogRef: MatDialogRef<ResponseModal>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.modalContent = this.data;
  }

  close() {
    this.dialogRef.close();
  }

}
